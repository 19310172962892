import { useMemo } from 'react';
import ActionDialog from 'components/ActionDialog.component';
import NeoMultiSelect from 'design/design_components/neo/form/NeoMultiSelect.base';
import useComponent from 'hooks/useComponent.hook';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import { Divider } from 'primereact/divider';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoSelectButton from 'design/design_components/neo/button/NeoSelectButton.base';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import ChatActionEmojisItem from 'views/chat/components/items/ChatActionEmojisItem.component';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import AutomaticRepliesService from 'services/automaticReplies.service';

const trim = (value) => {
    return value.trim().replace(/[ ]+/g, ' ');
};

const AutomaticReplyCreate = (props) => {
    const component = useComponent({
        state: {
            element: {
                title: {
                    value: '',
                    error: '',
                },
                selectMode: {
                    selected: props?.automaticReply?.mode ?? '',
                    options: [
                        { value: 'NO_AGENTS', label: 'Si no hay agentes' },
                        {
                            value: 'BUSY_AGENTS',
                            label: 'Si hay agentes pero en cola llena',
                        },
                        {
                            value: 'AVAILABLE_AGENTS',
                            label: 'Si hay agentes disponibles',
                        },
                        { value: 'ALWAYS', label: 'Siempre' },
                    ],
                },
                selectLine: {
                    selected: null,
                    options: [],
                },
                selectType: {
                    selected: props?.automaticReply?.type ?? 'DAILY',
                    options: [
                        { value: 'DAILY', label: 'Cotidiano' },
                        { value: 'SPECIFIC', label: 'Específico' },
                    ],
                },
                selectDays: {
                    selected: null,
                    options: [
                        { value: 1, label: 'L' },
                        { value: 2, label: 'M' },
                        { value: 3, label: 'X' },
                        { value: 4, label: 'J' },
                        { value: 5, label: 'V' },
                        { value: 6, label: 'S' },
                        { value: 0, label: 'D' },
                    ],
                },
                hasSchedules: {
                    value: false,
                },
                textArea: {
                    value: '',
                    error: '',
                },
                selectSchedules: {
                    values: [{ start: null, end: null }],
                },
                specificDate: {
                    value: null,
                },
            },
        },
    });

    component.useEffect(() => {
        resetForm();
    }, [props.automaticReply.type, props.automaticReply.mode]);

    component.useEffect(() => {
        if (Array.isArray(props.lines)) {
            component.setState((state) => {
                state.element.selectLine.options = props.lines;
            });
        }
    }, [props.lines]);

    const handleAccept = async () => {
        const type = component.state.element.selectType.selected;
        const hasSchedules = component.state.element.hasSchedules.value;
        const reply = {
            title: trim(component.state.element.title.value),
            message: trim(component.state.element.textArea.value),
            mode: component.state.element.selectMode.selected,
            type,
            ...(hasSchedules
                ? {
                      schedules:
                          component.state.element.selectSchedules.values.map(
                              (schedule) => {
                                  const { start, end } = schedule;
                                  
                                  const startHours = ("0" + start.getHours()).slice(-2);
                                  const startMinutes = ("0" + start.getMinutes()).slice(-2);
                                  
                                  const endHours = ("0" + end.getHours()).slice(-2);
                                  const endMinutes = ("0" + end.getMinutes()).slice(-2);

                                  return {
                                      start: `${startHours}:${startMinutes}`,
                                      end: `${endHours}:${endMinutes}`,
                                  };
                              }
                          ),
                  }
                : null),
            ...(type === 'SPECIFIC'
                ? { date: component.state.element.specificDate.value }
                : null),
            ...(type === 'DAILY'
                ? { days: component.state.element.selectDays.selected }
                : null),
            lineIds: component.state.element.selectLine.selected,
        };
        const response = await AutomaticRepliesService.createAutomaticReply(
            reply
        );
        if (response.success) {
            resetForm();
            props.actions.onCreate(response.payload);
            props.visibleSetter(false);
        } else {
        }
    };

    const handleCancel = () => {
        resetForm();
    };

    const resetForm = () => {
        component.setState((state) => {
            state.element.title.value = '';
            state.element.selectMode.selected =
                props?.automaticReply?.mode ?? '';
            state.element.selectLine.selected = null;
            state.element.selectType.selected =
                props?.automaticReply?.type ?? 'DAILY';
            state.element.selectDays.selected = null;
            state.element.hasSchedules.value = false;
            state.element.textArea.value = '';
            state.element.selectSchedules.values = [{ start: null, end: null }];
            state.element.specificDate.value = null;
        });
    };

    const isDisabled = useMemo(() => {
        if (
            component.state.element.title.value === '' ||
            component.state.element.textArea.value === '' ||
            component.state.element.selectMode.selected === '' ||
            !Array.isArray(component.state.element.selectLine.selected) ||
            component.state.element.selectLine.selected?.length < 1
        ) {
            return true;
        }

        if (
            component.state.element.hasSchedules.value &&
            (!Array.isArray(component.state.element.selectSchedules.values) ||
                component.state.element.selectSchedules.values.length < 1 ||
                component.state.element.selectSchedules.values.some(
                    (schedule) =>
                        schedule.start === null || schedule.end === null
                ) ||
                component.state.element.selectSchedules.values.some(
                    (schedule) => schedule.start > schedule.end
                ))
        ) {
            return true;
        }

        if (component.state.element.selectType.selected === 'DAILY') {
            if (
                Array.isArray(component.state.element.selectDays.selected) &&
                component.state.element.selectDays.selected.length > 0
            ) {
                return false;
            }
        }

        if (component.state.element.selectType.selected === 'SPECIFIC') {
            if (
                component.state.element.specificDate.value &&
                component.state.element.specificDate !== ''
            ) {
                return false;
            }
        }

        return true;
    }, [
        component.state.element.title.value,
        component.state.element.textArea.value,
        component.state.element.selectLine.selected,
        component.state.element.selectMode.selected,
        component.state.element.selectType.selected,
        component.state.element.selectDays.selected,
        component.state.element.specificDate.value,
        component.state.element.hasSchedules.value,
        component.state.element.selectSchedules.values,
    ]);

    const onScheduleChange = (event, input, index) => {
        component.setState((state) => {
            state.element.selectSchedules.values[index] = {
                ...state.element.selectSchedules.values[index],
                [input]: event.target.value,
            };
        });
    };

    const deleteSchedule = (index) => {
        component.setState((state) => {
            state.element.selectSchedules.values =
                state.element.selectSchedules.values.filter(
                    (_, i) => index !== i
                );
        });
    };

    return (
        <ActionDialog
            header='Crear respuesta automática'
            visible={props?.visible}
            visibleSetter={props?.visibleSetter}
            acceptMessage='Creando respuesta automática'
            acceptDisabled={isDisabled}
            onAccept={handleAccept}
            onCancel={handleCancel}
            onHide={handleCancel}
            acceptHiddenController={(payload) =>
                payload.success || payload.internal
            }
            extra='AutomaticReplyCreate'
        >
            <NeoGridContainer>
                <NeoColumn>
                    <NeoInputText
                        label='Nombre de respuesta'
                        name='title'
                        value={component.state.element.title.value}
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.title.value = event.target.value;
                            });
                        }}
                    />
                    <NeoDropdown
                        value={component.state.element.selectMode.selected}
                        options={component.state.element.selectMode.options}
                        label='Modo de habilitación'
                        optionValue='value'
                        optionLabel='label'
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.selectMode.selected = event.value;
                            });
                        }}
                    />
                    <NeoMultiSelect
                        value={component.state.element.selectLine.selected}
                        options={component.state.element.selectLine.options}
                        optionLabel='id'
                        optionValue='id'
                        label='Lineas'
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.selectLine.selected = event.value;
                            });
                        }}
                    />
                    {component.state.element.selectType.selected !==
                        'GENERIC' && (
                        <div className='form-group'>
                            <NeoSelectButton
                                label='Tipo de evento'
                                value={
                                    component.state.element.selectType.selected
                                }
                                options={
                                    component.state.element.selectType.options
                                }
                                optionValue='value'
                                optionLabel='label'
                                onChange={(event) =>
                                    component.setState((state) => {
                                        state.element.selectType.selected =
                                            event.value;
                                    })
                                }
                                unselectable={false}
                            />
                        </div>
                    )}

                    {component.state.element.selectType.selected ===
                        'DAILY' && (
                        <div className='form-group'>
                            <NeoSelectButton
                                value={
                                    component.state.element.selectDays.selected
                                }
                                options={
                                    component.state.element.selectDays.options
                                }
                                optionValue='value'
                                optionLabel='label'
                                onChange={(event) =>
                                    component.setState((state) => {
                                        state.element.selectDays.selected =
                                            event.value;
                                    })
                                }
                                multiple
                            />
                        </div>
                    )}
                    {component.state.element.selectType.selected ===
                        'SPECIFIC' && (
                        <NeoCalendar
                            minDate={new Date()}
                            value={component.state.element.specificDate.value}
                            label='Fecha'
                            onChange={(event) =>
                                component.setState((state) => {
                                    state.element.specificDate.value =
                                        event.value;
                                })
                            }
                            multiple
                        />
                    )}
                    {props?.automaticReply?.type !== 'GENERIC' && (
                        <div className='form-group input-switch'>
                            <NeoInputSwitch
                                label='Horarios específicos'
                                checked={
                                    component.state.element.hasSchedules.value
                                }
                                onChange={(event) =>
                                    component.setState((state) => {
                                        state.element.hasSchedules.value =
                                            event.value;
                                    })
                                }
                            />
                        </div>
                    )}
                </NeoColumn>
                <Divider md='0' layout='vertical' />
                <NeoColumn>
                    <div className='message'>
                        <NeoInputTextarea
                            rightIcon={
                                <InfoTooltip
                                    id='message'
                                    body='La respuesta automática se enviará solo una vez cada 24 horas cuando el contacto envíe un mensaje.'
                                />
                            }
                            maxLength={1024}
                            value={component.state.element.textArea.value}
                            error={component.state.element.textArea.error}
                            onChange={(event) => {
                                component.setState((state) => {
                                    state.element.textArea.value =
                                        event.target.value;
                                });
                            }}
                        />
                        <ChatActionEmojisItem
                            onEmojiSelect={(emoji) => {
                                component.setState((state) => {
                                    state.element.textArea.value =
                                        state.element.textArea.value +
                                        emoji.emoji;
                                });
                            }}
                        />
                    </div>
                    <NeoDataThird
                        extra='p-mt-0 text-align-right'
                        fact={
                            component.state.element.textArea.value.length +
                            '/1024'
                        }
                        label='Caracteres'
                    />
                </NeoColumn>
                <NeoColumn md='12'>
                    {component.state.element.hasSchedules.value && (
                        <div>
                            <h2>Horario</h2>
                            {component.state.element.selectSchedules.values.map(
                                (_, i) => (
                                    <div
                                        key={`schedule-${i}`}
                                        className='schedule'
                                    >
                                        <NeoCalendar
                                            timeOnly
                                            hourFormat='12'
                                            label='Horario de inicio'
                                            name={`schedule-start-${i}`}
                                            value={
                                                component.state.element
                                                    .selectSchedules.values[i]
                                                    .start
                                            }
                                            onChange={(event) =>
                                                onScheduleChange(
                                                    event,
                                                    'start',
                                                    i
                                                )
                                            }
                                        />
                                        <NeoCalendar
                                            timeOnly
                                            hourFormat='12'
                                            label='Horario de finalización'
                                            name={`schedule-end-${i}`}
                                            value={
                                                component.state.element
                                                    .selectSchedules.values[i]
                                                    .end
                                            }
                                            onChange={(event) =>
                                                onScheduleChange(
                                                    event,
                                                    'end',
                                                    i
                                                )
                                            }
                                        />
                                        {component.state.element.selectSchedules
                                            .values.length > 1 ? (
                                            <NeoButtonText
                                                label='Eliminar'
                                                extra='p-button-danger p-button-text btn'
                                                onClick={() =>
                                                    deleteSchedule(i)
                                                }
                                            />
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                )
                            )}
                            <NeoButtonText
                                label='Agregar horario'
                                extra='p-button-text btn btn--add-schedule'
                                onClick={() =>
                                    component.setState((state) => {
                                        state.element.selectSchedules.values.push(
                                            { start: '', end: '' }
                                        );
                                    })
                                }
                                icon='pi pi-plus-circle'
                            />
                        </div>
                    )}
                </NeoColumn>
            </NeoGridContainer>
        </ActionDialog>
    );
};

export default AutomaticReplyCreate;
