import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import CampaignContactsOriginOptions from 'views/campaigns/components/CampaignContactsOriginOptions.component';
import CampaignContactsOriginSummary from 'views/campaigns/components/CampaignContactsOriginSummary.component';

export default function CampaignContactsOrigin() {
  const history = useHistory();
  const [state, setState] = useStateParams();
  const [files, setFiles] = useState(state?.files ?? []);
  const [isCampaignWithContacts, setIsCampaignWithContacts] = useState(false);

  useEffect(() => initialize(), []);

  const initialize = () => {
    if(state?.flow === undefined) {
      history.replace(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
  }

  const handleFilesAdd = (event) => {
    const updatedFiles = [...files, ...event];
    setFiles(updatedFiles);
    setState({files: updatedFiles}, {merge: true});
  }

  const handleFilesChange = (event) => {
    setFiles(event);
    setState({files: event}, {merge: true});
  }

  const handleIsCampaignWithContactsChange = (event) => {
    setIsCampaignWithContacts(event);
    setState({isCampaignWithContacts: event}, {merge: true});
  }

  const handleNextButtonClick = () => {
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_TEMPLATES_MAPPING.PATH,
      state
    });
  }

  return (
    <>
      {
        (state?.flow !== undefined) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond 
              icon={Icono} 
              title='Crear campaña' 
              subtitle='Origen de contactos'
            />
          </NeoGridContainer>
          <NeoGridContainer>
            <NeoColumn md='6'>
              <CampaignContactsOriginOptions
                onFilesAdd={handleFilesAdd}
                onIsCampaignWithContactsChange={handleIsCampaignWithContactsChange}
              />
            </NeoColumn>
            <NeoColumn md='6'>
              <CampaignContactsOriginSummary
                files={files}
                isCampaignWithContacts={isCampaignWithContacts}
                onFilesChange={handleFilesChange}
              />
            </NeoColumn>
          </NeoGridContainer>
          <NeoButtonSection align='right'>
            <NeoButtonOutlined
              label='Atrás'
              onClick={() => history.goBack()}
            />
            <NeoButtonMain
              label='Siguiente'
              disabled={files.length === 0 && isCampaignWithContacts === false}
              onClick={handleNextButtonClick}
            />
          </NeoButtonSection>
        </>
      }
    </>
  );
}