import { forwardRef, useImperativeHandle } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TOAST from 'consts/toast.consts';
import ChatContentItem from 'views/chat/components/items/ChatContentItem.component';
import ChatActionEmojisItem from 'views/chat/components/items/ChatActionEmojisItem.component';
import ChatActionAttachmentFileItem from 'views/chat/components/items/ChatActionAttachmentFileItem.component';
import ChatActionQuickReplyItem from './ChatActionQuickReplyItem.component';
import ChatActionInputItem from 'views/chat/components/items/ChatActionInputItem.component';
import ChatActionButtonSendMessageItem from 'views/chat/components/items/ChatActionButtonSendMessageItem.component';
import ChatContentCommentIconItem from 'views/chat/components/items/ChatContentCommentIconItem.component';
import NeoInputFile from 'design/design_components/neo/form/NeoInputFile.base';
import NeoProgressBar from 'design/design_components/neo/overlay/NeoProgressBar.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import useMessages from 'hooks/useMessages.hook';
import useComponent from 'hooks/useComponent.hook';
import useChat from 'hooks/useChat.hook';
import ChatService from 'services/chat.service';
import useAuthentication from 'hooks/useAuthentication.hook';
import reactStringReplace from 'react-string-replace';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import InternalSpinner from 'components/InternalSpinner.component';

const FILTER_ORDER = {
  ASC: 1
};

const LIMIT_FILES_BY_REQUEST = 5;

const orderConversationContentByRecentAsc = (events) => {
  return [...events].sort((eventA, eventB) => {
    const A = 1;
    const B = -1;
    if (eventA.timestamp === eventB.timestamp) {
      if ((eventA.type === 'EVENT' && eventB.type === 'COMMENT')
        || (eventA.type === 'TEMPLATE' && eventB.type === 'EVENT')
        || ((eventA.type !== 'EVENT' && eventA.type !== 'COMMENT' && eventA.type !== 'TEMPLATE') && eventB.type === 'EVENT')
      ) return B;
      return A;
    } else {
      return new Date(eventB.timestamp) - new Date(eventA.timestamp);
    }
  }).reverse();
}

const Conversation = forwardRef((props, ref) => {
  const chat = useChat();
  const authentication = useAuthentication();
  const messages = useMessages();
  const component = useComponent({
    state: {
      element: {
        content: {
          filterOrderActive: FILTER_ORDER.ASC
        },
        attachmentFile: {
          show: false,
          uploading: false,
          progress: 0
        },
        addMessage: {
          input: {
            value: ''
          }
        },
        quicklyReply: {
          show: false,
          search: '',
          filtered: []
        }
      }
    }
  });

  const element = {
    ref: component.useRef(ref),
    loading: {
      ref: component.useRef(null)
    },
    content: {
      ref: component.useRef(null)
    },
    attachmentFile: {
      ref: component.useRef(null)
    },
    notice: {
      ref: component.useRef(null)
    },
    addMessage: {
      ref: component.useRef(null),
      input: {
        ref: component.useRef(null)
      }
    }
  }

  component.useEffect(() => {
    let content = chat.state.events;
    switch (component.state.element.content.filterOrderActive) {
      case FILTER_ORDER.ASC:
        content = orderConversationContentByRecentAsc(chat.state.events);
        break;
    }
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.CONVERSATION.SELECTED.CONTENT.FILTERED.TYPE,
        payload: {
          content
        }
      }
    })
  }, [chat.state.events])
  
  component.useEffect(() => {
    if (element.content.ref.current && chat.state.eventsFiltered.length > 0) {
      contentScrollBottom();
    }
  }, [element.content.ref.current, chat.state.eventsFiltered])

  component.useEffect(() => {
    component.setState((state) => {
      state.element.quicklyReply.filtered = chat.state.quicklyRepliesFiltered.filter(({title, replie}) => {
        return component.state.element.quicklyReply.search === '' 
          || title.toLowerCase().includes(component.state.element.quicklyReply.search.toLowerCase()) || replie.toLowerCase().includes(component.state.element.quicklyReply.search.toLowerCase());
      });
    })
  }, [component.state.element.quicklyReply.search])

  useImperativeHandle(ref, () => ({
    content: {
      scrollBottom: contentScrollBottom
    }
  }));

  const contentScrollBottom = () => {
    const contentElement = element.content.ref.current;
    if (contentElement) {
      const scrollHeightOfContent = contentElement.scrollHeight;
      contentElement.scrollTo(0, scrollHeightOfContent);

      if (!chat.state.conversationSelected?.isExpired && !chat.state.conversationSelected?.isFinished && Boolean(chat.state.conversationSelected?.newMessages) && chat.state.conversationSelected?.userId === authentication.user.uid) {
        authentication.socket.emit('messagesReadInConversation', {
          line: chat.state.lineSelected,
          conversation: chat.state.conversationSelected
        });
      }
    }
  }

  const sendNewMessage = async (contentOfMessage, type = 'text') => {
    const message = {
      from: chat.state.lineSelected.id,
      toWhatsAppId: chat.state.conversationSelected.id,
    };
    message['type'] = type.toUpperCase();
    message[type] = contentOfMessage;
    if (type === 'text') {
      message.text = message.text?.trim();
      if (chat.state.conversationSelected.id === message.toWhatsAppId) {
        message.tempId = uuidv4();
        chat.dispatch((TYPE) => {
          return {
            type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
            payload: {
              findBy: 'tempId',
              content: {
                tempId: message.tempId,
                chatId: message.from,
                isSender: true,
                lineId: chat.state.lineSelected.id,
                status: 'SENDING',
                text: message.text,
                type: 'TEXT',
                timestamp: new Date().toISOString(),
                sendingMessage: true,
              },
              conversation: chat.state.conversationSelected
            }
          }
        })
      }
    }

    const response = await ChatService.sendMessage(message);
    if (response.success) {
      return response;
    } else {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
          payload: {
            findBy: 'tempId',
            content: {
              tempId: message.tempId,
              status: 'FAILED',
              failedAt: new Date(),
              sendingMessage: false,
            }
          }
        }
      })
    }
  }

  const sendNewComment = async (comment) => {
    const response = await ChatService.addComment({
      lineId: chat.state.lineSelected.id,
      chatId: chat.state.conversationSelected.id,
      comment
    });
    return { success: response.success };
  }

  const attachmentFileUploadHandler = async ({ files }) => {
    component.setState((state) => {
      state.element.attachmentFile.uploading = true;
    });
    const filesProgress = [];
    const filesResponses = await Promise.all(files.map(async (file) => {
      filesProgress[file.name] = 0;
      const onUploadProgress = (progressEvent) => {
        const countFiles = files.length;
        filesProgress[file.name] = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        const totalOfUpload = Object.values(filesProgress).reduce((previous, current) => previous + current, 0) / countFiles;
        component.setState((state) => {
          state.element.attachmentFile.progress = totalOfUpload;
        });
      }
      const response = await ChatService.mediaFileToSend({ file }, onUploadProgress);
      const message = response.payload;
      const { type, ...contentOfMessage } = message;
      return await sendNewMessage(contentOfMessage, type);
    }));
    element.attachmentFile.ref?.current?.clear();
    component.setState((state) => {
      state.element.attachmentFile.show = false;
      state.element.attachmentFile.uploading = false;
      state.element.attachmentFile.progress = 0;
    });
  }

  const loading = (props.render.loading.isRender && props.isLoading) && 
  <div 
    className={`${props.component.name}__conversation__loading`} 
    ref={element.loading.ref}
  >
    <InternalSpinner size={60} />
  </div>;

  const content = (props.render.content.isRender && !props.isLoading) && 
  <div 
    className={`${props.component.name}__conversation__content`} 
    ref={element.content.ref}
  >
    {chat.state.eventsFiltered.map((event, key) => <ChatContentItem key={key} event={event}/>)}
  </div>;

  const attachmentFile = (props.render.attachmentFile.isRender && !props.isLoading) && <div className={`${props.component.name}__conversation__attachment-file ${component.state.element.attachmentFile.show ? 'show' : 'hide'}`}>
    <div className={`${props.component.name}__conversation__attachment-file-content`}>
      <div className={`${props.component.name}__conversation__attachment-file-content-header`}>
        {component.state.element.attachmentFile.uploading
          ? <NeoProgressBar
            value={component.state.element.attachmentFile.progress}
            style={{ width: `${component.state.element.attachmentFile.progress}%` }} />
          : <span>Carga de archivos</span>
        }
        <i className="pi pi-times" style={{ 'fontSize': '1.5em' }} onClick={() => {component.setState((state) => {state.element.attachmentFile.show = false;})}}></i>
      </div>
      <NeoInputFile
        ref={element.attachmentFile.ref}
        extra={props.showAttachmentFile ? 'back' : 'forth'}
        maxFileSize={200000000}
        customUpload
        uploadHandler={attachmentFileUploadHandler}
        multiple={true}
        emptyTemplate={<p>O arrástralos aquí</p>}
        chooseLabel={"Carga tus archivos"}
        onSelect={(event) => {
          const inputFile = element.attachmentFile.ref?.current;
          if (inputFile) {
            if (inputFile.files.length > LIMIT_FILES_BY_REQUEST) {
              messages.showToast(TOAST.SEVERITY.WARNING, 'Demasiados archivos', `Solamente está permitido subir ${LIMIT_FILES_BY_REQUEST} archivos por petición`);
              inputFile.clear();
            }
          }
        }}
        previewWidth={0}
        cancelOptions={{
          className: 'p-d-none'
        }}
        uploadOptions={{
          className: 'p-d-none'
        }}
      />
      <div className={`${props.component.name}__conversation__attachment-file-content-footer`}>
        <NeoButtonMain
          type="button"
          icon="fa-regular fa-paper-plane"
          onClick={() => {
            element.attachmentFile.ref?.current?.upload();
          }}
          disabled={component.state.element.attachmentFile.uploading}
        />
      </div>
    </div>
  </div>;

  const quicklyReply = (props.render.quicklyReply.isRender && !props.isLoading && chat.state.quicklyRepliesFiltered.length > 0) && <div className={`${props.component.name}__conversation__quickly-reply ${component.state.element.quicklyReply.show ? 'show' : 'hide'}`}>
    <div className={`${props.component.name}__conversation__quickly-reply-content`}>
      <div className={`${props.component.name}__conversation__quickly-reply-content-header`}>
        <span>Respuestas rápidas</span>
        <i className="pi pi-times" style={{ 'fontSize': '1.5em' }} onClick={() => {component.setState((state) => {state.element.quicklyReply.show = false;})}}></i>
      </div>
      <div className={`${props.component.name}__conversation__quickly-reply-actions`}>
        <NeoInputText
          label='Busca tu mensaje' 
          value={component.state.element.quicklyReply.search}
          onChange={(event) => {
            component.setState((state) => {state.element.quicklyReply.search = event.target.value})
          }}
        />
      </div>
      <div className={`${props.component.name}__conversation__quickly-reply-content-replies`}>
        {component.state.element.quicklyReply.filtered.map(({title, replie}, index) => {
          const text = reactStringReplace(replie, '\n', (match, index) => (
            <br key={index} />
          ));

          return <div 
            key={index}
            className={`${props.component.name}__conversation__quickly-reply-content-replies-replie`}
            onClick={() => {
              component.setState((state) => {
                state.element.quicklyReply.show = false;
                state.element.addMessage.input.value = replie;
              });
              element.addMessage.input.ref?.current?.focus();
            }}
          >
            <p>{title}</p>
            <div>{text}</div>
          </div>
        })
        }
      </div>
    </div>
  </div>;

  const notice = (props.render.notice.isRender && !props.isLoading) && (() => {
    const finishConversation = (chat.state.conversationSelected?.isFinished && !chat.state.conversationSelected?.isExpired && !chat.state.conversationSelected?.isReassigned) && 
    <div 
      className={`${props.component.name}__conversation--finished-conversation`}
      ref={element.notice.ref}
    >
      <div className="chat-status-message">
        <NeoTitleMain
          title="Has terminado la conversación"
          subtitle="Ya no tendrás acceso a los mensajes. Puedes continuar con tus demás conversaciones"
        />
      </div>
    </div>;

    const expiredConversation = chat.state.conversationSelected?.isExpired && 
    <div 
      className={`${props.component.name}__conversation--expired-conversation`}
      ref={element.notice.ref}
    >
      <NeoTitleMain
        title="La conversación se finalizó de manera automática"
        subtitle="Han transcurrido 24 horas desde el último mensaje con este contacto. Ya no podrás tener acceso a los mensajes enviados."
      />
    </div>;

    const reassignedConversation = chat.state.conversationSelected?.isReassigned && 
    <div
      className={`${props.component.name}__conversation--reassigned-conversation`}
      ref={element.notice.ref}
    >
      <NeoTitleMain
        title="La conversación se reasignó"
      />
    </div>;

    const waitAllowWrie = (!chat.state.conversationSelected?.isFinished && !chat.state.conversationSelected?.allowWrite) &&
    <div 
      className={`${props.component.name}__conversation--not-allowed-write`}
      ref={element.notice.ref}
    >
      <p>No puedes enviar mensajes hasta que el contacto responda la plantilla enviada.</p>
    </div>;

    const notificationNewMessages = (!chat.state.conversationSelected?.isFinished && !chat.state.conversationSelected?.isExpired && !chat.state.conversationSelected?.isReassigned && Boolean(chat.state.conversationSelected?.newMessages)) &&
    <div className={`${props.component.name}__conversation--notificationNewMessages`} onClick={contentScrollBottom}>
      <div className={`${props.component.name}__conversation--notificationNewMessages__body`}>
        <span className={`${props.component.name}__conversation--notificationNewMessages__body__count`}>{chat.state.conversationSelected.newMessages}</span>
        <span className={`${props.component.name}__conversation--notificationNewMessages__body__title`}>Ver mensajes nuevos</span>
      </div>
    </div>;

    return (<>
      {finishConversation}
      {expiredConversation}
      {reassignedConversation}
      {notificationNewMessages}
      {waitAllowWrie}
    </>);
  })();

  const addMessage = (props.render.addMessage.isRender && !props.isLoading) && (() => {
    const emojis = <ChatActionEmojisItem onEmojiSelect={(emoji) => {
      element.addMessage.input.ref?.current?.addText(emoji.emoji);
      element.addMessage.input.ref?.current?.focus();
    }} />;
    const file = <ChatActionAttachmentFileItem onClick={() => {
      component.setState((state) => {
        state.element.attachmentFile.show = true;
      })
    }} />;
    const addQuickReply = (chat.state.quicklyRepliesFiltered.length > 0) && <ChatActionQuickReplyItem onClick={() => {
      component.setState((state) => {
        state.element.quicklyReply.show = true;
      })
    }} />;
    const input = <ChatActionInputItem
      value={component.state.element.addMessage.input.value}
      ref={element.addMessage.input.ref}
      onChange={(value) => {
        component.setState((state) => {
          state.element.addMessage.input.value = value;
        })
      }}
      onKeyEnterDown={() => {
        if(component.state.element.addMessage.input.value.trim().length > 0) {
          sendNewMessage(component.state.element.addMessage.input.value);
          component.setState((state) => {
            state.element.addMessage.input.value = '';
          })
        }
      }}
    />;
    const sendMessage = <ChatActionButtonSendMessageItem onClick={() => {
      if(component.state.element.addMessage.input.value.trim().length > 0) {
        sendNewMessage(component.state.element.addMessage.input.value);
        component.setState((state) => {
          state.element.addMessage.input.value = '';
        })
      }
    }} />;
    const comment = <ChatContentCommentIconItem onSubmit={(comment) => {
      sendNewComment(comment);
    }} />;

    return (
      <div
        className={`${props.component.name}__conversation__add-message`}
        ref={element.addMessage.ref}
      >
        {emojis}
        {file}
        {addQuickReply}
        {input}
        {sendMessage}
        {comment}
      </div>
    );
  })();

  return (
    <div 
      className={`${props.component.name}__conversation`}
      ref={element.ref}
    >
      {loading}
      {content}
      {attachmentFile}
      {quicklyReply}
      {notice}
      {addMessage}
    </div>
  );
});

export default Conversation;