import EMPLOYEES from 'consts/employees.consts';

function getEmployeeRole(role) {
  return EMPLOYEES.ROLES[role] ?? 'Desconocido';
}

function getEmployeeGeneralStatus(ganaralStatus) {
  return EMPLOYEES.GENERAL_STATUSES[ganaralStatus] ?? 'Desconocido';
}

export default {
  getEmployeeRole,
  getEmployeeGeneralStatus
};