import {useState, useEffect, useRef} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import CampaignsService from 'services/campaigns.service';

export default function CampaignTrackedLinks(props) {
  const tableRef = useRef();
  const asyncState = useAsyncState();
  const [trackedLinks, setTrackedLinks] = useState([]);

  useEffect(async () => await initialize(), [props.campaign]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaignTrackedLinks()],
      {initialization: true}
    );
  }

  const getCampaignTrackedLinks = async () => {
    const {lineId, name, recurrentCampaignNumber} = props.campaign;
    const response = await CampaignsService.getCampaignTrackedLinks(lineId, name, {recurrentCampaignNumber});
    if(response.success) {
      setTrackedLinks(response.payload);
      return {success: true};
    }
    return {success: false};
  }
  
  const elements = {
    templateFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='template.name' placeholder='Buscar por plantilla'/>
    ),
    urlFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='value' placeholder='Buscar por link original'/>
    ),
    urlTrackedFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='urlTracked' placeholder='Buscar por link trackeado'/>
    ),
    opensFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='opens' placeholder='Buscar por cantidad'/>
    ),
    templateBody: (data) => (
      <>{data.template.name} ({data.template.language})</>
    ),
    urlBody: (data) => {
      return <a href={data.value} title={data.value} target="_blank">{data.value}</a>
    },
    urlTrackedBody: (data) => {
      return <a href={data.value} title={data.urlTracked} target="_blank">{data.urlTracked}</a>
    },
    opensBody: (data) => {
      return <>{data.opens}</>
    },
  }

	return (<>
    {trackedLinks.length > 0 &&
      <NeoCard>
        <NeoInnerTitle extra='primero'>Trackeo de links</NeoInnerTitle>
        {
          (asyncState.isLoading) &&
          <InternalSpinner/>
        }
        {
          (!asyncState.isLoading) &&
          <>
            {
              (asyncState.isInitialized) &&
              <NeoTable
                ref={tableRef}
                value={trackedLinks}
                paginator
                rows={10}
                removableSort
                sortField='template.name'
                sortOrder={-1}
                emptyMessage='No hay links'
                extra="tracked-links-table"
              >
                <NeoTableColumn
                  header='Plantilla'
                  field='template.name'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.templateFilterInput}
                  body={elements.templateBody}
                />
                <NeoTableColumn
                  header='Link original'
                  field='value'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.urlFilterInput}
                  body={elements.urlBody}
                />
                <NeoTableColumn
                  header='Link trackeado'
                  field='urlTracked'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.urlTrackedFilterInput}
                  body={elements.urlTrackedBody}
                />
                <NeoTableColumn
                  header='Visitas'
                  field='opens'
                  filter
                  sortable
                  filterMatchMode='contains'
                  body={elements.opensBody}
                  filterElement={elements.opensFilterInput}
                  style={{width: '10%'}}
                />
              </NeoTable>
            }
            {
              (!asyncState.isInitialized) &&
              <DataFetchError 
                internal 
                title='No se pudieron cargar los links'
                onRetry={initialize}
              />
            }
          </>
        }
      </NeoCard>
    }
  </>);
}