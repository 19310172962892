import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';

export default function InitializationError(props) {
  const title = props.title ?? 'No se pudieron cargar los datos';

  return (
    <NeoFlexContainer extra="p-flex-column p-col-12 DataFetchError" ai="center">
      {
        (props.internal !== true) &&
        <NeoTitleMain title={title}/>
      }
      {
        (props.internal === true) &&
        <p><b>{title}</b></p>
      }
      {
        (props.onRetry && typeof props.onRetry == 'function') &&
        <NeoButtonOutlined label='Intentar de nuevo' onClick={() => props.onRetry()}/>
      }
    </NeoFlexContainer>
  );
}