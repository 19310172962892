import { useEffect, useRef } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from 'hooks/useComponent.hook';
import ConversationsHeader from 'views/chat/components/headers/ConversationsHeader.component';
import ContactConversationsList from 'views/chat/components/lists/ContactConversationsList.component.js';
import NeoSlideMenu from 'design/design_components/neo/menu/NeoSlideMenu.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import useAuthentication from 'hooks/useAuthentication.hook';
import useChat from 'hooks/useChat.hook';

const FILTER_STATUS = {
  ALL: 1,
  TIME_ELAPSED: 2,
};

const FILTER_ORDER = {
  LONGER_WAITING_TIME: 1,
  MORE_RECENT: 2
};

export default function ConversationsUnassignedSection() {
  const chat = useChat();
  const asyncState = useAsyncState();
  const component = useComponent({
    name: 'ConversationsUnassignedSection',
    state: {
      transition: '',
      filterStatusActive: Object.values(FILTER_STATUS),
      filterOrderActive: FILTER_ORDER.LONGER_WAITING_TIME
    }
  });

  useEffect(() => {
    setTimeout(() => {
      component.setState((state) => {
        state.transition = "transition";
      })
    }, 1)
  }, []);

  useEffect(() => {
    const conversationsFilteredByLineId = chat.state.conversationsUnassigned.filter((conversation) => {
      return (conversation.lineId === chat.state.lineSelected.id);
    });

    const conversationsFilteredByStatus = conversationsFilteredByLineId.filter((conversation) => {
      let add = component.state.filterStatusActive.includes(FILTER_STATUS.ALL);

      if(!add && component.state.filterStatusActive.includes(FILTER_STATUS.TIME_ELAPSED)) {
        add = Boolean(conversation?.timeElapsedLastMessage);
      }

      return add;
    });

    let conversations = conversationsFilteredByStatus;
    switch (component.state.filterOrderActive) {
      case FILTER_ORDER.LONGER_WAITING_TIME:
        conversations = orderConversationsByUserWaiting(conversationsFilteredByStatus);
        break;
      case FILTER_ORDER.MORE_RECENT:
        conversations = orderConversationsByRecentAsc(conversationsFilteredByStatus);
        break;
    }
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.CONVERSATIONS.UNASSIGNED.FILTERED.TYPE,
        payload: {
          conversations
        }
      }
    })

    return () => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.UNASSIGNED.FILTERED.TYPE,
          payload: {
            conversations: []
          }
        }
      })
    }
  }, [chat.state.lineSelected.id, chat.state.conversationsUnassigned, component.state.filterStatusActive, component.state.filterOrderActive])

  const orderConversationsByUserWaiting = (conversations) => {
    const newArrayOfConversations = [];

    const conversationsWithMessageUser  = orderConversationsByRecentAsc(conversations).reverse();

    newArrayOfConversations.unshift(...conversationsWithMessageUser);
    return newArrayOfConversations;
  }

  const orderConversationsByRecentAsc = (conversations) => {
    return [...conversations].sort((conversationA, conversationB) => {
      return new Date(conversationB.lastMessageSentAt) - new Date(conversationA.lastMessageSentAt);
    });
  }

  const FilterStatus = () => {
    const menu = useRef(null);
    const filterStatusesArray = (status) => {
      if(component.state.filterStatusActive.includes(status)) {
        component.setState((state) => {
          state.filterStatusActive = state.filterStatusActive.filter(filter => filter !== status && filter !== FILTER_STATUS.ALL);
        })
      } else {
        component.setState((state) => {
          state.filterStatusActive = state.filterStatusActive.filter(filter => filter !== FILTER_STATUS.ALL).concat([status]);
        })
      }
    }

    const items = [
      {
        label: 'Todas',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.ALL) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          if(component.state.filterStatusActive.includes(FILTER_STATUS.ALL)) {
            component.setState((state) => {
              state.filterStatusActive = state.filterStatusActive.filter(f => f !== FILTER_STATUS.ALL);
            })
          } else {
            component.setState((state) => {
              state.filterStatusActive = Object.values(FILTER_STATUS);
            })
          }
        }
      },
      {
        label: 'Tiempo de respuesta excedido',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.TIME_ELAPSED) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          filterStatusesArray(FILTER_STATUS.TIME_ELAPSED);
        }
      },
    ];

    return <>
      <NeoSlideMenu ref={menu} model={items} popup className='FilterStatus'/>
      <NeoButtonMain type="button" icon="pi pi-filter" onClick={(event) => menu.current.toggle(event)} />
    </>
  }
  
  const FilterOrder = () => {
    const menu = useRef(null);
    const items = [
      {
        label: 'Cliente con más tiempo de espera',
        icon: component.state.filterOrderActive === FILTER_ORDER.LONGER_WAITING_TIME ? 'pi pi-circle-on' : 'pi pi-circle-off',
        command: () => {
          component.setState((state) => {
            state.filterOrderActive = FILTER_ORDER.LONGER_WAITING_TIME;
          })
        }
      },
      {
        label: 'Mensaje más reciente',
        icon: component.state.filterOrderActive === FILTER_ORDER.MORE_RECENT ? 'pi pi-circle-on' : 'pi pi-circle-off',
        command: () => {
          component.setState((state) => {
            state.filterOrderActive = FILTER_ORDER.MORE_RECENT;
          })
        }
      }
    ];

    return <>
      <NeoSlideMenu ref={menu} model={items} popup className='FilterOrder'/>
      <NeoButtonMain type="button" icon="pi pi-sort-amount-down" onClick={(event) => menu.current.toggle(event)} />
    </>
  }

  const totalConversationsUnassigned = component.useMemo(() => {
    return chat.state.conversationsUnassigned.reduce((initialValue, conversation) => {
      if(conversation.lineId === chat.state.lineSelected.id) {
        return initialValue + 1;
      }
      return initialValue;
    }, 0);
  }, [chat.state.conversationsUnassigned])

  return (
    <div className={component.classNames(component.name, component.state.transition)}>
      <ConversationsHeader
        isLoading={asyncState.isLoading}
        line={chat.state.lineSelected}
        agent={{
          name: "Conversaciones en cola",
          area: `Total: ${totalConversationsUnassigned}`,
        }}
        filters={[FilterStatus, FilterOrder]}
      />

      <ContactConversationsList
        isLoading={asyncState.isLoading}
        conversations={chat.state.conversationsUnassignedFiltered}
        conversationSelected={chat.state.conversationUnassignedSelected}
        onConversationSelect={() => {}}
      />
    </div>
  );
}