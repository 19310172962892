import {createContext, useState, useEffect} from 'react';
import {auth} from 'config/firebase.config';
import AuthenticationService from 'services/authentication.service';
import EmployeesService from 'services/employees.service';
import BACKEND from 'consts/server.consts';
import {io} from 'socket.io-client';

const AuthenticationContext = createContext();
export default AuthenticationContext;

const SOCKET_STATUS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  CONNECTING: 'CONNECTING'
};

export function AuthenticationContextProvider(props) {
  const [userAuth, setUserAuth] = useState();
  const [user, setUser] = useState();
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const [socket, setSocket] = useState();
  const [statusSocket, setStatusSocket] = useState(SOCKET_STATUS.CONNECTING);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUserAuth(user);
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if(userAuth !== undefined && userAuth !== null) {
          const authorization = await userAuth.getIdToken(true);
          const socket = io(BACKEND.BASE_URL, {
            transports: ['websocket'],
            auth: {
              token: authorization
            }
          });
          socket.on('connect', () => {
            getDataAccount();
            setStatusSocket(SOCKET_STATUS.CONNECTED);
          });
          socket.on('connect_error', (err) => {
            console.error(err);
            setStatusSocket(SOCKET_STATUS.DISCONNECTED);
          });
          Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
            socket.off(eventName);
            socket.on(eventName, eventFn);
          })
          setSocket(socket);
        } 
        else {
          if(socket) { socket.close(); }
          setSocket(null);
          setUser(userAuth === undefined ? undefined : null);
        }
      }
      catch(error) {
        setStatusSocket(SOCKET_STATUS.DISCONNECTED);
      }
    })();
    return () => {
      if(socket) {
        Object.entries(socketListeners).forEach(([eventName]) => {
          socket.off(eventName);
        })
        socket.close();
      }
    }
  }, [userAuth]);

  const getDataAccount = async () => {
    const user = {email: userAuth.email, uid: userAuth.uid};
    const responseRole = await AuthenticationService.getUserRole();
    user.role = responseRole.payload;
    const responseAccount = await EmployeesService.getStatus();
    user.status = responseAccount.payload;
    setUser(user);
  }

  const status = {
    update: async (_status) => {
      setIsStatusUpdating(true);
      const result = await EmployeesService.updateStatus(_status);
      if(result.success) {
        status.replace(_status);
      }
      setIsStatusUpdating(false);
      return _status;
    },
    replace: (_status) => {
      setUser((user) => {
        return {...user, status: _status};
      });
    },
    isUpdating: isStatusUpdating
  }

  const socketListeners = {
    ping: (params, callback) => {
      if(typeof callback === 'function') {
        callback({
          responseAt: new Date()
        });
      }
    }
  }

  return (
    <AuthenticationContext.Provider value={{user, getDataAccount, status, socket, statusSocket}}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}