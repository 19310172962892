import useCalendar from 'hooks/useCalendar.hook';
import Calendar from './Calendar.component';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';

export default function AutomaticReplySpecificCalendar(props) {
    const calendar = useCalendar(new Date());
    const isWeekly = props.view !== 'Mes';
    return (
        <div className='calendar-container'>
            <NeoCalendar
                extra='calendar__input-container'
                onChange={(e) => calendar.set(e.value)}
                value={
                    isWeekly
                        ? calendar.date.toJSDate()
                        : calendar.startOf('month').toJSDate()
                }
                view={isWeekly ? 'date' : 'month'}
                inline
                selectOtherMonths
            />

            <Calendar
                calendar={calendar}
                isWeekly={isWeekly}
                replies={props.automaticReplies}
                showNumber={isWeekly}
                actions={props.actions}
            />
        </div>
    );
}
