import {useState, useRef} from 'react';
import TOAST from 'consts/toast.consts';
import ActionDialog from 'components/ActionDialog.component';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoMessages from 'design/design_components/neo/messages/NeoMessages.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import EmployeesCsvFileExplorer from 'views/employees/components/EmployeesCsvFileExplorer.component';
import EmployeesService from 'services/employees.service';

const requiredHeaders = [
  {label: 'Correo', regexp: /^Correo$/i},
  {label: 'Contraseña', regexp: /^Contraseña$/i},
  {label: 'Nombre', regexp: /^Nombre$/i},
  {label: 'Apellidos', regexp: /^Apellidos$/i},
  {label: 'Rol', regexp: /^Rol$/i},
  {label: 'Líneas', regexp: /^L[ií]neas$/i}
];

export default function RegisterEmployeesDialog(props) {
  const messages = useRef(null);
  const [file, setFile] = useState(null);
  const [valid, setValid] = useState(false);
  const [fileError, setFileError] = useState(null);

  const handleEmployeesCsvFileExplorerChange = (event) => {
    setValid(false);
    setFileError(null);
    setFile(event.file);
    const {worksheet} = event;
    const headers = Object.values(worksheet.headers).map((header) => header.trim());
    for(const requiredHeader of requiredHeaders) {
      const includesHeader = headers.some((header) => requiredHeader.regexp.test(header) === true)
      if(includesHeader === false) {
        messages.current.show([
          {severity: TOAST.SEVERITY.ERROR, detail: `Falta la columna "${requiredHeader.label}"`, sticky: true, closable: false}
        ]);
        return false;
      }
    }
    const employeesCount = worksheet.rows.length;
    if(employeesCount === 0) {
      messages.current.show([
        {severity: TOAST.SEVERITY.ERROR, detail: `No hay empleados para registrar`, sticky: true, closable: false}
      ]);
      return false;
    }
    messages.current.show([{
      severity: TOAST.SEVERITY.SUCCESS, 
      detail: `Se registrará${employeesCount === 1 ? '' : 'n'} ${employeesCount} empleado${employeesCount === 1 ? '' : 's'}`, 
      sticky: true, 
      closable: false
    }]);
    setValid(true);
  }

  const handleAccept = async () => {
    const response = await EmployeesService.registerEmployees(file);
    if(response.success) {
      return {success: true, payload: response.payload};
    }
    else if (response.error.code === 'NON_EXISTENT_MAIN_ACCOUNT') {
      return {success: false, internal: true};
    }
    else if (response.error.code === 'INVALID_PASSWORD') {
      setFileError(`Existen empleados con contraseña insegura`);
      return {success: false, internal: false};
    }
    return {success: false, internal: true};
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    setFile(null);
    setValid(false);
    setFileError(null);
  }

  return (
    <ActionDialog
      header='Registrar empleados'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Registrando empleado(s)'
      acceptDisabled={file === null || valid === false}
      onAccept={handleAccept}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      {
        (file === null) &&
        <EmployeesCsvFileExplorer
          onChange={handleEmployeesCsvFileExplorerChange}
        />
      }
      {
        (file !== null) &&
        <NeoGridContainer col='12'>
          <NeoColumn md='12'>
            <NeoFlexContainer ai='center' jc='between'>
              <b>{file.name}</b>
              <NeoButtonOutlined
                label='Eliminar'
                onClick={() => setFile(null)}
              />
            </NeoFlexContainer>
          </NeoColumn>
          <NeoMessages ref={messages}/>
          {
            (fileError !== null) &&
            <>{fileError}</>
          }
        </NeoGridContainer>
      }
    </ActionDialog>
  );
}