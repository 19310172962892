import useCalendar from 'hooks/useCalendar.hook';
import Calendar from './Calendar.component';

export default function AutomaticReplyDailyCalendar(props) {
    const calendar = useCalendar(new Date());
    return (
        <div className='calendar-container'>
            <Calendar
                calendar={calendar}
                replies={props.automaticReplies}
                isWeekly
                actions={props.actions}
            />
        </div>
    );
}
