import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import ConversationsOriginCount from 'views/dashboard/components/ConversationsOriginCount.component';
import ConversationsOrigin from 'views/dashboard/components/ConversationsOrigin.component';
import DashboardUpdate from 'views/dashboard/components/DashboardUpdate.component';
import ConsumptionService from 'services/consumption.service';

export default function GeneralStatistics(props) {
  const asyncState = useAsyncState({isInitialized: true});
  const [statistics, setStatistics] = useState();

  useEffect(async () => await reboot(), [props.filters]);

  const reboot = async () => {
    await asyncState.allPromises([getCompanyStatistics()]);
  }

  const getCompanyStatistics = async () => {
    const linesId = props.filters.lines.map((line) => line.id);
    const fromDateString = props.filters.period.from;
    const toDateString = props.filters.period.to;
    const response = await ConsumptionService.getCompanyStatistics(linesId, fromDateString, toDateString);
    if(response.success) {
      setStatistics(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  return (
    <>
      {
        (props.filters.lines.length > 0 && props.filters.period) &&
        <>
          <NeoGridContainer>
            <NeoColumn col='12'>
              <ConversationsOriginCount
                loading={asyncState.isLoading}
                successfully={asyncState.isSuccessfully}
                data={statistics?.conversationsOriginCount}
                onRetry={() => reboot()}
              />
            </NeoColumn>
            <NeoColumn col='12'>
              <ConversationsOrigin
                loading={asyncState.isLoading}
                successfully={asyncState.isSuccessfully}
                data={statistics?.conversationsOriginByDate}
                onRetry={() => reboot()}
              />
            </NeoColumn>
            <NeoColumn col='12'>
              <DashboardUpdate
                loading={asyncState.isLoading}
                successfully={asyncState.isSuccessfully}
                data={statistics?.updatedAt}
                onRetry={() => reboot()}
              />
            </NeoColumn>
          </NeoGridContainer>
        </>
      }
      {
        (props.filters.lines.length == 0) &&
        <NeoInnerSubtitle>No hay líneas seleccionadas</NeoInnerSubtitle>
      }
    </>
  );
}