import backendRequestUtil from 'utils/backendRequest.util';

export default class DashboardsService {

  static async updateCompanyDashboards() {
    const {success, error} = await backendRequestUtil.put('/rest/companyDashboards');
    return success ? {success} : {success, error};
  }

  static async getCompanyDashboardsStatus() {
    const {success, body, error} = await backendRequestUtil.get('/rest/companyDashboardsStatus');
    return success ? {success, payload: body.dashboardsStatus} : {success, error};
  }

}