import {useState} from 'react';
import DashboardFilters from 'views/dashboard/components/DashboardFilters.component';
import GeneralStatistics from 'views/dashboard/components/GeneralStatistics.component';
import MessagesDetailDownloadButton from 'views/dashboard/components/MessagesDetailDownloadButton.component';

export default function GeneralDashboard() {
  const [filters, setFilters] = useState(null);

  const handleFiltersChange = (event) => {
    setFilters(event);
  }

  const elements = {
    downloadButton: (
      <MessagesDetailDownloadButton
        loading={filters === null}
        linesId={filters?.lines?.map((line) => line.id)}
        period={filters?.period}
      />
    )
  };

  return (
    <>
      <DashboardFilters 
        title='Estadísticas generales'
        onChange={handleFiltersChange}
        rightComponent={elements.downloadButton}
      />
      {
        (filters) &&
        <GeneralStatistics filters={filters}/>
      }
    </>
  );
}