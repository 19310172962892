import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import LinesList from 'views/lines/components/LinesList.component';
import Icono from 'design/assets/img/cta/icons/icon-lines.svg';

export default function LinesView() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title='Líneas' icon={Icono} />
      </NeoGridContainer>
      <LinesList />
    </>
  );
}