import { useState, useEffect } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputMask from 'design/design_components/neo/form/NeoInputMask.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InfoTooltip from 'components/InfoTooltip.component';
import useComponent from 'hooks/useComponent.hook';

const phonePattern = /^[+][0-9]{11,13}$/;

const BasicContactDataForm = (props) => {
  const component = useComponent({state: {changed: {}}});
  const [phone, setPhone] = useState(props.contact?.phone ?? '');
  const [firstName, setFirstName] = useState(props.contact?.firstName ?? props.contact?.name ?? '');
  const [lastName, setLastName] = useState(props.contact?.lastName ?? '');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => component.setState((state) => {state.changed = {}}), [props.contact]);
  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [phone, firstName, lastName]);
  useEffect(() => handlePhoneErrorCodeChange(), [props.phoneErrorCode]);

  const handleChange = () => {
    const event = { valid: true, payload: { phone, firstName, lastName } };
    event.valid = phone.length > 0 && phonePattern.test(phone) && firstName.length > 0;
    if (props.contact) {
      event.change = (firstName !== props.contact.firstName && firstName !== props.contact.name)
        || lastName !== props.contact.lastName;
    }
    props.onChange(event);
  }

  const handlePhoneErrorCodeChange = () => {
    if (props.phoneErrorCode === null) {
      setPhoneError('');
    }
    else if (props.phoneErrorCode === 'INVALID_PHONE_NUMBER') {
      setPhoneError('Número de teléfono inválido.');
    }
    else if (props.phoneErrorCode === 'ALREADY_EXIST') {
      setPhoneError('El número de teléfono ya se encuentra asociado a un contacto.');
    }
  }

  const handlePhoneInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setPhone(value);
  }

  const handleFirstNameInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setFirstName(value);
  }

  const handleLastNameInputChange = (event) => {
    const value = event.value ?? event.target.value;
    setLastName(value);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Información básica</NeoInnerTitle>
      <NeoInputMask
        md={props?.elements?.phone?.md} col="12"
        label='Número de teléfono'
        rightIcon={props.contact === undefined ? <InfoTooltip id='phone' body='El teléfono debe incluir el código de país.' /> : <></>}
        slotChar=''
        mask='+99999999999?99'
        value={phone}
        error={phoneError}
        disabled={props.contact !== undefined}
        onChange={handlePhoneInputChange}
        onFocus={() => setPhoneError('')}
      />
      {(!props.showOnlyFills || firstName.length > 0 || component.state.changed.firstName) &&
        <NeoInputText md={props?.elements?.firstName?.md} col="12"
          label='Nombre(s)'
          value={firstName}
          onChange={handleFirstNameInputChange}
          onFocus={() => {component.setState((state) => {state.changed.firstName = true})}}
        />
      }
      {(!props.showOnlyFills || lastName.length > 0 || component.state.changed.lastName) &&
        <NeoInputText md={props?.elements?.lastName?.md} col="12"
          label='Apellido(s)'
          value={lastName}
          onChange={handleLastNameInputChange}
          onFocus={() => {component.setState((state) => {state.changed.lastName = true})}}
        />
      }
    </NeoCard>
  );
}

BasicContactDataForm.defaultProps = {
  elements: {
    phone: {
      md: '4'
    },
    firstName: {
      md: '4'
    },
    lastName: {
      md: '4'
    }
  },
  showOnlyFills: false
}

export default BasicContactDataForm;