import {useHistory} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';

export default function RecurrentCampaignDetailHeader(props) {
  const history = useHistory();

  const handleCreateButtonClick = () => {
    const {campaign, lastSendingChange} = props;
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CREATE_CAMPAIGN.PATH,
      state: {
        name: campaign.name,
        line: props.line,
        template: {
          name: lastSendingChange.flow?.name,
          language: lastSendingChange.flow?.language
        },
        dynamicCampaignConfig: {
          isDynamic: lastSendingChange.isDynamic,
          isRecurrent: lastSendingChange.isRecurrent,
          initialSentType: lastSendingChange.flow?.sendingType
        },
        replyButtons: lastSendingChange.flow?.replyButtons,
        mainRecurrentDynamicCampaign: campaign.name
      }
    });
  }

  return (
    <NeoGridContainer>
      <NeoTitleSecond
        icon={Icono}
        title={props.campaign.name}
        subtitle='Detalle de campaña recurrente'
      />
      <NeoButtonSection align='right'>
        {/* <NeoButtonMain 
          label='Descargar detalle de respuestas' 
          onClick={() => {}} 
        /> */}
        {
          (props.line?.id !== undefined && props.lastSendingChange?.name !== undefined) &&
          <NeoButtonMain 
            label='Crear nuevo envío' 
            icon='pi pi-plus'
            onClick={handleCreateButtonClick} 
          />
        }
      </NeoButtonSection>
    </NeoGridContainer>
  );
}