import { classNames } from 'primereact/utils';

export default function CalendarDay(props) {
    return (
        <div
            className={classNames('calendar__day', {
                'calendar__cell--time': props.name === '',
            })}
        >
            <p>{props.name}</p>
            {props.showNumber ? <p>{props.number}</p> : null}
        </div>
    );
}
