import {useContext} from 'react';
import {auth} from 'config/firebase.config';
import AuthenticationContext from 'contexts/authentication.context';
import EmployeesService from 'services/employees.service';
import AuthenticationService from 'services/authentication.service';

export default function useAuthentication() {
  const {user, getDataAccount, status, socket, statusSocket} = useContext(AuthenticationContext);

  const isAuthenticated = () => {
    return user !== undefined ? user !== null : undefined;
  }

  const signInWithEmailAndPassword = async (email, password) => {
    await auth.signInWithEmailAndPassword(email, password);
  }

  const signInWithCustomToken = async (token) => {
    await auth.signInWithCustomToken(token);
  }

  const login = async (session, token) => {
    const response = await AuthenticationService.login(session, token);
    if(response.success) {
      await auth.signInWithCustomToken(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  const signOut = async () => {
    await auth.signOut();
  }

  return {
    user,
    login,
    signOut,
    isAuthenticated,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    reload: getDataAccount,
    status,
    socket,
    statusSocket
  };
}