import {useState} from 'react';
import ApiSettingsHeader from 'views/apiSettings/components/ApiSettingsHeader.component';
import ApiKeysList from 'views/apiSettings/components/ApiKeysList.component';

export default function ApiSettings() {
  const [webhookUrl, setWebhookUrl] = useState(null);
  const [apiKeys, setApiKeys] = useState(null);

  const handleApiSettingsChange = (event) => {
    setWebhookUrl(event.webhookUrl);
    setApiKeys(event.apiKeys);
  }

  return (
    <>
      <ApiSettingsHeader onChange={handleApiSettingsChange}/>
      {
        (webhookUrl !== null && apiKeys !== null) &&
        <ApiKeysList apiKeys={apiKeys}/>
      }
    </>
  );
}