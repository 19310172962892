import { forwardRef, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import useAsyncState from 'hooks/useAsyncState.hook';
import ChatService from 'services/chat.service';
import useAuthentication from 'hooks/useAuthentication.hook';
import useChat from 'hooks/useChat.hook';
import ConversationInfo from 'views/chat/components/items/ConversationInfo.component';
import Conversation from '../items/Conversation.component';
import ConversationContactInfo from 'views/chat/components/items/ConversationContactInfo.component';
import DataFetchError from 'components/DataFetchError.component';
import useComponent from 'hooks/useComponent.hook';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} render Define if render elements
 * @property {Object} line Line
 * @property {Object} conversation Conversation to chat
 */

const Component = {
  name: 'ChatSection',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const chat = useChat();
    const authentication = useAuthentication();
    const prevStatusSocketRef = useRef();
    const asyncState = useAsyncState();
    const component = useComponent({
      state: {
        element: {
          conversationContactInfo: {
            open: {
              show: false
            }
          }
        }
      }
    })

    const element = {
      ref: useRef(ref),
      conversation: {
        ref: useRef(null),
      },
      conversationContactInfo: {
        open: {
          onClick: () => {
            component.setState((state) => {
              state.element.conversationContactInfo.open.show = !state.element.conversationContactInfo.open.show;
            })
          }
        }
      }
    }

    const render = useMemo(() => {
      const element = {
        conversationInfo: {
          isRender: false,
          actions: {
            finalize: {
              isRender: false,
            }
          }
        },
        conversation: {
          isRender: false,
          loading: {
            isRender: false
          },
          content: {
            isRender: false
          },
          attachmentFile: {
            isRender: false
          },
          quicklyReply: {
            isRender: false
          },
          notice: {
            isRender: false
          },
          addMessage: {
            isRender: false
          },
        },
        conversationContactInfo: {
          isRender: false,
          open: {
            isRender: false
          },
          data: {
            isRender: false
          },
          actions: {
            isRender: false
          }
        }
      };
      if(chat.state.conversationSelected) {
        element.conversationInfo.isRender = true;
        if(props.render?.conversationInfo?.actions?.finalize?.isRender !== false) {
          element.conversationInfo.actions.finalize.isRender = true;
        }
        element.conversation.isRender = true;
        if(props.render?.conversationContactInfo?.isRender !== false) {
          element.conversationContactInfo.isRender = true;
          if(props.render?.conversationContactInfo?.open?.isRender !== false) {
            element.conversationContactInfo.open.isRender = true;
          }
          if(props.render?.conversationContactInfo?.data?.isRender !== false) {
            element.conversationContactInfo.data.isRender = true;
          }
          if(props.render?.conversationContactInfo?.actions?.isRender !== false) {
            element.conversationContactInfo.actions.isRender = true;
          }
        }
        if(chat.state.conversationSelected.isFinished || chat.state.conversationSelected.isExpired || chat.state.conversationSelected.isReassigned) {
          element.conversation.notice.isRender = true;
        } else {
          element.conversation.loading.isRender = true;
          element.conversation.content.isRender = true;
          if(chat.state.conversationSelected.allowWrite) {
            if(props.render?.conversation?.addMessage?.isRender !== false) {
              element.conversation.attachmentFile.isRender = true;
              element.conversation.quicklyReply.isRender = true;
              element.conversation.addMessage.isRender = true;
              if(Boolean(chat.state.conversationSelected.newMessages)) {
                element.conversation.notice.isRender = true;
              }
            }
          } else {
            element.conversation.notice.isRender = true;
          }
        }
      }
      return element;
    }, [chat.state.conversationSelected]);

    const reboot = async () => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATION.SELECTED.CONTENT.TYPE,
          payload: {
            content: []
          }
        }
      })
      if (!chat.state.conversationSelected?.isFinished && !chat.state.conversationSelected?.isExpired) {
        await asyncState.allPromises(
          [getEvents()]
        );
      }
    }

    useEffect(async () => {
      reboot()
    }, [chat.state.conversationSelected.assignedConversationId]);

    useEffect(() => {
      Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
        authentication.socket.off(eventName);
        authentication.socket.on(eventName, eventFn);
      })

      return () => {
        Object.entries(socketListeners).forEach(([eventName]) => {
          authentication.socket.off(eventName);
        })
      }
    }, [chat.state.lineSelected?.id, chat.state.conversationSelected?.id])

    useEffect(() => {
      if(prevStatusSocketRef.current === 'DISCONNECTED' && authentication.statusSocket === 'CONNECTED') {
        reboot();
      }
      prevStatusSocketRef.current = authentication.statusSocket;
    }, [authentication.statusSocket])

    const getEvents = async () => {
      const response = await ChatService.getContent(chat.state.lineSelected.id, chat.state.conversationSelected?.contact?.phone?.replace('+', ""));
      if (response.success) {
        chat.dispatch((TYPE) => {
          return {
            type: TYPE.CONVERSATION.SELECTED.CONTENT.TYPE,
            payload: {
              content: response.payload.chatContent
            }
          }
        })
        return { success: true };
      }
      return { success: false };
    }

    const socketListeners = {
      event: ({ event }) => {
        if (chat.state.conversationSelected.id === event.chatId) {
          chat.dispatch((TYPE) => {
            return {
              type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
              payload: {
                content: event
              }
            }
          })
        }
      },
      messageSent: ({ message }) => {
        if (chat.state.conversationSelected.id === message.chatId) {
          chat.dispatch((TYPE) => {
            return {
              type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
              payload: {
                content: message,
                findBy: message['tempId'] ? 'tempId' : 'id'
              }
            }
          })
        }
      },
      messageReceived: ({ message }, callback) => {
        if (chat.state.conversationSelected.id === message.chatId) {
          chat.dispatch((TYPE) => {
            return {
              type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
              payload: {
                content: message
              }
            }
          })

          if (callback) {
            callback({
              event: {
                message: {
                  read: true
                }
              }
            });
          }
        }
      },
      chatCommentAdded: ({ line, comment }) => {
        if (chat.state.lineSelected.id === line.id) {
          if (chat.state.conversationSelected.id === comment.chatId) {
            chat.dispatch((TYPE) => {
              return {
                type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
                payload: {
                  content: comment
                }
              }
            })
          }
        }
      },
      messageStatusUpdate: ({ chat: conversation, message }) => {
        if (chat.state.conversationSelected.id === conversation.id) {
          chat.dispatch((TYPE) => {
            return {
              type: TYPE.CONVERSATION.SELECTED.CONTENT.UPSERT.TYPE,
              payload: {
                content: message
              }
            }
          })
        }
      }
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);

    return (
      <div 
        id={props.id}
        className={className}
        ref={element.ref}
        style={props.style} {...otherProps}
      >
        {render.conversationInfo.isRender && <ConversationInfo component={{name: Component.name}} render={render.conversationInfo} />}
        <div className={`${Component.name}__body ${component.state.element.conversationContactInfo.open.show ? 'contact-info-open' : ''}`}>
          {render.conversation.isRender && !asyncState.isError && <Conversation component={{name: Component.name}} ref={element.conversation.ref} render={render.conversation} isLoading={asyncState.isLoading}/>}
          {render.conversationContactInfo.isRender && !asyncState.isError && <ConversationContactInfo component={{name: Component.name}} render={render.conversationContactInfo} element={element.conversationContactInfo} state={component.state.element.conversationContactInfo}/>}
          {asyncState.isError &&
            <DataFetchError onRetry={reboot}/>
          }
        </div>
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  line: PropTypes.object,
  conversation: PropTypes.object
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  render: null,
  line: null,
  conversation: null
};

export default Component.element;