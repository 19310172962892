import CalendarMessage from './CalendarMessage.component';

export default function CalendarMessageBlock(props) {
    const { reply, isWeekly, backgroundColor, actions } = props;
    const { days, schedules: schedulesRaw } = reply;

    const schedules =
        Array.isArray(schedulesRaw) && schedulesRaw.length > 0
            ? isWeekly
                ? schedulesRaw
                : [schedulesRaw[0]]
            : [{ start: '00:00', end: '24:00' }];

    const messages = (day, idx) =>
        schedules.map((schedule) => (
            <CalendarMessage
                key={`${day}-${schedule.start}:${schedule.end}-${idx}`}
                reply={reply}
                schedule={schedule}
                day={day}
                backgroundColor={backgroundColor}
                actions={actions}
            />
        ));

    if (reply.type === 'DAILY') {
        return days?.map((day) => messages(day));
    }

    const date = new Date(reply.date)

    if (date instanceof Date && !isNaN(date)) {
        return messages(date.getDay());
    }

    return null;
}
