import {useState} from 'react';
import EmployeesHeader from 'views/employees/components/EmployeesHeader.component';
import EmployeesList from 'views/employees/components/EmployeesList.component';

export default function EmployeesView() {
  const [registeredEmployees, setRegisteredEmployees] = useState(0);

  return (
    <>
      <EmployeesHeader 
        onRegisterEmployees={(event) => setRegisteredEmployees(event)}
      />
      <EmployeesList
        registeredEmployees={registeredEmployees}
        onReboot={() => setRegisteredEmployees(0)}
      />
    </>
  );
}