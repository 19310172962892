import { useEffect, useRef } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from 'hooks/useComponent.hook';
import AgentsHeader from 'views/chat/components/headers/AgentsHeader.component';
import AgentConversationsList from 'views/chat/components/lists/AgentConversationsList.component.js';
import NeoSlideMenu from 'design/design_components/neo/menu/NeoSlideMenu.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import useChat from 'hooks/useChat.hook';

const FILTER_ORDER = {
  LONGER_WAITING_TIME: 1
};

export default function AgentsSection(props) {
  const chat = useChat();
  const asyncState = useAsyncState();
  const component = useComponent({
    state: {
      transition: '',
      filterOrderActive: FILTER_ORDER.LONGER_WAITING_TIME
    }
  });

  useEffect(() => {
    setTimeout(() => {
      component.setState((state) => {
        state.transition = "transition";
      })
    }, 1)

    return () => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.AGENT.SELECTED.TYPE,
          payload: {
            agent: null
          }
        }
      })
    }
  }, []);

  useEffect(() => {
    let agents = chat.state.agents.filter((agent) => {
      return agent.lineId === chat.state.lineSelected.id;
    });

    switch (component.state.filterOrderActive) {
      case FILTER_ORDER.LONGER_WAITING_TIME:
        agents = orderAgentsByWaiting(agents);
        break;
    }
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.AGENTS.ASSIGNED.FILTERED.TYPE,
        payload: {
          agents
        }
      }
    })
  }, [chat.state.lineSelected?.id, chat.state.agents, component.state.filterOrderActive])

  const orderAgentsByWaiting = (agents) => {
    const newArrayOfAgents = [];
    const agentsWithMessagesContact  = orderAgentsByRecentAsc(agents.filter((agent) => {
      if(!agent.detail?.lastMessageWasSentByContact) {
        newArrayOfAgents.push(agent);
      }
      return agent.detail?.lastMessageWasSentByContact;
    })).reverse();

    newArrayOfAgents.unshift(...agentsWithMessagesContact);
    return newArrayOfAgents;
  }

  const orderAgentsByRecentAsc = (agents) => {
    return agents.sort((conversationA, conversationB) => {
      return new Date(conversationB.dateLastMessage) - new Date(conversationA.dateLastMessage);
    });
  }

  const FilterOrder = () => {
    const menu = useRef(null);
    const items = [
      {
        label: 'Cliente con más tiempo de espera',
        icon: component.state.filterOrderActive === FILTER_ORDER.LONGER_WAITING_TIME ? 'pi pi-circle-on' : 'pi pi-circle-off',
        command: () => {
          component.setState((state) => {
            state.filterOrderActive = FILTER_ORDER.LONGER_WAITING_TIME;
          });
        }
      },
    ];

    return <>
      <NeoSlideMenu ref={menu} model={items} popup className='FilterOrder'/>
      <NeoButtonMain type="button" icon="pi pi-sort-amount-down" onClick={(event) => menu.current.toggle(event)} />
    </>
  }

  return (
    <div className={`AgentsSection ${component.state.transition}`}>
      <AgentsHeader 
        isLoading={asyncState.isLoading}
        line={chat.state.lineSelected}
        filters={[FilterOrder]}
      />

      <AgentConversationsList
        isLoading={asyncState.isLoading}
        agents={chat.state.agentsFiltered}
        agentSelected={chat.state.agentSelected}
        onAgentSelect={(agent) => {
          if(agent !== "UNASSIGNED_CONVERSATIONS") {
            chat.dispatch((TYPE) => {
              return {
                type: TYPE.AGENT.SELECTED.TYPE,
                payload: {
                  agent
                }
              }
            })
          }
          if(typeof props.onAgentSelect === 'function') {
            props.onAgentSelect(agent);
          }
        }}
        activeUnassignedConversations={props.activeUnassignedConversations}
      />
    </div>
  );
}