import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import GeneralCampaignDetails from 'views/dashboard/components/GeneralCampaignDetails.component';
import CampaignTrackedLinks from 'views/dashboard/components/CampaignTrackedLinks.component';
import CampaignStatistics from 'views/dashboard/components/CampaignStatistics.component';
import NeoFlexColumn from 'design/design_components/neo/layout/NeoFlexColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import ResourceNotFound from 'components/ResourceNotFound.component';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import DataFetchError from 'components/DataFetchError.component';
import CampaignDetailHeader from 'views/dashboard/components/CampaignDetailHeader.component';
import CampaignsService from 'services/campaigns.service';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import CampaignFlowPreview from 'components/CampaignFlowPreview.component';

export default function CampaignDetail() {
  const { id } = useParams();
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(null);
  const [results, setResults] = useState(false);
  const [campaignFlow, setCampaignFlow] = useState([]);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaign()],
      {initialization: true}
    );
  }

  const getCampaign = async () => {
    const [lineId, name, recurrentCampaignNumber] = id.split('-');
    const response = await CampaignsService.getCampaign(lineId, name, {recurrentCampaignNumber});
    if (response.success) {
      setCampaign(response.payload);
      return { success: true };
    }
    if (response.error.internal) {
      return { success: false };
    }
    return { success: true };
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (campaign) &&
                <>
                  <CampaignDetailHeader
                    campaign={campaign}
                    results={results}
                    onChange={(value) => setCampaign(value)}
                  />
                  <NeoGridContainer>
                    <NeoFlexColumn sm="12" md="5" lg="6">
                      <GeneralCampaignDetails
                        campaign={campaign}
                      />
                    </NeoFlexColumn>
                    <NeoFlexColumn sm="12" md="7" lg="6">
                      <NeoCard>
                        <NeoInnerTitle extra='primero'>
                          Previsualización de campaña {campaign?.isDynamic === true ? 'dinámica' : ''}
                        </NeoInnerTitle>
                        <CampaignFlowPreview
                          campaign={campaign}
                          value={campaignFlow}
                          flow={campaign?.template ?? campaign?.flow}
                          isDynamicCampaign={campaign?.isDynamic === true}
                          onChange={(event) => setCampaignFlow(event)}
                        />
                        {
                          (campaign?.isDynamic === true) &&
                          <NeoButtonSection align='center'>
                            <NeoButtonMain
                              label='Reiniciar demo de campaña'
                              icon='pi pi-fw pi-refresh'
                              onClick={() => setCampaignFlow([])}
                            />
                          </NeoButtonSection>
                        }
                      </NeoCard>
                    </NeoFlexColumn>
                  </NeoGridContainer>
                  <CampaignStatistics
                    campaign={campaign}
                    onResultsChange={(event) => setResults(event)}
                  />
                  {
                    (campaign?.trackingURL === true) &&
                    <CampaignTrackedLinks 
                      campaign={campaign}
                    />
                  }
                </>
              }
              {
                (!campaign) &&
                <ResourceNotFound
                  title='La campaña no existe'
                  redirection={{label: 'Ir a campañas', path: MENU.ADMIN.CAMPAIGNS.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
}