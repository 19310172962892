import InternalSpinner from 'components/InternalSpinner.component';
import NeoProgressBar from 'design/design_components/neo/overlay/NeoProgressBar.base';

export default function LoadingMessage(props) {
  return (
    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginLeft: props.marginLeft ?? '0em'}}>
      <InternalSpinner size={40}/>
      <span style={{marginLeft: '1em', width: '100%'}}>
        {props.message ?? 'Por favor espere'}
        {props.progress !== undefined &&
          <NeoProgressBar value={props.progress} showValue={props.showValue} />
        }
      </span>
    </div>
  );
}