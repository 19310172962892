import { DateTime, DateTimeUnit } from 'luxon';
import { useState } from 'react';

export default function useCalendar(dateRaw) {
    const [date, setDate] = useState(DateTime.fromJSDate(dateRaw));
    const monthStart = date.startOf('month');
    const calendarStart = monthStart.minus({
        days: (monthStart.weekday - 1) % 7,
    });

    /**
     * @param unit {DateTimeUnit}
     */
    function prev(unit) {
        setDate(date.startOf(unit).minus({ [`${unit}s`]: 1 }));
    }

    /**
     * @param unit {DateTimeUnit}
     */
    function next(unit) {
        setDate(date.startOf(unit).plus({ [`${unit}s`]: 1 }));
    }

    /**
     * @param unit {DateTimeUnit}
     */
    function startOf(unit) {
        return date.startOf(unit);
    }

    /**
     * @param unit {DateTimeUnit}
     */
    function endOf(unit) {
        return date.endOf(unit);
    }

    function set(dateJS) {
        setDate(DateTime.fromJSDate(dateJS));
    }

    function reset() {
        setDate(DateTime.now());
    }

    return {
        date: date,
        start: calendarStart,
        set,
        startOf,
        endOf,
        next,
        prev,
        reset,
    };
}
