import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import { DateTime } from 'luxon';
import ChatBalloonContent from 'views/chat/components/items/ChatBalloonContent.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} event Chat event
 */

const Component = {
  name: 'ChatBalloon',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const event = props?.event && <ChatBalloonContent event={props.event}/>

      return (
        <div className={`${Component.name}__body`}>
          {event}
        </div>
      );
    };

    const createFooter = () => {
      const type = props?.event?.type === 'TEMPLATE' && <div className={`${Component.name}__footer__type`} key="type">
      {<span>Plantilla</span>}
    </div>;

      const agent = props?.event?.user && <div className={`${Component.name}__footer__agent`} key="agent">
        {<span>{props.event.user?.fullName ?? props.event.user?.email}</span>}
      </div>;

      const sending = props?.event?.sendingMessage && (
        <div className={`${Component.name}__footer__sending`} key="sending">
          <i className="fa-regular fa-clock"></i>
        </div>
      );

      const writtenAt = props?.event?.writtenAt && (
        <div className={`${Component.name}__footer__writtenAt`} key="writtenAt">
          <i className="fa-solid fa-check"></i>
        </div>
      );

      const failedAt = props?.event?.failedAt && (
        <div className={`${Component.name}__footer__failedAt`} key="failedAt">
          <i className="fa-solid fa-times"></i>
        </div>
      );

      const sentAt = props?.event?.sentAt && (
        <div className={`${Component.name}__footer__sentAt`} key="sentAt">
          <i className="fa-solid fa-check"></i>
        </div>
      );

      const readAt = props?.event?.readAt && (
        <div className={`${Component.name}__footer__readAt`} key="readAt">
          <i className="fa-solid fa-check-double"></i>
        </div>
      );

      const deliveredAt = props?.event?.deliveredAt && (
        <div className={`${Component.name}__footer__deliveredAt`} key="deliveredAt">
          <i className="fa-solid fa-check-double"></i>
        </div>
      );

      const date = props?.event?.timestamp && <div className={`${Component.name}__footer__date`} key="date">
        <span>
          {(() => {
            const now = DateTime.now();
            const datetime = DateTime.fromISO(props.event.timestamp);
            const diff = now.diff(datetime, ['days']).toObject();

            if(diff.days < 1) {
              return datetime.toFormat('h:mm a').toLowerCase();
            } else {
              return datetime.toFormat('dd-MM-yyyy h:mm a').toLowerCase();
            }
          })()}
        </span>
      </div>;

      return (
        <div className={`${Component.name}__footer`}>
          {props?.event?.isSender === true &&
            [
              type, 
              agent,
              failedAt || readAt || deliveredAt || sentAt || writtenAt || sending
            ]
          }
          {date}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, `${Component.name}--${props?.event?.isSender ? 'sender' : 'reciever'}`, props.className);
    const body = createBody();
    const footer = createFooter();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
        {footer}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  event: PropTypes.object,
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  event: null,
};

export default Component.element;