import backendRequestUtil from 'utils/backendRequest.util';

export default class AutomaticRepliesService {
    static async getAutomaticReplies(lineId, type, mode) {
        const params = { lineId, type, mode };
        const { success, body, error } = await backendRequestUtil.get(
            '/rest/automaticReplies',
            { params }
        );
        return success
            ? { success, payload: body.automaticReplies }
            : { success, error };
    }

    static async createAutomaticReply(reply) {
        const { success, body, error } = await backendRequestUtil.post(
            '/rest/automaticReply',
            reply
        );
        return success
            ? { success, payload: body }
            : { success, error };
    }

    static async deleteAutomaticReply(replyId) {
        const params = { replyId };
        const { success, body, error } = await backendRequestUtil.delete(
            '/rest/automaticReply',
            { params }
        );
        return success
            ? { success, payload: body }
            : { success, error };
    }

    static async updateAutomaticReply(replyId, reply) {
        const params = { replyId };
        const { success, body, error } = await backendRequestUtil.put(
            '/rest/automaticReply',
            reply,
            { params }
        );
        return success
            ? { success, payload: body }
            : { success, error };
    }
}
