export default {
  LANGUAGES: {
    af: 'Afrikáans (af)',
    ar: 'Árabe (ar)',
    az: 'Azerí (az)',
    bg: 'Búlgaro (bg)',
    bn: 'Bengalí (bn)',
    ca: 'Catalán (ca)',
    cs: 'Checo (cs)',
    da: 'Danés (da)',
    de: 'Alemán (de)',
    el: 'Griego (el)',
    en: 'Inglés (en)',
    en_GB: 'Inglés Reino Unido (en_GB)',
    en_US: 'Inglés Estados Unidos (en_US)',
    es: 'Español (es)',
    es_AR: 'Español Argentina (es_AR)',
    es_ES: 'Español España (es_ES)',
    es_MX: 'Español México (es_MX)',
    et: 'Estonio (et)',
    fa: 'Persa (fa)',
    fi: 'Finés (fi)',
    fil: 'Filipino (fil)',
    fr: 'Francés (fr)',
    ga: 'Irlandés (ga)',
    gu: 'Guyaratí (gu)',
    he: 'Hebreo (he)',
    hi: 'Hindi (hi)',
    hr: 'Croata (hr)',
    hu: 'Húngaro (hu)',
    id: 'Indonesio (id)',
    it: 'Italiano (it)',
    ja: 'Japonés (ja)',
    kk: 'Kazajo (kk)',
    kn: 'Canarés (kn)',
    ko: 'Coreano (ko)',
    lo: 'Lao (lo)',
    lt: 'Lituano (lt)',
    lv: 'Letón (lv)',
    mk: 'Macedonio (mk)',
    mr: 'Maratí (mr)',
    ms: 'Malayo (ms)',
    nb: 'Noruego (nb)',
    nl: 'Holandés (nl)',
    pa: 'Punyabí (pa)',
    pl: 'Polaco (pl)',
    pt_BR: 'Portugués Brasil (pt_BR)',
    pt_PT: 'Portugués Portugal (pt_PT)',
    ro: 'Rumano (ro)',
    ru: 'Ruso (ru)',
    sk: 'Eslovaco (sk)',
    sl: 'Eslovenio (sl)',
    sq: 'Albanés (sq)',
    sr: 'Serbio (sr)',
    sv: 'Sueco (sv)',
    sw: 'Suajili (sw)',
    ta: 'Tamil (ta)',
    te: 'Telugu (te)',
    th: 'Tailandés (th)',
    tr: 'Turco (tr)',
    uk: 'Ucraniano (uk)',
    ur: 'Urdu (ur)',
    uz: 'Uzbeko (uz)',
    vi: 'Vietnamita (vi)',
    zh_CN: 'Chino (zh_CN)',
    zh_HK: 'Chino Hong Kong (zh_HK)',
    zh_TW: 'Chino Taiwán (zh_TW)'
  },
  CATEGORIES: {
    ACCOUNT_UPDATE: 'Actualización de cuenta',
    PAYMENT_UPDATE: 'Actualización de pago',
    PERSONAL_FINANCE_UPDATE: 'Actualización de finanzas personales',
    SHIPPING_UPDATE: 'Actualización de envío',
    RESERVATION_UPDATE: 'Actualización de reservación',
    ISSUE_RESOLUTION: 'Resolución de problema',
    APPOINTMENT_UPDATE: 'Actualización de cita',
    TRANSPORTATION_UPDATE: 'Actualización de transporte',
    TICKET_UPDATE: 'Actualización de boleto',
    ALERT_UPDATE: 'Actualización de alerta',
    AUTO_REPLY: 'Respuesta automática',
    AUTHENTICATION: 'Autenticación',
    MARKETING: 'Marketing',
    UTILITY: 'Utilidad',
  },
  TYPE_OF_USE: {
    CAMPAIGN: 'Campaña',
    START_CONVERSATION: 'Inicio de conversación'
  },
  STATUSES: {
    SUBMITTED: 'Enviada',
    PENDING: 'Pendiente',
    APPROVED: 'Aprobada',
    REJECTED: 'Rechazada'
  },
  HEADER: {
    TYPES: {
      TEXT: 'Texto',
      IMAGE: 'Imagen',
      VIDEO: 'Video',
      DOCUMENT: 'Documento'
    }
  }
};