import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import ContactsService from 'services/contacts.service';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import RegisteredContacts from 'views/dashboard/components/RegisteredContacts.components';
import IncludedContacts from 'views/dashboard/components/IncludedContacts.component';
import NewReachedContacts from 'views/dashboard/components/NewReachedContacts.components';

export default function ContactsStatistics(props) {
  const asyncState = useAsyncState({isInitialized: true});
  const [statistics, setStatistics] = useState();

  useEffect(async () => await reboot(), [props.filters]);

  const reboot = async () => {
    await asyncState.allPromises([getContactsStatistics()]);
  }

  const getContactsStatistics = async () => {
    const fromDateString = props.filters?.period?.from;
    const toDateString = props.filters?.period?.to;
    const response = await ContactsService.getContactsStatistics(fromDateString, toDateString);
    if(response.success) {
      setStatistics(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  return (
    <NeoGridContainer>
      <NeoColumn md='5'>
        <RegisteredContacts
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.registeredContactsCount}
          onRetry={() => reboot()}
        />
      </NeoColumn>
      <NeoColumn md='7'>
        <IncludedContacts
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.includedContactsCount}
          onRetry={() => reboot()}
        />
      </NeoColumn>
      <NeoColumn md='12'>
        <NewReachedContacts
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.reachedContactsByDate}
          onRetry={() => reboot()}
        />
      </NeoColumn>
    </NeoGridContainer>
  );
}