import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import NeoButtonSmallSquared from 'design/design_components/neo/button/NeoButtonSmallSquared.base';
import NeoLink from 'design/design_components/neo/button/NeoLink.base';

export default function CampaignDetailLink(props) {
  const { campaign } = props;
  const history = useHistory();

  const handleBasicCampaignNameClick = () => {
    const { lineId, name } = campaign;
    const campaignId = `${lineId}-${name}`;
    history.push(`${MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.BASE}/${campaignId}`);
  }

  const handleRecurrentCampaignNameClick = () => {
    const { lineId, name } = campaign;
    const campaignId = `${lineId}-${name}`;
    history.push(`${MENU.ADMIN.DASHBOARD.RECURRENT_CAMPAIGN_DETAIL.BASE}/${campaignId}`);
  }

  const handleRecurrentCampaignSendingClick = () => {
    const { lineId, name, recurrentCampaignNumber } = campaign;
    const campaignId = `${lineId}-${name}-${recurrentCampaignNumber}`;
    history.push(`${MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.BASE}/${campaignId}`);
  }

  return (
    <>
      {
        (campaign.isRecurrent === false) &&
        <NeoLink onClick={handleBasicCampaignNameClick}>
          <b>{campaign.name}</b>
        </NeoLink>
      }
      {
        (campaign.isRecurrent === true && campaign.recurrentCampaignNumber !== undefined) &&
        <>
          <div className='campaign-detail-link-container'>
            <NeoLink title={campaign.name} onClick={handleRecurrentCampaignNameClick}>
              <b>{campaign.name}</b>
            </NeoLink>
          </div>
          <NeoButtonSmallSquared
            label={`envío ${campaign.recurrentCampaignNumber}`}
            onClick={handleRecurrentCampaignSendingClick}
          />
        </>
      }
    </>
  );
}