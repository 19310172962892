import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base"
import EmployeesService from 'services/employees.service';
import useComponent from 'hooks/useComponent.hook';
import _ from 'lodash';
import NeoMessage from "design/design_components/neo/messages/NeoMessage.base";

const AgentSelectGroupStatus = (props) => {
    const component = useComponent({
        name: 'AgentSelectGroupStatus',
        state: {
            element: {
                dropdown: {
                    options: [],
                    selected: -1,
                    message: null,
                    disabled: false
                }
            }
        },
        async: {
            key: [props.lineId, props.areaId],
            get: async () => {
                const response = await EmployeesService.getAgents(props.lineId, props.areaId);
                if(response.success) {
                    let agents = response.payload?.agents?.map((agent) => {
                        agent.username = agent.name ?? agent.email;
                        return agent
                    })
                    if(typeof props.agentsFilter === 'function') {
                        agents = agents.filter(props.agentsFilter);
                    }
                    return agents;
                }
            }
        }
    });

    component.useEffect(() => {
        if(typeof props.onChange === 'function') {
            props.onChange(component.state.element.dropdown.disabled 
                ? null 
                : {id: component.state.element.dropdown.selected}
            );
        }
    }, [component.state.element.dropdown.selected, component.state.element.dropdown.disabled])

    component.useEffect(() => {
        component.setState((state) => {
            if(Array.isArray(component.async.get.data)) {
                state.element.dropdown.options = _
                .chain(component.async.get.data)
                .groupBy('status')
                .map((value, key) => {
                    switch (key) {
                        case 'ONLINE':
                            key = 'Disponible';
                            break;
                        case 'TO_GET_OUT':
                            key = 'Por terminar';
                            break;
                        case 'AWAY':
                        case 'DISCONNECTED':
                            key = 'No disponible';
                            break;
                    }
                    return { status: key, users: value }
                })
                .value();

                if(state.element.dropdown.selected !== -1 && component.async.get.data.find((agent) => agent.id === component.state.element.dropdown.selected) === undefined) {
                    state.element.dropdown.selected = -1;
                }
            } else {
                state.element.dropdown.options = [];
            }
            state.element.dropdown.options.unshift({status: '', users: [{id: -1, username: 'Primer agente con disponibilidad'}]});
            state.element.dropdown.disabled = state.element.dropdown.options.length === 1 ? true : false;
        });
    }, [component.async.get.data])

    component.useEffect(() => {
        component.setState((state) => {
            const id = state.element.dropdown.selected;
            state.element.dropdown.message = null;
            if(id === -1) {
                if(component.async.get.data?.length === 0) {
                    state.element.dropdown.message = {
                        severity: 'warn',
                        text: 'No existen otros agentes dentro del área seleccionada'
                    };
                }
                else if(component.async.get.data?.some(agent => agent.status === 'ONLINE') === false) {
                    state.element.dropdown.message = {
                        severity: 'warn',
                        text: '*Por el momento no hay agentes disponibles, el cliente quedará desatendido hasta que algún otro agente se conecte'
                    }
                }
            } else {
                const agent = component.async.get.data?.find(agent => agent.id === id);
                if(agent && agent.status !== 'ONLINE') {
                    state.element.dropdown.message = {
                        severity: 'warn',
                        text: '*Este agente no está disponible, el cliente quedará desatendido hasta que el agente se conecte de nuevo'
                    }
                }
            }
        });
    }, [component.state.element.dropdown.selected, component.async.get.data])

    const onChange = (event) => {
        const {value: id} = event;
        component.setState((state) => {state.element.dropdown.selected = id});
    }

    return (<>
        <NeoDropdown 
            label="Agente a asignar"
            value={component.state.element.dropdown.selected}
            options={component.state.element.dropdown.options}
            filter
            filterBy="username"
            optionLabel="username"
            optionValue="id"
            optionGroupLabel="status"
            optionGroupChildren="users"
            emptyMessage='No hay agentes disponibles'
            emptyFilterMessage='Sin resultados'
            onChange={onChange}
            disabled={component.state.element.dropdown.disabled}
        />
        {component.state.element.dropdown.message &&
            <NeoMessage 
                severity={component.state.element.dropdown.message.severity} 
                text={component.state.element.dropdown.message.text}
            />
        }
    </>)
}

export default AgentSelectGroupStatus;
