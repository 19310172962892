import { InputText } from "primereact/inputtext";

const BaseInputGroup = (props) => {
    return (
        <div className={`p-inputgroup ${props.className}`} ref={props.ref}>
            <span className="p-inputgroup-addon" title={props.title}>
                {props.hasOwnProperty("icon") &&
                    <i className={props.icon}></i>
                }
                {props.hasOwnProperty("label") &&
                    <span className="label">{props.label}</span>
                }
            </span>
            {props?.type === 'TEXT' &&
                <span key={props.key} className="p-float-label">
                    <InputText value={props.inputValue} onChange={props.inputOnChange} />
                    <label>{props.inputLabel}</label>
                </span>
            }
        </div>
    )
}

BaseInputGroup.defaultProps = {
    className: ''
}

export default BaseInputGroup;