import csvUtil from 'utils/csv.util';
import NeoInputFile from 'design/design_components/neo/form/NeoInputFile.base';

const types = [
  'text/csv',
  'application/vnd.ms-excel'
];

export default function CsvFileExplorer(props) {

  const handleFileInputChange = async (event) => {
    const files = [];
    const csvFiles = event.files.filter((file) => types.includes(file.type));
    event.options.clear();
    for(const csvFile of csvFiles) {
      const buffer = Buffer.from(await csvFile.arrayBuffer());
      const worksheet = await csvUtil.readFromBuffer(buffer, {limitRows: 3});
      files.push({file: csvFile, worksheet});
    }
    props.onChange(files);
  }

  return (
    <>
      <NeoInputFile
        auto
        customUpload
        multiple={true}
        accept='.csv'
        chooseLabel='Carga archivos .csv'
        emptyTemplate={<p>O arrástralos aquí</p>}
        uploadHandler={handleFileInputChange}
      />
    </>
  );
}