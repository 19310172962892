import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import StartConversationTemplateVariable from 'views/conversations/components/StartConversationTemplateVariable.component';

const UNMAPPED = '';

export default function StartConversationTemplateBodyVariables(props) {
  const [variables, setVariables] = useState([]);

  useEffect(() => getVariables(), [props.requiredVariables]);

  const getVariables = () => {
    const requiredVariables = props.requiredVariables?.map((variable) => {
      const label = variable;
      const key = variable.substring(2, variable.length - 2);
      const value = props.value?.[key] ?? {type: 'OPTION', payload: UNMAPPED};
      return {label, key, value};
    });
    setVariables(requiredVariables ?? []);
  }

  const handleVariableChange = (index, event) => {
    const variablesEntries = variables.map((variable) => ({...variable}));
    variablesEntries[index].value = event;
    handleChange(variablesEntries);
  }

  const handleChange = (updatedVariables) => {
    const variablesEntries = updatedVariables.map((variable) => [variable.key, variable.value]);
    const payload = Object.fromEntries(variablesEntries);
    props.onChange(payload);
  }

  return (
    <>
      {
        (variables.length > 0) &&
        <>
          <NeoInnerSubtitle>Cuerpo</NeoInnerSubtitle>
          {
            variables.map((variable, index) => (
              <StartConversationTemplateVariable
                key={index}
                optionPlaceholder='Campo a mapear'
                textPlaceholder='Valor de variable'
                variable={variable.label}
                value={variable.value}
                options={props.options}
                onChange={(event) => handleVariableChange(index, event)}
              />
            ))
          }
        </>
      }
    </>
  );
}