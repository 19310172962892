import NeoTabMenu from 'design/design_components/neo/menu/NeoTabMenu.base';
import useComponent from 'hooks/useComponent.hook';
import AutomaticReplyDefault from 'views/settings/components/AutomaticReplyDefault.component';
import AutomaticReplyDailyCalendar from '../automaticReply/AutomaticReplyDailyCalendar.component';
import AutomaticReplySpecificCalendar from '../automaticReply/AutomaticReplySpecificCalendar.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';

const MODELS = {
    NO_AGENTS: {label: 'Si no hay agentes'},
    BUSY_AGENTS: {label: 'Si hay agentes pero en cola llena'},
    AVAILABLE_AGENTS: {label: 'Si hay agentes disponibles'},
    ALWAYS: {label: 'Siempre'},
};

const AutomaticReplyTabPanel = (props) => {
    const component = useComponent({
        state: {
            activeIndex: 0
        }
    });

    component.useEffect(() => {
        if(props.mode) {
            const activeIndex = Object.entries(MODELS).findIndex(([KEY]) => {
                return KEY === props.mode;
            });
            if(activeIndex !== -1 && component.state.activeIndex !== activeIndex) {
                component.setState((state) => {
                    state.activeIndex = activeIndex;
                })
            }
        }
    }, [props.mode])

    const getModels = () => {
        return Object.entries(MODELS).map(([KEY, properties]) => {
            const {label} = properties;
            return {label};
        });
    }

    return (<>
        <NeoTabMenu 
            model={getModels()}
            activeIndex={component.state.activeIndex}
            onTabChange={(event) => {
                const {index} = event;
                component.setState((state) => {
                    state.activeIndex = index;
                })
                if(typeof props?.onModeChange === 'function') {
                    const [KEY] = Object.entries(MODELS)[index];
                    props.onModeChange(KEY);
                }
            }}
        />

        {props.type === 'GENERIC' &&
            <AutomaticReplyDefault 
                automaticReplies={props.automaticReplies}
                actions={props?.generic?.actions}
            />
        }

        {props.type === 'DAILY' &&
            <NeoCard>
                <AutomaticReplyDailyCalendar 
                    automaticReplies={props.automaticReplies}
                    actions={props.generic?.actions}
                />
            </NeoCard>
        }

        {props.type === 'SPECIFIC' &&
            <NeoCard>
                <AutomaticReplySpecificCalendar 
                    automaticReplies={props.automaticReplies}
                    actions={props.generic?.actions}
                    view={props.view}
                />
            </NeoCard>
        }
    </>)
}

export default AutomaticReplyTabPanel;
