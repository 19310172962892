import Icono from 'design/assets/img/cta/icons/icon-contacts.svg';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';

export default function ContactDetailHeader(props) {
  return (
    <NeoGridContainer>
      <NeoTitleSecond
        icon={Icono}
        title={props.contact.name ?? `~ ${props.contact.alias}`}
        subtitle='Detalle de contacto'
      />
    </NeoGridContainer>
  );
}