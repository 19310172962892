import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

export default function ReachedContacts(props) {
  const [data, setData] = useState({});

  useEffect(async () => reboot(), [props.data]);

  const reboot = () => {
    setData({
      new: props.data?.new ?? 0,
      reached: props.data?.reached ?? 0,
      invalid: props.data?.invalid ?? 0
    });
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoInnerTitle extra='primero'>Contactos incluidos</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Nuevos'
                  fact={`${data.new}`}
                />
              </NeoColumn>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Alcanzados'
                  fact={`${data.reached}`}
                />
              </NeoColumn>
              <NeoColumn col='4'>
                <NeoDataSecond
                  label='Sin WhatsApp'
                  fact={`${data.invalid}`}
                />
              </NeoColumn>
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}