import backendRequestUtil from 'utils/backendRequest.util';

export default class LinesService {

  static async getLine(lineId) {
    const params = {lineId};
    const {success, body, error} = await backendRequestUtil.get('/api/line', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getLines() {
    const {success, body, error} = await backendRequestUtil.get('/api/lines');
    return success ? {success, payload: body.lines} : {success, error};
  }

  static async getLinePhoto(lineId) {
    const params = {lineId};
    const {success, headers, body, error} = await backendRequestUtil.get('/api/linePhoto', {params, responseType: 'arraybuffer'});
    return success ? {success, payload: {mimeType: headers['content-type'], buffer: Buffer.from(body)}} : {success, error};
  }

  static async getLineInfo(lineId) {
    const params = {lineId};
    const {success, body, error} = await backendRequestUtil.get('/api/lineInfo', {params});
    return success ? {success, payload: body.info} : {success, error};
  }

  static async getLineProfile(lineId) {
    const params = {lineId};
    const {success, body, error} = await backendRequestUtil.get('/api/lineProfile', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async updateLine(lineId, fields = {}) {
    const params = {lineId};
    const requestBody = {...fields};
    const {success, error} = await backendRequestUtil.put('/api/line', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async updateLinePhoto(lineId, file) {
    const params = {lineId};
    const headers = {'Content-Type': file.type};
    const {success, error} = await backendRequestUtil.put('/api/linePhoto', file, {headers, params});
    return success ? {success} : {success, error};
  }

  static async updateLineInfo(lineId, info) {
    const params = {lineId};
    const requestBody = {info};
    const {success, error} = await backendRequestUtil.put('/api/lineInfo', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async updateLineProfile(lineId, profile) {
    const params = {lineId};
    const requestBody = {...profile};
    const {success, error} = await backendRequestUtil.put('/api/lineProfile', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async getAreas(lineId) {
    const params = {lineId};
    const {success, body, error} = await backendRequestUtil.get('/rest/lineAreas', {params});
    return success ? {success, payload: body} : {success, error};
  }

}