import {useState, useEffect} from 'react';
import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend} from 'recharts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoResponsiveContainer from 'design/design_components/neo/chart/NeoResponsiveContainer.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import InfoTooltip from 'components/InfoTooltip.component';
import dateTimeUtil from 'utils/dateTime.util';

const colors = {
  campaigns: '#F5B379',
  business: '#7292BE',
  client: '#94CF7A'
};

export default function ConversationsOrigin(props) {
  const [data, setData] = useState([]);

  useEffect(async () => await reboot(), [props.data]);

  const reboot = async () => {
    if(props.data) {
      const payload = props.data.map((value) => {
        const {day, month, year} = value.date;
        const date = new Date(`${year}-${month}-${day} 00:00`);
        const dateString = dateTimeUtil.getDateString(date, {dateStyle: 'medium'});
        return {...value, date: dateString};
      });
      setData(payload);
    }
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoFlexContainer>
        <NeoInnerTitle extra='primero'>Origen de conversaciones con cobro</NeoInnerTitle>
        <InfoTooltip extra="large" position="left" id='description'>
          <p className='mt-0'>Esta gráfica muestra un comparativo del origen de las conversaciones iniciadas que resultaron en un cobro.</p>
          <p><b><i>Empresa-Campaña:</i></b> Conversaciones iniciadas por la empresa con envío masivo de plantillas.</p>
          <p><b><i>Empresa-Unitario:</i></b> Conversaciones iniciadas por la empresa con envío unitario de una plantilla.</p>
          <p className='mb-0'><b><i>Cliente:</i></b> Conversaciones iniciadas por el cliente con mensaje entrante.</p>
        </InfoTooltip>
      </NeoFlexContainer>
      {
        (props.loading) &&
        <InternalSpinner />
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <NeoResponsiveContainer width='100%' height={300}>
              <AreaChart data={data}>
                <XAxis dataKey='date' />
                <YAxis label={{ value: 'Conversaciones', angle: -90, position: 'insideBottomLeft' }} />
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip />
                <Legend />
                <Area type='monotone' name='Empresa-Campaña' dataKey='campaigns' stroke={colors.campaigns} fill={colors.campaigns} />
                <Area type='monotone' name='Empresa-Unitario' dataKey='business' stroke={colors.business} fill={colors.business} />
                <Area type='monotone' name='Cliente' dataKey='client' stroke={colors.client} fill={colors.client} />
              </AreaChart>
            </NeoResponsiveContainer>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}