import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';

export default function MultimediaFilesExplorer(props) {
  
  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files).map((file) => {
      const {name, size, type} = file;
      return {name, size, type, file};
    });
    props.onChange(files);
    event.target.value = '';
  }

  return (
    <>
      <input
        multiple
        className='p-d-none'
        id='explorer'
        type='file'
        onChange={handleFileInputChange}
      />
      <NeoButtonMain
        label='Agregar archivos'
        icon="pi pi-plus"
        disabled={props.disabled === true}
        onClick={() => document.getElementById('explorer').click()}
      />
    </>
  );
}