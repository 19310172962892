import {useState} from 'react';
import useMessages from 'hooks/useMessages.hook';
import TOAST from 'consts/toast.consts';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import LoadingDialog from 'components/LoadingDialog.component';
import ContactsService from 'services/contacts.service';
import csvUtil from 'utils/csv.util';

export default function InvalidPhonesDownloadButton(props) {
  const messages = useMessages();
  const [showDownloadingDialog, setShowDownloadingDialog] = useState(false);

  const handleDownloadButtonClick = async () => {
    const fromDateString = props.period?.from;
    const toDateString = props.period?.to;
    if(fromDateString !== undefined && toDateString !== undefined) {
      setShowDownloadingDialog(true);
      const response = await ContactsService.getInvalidPhonesDetailFile(fromDateString, toDateString);
      if(response.success) {
        const downloadInstance = document.createElement('a');
        downloadInstance.href = response.payload.urlDownload;
        downloadInstance.target = '_blank';
        downloadInstance.click();
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se descargó el reporte de números sin WhatsApp.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo descargar el reporte de números sin WhatsApp, inténtalo de nuevo.');
      }
      setShowDownloadingDialog(false);
    }
  }

  return (
    <>
      <NeoButtonMain
        icon='pi pi-download'
        label='Reporte de números sin WhatsApp'
        disabled={props.loading}
        onClick={handleDownloadButtonClick}
      />
      {
        <LoadingDialog
          visible={showDownloadingDialog}
          message='Generando archivo (.csv)'
        />
      }
    </>
  );
}