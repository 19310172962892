import {useState, useEffect, useRef} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import InternalSpinner from 'components/InternalSpinner.component';
import campaignsConsts from 'consts/campaigns.consts';
import csvUtil from 'utils/csv.util';

export default function ContactsOriginSummary(props) {
  const tableRef = useRef();
  const asyncState = useAsyncState();
  const [files, setFiles] = useState([]);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getFiles()],
      {initialization: true}
    );
  }

  const getFiles = async () => {
    const filesDatabase = [];
    for(const file of props.files) {
      const filename = file.file.name;
      const buffer = Buffer.from(await file.file.arrayBuffer());
      const worksheet = await csvUtil.readFromBuffer(buffer);
      const columns = getFileRequiredColumns(file.map, {isDynamic: props.isDynamic === true});
      const {records, exampleRows} = getRecordsCount(worksheet.rows, columns);
      filesDatabase.push({filename, ...records, exampleRows});
    }
    const updatedFiles = [...props.files].map((file, index) => {
      const {exampleRows} = filesDatabase[index];
      return {...file, exampleRows};
    });
    setFiles(filesDatabase);
    props.onFilesChange(updatedFiles);
    return {success: true};
  }

  const getFileRequiredColumns = (map, options = {}) => {
    let columns = [...new Set(Object.values(map.map))];
    if(options.isDynamic === true) {
      const autoReplies = Object.values(map.autoReplies ?? {});
      for(const autoReply of autoReplies) {
        columns = [...new Set([...columns, ...Object.values(autoReply.map)])];
      }
    }
    return columns;
  }

  const getRecordsCount = (rows, columns) => {
    const exampleRows = [];
    const uniqueRowsStrings = {};
    const records = {total: 0, empty: 0, full: 0, duplicate: 0};
    for(const row of rows) {
      const sanitizedRow = Object.fromEntries(columns.map((column) => [column, row[column]]));
      const containsAllColumns = columns.every((column) => sanitizedRow[column]?.length > 0);
      if(containsAllColumns === true) {
        const rowString = JSON.stringify(sanitizedRow);
        if(uniqueRowsStrings[rowString] === true) { records.duplicate += 1; }
        else {
          if(exampleRows.length < campaignsConsts.EXAMPLES_PER_DATABASE) { exampleRows.push(sanitizedRow); }
          uniqueRowsStrings[rowString] = true; 
        }
        records.full += 1;
      }
      else { records.empty += 1; }
      records.total += 1;
    }
    return {records, exampleRows};
  }

  const elements = {
    filenameColumnBody: (data) => (
      <b>{data.filename}</b>
    ),
  };

  return (
    <>
      {
        (props.files.length > 0) &&
        <>
          <NeoTitleMain title='Resumen de origen de contactos'/>
          {
            (asyncState.isInitialized) &&
            <NeoTable
              ref={tableRef}
              value={files}
              removableSort
              extra='no-filters p-mb-3'
              emptyMessage='Aún no has cargado ningún archivo con los contactos que recibirán esta campaña'
            >
              <NeoTableColumn
                header='Origen'
                field='filename'
                body={elements.filenameColumnBody}
              />
              <NeoTableColumn
                header='Registros totales'
                field='total'
              />
              <NeoTableColumn
                header='Registros vacíos'
                field='empty'
              />
              <NeoTableColumn
                header='Registros duplicados'
                field='duplicate'
              />
              <NeoTableColumn
                header='Registros completos'
                field='full'
              />
            </NeoTable>
          }
          {
            (!asyncState.isInitialized) &&
            <NeoCard>
              <InternalSpinner/>
            </NeoCard>
          }
        </>
      }
    </>
  );
}