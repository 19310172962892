import { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from "hooks/useComponent.hook";
import ChatService from 'services/chat.service';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import DataFetchError from 'components/DataFetchError.component';
import reactStringReplace from 'react-string-replace';
import TOAST from 'consts/toast.consts';
import ModifyQuickReplyDialog from './ModifyQuickReplyDialog.component';
import useMessages from 'hooks/useMessages.hook';

const QuicklyRepliesList = forwardRef((props, ref) => {
  const messages = useMessages()
  const tableRef = useRef();
  const asyncState = useAsyncState({ isInitialized: true });
  const component = useComponent({
    state: {
      element: {
        tableQuickReplies: {
          quickReplies: []
        }
      }
    }
  });

  useImperativeHandle(ref, () => ({
    addQuicklyReply: (quicklyReply) => {
      component.setState((state) => {
        state.element.tableQuickReplies.quickReplies.push(quicklyReply);
      })
    },
    setQuickReplies: (quickReplies) => {
      component.setState((state) => {
        state.element.tableQuickReplies.quickReplies = quickReplies
      })
    },
    deleteQuickReply: (quickReplyId) => {
      component.setState((state) => {
        const quickReplies = state.element.tableQuickReplies.quickReplies.filter(quickReply => quickReply.id !== quickReplyId)
        state.element.tableQuickReplies.quickReplies = quickReplies
      })
    },
    updateQuickReply: (updatedQuickReply) => {
      component.setState((state) => {
        const id = state.element.tableQuickReplies.quickReplies.findIndex(quickReply => quickReply.id === updatedQuickReply.id)
        state.element.tableQuickReplies.quickReplies[id] = updatedQuickReply
      })
    }
  }));

  useEffect(async () => await reboot(), []);

  const reboot = async () => {
    await asyncState.allPromises(
      [getQuicklyReplies()]
    );
  }

  const getQuicklyReplies = component.useAsync(async () => {
    const response = await ChatService.getQuicklyReplies();
    if(response.success) {
      ref.current?.setQuickReplies(response.payload.quicklyReplies)
      return {success: true};
    }
    return {success: false};
  });

  const elements = {
    titleFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='title' placeholder='Buscar por título' />
    ),
    replieFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='replie' placeholder='Buscar por descripción' />
    ),
    titleColumnBody: (data) => (
      <>{data.title}</>
    ),
    replieColumnBody: (data) => {
      const replie = reactStringReplace(data.replie, '\n', () => (
        <br/>
      ));
      return <>{replie}</>
    },
  };

  // Modify

  const [quickReply, setQuickReply] = useState({ id: 0, title: '', replie: '' })
  const [showModifyQuickReplyDialog, setShowModifyQuickReplyDialog] = useState(false)
  const handleModifyQuickReplyDialogHide = (event) => {
    if (event.success) {
      setShowModifyQuickReplyDialog(false)
      ref.current?.updateQuickReply(event.payload)
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se modificó la respuesta rápida.'
      );
    } else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo actualizar el nombre de la respuesta rápida, inténtalo de nuevo.'
      );
    }
  };
  const handleTableRowSelect = (event) => {
    setQuickReply(event.data)
    setShowModifyQuickReplyDialog(true)
  }

  return (
    <>
      <NeoTable
        selectionMode="single"
        onRowSelect={handleTableRowSelect}
        ref={tableRef}
        value={component.state.element.tableQuickReplies.quickReplies}
        paginator
        rows={10}
        removableSort
        sortField='title'
        sortOrder={-1}
        emptyMessage='Sin respuestas rápidas'
      >
        <NeoTableColumn
          field='title'
          header='Titulo'
          filter
          sortable
          filterMatchMode='contains'
          filterElement={elements.titleFilterInput}
          body={elements.titleColumnBody}
          style={{ width: '25%' }}
        />
        <NeoTableColumn
          field='replie'
          header='Descripción'
          filter
          sortable
          filterMatchMode='contains'
          filterElement={elements.replieFilterInput}
          body={elements.replieColumnBody}
          className='text-ellipsis'
        />
      </NeoTable>
      {
        (!asyncState.isSuccessfully && !asyncState.isLoading) &&
        <NeoCard>
          <DataFetchError 
            internal 
            onRetry={reboot}
            title='No se pudieron cargar las respuestas rápidas'
          />
        </NeoCard>
      }

      <ModifyQuickReplyDialog
        listRef={ref}
        quickReply={quickReply}
        visible={showModifyQuickReplyDialog}
        visibleSetter={setShowModifyQuickReplyDialog}
        onHide={handleModifyQuickReplyDialogHide}
      />
    </>
  );
})

export default QuicklyRepliesList;
