import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import TemplateHeaderMapping from 'views/campaigns/components/TemplateHeaderMapping.component';
import TemplateBodyMapping from 'views/campaigns/components/TemplateBodyMapping.component';
import TemplateButtonsMapping from 'views/campaigns/components/TemplateButtonsMapping.component';
import templatesUtil from 'utils/templates.util';

const UNMAPPED = '';

export default function TemplateMapping(props) {
  const [templateRequiredVariables] = useState(templatesUtil.getTemplateRequiredParams(props.template.structure));

  useEffect(() => handleMappingChange(), [props.mapping]);

  const handleMappingChange = () => {
    const valid = getIsMappingValid(props.mapping);
    props.onMappingValidationChange(valid);
  }

  const handleTemplateMappingChange = (event) => {
    const mapping = JSON.parse(JSON.stringify({...props.mapping, ...event}));
    props.onMappingChange(mapping);
  }

  const getIsMappingValid = (mapping) => {
    const isHeaderMappingValid = getIsHeaderMappingValid(mapping);
    const isBodyMappingValid = getIsBodyMappingValid(mapping);
    const isButtonMappingValid = getIsButtonsMappingValid(mapping);
    return isHeaderMappingValid && isBodyMappingValid && isButtonMappingValid;
  }

  const getIsHeaderMappingValid = (mapping) => {
    if(templateRequiredVariables.header !== undefined) {
      const {header} = templateRequiredVariables;
      if(header.type === 'TEXT') {
        const variable = header.param;
        const key = `header.text.${variable.substring(2, variable.length - 2)}`;
        const map = mapping[key];
        return map !== undefined && map !== UNMAPPED;
      }
      const lowerCaseType = header.type.toLowerCase();
      const idMap = mapping[`header.${lowerCaseType}.id`];
      const urlMap = mapping[`header.${lowerCaseType}.url`];
      const idValid = idMap !== undefined && idMap !== UNMAPPED;
      const urlValid = urlMap !== undefined && urlMap !== UNMAPPED;
      return (idValid || urlValid) && idValid !== urlValid;
    }
    return true;
  }

  const getIsBodyMappingValid = (mapping) => {
    const variablesMappingStatus = templateRequiredVariables.body?.map((variable) => {
      const key = `body.${variable.substring(2, variable.length - 2)}`;
      const map = mapping[key];
      return map !== undefined && map !== UNMAPPED;
    });
    return (variablesMappingStatus ?? []).every((status) => status === true);
  }

  const getIsButtonsMappingValid = (mapping) => {
    const variablesMappingStatus = templateRequiredVariables.buttons?.map((variable) => {
      const key = `buttons.${variable.index + 1}.url.${variable.param.substring(2, variable.param.length - 2)}`;
      const map = mapping[key];
      return map !== undefined && map !== UNMAPPED;
    });
    return (variablesMappingStatus ?? []).every((status) => status === true);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>
        {props.template.name} ({props.template.language})
      </NeoInnerTitle>
      {
        (
          templateRequiredVariables.header === undefined &&
          templateRequiredVariables.body.length === 0 &&
          templateRequiredVariables.buttons === undefined
        ) &&
        <NeoInnerSubtitle>La plantilla no tiene variables.</NeoInnerSubtitle>
      }
      <TemplateHeaderMapping
        mapping={props.mapping}
        requiredVariables={templateRequiredVariables.header}
        databaseType={props.databaseType}
        mappingOptions={props.mappingOptions}
        onChange={(event) => handleTemplateMappingChange(event)}
      />
      <TemplateBodyMapping
        mapping={props.mapping}
        requiredVariables={templateRequiredVariables.body}
        databaseType={props.databaseType}
        mappingOptions={props.mappingOptions}
        onChange={(event) => handleTemplateMappingChange(event)}
      />
      <TemplateButtonsMapping
        mapping={props.mapping}
        requiredVariables={templateRequiredVariables.buttons}
        databaseType={props.databaseType}
        mappingOptions={props.mappingOptions}
        onChange={(event) => handleTemplateMappingChange(event)}
      />
    </NeoCard>
  );
}