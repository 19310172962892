import { forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import ContactConversationItem from 'views/chat/components/items/ContactConversationItem.component';
import useComponent from 'hooks/useComponent.hook';
import useChat from 'hooks/useChat.hook';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {Array} conversations Array of objects conversations
 * @property {Object} conversationSelected Conversation selected
 * @property {Function} onConversationSelect Action on select conversation
 * @property {Object} elements Status of child elements
 */

const Component = {
  name: 'ContactConversationsList',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const component = useComponent();
    const chat = useChat();
    const elementRef = useRef(ref);

    const createBody = () => {
      const conversationsLazyLoading = (props.isLoading) && [...Array(7)].map((value, key) => {
        return <ContactConversationItem key={key} isLoading={true} />
      });

      const conversations = (props.conversations && !props.isLoading ) && props.conversations.map((conversation, key) => {
        return <ContactConversationItem
          key={key}
          onClick={() => props?.onConversationSelect(conversation)}
          name={conversation?.contact?.name}
          alias={conversation?.contact?.alias}
          phone={conversation?.contact?.phone}
          color={conversation?.contact?.color}
          lastMessageSentAt={conversation?.lastMessageSentAt}
          newMessages={conversation?.newMessages}
          lastMessageWasSentByContact={conversation?.lastMessageWasSentByContact}
          isReassigned={conversation?.isReassigned}
          sendingMessage={conversation?.sendingMessage}
          lastMessageIsViewed={conversation?.lastMessageIsViewed}
          isExpired={conversation?.isExpired}
          lastMessageIsReceived={conversation?.lastMessageIsReceived}
          timeElapsedLastMessage={conversation?.timeElapsedLastMessage}          
          className={conversation.id === props?.conversationSelected?.id ? 'active' : ''}
        />
      });

      return (
        <div className={`${Component.name}__body`}>
          {conversationsLazyLoading}
          {conversations}
        </div>
      );
    };

    const totalConversationsUnassigned = useMemo(() => {
      return chat.state.conversationsUnassigned.reduce((initialValue, conversation) => {
        if(conversation.lineId === chat.state.lineSelected.id) {
          return initialValue + 1;
        }
        return initialValue;
      }, 0);
    }, [chat.state.conversationsUnassigned])


    const createFooter = () => {
      const unassignedConversations = <ContactConversationItem
        onClick={() => props?.onConversationSelect('UNASSIGNED_CONVERSATIONS')}
        name={`Conversaciones en cola: ${totalConversationsUnassigned}`}
        showAvatar={false}
        showAliasWithName={true}
        className={component.classNames({'active': props.activeUnassignedConversations})}
      />

      return (
        <div className={`${Component.name}__footer`}>
          {unassignedConversations}
        </div>
      );
    }

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();
    const footer = (props?.elements?.footer?.show ?? true) && createFooter();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
        {footer}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  conversations: PropTypes.array,
  conversationSelected: PropTypes.object,
  onConversationSelect: PropTypes.func,
  elements: PropTypes.object
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  conversations: null,
  conversationSelected: null,
  onConversationSelect: null,
  elements: null
};

export default Component.element;