import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import AgentsHeaderInfoItem from 'views/chat/components/items/AgentsHeaderInfoItem.component';
import AgentsHeaderFiltersSection from 'views/chat/components/sections/AgentsHeaderFiltersSection.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {Object} line Line
 * @property {Array} filters Array of filters
 */

const Component = {
  name: 'AgentsHeader',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const icon = props.line && <AgentsHeaderInfoItem line={props.line} />;
      const info = (props.line?.name || props.line?.phone) && <div className={`${Component.name}__body__info`}>
        {props.line?.name &&
          <div className={`${Component.name}__body__info__name`}>{ObjectUtils.getJSXElement(props.line?.name, props)}</div>
        }
        {props.line?.phone &&
          <div className={`${Component.name}__body__info__phone`}>{ObjectUtils.getJSXElement(props.line?.phone, props)}</div>
        }
      </div>;
      const filters = (!props.isLoading && props.filters) && <AgentsHeaderFiltersSection filters={props.filters} />;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
          {info}
          {filters}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  line: PropTypes.object,
  filters: PropTypes.array
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  line: null,
  filters: null
};

export default Component.element;