import {Menu} from 'primereact/menu';

export default Menu;

// export default function BaseOverlayMenu(props) {
//   return (
//     <Menu
//       {...props}
//       popup={true}
//       ref={props.ref}
//     />
//   );
// }