import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import DataFetchError from 'components/DataFetchError.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import InfoTooltip from 'components/InfoTooltip.component';
import LinesService from 'services/lines.service';

export default function GeneralCampaignDataForm(props) {
  const asyncState = useAsyncState();
  const [state, setState] = useStateParams();
  const [name, setName] = useState('');
  const [lines, setLines] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [sendingName, setSendingName] = useState('');
  const [sendingDetail, setSendingDetail] = useState('');

  useEffect(async () => await initialize(), []);
  useEffect(() => handleChangeGeneralData(), [name, selectedLine, sendingName, sendingDetail]);

  const initialize = async () => {
    const allPromises = await asyncState.allPromises(
      [getLines()],
      {initialization: true}
    );
    if(allPromises.fulfilled && allPromises.successfully) {
      const lines = allPromises.results[0].payload;
      const name = getName();
      const selectedLine = getSelectedLine(lines);
      const sendingName = getSendingName();
      const sendingDetail = getSendingDetail();
      setName(name);
      setSelectedLine(selectedLine);
      setSendingName(sendingName);
      setSendingDetail(sendingDetail);
    }
  }

  const handleChangeGeneralData = () => {
    const trimSendingName = (sendingName ?? '').trim();
    const trimSendingDetail = (sendingDetail ?? '').trim();
    const event = {
      valid: false,
      payload: {
        name,
        line: selectedLine,
        sendingName: trimSendingName.length > 0 ? trimSendingName: undefined,
        sendingDetail: trimSendingDetail.length > 0 ? trimSendingDetail: undefined
      }
    };
    const {payload} = event;
    event.valid = payload.name.length > 0 && payload.line !== undefined;
    setState(payload, {merge: true});
    props.onChange(event);
  }

  const getLines = async () => {
    const response = await LinesService.getLines();
    if(response.success) {
      const lines = response.payload.map((line) => {
        return {id: line.id, name: line.name, phone: line.phone};
      });
      setLines(lines);
      return {success: true, payload: lines};
    }
    return {success: false};
  }

  const getName = () => {
    return state.name ?? '';
  }

  const getSelectedLine = (lines) => {
    return state.line ?? lines[0];
  }

  const getSendingName = () => {
    return state.sendingName ?? '';
  }

  const getSendingDetail = () => {
    return state.sendingDetail ?? '';
  }

  const handleNameInputChange = (event) => {
    let name = event.value ?? event.target.value;
    if(name[0] != '_' && Number.isNaN(Number.parseInt(name[0]))) {
      name = name.trim();
      name = name.replace(/[^_a-zA-ZñÑ0-9]+/g, '_');
      setName(name);
    }
  }

  const handleSendingNameInputChange = (event) => {
    let name = event.value ?? event.target.value;
    if(name[0] != '_' && Number.isNaN(Number.parseInt(name[0]))) {
      name = name.trim();
      name = name.replace(/[^_a-zA-ZñÑ0-9]+/g, '_');
      setSendingName(name);
    }
  }

  const handleSendingDetailInputChange = (event) => {
    let detail = event.value ?? event.target.value;
    setSendingDetail(detail);
  }

  const handleLineDropdownChange = (event) => {
    const value = event.value;
    setSelectedLine(value);
  }

  return (
    <>
      {
        (asyncState.isInitialized) &&
        <NeoCard>
          <NeoInputText 
            md='6'
            label='Nombre de la campaña'
            rightIcon={<InfoTooltip id='name' body='El nombre de la campaña no contiene espacios ni caracteres especiales.'/>}
            value={name}
            disabled={state.mainRecurrentDynamicCampaign !== undefined}
            maxlength='48'
            keyfilter={/^[_a-zA-ZñÑ0-9]+$/}
            onChange={handleNameInputChange}
          />
          <NeoDropdown 
            md='6'
            label='Línea de teléfono'
            value={selectedLine}
            disabled={state.mainRecurrentDynamicCampaign !== undefined}
            options={lines.map((line) => ({label: `${line.name} | ${line.phone}`, value: line}))}
            onChange={handleLineDropdownChange}
          />
          {
            (props.dynamicCampaignConfig?.isRecurrent === true) &&
            <>
              <NeoInputText 
                md='6'
                label='Nombre del envío'
                rightIcon={<InfoTooltip id='sendingName' body='El nombre del envío no contiene espacios ni caracteres especiales.'/>}
                value={sendingName}
                maxlength='48'
                keyfilter={/^[_a-zA-ZñÑ0-9]+$/}
                onChange={handleSendingNameInputChange}
              />
              <NeoInputText 
                md='6'
                label='Detalle del envío'
                value={sendingDetail}
                maxlength='1024'
                onChange={handleSendingDetailInputChange}
              />
            </>
          }
        </NeoCard>
      }
      {
        (!asyncState.isInitialized) &&
        <>
          {
            (asyncState.isLoading) &&
            <NeoSpinner/>
          }
          {
            (!asyncState.isLoading) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}