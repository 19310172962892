import { forwardRef, useRef, createElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Function} onClick Function to on click
 */

const Component = {
  name: 'ChatActionQuickReplyItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {    
      const icon = <div className={`${Component.name}__body__icon`}>
        <i className="fa fa-envelope-open-text" style={{'fontSize': '1em'}} onClick={props?.onClick}></i>
      </div>;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  onClick: null
};

export default Component.element;