import { useState, useEffect, useRef } from 'react';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import DataFetchError from 'components/DataFetchError.component';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import InternalSpinner from 'components/InternalSpinner.component';
import SettingsService from 'services/settings.service';
import QuicklyRepliesList from 'views/settings/components/QuicklyRepliesList.component';
import QuicklyRepliesCreate from 'views/settings/components/QuicklyReplyCreate.component';
import useComponent from 'hooks/useComponent.hook';

export default function Settings() {
  const messages = useMessages();
  const asyncState = useAsyncState();
  const [isEnabledTrackingURL, setIsEnabledTrackingURL] = useState();
  const quicklyRepliesListRef = useRef(null);
  const component = useComponent({
    state: {
      createQuickReply: {
        show: false
      }
    }
  });

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getSettings()], 
      {initialization: true}
    );
  }

  const getSettings = async () => {
    const response = await SettingsService.getSettings();
    if(response.success) {
      setIsEnabledTrackingURL(response.payload.trackingURL);
      return {success: true};
    }
    return {success: false};
  }

  const handleSaveChangesButtonClick = async () => {
    const requestUpdateSettings = SettingsService.updateSettings({
      trackingURL: isEnabledTrackingURL
    });

    await Promise.allSettled([
      requestUpdateSettings
    ])
    .then(() => {
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Configuración actualizada.');
    })
    .catch(() => {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo guardar la configuración, inténtalo de nuevo.');
    });
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          <NeoCard>
            <NeoInnerTitle extra="primero">General</NeoInnerTitle>
            <NeoColumn col="6">
              <NeoInputSwitch
                checked={isEnabledTrackingURL}
                onChange={(event) => setIsEnabledTrackingURL(event.value)}
                label="Trackeo de links"
              />
            </NeoColumn>
          </NeoCard>

          <NeoCard>
            <NeoInnerTitle extra="primero p-md-4">Respuestas rápidas</NeoInnerTitle>
            <NeoButtonSection align='right' col='12' md='6'>
              <NeoButtonMain
                icon="pi pi-plus"
                label='Crear respuesta rápida'
                onClick={() => {component.setState((state) => {state.createQuickReply.show = true})}}
              />
            </NeoButtonSection>
            <QuicklyRepliesList ref={quicklyRepliesListRef}/>
          </NeoCard>
          <QuicklyRepliesCreate 
            show={component.state.createQuickReply.show}
            visibleSetter={() => {component.setState((state) => {state.createQuickReply.show = false})}}
            onCreate={(quicklyReply) => {
              quicklyRepliesListRef.current?.addQuicklyReply(quicklyReply);
            }}
          />

          <NeoButtonSection align='right'>
            <NeoButtonMain
              label='Guardar cambios'
              onClick={handleSaveChangesButtonClick}
            />
          </NeoButtonSection>

          {
            (!asyncState.isInitialized) &&
            <NeoCard>
              <DataFetchError onRetry={initialize}/>
            </NeoCard>
          }
        </>
      }
    </>
  )
}