import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import ContactsOriginSummary from 'views/campaigns/components/ContactsOriginSummary.component';
import CampaignContactsSelection from 'views/campaigns/components/CampaignContactsSelection.component';
import campaignsConsts from 'consts/campaigns.consts';

export default function CampaignContacts() {
  const history = useHistory();
  const [state, setState] = useStateParams();
  const [files, setFiles] = useState(state.files ?? []);
  const [contacts, setContacts] = useState(state.contacts ?? {});

  useEffect(() => initialize(), []);

  const initialize = () => {
    if(state?.flow === undefined) {
      history.replace(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
  }

  const handleSelectionContactsChange = (event) => {
    setContacts(event);
    setState({contacts: event}, {merge: true});
  }

  const getTestMapping = () => {
    const filesTestMapping = files.map((file) => {
      return file.exampleRows.map((exampleRow) => generateTestCampaignMapping(file.map, exampleRow));
    });
    let testMapping = [].concat(...filesTestMapping);
    if(state.isCampaignWithContacts === true) {
      const contactsTestMapping = contacts.contacts
        .slice(0, campaignsConsts.EXAMPLES_PER_DATABASE)
        .map((contact) => generateTestCampaignMapping(contacts.map, contact));
      testMapping = testMapping.concat(contactsTestMapping);
    }
    return testMapping;
  }

  const getCampaignPayload = () => {
    const payload = {
      name: state.name,
      line: state.line,
      flow: state.flow,
      isDynamic: state.isDynamic,
      isRecurrent: state.isRecurrent,
      sendingName: state.sendingName,
      sendingDetail: state.sendingDetail
    };
    if(state.mainRecurrentDynamicCampaign !== undefined) {
      payload.mainRecurrentDynamicCampaign = state.mainRecurrentDynamicCampaign;
    }
    payload.files = state.files.map(({file, map}) => {
      const mapping = {map: {to: map.to, ...map.map}};
      if(map.autoReplies !== undefined) { mapping.autoReplies = map.autoReplies };
      return {file, map: mapping};
    });
    if(state.isCampaignWithContacts === true) {
      payload.contacts = {
        contacts: state.contacts.contacts.map(({id}) => id),
        map: JSON.parse(JSON.stringify(state.contacts.map))
      };
    }
    payload.testMapping = getTestMapping();
    return payload;
  }

  const generateTestCampaignMapping = (map, payload) => {
    const testMapping = {};
    testMapping.map = generateTestTemplateMapping(map.map, payload);
    if(state.isDynamic === true && map.autoReplies !== undefined) {
      testMapping.autoReplies = {};
      for(const [text, autoReplyMap] of Object.entries(map.autoReplies)) {
        testMapping.autoReplies[text] = {map: generateTestTemplateMapping(autoReplyMap.map, payload)};
      }
    }
    return testMapping;
  }

  const generateTestTemplateMapping = (map, payload) => {
    return Object.fromEntries(Object.entries(map).map(([key, mapping]) => [key, payload[mapping]]));
  }

  const handleNextButtonClick = () => {
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH,
      state: getCampaignPayload()
    });
  }

  return (
    <>
      {
        (state?.flow !== undefined) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond 
              icon={Icono} 
              title='Crear campaña' 
              subtitle='Contactos de campaña'
            />
          </NeoGridContainer>
          <CampaignContactsSelection
            contacts={contacts}
            isCampaignWithContacts={state.isCampaignWithContacts}
            isDynamic={state.isDynamic}
            onSelectionChange={handleSelectionContactsChange}
          />
          <ContactsOriginSummary
            files={files}
            isDynamic={state.isDynamic}
            onFilesChange={(event) => setFiles(event)}
          />
          <NeoButtonSection align='right'>
            <NeoButtonOutlined
              label='Atrás'
              onClick={() => history.goBack()}
            />
            <NeoButtonMain
              label='Siguiente'
              disabled={state.isCampaignWithContacts === true && (contacts.contacts ?? []).length === 0}
              onClick={handleNextButtonClick}
            />
          </NeoButtonSection>
        </>
      }
    </>
  );
}