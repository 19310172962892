import { useState, useEffect, useMemo } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import useComponent from 'hooks/useComponent.hook';

const ContactExtraTokenFieldsForm = (props) => {
  const component = useComponent({state: {changed: {}}});
  const [tokens, setTokens] = useState(Array(CONTACTS.DATA.EXTRA_TOKEN_FIELDS).fill('').map((_, index) => {
    const field = `token${index + 1}`;
    return props.contact ? (props.contact[field] ?? '') : '';
  }));

  useEffect(() => component.setState((state) => {state.changed = {}}), [props.contact]);
  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [tokens]);

  const render = useMemo(() => {
    if(props.showOnlyFills) {
      return tokens.reduce((render, value, index) => {
        return render === true ? true : (value.length > 0 || component.state.changed[`token${index + 1}`])
      }, false);
    }
    return true;
  }, [props.showOnlyFills, tokens, component.state.changed]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = tokens.map((token, index) => {
      const field = `token${index + 1}`;
      event.payload[field] = token;
      return props.contact && token !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleTokenInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedTokens = [...tokens];
    updatedTokens[index] = value;
    setTokens(updatedTokens);
  }

  return (<>
  {render &&
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de token</NeoInnerTitle>
      {
        tokens.map((token, index) => (
          (!props.showOnlyFills || token.length > 0 || component.state.changed[`token${index + 1}`]) &&
            <NeoInputText
              key={index}
              md={props?.elements?.token?.md}
              col="12"
              label={`Token ${index + 1}`}
              value={token}
              keyfilter={/^[^ ]+$/}
              onChange={(event) => handleTokenInputChange(index, event)}
              onFocus={() => {component.setState((state) => {state.changed[`token${index + 1}`] = true})}}
            />
        ))
      }
    </NeoCard>
  }</>);
}

ContactExtraTokenFieldsForm.defaultProps = {
  elements: {
    token: {
      md: '4'
    }
  },
  showOnlyFills: false
}

export default ContactExtraTokenFieldsForm;
