import backendRequestUtil from 'utils/backendRequest.util';
import fileUtil from 'utils/file.util';

export default class ChatService {
  static async getContent(lineId, chatId) {
    const params = {lineId, chatId};
    const {success, body, error} = await backendRequestUtil.get('/rest/chatContent', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async sendMessage(message) {
    const bodyRequest = message;
    const {success, error} = await backendRequestUtil.post('/api/messaging', bodyRequest);
    return success ? {success} : {success, error};
  }

  static async addComment({lineId, chatId, comment}) {
    const params = {lineId, chatId};
    const bodyRequest = {comment};
    const {success, error} = await backendRequestUtil.post('/rest/comment', bodyRequest, {params});
    return success ? {success} : {success, error};
  }

  static async finishConversation({lineId, chatId, comment}) {
    const params = {lineId, chatId};
    const bodyRequest = {comment};
    const {success, error} = await backendRequestUtil.post('/rest/finishConversation', bodyRequest, {params});
    return success ? {success} : {success, error};
  }

  static async getMedia({lineId, mediaId}) {
    const params = {lineId, mediaId};
    const {success, body, error} = await backendRequestUtil.get('/api/media', {params, responseType: 'blob'});
    return success ? {success, payload: body} : {success, error};
  }

  static async mediaFileToSend({file}, onUploadProgress) {
    const {type, name} = file;
    const headers = {'Content-Type': type};
    const {success, body, error} = await backendRequestUtil.post('/rest/mediaFileToSend', file, {headers, onUploadProgress});
    if(success) {
      file = {
        type: fileUtil.getTypeOfFileFromMimeType(type)?.type ?? 'document',
        url: body.url
      }
      if(file.type === 'document') {
        file.filename = name;
      }
    }

    return success ? {success, payload: file} : {success, error};
  }

  static async chatReassignment({lineId, chatId, areaId, agentId, reason}) {
    const params = {lineId, chatId, agentId};
    const bodyRequest = {areaId, reason};
    const {success, error} = await backendRequestUtil.post('/rest/chatReassignment', bodyRequest, {params});
    return success ? {success} : {success, error};
  }

  static async startChat(lineId, contactId, template) {
    const bodyRequest = {from: lineId, contactId, template};
    const {success, error} = await backendRequestUtil.post('/rest/startChat', bodyRequest);
    if(!success && !error.internal) {
      if(/^El contacto ya tiene una conversación iniciada$/i.test(error.message)) {
        error.code = 'CONTACT_NOT_AVAILABLE_FOR_NEW_CHAT';
      }
      else if(/^Número de teléfono inválido$/i.test(error.message)) {
        error.code = 'INVALID_PHONE_NUMBER';
      }
    }
    return success ? {success} : {success, error};
  }

  static async getQuicklyReplies() {
    const {success, body, error} = await backendRequestUtil.get('/rest/quicklyReplies');
    return success ? {success, payload: body} : {success, error};
  }

  static async createQuicklyReply({title, replie}) {
    const bodyRequest = {title, replie};
    const {success, body, error} = await backendRequestUtil.post('/rest/quicklyReply', bodyRequest);
    return success ? {success, payload: body} : {success, error};
  }

  static async getQuicklyReply(replyId) {
    const params = { replyId };
    const { success, body, error } = await backendRequestUtil.get('/rest/quicklyReply', { params });
    return success ? { success, payload: body } : { success, error}
  }

  static async updateQuicklyReply(replyId, quickReplyFields) {
    const params = { replyId }
    const { success, error } = await backendRequestUtil.put('/rest/quicklyReply', quickReplyFields, { params });
    return success ? { success } : { success, error };
  }

  static async deleteQuicklyReply(replyId) {
    const params = { replyId };
    const {success, error} = await backendRequestUtil.delete('/rest/quicklyReply', { params });
    return success ? { success } : { success, error };
  }

  static async getUnassigned() {
    const { success, body, error } = await backendRequestUtil.get('/rest/chatsUnassigned');
    return success ? { success, payload: body } : { success, error}
  }
}