import {useState, useEffect, useRef} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import dateTimeUtil from 'utils/dateTime.util';
import messagesUtil from 'utils/messages.util';

const messagesStatusValues = {
  SENT: 1,
  DELIVERED: 2,
  READ: 3
};

const messagesStatusOptions = [
  {label: 'Enviado', value: 'SENT'},
  {label: 'Recibido', value: 'DELIVERED'},
  {label: 'Leído', value: 'READ'},
  {label: 'Fallido', value: 'FAILED'},
  {label: 'No enviado', value: 'ERROR'}
];

export default function CampaignMessagesDetailTable(props) {
	const tableRef = useRef();
	const [messagesDetail, setMessagesDetail] = useState([]);

	useEffect(() => reboot(), [props.data]);

	const reboot = () => {
		const sortedMessagesDetail = props.data?.sort((message1, message2) => {
			message1 = message1.message;
			message2 = message2.message;
			const message1At = `${message1.status.toLowerCase()}At`;
			const message2At = `${message2.status.toLowerCase()}At`;
			return (message2[message2At] - message1[message1At]);
		});
		setMessagesDetail(sortedMessagesDetail ?? []);
	}

	const statusSortFunction = (event) => {
    const { order } = event;
    return messagesDetail.sort((message1, message2) => {
      message1 = message1.message;
      message2 = message2.message;
      return (messagesStatusValues[message1.status] - messagesStatusValues[message2.status]) * order;
    });
  }

	const statusAtFilterFunction = (value, filter) => {
    const at = `${value.status.toLowerCase()}At`;
    const statusAt = value[at];
    const statusAtMillis = statusAt.getTime();
    if (!filter[0] && !filter[1]) {
      return false;
    }
    filter[0].setHours(0);
    filter[0].setMinutes(0);
    filter[0].setSeconds(0);
    const fromMillis = filter[0].getTime();
    if (!filter[1]) {
      return statusAtMillis >= fromMillis;
    }
    filter[1].setHours(23);
    filter[1].setMinutes(59);
    filter[1].setSeconds(59);
    const toMillis = filter[1].getTime();
    return statusAtMillis >= fromMillis && statusAtMillis <= toMillis;
  }

	const statusAtSortFunction = (event) => {
    const { order } = event;
    return messagesDetail.sort((message1, message2) => {
      message1 = message1.message;
      message2 = message2.message;
      const message1At = `${message1.status.toLowerCase()}At`;
      const message2At = `${message2.status.toLowerCase()}At`;
      return (message1[message1At] - message2[message2At]) * order;
    });
  }

	const statusMultiSelectItemTemplate = (option) => (
    <>
      <span className={`chart-legend-icon icon-${option.value.toLowerCase()}`} /> {option.label}
    </>
  );

	const elements = {
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='message.phone' placeholder='Buscar por teléfono' filterMatch='contains' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        field='message.status'
        placeholder='Todos'
        options={messagesStatusOptions}
        itemTemplate={statusMultiSelectItemTemplate}
      />
    ),
    statusAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='message' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    phoneColumnBody: (data) => (
      <>{data.message.phone}</>
    ),
    statusColumnBody: (data) => (
      <><span className={`chart-legend-icon icon-${data.message.status.toLowerCase()}`} /> <span>{messagesUtil.getMessageStatus(data.message.status)}</span></>
    ),
    statusAtColumnBody: (data) => {
      const { message } = data;
      const at = `${message.status.toLowerCase()}At`;
      return (
        <>{dateTimeUtil.getDateString(message[at], { dateStyle: 'medium', timeStyle: 'short' })}</>
      );
    }
  };

	return (
		<>
			{
        (props.loading) &&
				<NeoCard>
        	<InternalSpinner/>
				</NeoCard>
      }
			{
        (!props.loading) &&
        <>
					{
            (props.successfully) &&
            <NeoTable
              ref={tableRef}
              value={messagesDetail}
              paginator
              rows={10}
              removableSort
              sortField='message'
              sortOrder={-1}
              emptyMessage='No hay datos'
            >
							<NeoTableColumn
                header='Teléfono'
                field='message.phone'
                filter
                sortable
                filterElement={elements.phoneFilterInput}
                filterMatchMode='contains'
                body={elements.phoneColumnBody}
              />
              <NeoTableColumn
                field='message.status'
                header='Estatus'
                filter
                sortable
                sortFunction={statusSortFunction}
                filterElement={elements.statusFilterMultiSelect}
                body={elements.statusColumnBody}
              />
              <NeoTableColumn
                field='message'
                header='Fecha de estatus'
                filter
                sortable
                sortFunction={statusAtSortFunction}
                filterElement={elements.statusAtFilterRange}
                filterFunction={statusAtFilterFunction}
                filterMatchMode='custom'
                body={elements.statusAtColumnBody}
              />
						</NeoTable>
          }
          {
            (!props.successfully) &&
						<NeoCard>
            	<DataFetchError internal align='start' onRetry={props.onRetry}/>
						</NeoCard>
          }
        </>
      }
		</>
	);
}