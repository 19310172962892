import CalendarMessageListItem from './CalendarMessageListItem.component';

export default function CalendarMessageList(props) {
    const { replies, actions } = props;
    return (
        <ul className='message-list'>
            {replies.map((reply) => (
                <CalendarMessageListItem
                    key={reply.replyId}
                    reply={reply}
                    actions={actions}
                />
            ))}
        </ul>
    );
}
