import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} name Name of agent
 * @property {string} status Status of agent
 */

const Component = {
  name: 'AgentConversationItemIcon',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const firstLetterOfEachWord = props?.name.split(/\s/).reduce((response, word) => `${response}${word.slice(0,1)}`, '');
      const icon = firstLetterOfEachWord && <div className={`${Component.name}__body__icon ONLINE`}>
        <span>{firstLetterOfEachWord}</span>
        <span className={`status pi pi-fw pi-circle-fill ${props.status}`}></span>
      </div>;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  name: null,
  status: null,
};

export default Component.element;