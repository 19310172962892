import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import useMessages from 'hooks/useMessages.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import DataFetchError from 'components/DataFetchError.component';
import ResourceNotFound from 'components/ResourceNotFound.component';
import ContactDetailHeader from 'views/contacts/components/ContactDetailHeader.component';
import BasicContactDataForm from 'views/contacts/components/BasicContactDataForm.component';
import ExtraContactDataForm from 'views/contacts/components/ExtraContactDataForm.component';
import ContactExtraTextFieldsForm from 'views/contacts/components/ContactExtraTextFieldsForm.component';
import ContactExtraNumberFieldsForm from 'views/contacts/components/ContactExtraNumberFieldsForm.component';
import ContactExtraDateFieldsForm from 'views/contacts/components/ContactExtraDateFieldsForm.component';
import ContactExtraTokenFieldsForm from 'views/contacts/components/ContactExtraTokenFieldsForm.component';
import ContactExtraFileNameFieldsForm from 'views/contacts/components/ContactExtraFileNameFieldsForm.component';
import LoadingDialog from 'components/LoadingDialog.component';
import ContactsService from 'services/contacts.service';
import ContactAgentAssigned from './components/ContactAgentAssigned.component';

export default function ContactDetailView() {
  const {id} = useParams();
  const history = useHistory();
  const messages = useMessages();
  const asyncState = useAsyncState();
  const [contact, setContact] = useState(null);
  const [basicData, setBasicData] = useState(null);
  const [extraData, setExtraData] = useState(null);
  const [extraTextFields, setExtraTextFields] = useState(null);
  const [extraNumberFields, setExtraNumberFields] = useState(null);
  const [extraDateFields, setExtraDateFields] = useState(null);
  const [extraFileNameFields, setExtraFileNameFields] = useState(null);
  const [extraTokenFields, setExtraTokenFields] = useState(null);
  const [showUpdatingDialog, setShowUpdatingDialog] = useState(false);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getContact()], 
      {initialization: true}
    );
  }

  const getContact = async () => {
    const response = await ContactsService.getContact(id);
    if(response.success) {
      setContact(response.payload);
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  const handleUpdateButtonClick = async () => {
    setShowUpdatingDialog(true);
    const {firstName, lastName} = basicData.payload;
    const name = firstName + (lastName.length > 0 ? ` ${lastName}` : '');
    const fields = {
      name, firstName, lastName,
      ...extraData.payload, 
      ...extraTextFields.payload,
      ...extraNumberFields.payload,
      ...extraDateFields.payload,
      ...extraFileNameFields.payload,
      ...extraTokenFields.payload
    };
    const response = await ContactsService.updateContact(id, fields);
    setShowUpdatingDialog(false);
    if(response.success) {
      setContact(fields);
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se actualizó el contacto.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo actualizar el contacto, inténtalo de nuevo.'
      );
    }
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (contact) &&
                <>
                  <ContactDetailHeader 
                    contact={contact}
                  />
                  <BasicContactDataForm 
                    contact={contact}
                    onChange={(event) => setBasicData(event)}
                  />
                  <ExtraContactDataForm
                    contact={contact}
                    onChange={(event) => setExtraData(event)}
                  />
                  <ContactAgentAssigned
                    contact={contact}
                    onChange={(event) => setExtraTextFields(event)}
                  />
                  <ContactExtraTextFieldsForm
                    contact={contact}
                    onChange={(event) => setExtraTextFields(event)}
                  />
                  <ContactExtraNumberFieldsForm
                    contact={contact}
                    onChange={(event) => setExtraNumberFields(event)}
                  />
                  <ContactExtraDateFieldsForm
                    contact={contact}
                    onChange={(event) => setExtraDateFields(event)}
                  />
                  <ContactExtraTokenFieldsForm
                    contact={contact}
                    onChange={(event) => setExtraTokenFields(event)}
                  />
                  <ContactExtraFileNameFieldsForm
                    contact={contact}
                    onChange={(event) => setExtraFileNameFields(event)}
                  />
                  <NeoButtonSection align='right'>
                    <NeoButtonOutlined
                      label='Cancelar'
                      onClick={() => history.replace(MENU.ADMIN.CONTACTS.ROOT.PATH)}
                    />
                    <NeoButtonMain 
                      label='Guardar cambios'
                      disabled={
                        !basicData || basicData.valid === false ||
                        !extraData ||
                        !extraTextFields ||
                        !extraNumberFields || extraNumberFields.valid === false ||
                        !extraDateFields ||
                        !extraFileNameFields ||
                        !extraTokenFields ||
                        (
                          basicData.change === false && 
                          extraData.change === false && 
                          extraTextFields.change === false && 
                          extraNumberFields.change === false &&
                          extraDateFields.change === false &&
                          extraFileNameFields.change === false &&
                          extraTokenFields.change === false
                        )
                      }
                      onClick={handleUpdateButtonClick}
                    />
                  </NeoButtonSection>
                  {
                    <LoadingDialog
                      visible={showUpdatingDialog}
                      message='Actualizando contacto'
                    />
                  }
                </>
              }
              {
                (!contact) &&
                <ResourceNotFound 
                  title='El contacto no existe'
                  redirection={{label: 'Ir a contactos', path: MENU.ADMIN.CONTACTS.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}