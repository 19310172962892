import { useState, useEffect, useMemo } from 'react';
import TOAST from 'consts/toast.consts';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import BasicContactDataForm from 'views/contacts/components/BasicContactDataForm.component';
import ExtraContactDataForm from 'views/contacts/components/ExtraContactDataForm.component';
import ContactExtraTextFieldsForm from 'views/contacts/components/ContactExtraTextFieldsForm.component';
import ContactExtraNumberFieldsForm from 'views/contacts/components/ContactExtraNumberFieldsForm.component';
import ContactExtraDateFieldsForm from 'views/contacts/components/ContactExtraDateFieldsForm.component';
import ContactExtraTokenFieldsForm from 'views/contacts/components/ContactExtraTokenFieldsForm.component';
import ContactExtraFileNameFieldsForm from 'views/contacts/components/ContactExtraFileNameFieldsForm.component';
import ContactsService from 'services/contacts.service';
import useChat from 'hooks/useChat.hook';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from 'hooks/useComponent.hook';

const ConversationContactInfo = (props) => {
  const chat = useChat();
  const messages = useMessages();
  const asyncState = useAsyncState();
  const component = useComponent({
    state: {
      element: {
        data: {
          showOnlyFills: true
        }
      }
    }
  });
  const [contact, setContact] = useState(null);
  const [basicData, setBasicData] = useState(null);
  const [extraData, setExtraData] = useState(null);
  const [extraTextFields, setExtraTextFields] = useState(null);
  const [extraNumberFields, setExtraNumberFields] = useState(null);
  const [extraDateFields, setExtraDateFields] = useState(null);
  const [extraFileNameFields, setExtraFileNameFields] = useState(null);
  const [extraTokenFields, setExtraTokenFields] = useState(null);
  const [updatingDialog, setUpdatingDialog] = useState(false);

  useEffect(async () => await reboot(), [chat.state.conversationSelected?.contact?.id]);

  const reboot = async () => {
    setContact(null);
    await asyncState.allPromises(
      [getContact()]
    );
  }

  const getContact = async () => {
    const response = await ContactsService.getContact(chat.state.conversationSelected?.contact?.id);
    if(response.success) {
      setContact(response.payload);
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  const handleUpdateButtonClick = async () => {
    setUpdatingDialog(true);
    const {firstName, lastName} = basicData.payload;
    const name = firstName + (lastName.length > 0 ? ` ${lastName}` : '');
    const fields = {
      name, firstName, lastName,
      ...extraData.payload, 
      ...extraTextFields.payload,
      ...extraNumberFields.payload,
      ...extraDateFields.payload,
      ...extraFileNameFields.payload,
      ...extraTokenFields.payload
    };
    const response = await ContactsService.updateContact(chat.state.conversationSelected?.contact?.id, fields);
    setUpdatingDialog(false);
    if(response.success) {
      setContact(fields);
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONTACT.UPDATE.TYPE,
          payload: {
            contact: {id: chat.state.conversationSelected?.contact?.id, ...fields}
          }
        }
      })

      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se actualizó el contacto.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo actualizar el contacto, inténtalo de nuevo.'
      );
    }
  }

  const render = useMemo(() => {
    const element = {
      open: {
        isRender: false
      },
      data: {
        isRender: false
      },
      actions: {
        isRender: false
      }
    };
    if(contact) {
      if(props.render?.open?.isRender !== false) {
        element.open.isRender = true;
      }
      if(props.render?.data?.isRender !== false) {
        element.data.isRender = true;
      }
      if(props.render?.actions?.isRender !== false) {
        element.actions.isRender = true;
      }
    }
    return element;
  }, [props, contact]);

  const open = 
  <NeoButtonMain 
    extra='open-button' 
    icon={`pi ${props?.state?.open?.show ? 'pi-angle-double-right' : 'pi-angle-double-left'}`}
    onClick={props?.element?.open?.onClick}
  />;

  const data = 
  <div className={`${props.component.name}__contact-info__data`}>
    <div className={`${props.component.name}__contact-info__data__filters`}>
      <NeoInputSwitch
        label='Solo campos con datos' 
        checked={component.state.element.data.showOnlyFills} 
        onChange={(event) => {component.setState((state) => {state.element.data.showOnlyFills = event.value})}}
      />
    </div>
    <BasicContactDataForm
      contact={contact}
      elements={{
        phone: {md: '12'},
        firstName: {md: '12'},
        lastName: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setBasicData(event)}
    />
    <ExtraContactDataForm
      contact={contact}
      elements={{
        email: {md: '12'},
        userId: {md: '12'},
        company: {md: '12'},
        address: {md: '12'},
        postalCode: {md: '12'},
        country: {md: '12'},
        gender: {md: '12'},
        type: {md: '12'},
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraData(event)}
    />
    <ContactExtraTextFieldsForm
      contact={contact}
      elements={{
        text: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraTextFields(event)}
    />
    <ContactExtraNumberFieldsForm
      contact={contact}
      elements={{
        number: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraNumberFields(event)}
    />
    <ContactExtraDateFieldsForm
      contact={contact}
      elements={{
        date: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraDateFields(event)}
    />
    <ContactExtraTokenFieldsForm
      contact={contact}
      elements={{
        token: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraTokenFields(event)}
    />
    <ContactExtraFileNameFieldsForm
      contact={contact}
      elements={{
        fileName: {md: '12'}
      }}
      showOnlyFills={component.state.element.data.showOnlyFills}
      onChange={(event) => setExtraFileNameFields(event)}
    />
  </div>;

  const actions = 
  <div className={`${props.component.name}__contact-info__actions`}>
    <NeoButtonMain 
      label='Guardar cambios'
      disabled={
        !basicData || basicData.valid === false ||
        !extraData ||
        !extraTextFields ||
        !extraNumberFields || extraNumberFields.valid === false ||
        !extraDateFields ||
        !extraFileNameFields ||
        !extraTokenFields ||
        (
          basicData.change === false && 
          extraData.change === false && 
          extraTextFields.change === false && 
          extraNumberFields.change === false &&
          extraDateFields.change === false &&
          extraFileNameFields.change === false &&
          extraTokenFields.change === false
        )
      }
      loading={updatingDialog}
      onClick={handleUpdateButtonClick}
    />
  </div>;

  return (
    <div className={`${props.component.name}__contact-info`}>
      {render.open.isRender && open}
      {render.data.isRender && data}
      {render.actions.isRender && actions}
    </div>
  );
}

export default ConversationContactInfo;