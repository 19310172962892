export default {
  ADMIN: {
    SETTINGS: {
      ROOT: {
        LABEL: 'Configuración',
      },
      GENERAL: {
        LABEL: 'General',
        PATH: '/settings'
      },
      AUTOMATIC_REPLY: {
        LABEL: 'Respuestas automáticas',
        PATH: '/automatic-reply'
      }
    },
    API: {
      ROOT: {
        LABEL: 'API',
        PATH: '/api-settings'
      },
      API_KEY_DETAIL: {
        BASE: '/api-key',
        PATH: '/api-key/:id'
      }
    },
    LINES: {
      ROOT: {
        LABEL: 'Líneas',
        PATH: '/lines'
      },
      LINE_PROFILE: {
        BASE: '/line',
        PATH: '/line/:id'
      }
    },
    MULTIMEDIA: {
      ROOT: {
        LABEL: 'Multimedia',
        PATH: '/multimedia'
      },
      CREATE_MULTIMEDIA: {
        PATH: '/create-multimedia'
      }
    },
    TEMPLATES:{
      ROOT: {
        LABEL: 'Plantillas',
        PATH: '/templates' 
      },
      CREATE_TEMPLATE: {
        PATH: '/create-template'
      },
      TEMPLATE_PREVIEW: {
        PATH: '/template-preview'
      },
      TEMPLATE_DETAIL: {
        BASE: '/template',
        PATH: '/template/:id'
      }
    },
    DASHBOARD: {
      ROOT: {
        LABEL: 'Dashboard',
      },
      GENERAL: {
        LABEL: 'General',
        PATH: '/'
      },
      CAMPAIGNS: {
        LABEL: 'Campañas',
        PATH: '/campaigns-dashboard'
      },
      CAMPAIGN_DETAIL: {
        BASE: '/campaign',
        PATH: '/campaign/:id'
      },
      RECURRENT_CAMPAIGN_DETAIL: {
        BASE: '/recurrent-campaign',
        PATH: '/recurrent-campaign/:id'
      },
      CONTACTS: {
        LABEL: 'Contactos',
        PATH: '/contacts-dashboard'
      }
    },
    CAMPAIGNS: {
      ROOT: {
        LABEL: 'Campañas',
        PATH: '/campaigns'
      },
      CREATE_CAMPAIGN: {
        PATH: '/create-campaign'
      },
      CAMPAIGN_CONTACTS_ORIGIN: {
        PATH: '/campaign-contacts-origin'
      },
      CAMPAIGN_TEMPLATES_MAPPING: {
        PATH: '/campaign-templates-mapping'
      },
      CAMPAIGN_CONTACTS: {
        PATH: '/campaign-contacts'
      },
      CAMPAIGN_PREVIEW_AND_TESTING: {
        PATH: '/campaign-preview-and-testing'
      }
    },
    CONTACTS: {
      ROOT: {
        LABEL: 'Contactos',
        PATH: '/contacts'
      },
      CREATE_CONTACT: {
        PATH: '/create-contact'
      },
      CONTACT_DETAIL: {
        BASE: '/contact',
        PATH: '/contact/:id'
      }
    },
    EMPLOYEES: {
      ROOT: {
        LABEL: 'Empleados',
        PATH: '/employees'
      }
    },
    CHAT: {
      ROOT: {
        LABEL: 'Chat',
        PATH: '/chat'
      }
    },
    CONVERSATIONS: {
      START_CONVERSATION: {
        BASE: '/start-conversation',
        PATH: '/start-conversation/:id'
      }
    }
  },
  UNAUTHENTICATED: {
    LOGIN: {
      ROOT: {
        LABEL: 'Iniciar sesión',
        PATH: '/login'
      },
      TEMPORARY: {
        LABEL: 'Iniciar sesión'
      },
      SSO: {
        PATH: '/loginSSO'
      }
    }
  }
};
