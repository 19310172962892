import { useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import InfoTooltip from 'components/InfoTooltip.component';
import stringUtil from 'utils/string.util';

const headerTypesOptions = [
  { label: 'Ninguno', value: 'NONE' },
  { label: 'Texto', value: 'TEXT' },
  { label: 'Imagen', value: 'IMAGE' },
  { label: 'Video', value: 'VIDEO' },
  { label: 'Documento', value: 'DOCUMENT' }
];

export default function TemplateHeaderStructureForm(props) {
  const [state] = useStateParams();
  const [selectedType, setSelectedType] = useState();
  const [headerText, setHeaderText] = useState('');
  const [headerTextError, setHeaderTextError] = useState('');
  const [variableCount, setVariableCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => initialize(), []);
  useEffect(() => handleChange(), [selectedType, headerText]);

  const initialize = () => {
    setSelectedType(state.header?.type ?? 'NONE');
    setHeaderText(state.header?.text ?? '');
  }

  const handleChange = () => {
    const event = { valid: true, header: undefined };
    if (selectedType && selectedType != 'NONE') {
      event.header = { type: selectedType };
      if (selectedType == 'TEXT') {
        const containsEmojis = stringUtil.containsEmojis(headerText);
        event.header.text = headerText;
        const params = headerText.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
        const textWithoutParams = headerText.replace(/\{\{[_0-9a-zA-Z]+\}\}/g, '');
        const textLength = textWithoutParams.length + params.length * 40;
        event.valid = (textLength > 0 && textLength <= 60 && params.length <= 1 && !containsEmojis);
        setVariableCount(params.length);
        setCharacterCount(textLength);
        setHeaderTextError(
          params.length > 1 ? 'El texto del encabezado solo puede contener 1 variable.'
          : textLength > 60 ? 'El texto del encabezado solo puede contener 60 caracteres.'
          : containsEmojis ? 'El texto del encabezado no puede contener emojis.'
          : ''
        );
      }
    }
    props.onChange(event);
  }

  const handleHeaderTextInputChange = (event) => {
    const headerText = event.value ?? event.target.value;
    setHeaderText(headerText);
  }

  return (
    <NeoCard>
      <NeoInnerSubtitle>Encabezado</NeoInnerSubtitle>
      <NeoDropdown
        label="Tipo de encabezado"
        value={selectedType}
        options={headerTypesOptions}
        onChange={(event) => setSelectedType(event.value)}

      />
      {
        (selectedType == 'TEXT') &&
        <>
          <NeoInputText
            col="12"
            md="8"
            label='Texto del encabezado'
            rightIcon={<InfoTooltip id='header' body='El encabezado puede contener como máximo 1 variable.' />}
            maxlength={60}
            value={headerText}
            error={headerTextError}
            onChange={handleHeaderTextInputChange}
          />

          <NeoFlexContainer jc="end">
            <fiv className="p-pt-0 p-mr-3">
              <NeoDataThird extra="p-mt-0 text-align-right" fact={variableCount + "/1"} label="Variables" />
            </fiv>
            <fiv className="p-pt-0" col="12">
              <NeoDataThird extra="p-mt-0 text-align-right" fact={characterCount + "/60"} label="Caracteres" />
            </fiv>
          </NeoFlexContainer>
        </>
      }
    </NeoCard>
  );
}