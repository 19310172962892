import {useState, useEffect} from 'react';
import {LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend} from 'recharts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoResponsiveContainer from 'design/design_components/neo/chart/NeoResponsiveContainer.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import InfoTooltip from 'components/InfoTooltip.component';
import dateTimeUtil from 'utils/dateTime.util';

const colors = {
  reached: '#FF0000',
  new: '#000000'
};

export default function NewReachedContacts(props) {
  const [data, setData] = useState([]);

  useEffect(async () => reboot(), [props.data]);

  const reboot = () => {
    if(props.data) {
      const payload = props.data.map((value) => {
        const {day, month, year} = value.date;
        const date = new Date(`${year}-${month}-${day} 00:00`);
        const dateString = dateTimeUtil.getDateString(date, {dateStyle: 'medium'});
        return {...value, date: dateString};
      });
      setData(payload);
    }
  }

  return (
    <NeoCard>
      <NeoFlexContainer extra='px-0'>
        <NeoInnerTitle extra='primero'>Contactos nuevos alcanzados</NeoInnerTitle>
        <InfoTooltip id='description'>
          <p>Esta gráfica muestra un comparativo de la cantidad de contactos alcanzados contra la cantidad de contactos nuevos alcanzados.</p>
        </InfoTooltip>
      </NeoFlexContainer>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <NeoResponsiveContainer width='100%' height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray='3 3'/>
                <XAxis dataKey='date'/>
                <YAxis label={{value: 'Contactos', angle: -90, position: 'insideBottomLeft'}}/>
                <Tooltip/>
                <Legend/>
                <Line dot={false} type='monotone' name='Alcanzados' dataKey='reached' stroke={colors.reached}/>
                <Line dot={false} type='monotone' name='Nuevos' dataKey='new' stroke={colors.new}/>
              </LineChart>
            </NeoResponsiveContainer>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}