import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class MultimediaService {

  static async getMultimedia() {
    const params = {limit: 1000000};
    const {success, body, error} = await backendRequestUtil.get('/api/mediaFiles', {params});
    if(success) {
      const mediaFiles = body.mediaFiles.map((mediaFile) => {
        mediaFile.createdAt = dateTimeUtil.backendDateStringToDate(mediaFile.createdAt);
        return mediaFile;
      });
      return {success, payload: mediaFiles};
    }
    return {success, error};
  }

  static async getMultimediaUrl(multimediaId) {
    const params = {mediaFileId: multimediaId};
    const {success, body, error} = await backendRequestUtil.get('/rest/mediaFileUrl', {params});
    return success ? {success, payload: body.url} : {success, error};
  }

  static async createMultimedia(multimediaId, file, options = {}) {
    const params = {id: multimediaId, ...options};
    const headers = {'Content-Type': file.type};
    const {success, error} = await backendRequestUtil.post('/api/mediaFile', file, {params, headers});
    if(!success && !error.internal) {
      if(/^[^]+ ya existe$/.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
    }
    return success ? {success} : {success, error};
  }

  static async deleteMultimedia(multimediaId) {
    const params = {mediaFileId: multimediaId};
    const {success, error} = await backendRequestUtil.delete('/api/mediaFile', {params});
    return success ? {success} : {success, error};
  }

}