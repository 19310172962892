import {useRef, useState, useEffect} from 'react';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import TOAST from 'consts/toast.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import CreateTestContactDialog from 'views/campaigns/components/CreateTestContactDialog.component';
import TestContactsService from 'services/testContacts.service';

export default function CampaignTestSending(props) {
  const tableRef = useRef();
  const messages = useMessages();
  const asyncState = useAsyncState({isInitialized: true});
  const [state, setState] = useStateParams();
  const [testContacts, setTestContacts] = useState([]);
  const [selectedTestContacts, setSelectedTestContacts] = useState(state.selectedTestContacts ?? []);
  const [showCreateTestContactDialog, setShowCreateTestContactDialog] = useState(false);
  const [showTestSendDialog, setShowTestSendDialog] = useState(false);

  useEffect(() => reboot(), []);
  useEffect(() => handleSelectedTestContactsChange(), [selectedTestContacts]);

  const reboot = async () => {
    await asyncState.allPromises(
      [getTestContacts()]
    );
  }

  const handleSelectedTestContactsChange = () => {
    setState({selectedTestContacts}, {merge: true});
  }

  const getTestContacts = async () => {
    const response = await TestContactsService.getTestContacts();
    if(response.success) {
      setTestContacts(response.payload);
      getInitialSelectedTestContacts(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  const getInitialSelectedTestContacts = (loadedTestContacts) => {
    const initial = state.selectedTestContacts ?? [];
    const updatedSelectedTestContacts = [];
    for(const testContact of initial) {
      const index = loadedTestContacts.findIndex((loadedTestContact) => loadedTestContact.id === testContact.id);
      if(index >= 0) {
        updatedSelectedTestContacts.push(loadedTestContacts[index]);
      }
    }
    setSelectedTestContacts(updatedSelectedTestContacts);
  }

  const sendCampaignTest = async () => {
    const lineId = props.line.id;
    const toTestContacts = selectedTestContacts.map(({id}) => id);
    const flow = getSanitizedFlow();
    const isDynamic = props.campaign.isDynamic;
    const map = props.mapping;
    const response = await TestContactsService.sendCampaignTest(lineId, toTestContacts, flow, map, {isDynamic});
    return {success: response.success};
  }

  const getSanitizedFlow = () => {
    const {name, language, replyButtons} = props.campaign.flow;
    const flow = {name, language};
    if(replyButtons !== undefined) {
      flow.replyButtons = {};
      for(const [text, replyButton] of Object.entries(replyButtons)) {
        const replyButtonPayload = {};
        if(replyButton.autoReply !== undefined) {
          if(replyButton.autoReply.type === 'TEMPLATE') {
            const {name, language} = replyButton.autoReply.template;
            replyButtonPayload.autoReply = {type: 'TEMPLATE', template: {name, language}};
          }
          if(replyButton.autoReply.type === 'TEXT') {
            replyButtonPayload.autoReply = {...replyButton.autoReply};
          }
        }
        flow.replyButtons[text] = replyButtonPayload;
      }
    }
    return flow;
  }

  const handleCreateTestContactDialogHide = async (event) => {
    if(event.success == true) {
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se agregó el contacto de prueba.'
      );
      await reboot();
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        'No se pudo registrar el contacto de prueba, inténtalo de nuevo.'
      );
    }
  }

  const handleTestSendDialogHide = (event) => {
    if(event.action == 'accept') {
      const payload = event.payload;
      if(payload?.success === true) {
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Se envió la prueba de campaña.'
        );
      }
      else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          'No se pudo enviar la prueba de campaña, inténtalo de nuevo.'
        );
      }
    }
  }

  const handleTestContactsSelectionChange = (event) => {
    const {value} = event;
    setSelectedTestContacts(value);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Contactos de prueba</NeoInnerTitle>
      {
        (asyncState.isLoading) &&
        <InternalSpinner/>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                extra='no-filters with-footer p-mb-3'
                ref={tableRef}
                value={testContacts}
                selection={selectedTestContacts}
                removableSort
                emptyMessage='No hay contactos de prueba'
                onSelectionChange={handleTestContactsSelectionChange}
                footer={`${selectedTestContacts.length} contacto${selectedTestContacts.length != 1 ? 's' : ''} de prueba seleccionado${selectedTestContacts.length != 1 ? 's' : ''}`}
              >
                <NeoTableColumn
                  selectionMode='multiple'
                  style={{width: '3em'}}
                />
                <NeoTableColumn
                  header='Nombre'
                  field='name'
                />
                <NeoTableColumn
                  header='Teléfono'
                  field='phone'
                />
              </NeoTable>
              <NeoButtonSection align='center'>
                <NeoButtonMain
                  label='Agregar contacto'
                  icon='pi pi-plus'
                  onClick={() => setShowCreateTestContactDialog(true)}
                />
                <NeoButtonMain
                  label='Enviar prueba a seleccionados'
                  icon='pi pi-fw pi-send'
                  disabled={selectedTestContacts.length === 0}
                  onClick={() => setShowTestSendDialog(true)}
                />
              </NeoButtonSection>
              {
                <CreateTestContactDialog
                  visible={showCreateTestContactDialog}
                  visibleSetter={setShowCreateTestContactDialog}
                  onHide={handleCreateTestContactDialogHide}
                />
              }
              {
                <ActionDialog
                  header='Enviar prueba de campaña'
                  visible={showTestSendDialog}
                  visibleSetter={setShowTestSendDialog}
                  acceptMessage='Enviando prueba de campaña'
                  onAccept={sendCampaignTest}
                  onHide={handleTestSendDialogHide}
                >
                  Se enviará una prueba de campaña a los contactos seleccionados. El mensaje a enviar se genera con datos aleatorios y será el mismo para todos. ¿Deseas proceder?
                </ActionDialog>
              }
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <DataFetchError internal align='start' onRetry={reboot}/>
          }
        </>
      }
    </NeoCard>
  );
}