import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

export default function ConversationsOriginCount(props) {
  const [data, setData] = useState({});

  useEffect(async () => await reboot(), [props.data]);

  const reboot = async () => {
    if(props.data) {
      setData(props.data);
    }
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoInnerTitle extra='primero'>Conversaciones cobradas</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              <NeoColumn col='3'>
                <NeoDataSecond
                  label='Total de conversaciones'
                  fact={((data.campaigns ?? 0) + (data.business ?? 0) + (data.client ?? 0)).toString() ?? '-'}
                />
              </NeoColumn>
              <NeoColumn col='3'>
                <NeoDataSecond
                  label='Iniciadas por Empresa-Campaña'
                  fact={data.campaigns?.toString() ?? '-'}
                />
              </NeoColumn>
              <NeoColumn col='3'>
                <NeoDataSecond
                  label='Iniciadas por Empresa-Unitario'
                  fact={data.business?.toString() ?? '-'}
                />
              </NeoColumn>
              <NeoColumn col='3'>
                <NeoDataSecond
                  label='Iniciadas por Cliente'
                  fact={data.client?.toString() ?? '-'}
                />
              </NeoColumn>
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry}/>
          }
        </>
      }
    </NeoCard>
  );
}