import { useMemo, useState } from 'react';
import useChat from 'hooks/useChat.hook';
import ChatService from 'services/chat.service';
import ContactConversationItem from 'views/chat/components/items/ContactConversationItem.component';
import ChatHeaderActionsSection from 'views/chat/components/sections/ChatHeaderActionsSection.component';
import CreateCommentDialog from '../dialogs/CreateCommentDialog.component';
import ReassignedConversationDialog from '../dialogs/ReassignedConversationDialog.component';
import NeoButtonSplit from 'design/design_components/neo/button/NeoButtonSplit.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';

const ConversationInfo = (props) => {
  const chat = useChat();

  const reassignedConversation = async (areaId, agentId, reason) => {
    agentId = agentId === -1 ? undefined : agentId;
    const response = await ChatService.chatReassignment({
      lineId: chat.state.lineSelected.id,
      chatId: chat.state.conversationSelected.id,
      areaId,
      agentId,
      reason
    });
    if (response.success) {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.REASSIGNED.TYPE,
          payload: {
            conversation: chat.state.conversationSelected
          }
        }
      })
    }
    return { success: response.success };
  }

  const finishConversation = async (comment) => {
    const response = await ChatService.finishConversation({
      lineId: chat.state.lineSelected.id,
      chatId: chat.state.conversationSelected.id,
      comment
    });
    return { success: response.success };
  }

  const contact = chat.state.conversationSelected && <ContactConversationItem name={chat.state.conversationSelected?.contact?.name} alias={chat.state.conversationSelected.contact?.alias} phone={chat.state.conversationSelected?.contact?.phone} />;

  const actions = (!chat.state.conversationSelected?.isExpired && !chat.state.conversationSelected?.isFinished && !chat.state.conversationSelected?.isReassigned) && (() => {
    const [showReassignedDialog, setShowReassignedDialog] = useState(false);
    const [showCreateCommentDialog, setShowCreateCommentDialog] = useState(false);

    const ButtonsSplit = () => {
      const model=[];
      if(props.render?.actions?.finalize?.isRender !== false) {
        model.push({
          label: 'Finalizar conversación', 
          icon: 'pi pi-power-off', 
          command: () => setShowCreateCommentDialog(true)
        });
      }

      return <NeoButtonSplit
        label="Reasignar"
        icon="pi pi-users"
        onClick={() => setShowReassignedDialog(true)}
        model={model}>
      </NeoButtonSplit>;
    }
    
    const ButtonReassigned = () => {
      return <>
        <NeoButtonMain
          type="button"
          icon="pi pi-users"
          label="Reasignar"
          onClick={() => setShowReassignedDialog(true)}
        />
      </>
    }

    const ButtonFinalize = () => {
      return <>
        <NeoButtonMain
          type="button"
          icon="pi pi-power-off"
          label="Finalizar conversación"
          onClick={() => setShowCreateCommentDialog(true)}
        />
      </>
    }

    const chatHeaderActionsComponents = useMemo(() => {
      const buttons = [
        ButtonsSplit, 
        ButtonReassigned,
        (props.render?.actions?.finalize?.isRender !== false) && ButtonFinalize
      ];
      return buttons;
    }, [props.render])

    return (<>
      <ChatHeaderActionsSection
        actions={chatHeaderActionsComponents} 
      />
      <ReassignedConversationDialog
        line={chat.state.lineSelected}
        conversation={chat.state.conversationSelected}
        visible={showReassignedDialog}
        visibleSetter={setShowReassignedDialog}
        onHide={() => { }}
        onSubmit={reassignedConversation}
      />
      <CreateCommentDialog
        visible={showCreateCommentDialog}
        visibleSetter={setShowCreateCommentDialog}
        onHide={() => { }}
        onSubmit={finishConversation}
        title="Finalizar conversación"
        description="Ingresa un comentario interno final sobre esta conversación. 
        Recuerda que al finalizarla no tendrás acceso nuevamente a esta"
        placeholder="Añade un comentario de cierre:"
      />
    </>)
  })();

  return (
    <div className={`${props.component.name}__conversation-info`}>
      {contact}
      {actions}
    </div>
  );
}

export default ConversationInfo;