import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} name Name of contact
 * @property {string} phone Phone of contact
 * @property {string} alias Alias of contact
 * @property {boolean} showVirgulilla Define if show virgulilla
 * @property {boolean} showAliasWithName Define if show alias with contain name
 */

const Component = {
  name: 'ContactConversationItemInfo',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const name = props?.name && <div className={`${Component.name}__body__name`}>{ObjectUtils.getJSXElement(props.name, props)}</div>;
      const phone = props?.phone && <div className={`${Component.phone}__body__phone`}>{ObjectUtils.getJSXElement(props.phone, props)}</div>;
      
      const showVirgulilla = props.showVirgulilla ?? true;
      const alias = props?.alias && <div className={`${Component.name}__body__alias`}>
        {showVirgulilla && <>~</>} {ObjectUtils.getJSXElement(props.alias, props)}
      </div>;

      const showAliasWithName = props.showAliasWithName ?? false;

      return (
        <div className={`${Component.name}__body`}>
          {name || phone}
          {(!name || showAliasWithName) && alias}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  alias: PropTypes.string,
  showVirgulilla: PropTypes.bool,
  showAliasWithName: PropTypes.bool,
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  name: null,
  phone: null,
  alias: null,
  showVirgulilla: null,
  showAliasWithName: null,
};

export default Component.element;