import { forwardRef, useRef, createElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import NeoOverlayPanel from 'design/design_components/neo/overlay/NeoOverlayPanel.base';
import EmojiPicker from 'emoji-picker-react';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Function} onEmojiSelect Function to on emoji selected
 */

const Component = {
  name: 'ChatActionEmojisItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const overlayRef = useRef(null);
    
      const emojis = <div className={`${Component.name}__body__emojis`}>
        <i className="fa-regular fa-face-smile" onClick={(e) => overlayRef.current.toggle(e)}/>
        <NeoOverlayPanel ref={overlayRef} showCloseIcon className={`${Component.name}__overlay`}>
          <EmojiPicker
            searchPlaceholder="Buscar"
            suggestedEmojisMode="recent"
            emojiStyle="apple"
            emojiVersion="0.6"
            lazyLoadEmojis={true}
            onEmojiClick={(event) => {
              props?.onEmojiSelect({
                emoji: event.emoji
              });
            }}/>
        </NeoOverlayPanel>
      </div>;

      return (
        <div className={`${Component.name}__body`}>
          {emojis}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  onEmojiSelect: PropTypes.func
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  onEmojiSelect: null
};

export default Component.element;