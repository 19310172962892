import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';

export default function ContactsDatabaseNavigationButtons(props) {
  return (
    <>
      {
        (props.contactsDatabase.length > 1) &&
        <NeoGridContainer>
          <NeoButtonSection align='right'>
          {
            (props.value !== 0) &&
            <NeoButtonText
              label='Anterior'
              icon='pi pi-arrow-left'
              onClick={() => props.onChange(props.value - 1)}

            />
          }
          </NeoButtonSection>
          <NeoButtonSection align='left'>
          {
            (props.value !== props.contactsDatabase.length - 1) &&
            <NeoButtonText
              label='Siguiente'
              icon='pi pi-arrow-right'
              iconPos='right'
              onClick={() => props.onChange(props.value + 1)}

            />
          }
          </NeoButtonSection>
        </NeoGridContainer>
      }
    </>
  );
}