import {useRef} from 'react';
import LinesSection from 'views/chat/components/sections/LinesSection.component';
import ConversationsSection from 'views/chat/components/sections/ConversationsSection.component';
import ChatSection from 'views/chat/components/sections/ChatSection.component';
import useAuthentication from 'hooks/useAuthentication.hook';
import NeoDialogo from 'design/design_components/neo/overlay/NeoDialog.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import useChat from 'hooks/useChat.hook';
import NeoButtonSecondary from 'design/design_components/neo/button/NeoButtonOutlined.base';
import ConversationsUnassignedSection from 'views/chat/components/sections/ConversationsUnassignedSection.component';

export default function ChatView() {
  const domChat = useRef();
  const authentication = useAuthentication();
  const chat = useChat();

  const render = {
    conversationContactInfo: {
      open: {
        isRender: false
      }
    }
  }

  return (
    <div className='Chat AgentChat' ref={domChat}>
      {(domChat.current) &&
        <NeoDialogo
          custom="employee-status"
          visible={authentication.user.status === 'AWAY'}
          appendTo={domChat.current}
          closable={false}
          header="Tu disponibilidad es ausente"
          footer={<>
            <NeoButtonSecondary
              label="Cambiar a por terminar"
              onClick={() => authentication.status.update('TO_GET_OUT')}
              loading={authentication.status.isUpdating}
            />
            <NeoButtonMain
              label="Cambiar a disponible"
              onClick={() => authentication.status.update('ONLINE')}
              loading={authentication.status.isUpdating}
            />
          </>}
        >
          <p>Cambia tu estatus de disponibilidad para poder contestar conversaciones con tus clientes</p>
        </NeoDialogo>
      }

      <LinesSection />

      {
        (chat.state.lineSelected) &&
        <ConversationsSection
          elements={{
            ContactConversationsList: {
              footer: {
                show: true
              }
            }
          }}
        />
      }

      {
        (chat.state.conversationSelected) && 
        <ChatSection 
          render={render}
        />
      }
    </div>
  );
}