import {Readable} from 'stream';
import {Workbook} from 'exceljs';

async function readFromBuffer(buffer, options = {}) {
  const includeRows = options.rows !== false;
  const limitRows = options.limitRows ?? null;
  const workbook = new Workbook();
  const readable = new Readable();
  readable._read = () => {};
  readable.push(buffer);
  readable.push(null);
  const worksheet = await workbook.csv.read(readable, {map: (data) => data});
  let headers = {};
  const rows = [];
  worksheet.eachRow({includeEmpty: options.includeEmpty ?? true}, (row, rowNumber) => {
    const rowData = {};
    row.eachCell((cell, cellNumber) => {rowData[cellNumber] = cell.value.toString()});
    if(rowNumber == 1) { headers = rowData; }
    else if(includeRows === true && (limitRows === null || rows.length < limitRows)) { rows.push(rowData); }
  });
  return {headers, rows};
}

async function create(columns, rows, options = {}) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('sheet');
  worksheet.columns = columns;
  worksheet.addRows(rows);
  const buffer = await workbook.csv.writeBuffer();
  const blob = new Blob(['\ufeff' + buffer], {type: 'text/csv;charset=utf-8;'});
  const url = window.URL.createObjectURL(blob);
  const downloadInstance = document.createElement('a');
  downloadInstance.href = url;
  downloadInstance.target = '_blank';
  downloadInstance.download = `${options.filename ?? Date.now()}.csv`;
  downloadInstance.click();
}

export default {
  readFromBuffer,
  create
};