import {useState, useEffect} from 'react';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import ActionDialog from 'components/ActionDialog.component';
import ChatService from 'services/chat.service';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import useMessages from 'hooks/useMessages.hook';
import TOAST from 'consts/toast.consts';

export default function ModifyQuickReplyDialog(props) {
  const messages = useMessages()
  const [quickReply, setQuickReply] = useState({
    title: props.quickReply.title,
    replie: props.quickReply.replie
  })

  useEffect(() => resetFrom(), [props.quickReply]);

  const trim = (value) => {
    return value.trim().replace(/[ ]+/g, ' ');
  }

  const resetFrom = () => {
    setQuickReply({ 
      title: props.quickReply.title,
      replie: props.quickReply.replie
     });
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    if(event.action == 'cancel' || !event.payload.success) { resetFrom(); }
    if(event.action === 'delete') {
      setShowDeleteDialog(true)
    }
  }

  const handleDelete = () => ({ success: true })

  const updateQuickReply = async () => {
    const updatedQuickReply = {
      title: trim(quickReply.title),
      replie: trim(quickReply.replie)
    }
    setQuickReply(updatedQuickReply)
    const response = await ChatService.updateQuicklyReply(props.quickReply.id, updatedQuickReply);
    if(response.success) {
      return { success: true, payload: { id: props.quickReply.id, ...updatedQuickReply } };
    }
    return { success: false, internal: true };
  }

  const handleInputChange = (event) => {
    setQuickReply({ ...quickReply, [event.target.name]: event.value ?? event.target.value });
  }

  // delete quick reply
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteQuickReply = async () => {
      const response = await ChatService.deleteQuicklyReply(props.quickReply.id);
      return { success: response.success };
  };
  const handleDeleteDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        props.visibleSetter(false)
        props.listRef.current?.deleteQuickReply(props.quickReply.id)
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Se eliminó la respuesta rápida.'
        );
      } else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          'No se pudo eliminar la la respuesta rápida, inténtalo de nuevo.'
        );
      }
    }
  };
  const handleDeleteDialogCancel = () => {
    props.visibleSetter(true)
  }

  return (
    <>
      <ActionDialog
        header='Modificar respuesta rápida'
        visible={props.visible}
        visibleSetter={props.visibleSetter}
        acceptMessage='Modificando respuesta rápida'
        acceptDisabled={
          (quickReply.replie.length == 0 || trim(quickReply.replie) == trim(props.quickReply.replie)) &&
          (quickReply.title.length == 0 || trim(quickReply.title) == trim(props.quickReply.title))
        }
        onAccept={updateQuickReply}
        onHide={handleHide}
        onDelete={handleDelete}
        delete
        deleteHiddenController={(payload) => payload.success || payload.internal}
        acceptHiddenController={(payload) => payload.success || payload.internal}
      >
        <NeoInputText
          label='Título'
          name='title'
          value={quickReply.title}
          onChange={handleInputChange}
        />
        <NeoInputTextarea
          label='Descripción'
          name='replie'
          value={quickReply.replie}
          onChange={handleInputChange}
        />
      </ActionDialog>


      <ActionDialog
        header="Eliminar respuesta rápida"
        visible={showDeleteDialog}
        visibleSetter={setShowDeleteDialog}
        acceptMessage='Eliminando respuesta rápida'
        onAccept={deleteQuickReply}
        onHide={handleDeleteDialogHide}
        onCancel={handleDeleteDialogCancel}
      >
        Se eliminará la respuesta rápida. ¿Deseas proceder?
      </ActionDialog>
    </>
  );
}
