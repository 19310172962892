import CAMPAIGNS from 'consts/campaigns.consts';

function getCampaignStatus(status) {
  return CAMPAIGNS.STATUSES[status] ?? 'Desconocido';
}

function getCampaignMessageErrorType(errorType) {
  return CAMPAIGNS.MESSAGE_ERRORS_TYPES[errorType] ?? 'Error interno genérico';
}

function getInitialTemplateSendingType(sendingType) {
  return CAMPAIGNS.INITIAL_TEMPLATE_SENDING_TYPE[sendingType] ?? 'Desconocido';
}

function getCampaignType(campaign) {
  if(campaign.isDynamic === true) {
    return campaign.isRecurrent === true ? 'RECURRENT_DYNAMIC' : 'DYNAMIC';
  }
  return 'BASIC';
}

export default {
  getCampaignType,
  getCampaignStatus,
  getCampaignMessageErrorType,
  getInitialTemplateSendingType
};