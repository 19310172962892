import {useState, useEffect} from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import TEMPLATES from 'consts/templates.consts';

const autoReplyDropdownOptions = [
  {value: 'TEXT', label: 'Texto'},
  {value: 'TEMPLATE', label: 'Plantilla'},
  {value: 'NONE', label: 'Sin respuesta'}
];

const actionOptions = [
  {value: 'ADD_BLACK_LIST', label: 'Agregar a la lista negra para el envío de mensajes'}
];

const actionOptionsDynamic = [
  {value: 'SUBSCRIBE', label: 'Recibir envíos posteriores de esta campaña recurrente'},
  {value: 'UNSUBSCRIBE', label: 'Omitir envíos de esta campaña recurrente'},
  {value: 'NONE', label: 'Sin acción'}
];

export default function DynamicCampaignButtonActionsForm(props) {
  const [state] = useStateParams();
  const [autoReplyType, setAutoReplyType] = useState((state.replyButtons ?? {})[props.text]?.autoReply?.type);
  const [action, setAction] = useState((state.replyButtons ?? {})[props.text]?.action);
  const [actionDropdownOptions, setActionDropdownOptions] = useState([]);
  const [text, setText] = useState((state.replyButtons ?? {})[props.text]?.autoReply?.text ?? '');
  const [selectedTemplate, setSelectedTemplate] = useState(() => {
      const templates = props.templates?.filter((template) => template.name === (state.replyButtons ?? {})[props.text]?.autoReply?.template?.name);
      return (templates ?? [])[0];
  });
  const [selectedTranslation, setSelectedTranslation] = useState(() => {
    const templates = props.templates?.filter((template) => template.name === (state.replyButtons ?? {})[props.text]?.autoReply?.template?.name);
    const template = (templates ?? [])[0];
    const translations = template?.translations?.filter((translation) => translation.language === (state.replyButtons ?? {})[props.text]?.autoReply?.template?.language);
    return (translations ?? [])[0];
  });
  
  useEffect(() => reboot(), [autoReplyType, props.dynamicCampaignConfig]);
  useEffect(() => handleChange(), [autoReplyType, action, text, selectedTemplate, selectedTranslation, props.dynamicCampaignConfig]);

  const reboot = () => {
    let options = [];
    if(autoReplyType === 'TEXT') {
      options = options.concat(actionOptions);
    }
    if(props.dynamicCampaignConfig?.isRecurrent) {
      options = options.concat(actionOptionsDynamic);
    }
    if(props.dynamicCampaignConfig?.isRecurrent === false) {
      setAction(undefined);
    }
    else if(autoReplyType === 'TEXT' && props.dynamicCampaignConfig?.initialSentType === 'UNIQUE') {
      if(action === 'SUBSCRIBE') { setAction(undefined); }
      options = options.filter((option) => {
        return option.value !== 'SUBSCRIBE';
      })
    }
    setActionDropdownOptions(options);
  }

  const handleChange = () => {
    const event = {
      valid: true,
      payload: {}
    };
    const {payload} = event;
    if(action !== 'NONE' && action) {
      payload.action = action;
    }
    if(autoReplyType === 'TEXT') {
      event.valid = text?.trim().length > 0;
      payload.autoReply = {type: autoReplyType, text};
    }
    if(autoReplyType === 'TEMPLATE') {
      const name = selectedTemplate?.name;
      const language = selectedTranslation?.language;
      const structure = selectedTranslation?.structure;
      event.valid = name !== undefined && language !== undefined;
      payload.autoReply = {type: autoReplyType, template: {name, language, structure}};
    }
    event.valid = event.valid === true 
      && (props.dynamicCampaignConfig?.isRecurrent === false || action !== undefined) 
      && autoReplyType !== undefined;
    props.onChange(event);
  }

  const handleAutoReplyTypeDropdownChange = (event) => {
    const type = event.value;
    if(type === 'TEMPLATE') {
      const template = props.templates[0];
      const translations = template?.translations ?? [];
      setSelectedTemplate(template);
      setSelectedTranslation(translations[0]);
    }
    setAutoReplyType(type);
  }

  const handleTemplateDropdownChange = (event) => {
    const selectedTemplate = event.value;
    const selectedTranslation = selectedTemplate.translations[0];
    setSelectedTemplate(selectedTemplate);
    setSelectedTranslation(selectedTranslation);
  }

  const handleLanguageDropdownChange = (event) => {
    const selectedTranslation = event.value;
    setSelectedTranslation(selectedTranslation);
  }

  return (
    <NeoCard>
			<NeoInnerTitle extra='primero'>Acciones de botón "{props.text}"</NeoInnerTitle>
      <NeoDropdown
        md='6'
        label='Tipo de respuesta'
        value={autoReplyType}
        options={autoReplyDropdownOptions}
        onChange={handleAutoReplyTypeDropdownChange}
      />
      <NeoDropdown
        md='6'
        label='Acción que detona este botón para el contacto'
        value={action}
        disabled={actionDropdownOptions.length === 0}
        options={actionDropdownOptions}
        onChange={(event) => setAction(event.value)}
      />
      {
        (autoReplyType === 'TEXT') &&
        <NeoInputTextarea
          label='Texto de respuesta'
          value={text}
          onChange={(event) => setText(event.value ?? event.target.value)}
        />
      }
      {
        (autoReplyType === 'TEMPLATE') &&
        <>
          <NeoDropdown
            md='6'
            label='Nombre de plantilla'
            value={selectedTemplate}
            options={props.templates.map((template) => ({label: template.name, value: template}))}
            onChange={handleTemplateDropdownChange}
          />
          <NeoDropdown
            md='6'
            label='Idioma de plantilla'
            value={selectedTranslation}
            options={selectedTemplate?.translations.map((translation) => ({label: TEMPLATES.LANGUAGES[translation.language], value: translation}))}
            onChange={handleLanguageDropdownChange}
          />
        </>
      }
		</NeoCard>
  );
}