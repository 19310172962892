import 'design/assets/styles/cta/style.scss';


import { BrowserRouter } from 'react-router-dom';

import ApplicationContextProvider from 'contexts/application.context';
import { AuthenticationContextProvider } from 'contexts/authentication.context';
import { MessagesContextProvider } from 'contexts/messages.context';
import MainRouter from 'routers/Main.router';

function App() {
  return (
    <BrowserRouter>
      <ApplicationContextProvider>
        <AuthenticationContextProvider>
          <MessagesContextProvider>
            <MainRouter />
          </MessagesContextProvider>
        </AuthenticationContextProvider>
      </ApplicationContextProvider>
    </BrowserRouter>
  );
}

export default App;