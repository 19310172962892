import ActionDialog from 'components/ActionDialog.component';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';
import NeoSelectButton from 'design/design_components/neo/button/NeoSelectButton.base';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import NeoMultiSelect from 'design/design_components/neo/form/NeoMultiSelect.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import useComponent from 'hooks/useComponent.hook';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import { DateTime } from 'luxon';
import { Divider } from 'primereact/divider';
import { useMemo } from 'react';
import AutomaticRepliesService from 'services/automaticReplies.service';
import ChatActionEmojisItem from 'views/chat/components/items/ChatActionEmojisItem.component';

const trim = (value) => {
    return value.trim().replace(/[ ]+/g, ' ');
};

const AutomaticReplyModify = (props) => {
    const messages = useMessages();
    const component = useComponent({
        state: {
            element: {
                title: {
                    value: '',
                    error: '',
                },
                selectMode: {
                    selected: '',
                    options: [
                        { value: 'NO_AGENTS', label: 'Si no hay agentes' },
                        {
                            value: 'BUSY_AGENTS',
                            label: 'Si hay agentes pero en cola llena',
                        },
                        {
                            value: 'AVAILABLE_AGENTS',
                            label: 'Si hay agentes disponibles',
                        },
                        { value: 'ALWAYS', label: 'Siempre' },
                    ],
                },
                selectLine: {
                    selected: [],
                    options: [],
                },
                selectType: {
                    selected: 'DAILY',
                    options: [
                        { value: 'DAILY', label: 'Cotidiano' },
                        { value: 'SPECIFIC', label: 'Específico' },
                    ],
                },
                selectDays: {
                    selected: [],
                    options: [
                        { value: 1, label: 'L' },
                        { value: 2, label: 'M' },
                        { value: 3, label: 'X' },
                        { value: 4, label: 'J' },
                        { value: 5, label: 'V' },
                        { value: 6, label: 'S' },
                        { value: 0, label: 'D' },
                    ],
                },
                hasSchedules: {
                    value: false,
                },
                textArea: {
                    value: '',
                    error: '',
                },
                selectSchedules: {
                    values: [{ start: '', end: '' }],
                },
                specificDate: {
                    value: null,
                },
            },
        },
    });

    component.useEffect(() => {
        if (Array.isArray(props.lines)) {
            component.setState((state) => {
                state.element.selectLine.options = props.lines;
            });
        }
    }, [props.lines]);

    component.useEffect(() => {
        component.setState((state) => {
            state.element.title.value = props.automaticReply?.title ?? '';
            state.element.textArea.value = props.automaticReply?.message ?? '';
            state.element.selectType.selected =
                props.automaticReply?.type ?? props.type ?? '';
            state.element.selectMode.selected =
                props.automaticReply?.mode ?? '';
            state.element.selectLine.selected = props.automaticReply?.lineIds;
            state.element.selectDays.selected = props.automaticReply?.days;
            state.element.specificDate.value = props.automaticReply?.date
                ? new Date(props.automaticReply?.date)
                : null;
            state.element.hasSchedules.value =
                props.automaticReply?.schedules?.length > 0;
            state.element.selectSchedules.values =
                props.automaticReply?.schedules?.map((schedule) => {
                    const [startHour, startMinute] =
                        schedule?.start?.split(':');
                    const [endHour, endMinute] = schedule?.end?.split(':');
                    return {
                        start: DateTime.now()
                            .startOf('day')
                            .plus({
                                hours: startHour,
                                minutes: startMinute,
                            })
                            .toJSDate(),
                        end: DateTime.now()
                            .startOf('day')
                            .plus({
                                hours: endHour,
                                minutes: endMinute,
                            })
                            .toJSDate(),
                    };
                });
        });
    }, [
        props.type,
        props?.automaticReply,
        props.automaticReply?.title,
        props.automaticReply?.message,
        props.automaticReply?.mode,
        props.automaticReply?.type,
        props.automaticReply?.schedules,
        props.automaticReply?.date,
        props.automaticReply?.days,
        props.automaticReply?.lineIds,
    ]);

    component.useEffect(() => {
        if (
            !Array.isArray(component.state.element.selectSchedules.values) ||
            component.state.element.selectSchedules.values?.length === 0
        ) {
            component.setState((state) => {
                state.element.selectSchedules.values = [{ start: '', end: '' }];
            });
        }
    }, [component.state.element.hasSchedules.value]);

    const handleAccept = async () => {
        const replyId = props.automaticReply?.replyId;
        const type = component.state.element.selectType.selected;
        const hasSchedules = component.state.element.hasSchedules.value;
        const reply = {
            title: trim(component.state.element.title.value),
            message: trim(component.state.element.textArea.value),
            mode: component.state.element.selectMode.selected,
            type,
            ...(hasSchedules
                ? {
                    schedules:
                        component.state.element.selectSchedules.values.map(
                            (schedule) => {
                                const { start, end } = schedule;

                                const startHours = ("0" + start.getHours()).slice(-2);
                                const startMinutes = ("0" + start.getMinutes()).slice(-2);
                                
                                const endHours = ("0" + end.getHours()).slice(-2);
                                const endMinutes = ("0" + end.getMinutes()).slice(-2);

                                return {
                                    start: `${startHours}:${startMinutes}`,
                                    end: `${endHours}:${endMinutes}`,
                                };
                            }
                        ),
                }
                : null),
            ...(type === 'SPECIFIC'
                ? { date: component.state.element.specificDate.value }
                : null),
            ...(type === 'DAILY'
                ? { days: component.state.element.selectDays.selected }
                : null),
            lineIds: component.state.element.selectLine.selected,
        };

        let response;

        if (Object.keys(props.automaticReply).length === 0) {
            response = await AutomaticRepliesService.createAutomaticReply(
                reply
            );
        } else {
            response = await AutomaticRepliesService.updateAutomaticReply(
                replyId,
                reply
            );
        }

        if (response.success) {
            props.visibleSetter(false);
            if (Object.keys(props.automaticReply).length === 0) {
                props.actions.onCreate(response.payload);
            } else {
                props.actions.onModify(replyId, response.payload);
            }
        } else {
            messages.showToast(
                TOAST.SEVERITY.ERROR,
                'Algo salió mal',
                response.error?.message
            );
        }
    };

    const handleCancel = () => {};

    const isDisabled = useMemo(() => {
        const schedules = props.automaticReply?.schedules?.map((schedule) => {
            const [startHour, startMinute] = schedule?.start?.split(':');
            const [endHour, endMinute] = schedule?.end?.split(':');
            return {
                start: DateTime.now()
                    .startOf('day')
                    .plus({
                        hours: startHour,
                        minutes: startMinute,
                    })
                    .toJSDate(),
                end: DateTime.now()
                    .startOf('day')
                    .plus({
                        hours: endHour,
                        minutes: endMinute,
                    })
                    .toJSDate(),
            };
        });

        if (
            trim(component.state.element.title.value) ===
                props.automaticReply?.title &&
            trim(component.state.element.textArea.value) ===
                props.automaticReply?.message &&
            component.state.element.selectType.selected ===
                props.automaticReply?.type &&
            component.state.element.selectMode.selected ===
                props.automaticReply?.mode &&
            (component.state.element.selectType.selected === 'SPECIFIC'
                ? component.state.element.specificDate.value.toString() ===
                  new Date(props.automaticReply?.date).toString()
                : true) &&
            (component.state.element.selectType.selected === 'DAILY'
                ? component.state.element.selectDays.selected?.every(
                      (day, i) => day === props.automaticReply?.days[i]
                  ) &&
                  component.state.element.selectDays.selected?.length ===
                      props.automaticReply?.days?.length
                : true) &&
            component.state.element.selectLine.selected?.every(
                (line, i) => line === props.automaticReply?.lineIds[i]
            ) &&
            (component.state.element.hasSchedules.value
                ? Array.isArray(props.automaticReply?.schedules) &&
                  component.state.element.selectSchedules.values?.every(
                      (schedule, i) => {
                          if (!schedule.start || !schedule.end) {
                              return true;
                          }

                          if (!schedules[i]?.start || !schedules[i]?.end) {
                              return true;
                          }

                          return (
                              schedule?.start?.toString() ===
                                  schedules[i]?.start?.toString() &&
                              schedule?.end?.toString() ===
                                  schedules[i]?.end?.toString()
                          );
                      }
                  )
                : false)
        ) {
            return true;
        }

        if (
            trim(component.state.element.title.value) === '' ||
            trim(component.state.element.textArea.value) === '' ||
            component.state.element.selectMode.selected === '' ||
            !Array.isArray(component.state.element.selectLine.selected) ||
            component.state.element.selectLine.selected?.length < 1
        ) {
            return true;
        }

        if (component.state.element.selectType.selected === 'GENERIC') {
            return false;
        }

        if (
            component.state.element.hasSchedules.value &&
            (!Array.isArray(component.state.element.selectSchedules.values) ||
                component.state.element.selectSchedules.values.length < 1 ||
                component.state.element.selectSchedules.values.some(
                    (schedule) =>
                        schedule.start === null || schedule.end === null
                ) ||
                component.state.element.selectSchedules.values.some(
                    (schedule) => schedule.start > schedule.end
                ))
        ) {
            return true;
        }

        if (component.state.element.selectType.selected === 'DAILY') {
            if (
                Array.isArray(component.state.element.selectDays.selected) &&
                component.state.element.selectDays.selected.length > 0
            ) {
                return false;
            }
        }

        if (component.state.element.selectType.selected === 'SPECIFIC') {
            if (
                component.state.element.specificDate.value &&
                component.state.element.specificDate !== ''
            ) {
                return false;
            }
        }

        return true;
    }, [
        component.state.element.title.value,
        component.state.element.textArea.value,
        component.state.element.selectLine.selected,
        component.state.element.selectMode.selected,
        component.state.element.selectType.selected,
        component.state.element.selectDays.selected,
        component.state.element.specificDate.value,
        component.state.element.hasSchedules.value,
        component.state.element.selectSchedules.values,
    ]);

    const onScheduleChange = (event, input, index) => {
        component.setState((state) => {
            state.element.selectSchedules.values[index] = {
                ...state.element.selectSchedules.values[index],
                [input]: event.target.value,
            };
        });
    };

    const deleteSchedule = (index) => {
        component.setState((state) => {
            state.element.selectSchedules.values =
                state.element.selectSchedules.values.filter(
                    (_, i) => index !== i
                );
        });
    };

    const handleDelete = async () => {
        const replyId = props.automaticReply?.replyId;
        const response = await AutomaticRepliesService.deleteAutomaticReply(
            replyId
        );

        if (response.success) {
            props.actions.onDelete(replyId);
        }
    };

    return (
        <ActionDialog
            header='Modificar respuesta automática'
            visible={props?.visible}
            visibleSetter={props?.visibleSetter}
            acceptMessage='Modificando respuesta automática'
            acceptDisabled={isDisabled}
            onAccept={handleAccept}
            onCancel={handleCancel}
            onHide={handleCancel}
            onDelete={handleDelete}
            delete={Object.keys(props.automaticReply).length > 0}
            acceptHiddenController={(payload) =>
                payload?.success || payload?.internal
            }
            extra='AutomaticReplyCreate'
        >
            <NeoGridContainer>
                <NeoColumn>
                    <NeoInputText
                        label='Nombre de respuesta'
                        name='title'
                        value={component.state.element.title.value}
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.title.value = event.target.value;
                            });
                        }}
                    />
                    <NeoDropdown
                        value={component.state.element.selectMode.selected}
                        options={component.state.element.selectMode.options}
                        label='Modo de habilitación'
                        optionValue='value'
                        optionLabel='label'
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.selectMode.selected = event.value;
                            });
                        }}
                    />
                    <NeoMultiSelect
                        value={component.state.element.selectLine.selected}
                        options={component.state.element.selectLine.options}
                        optionLabel='id'
                        optionValue='id'
                        label='Lineas'
                        onChange={(event) => {
                            component.setState((state) => {
                                state.element.selectLine.selected = event.value;
                            });
                        }}
                    />
                    {component.state.element.selectType.selected !==
                        'GENERIC' &&
                        props.type !== 'GENERIC' && (
                            <div className='form-group'>
                                <NeoSelectButton
                                    label='Tipo de evento'
                                    value={
                                        component.state.element.selectType
                                            .selected
                                    }
                                    options={
                                        component.state.element.selectType
                                            .options
                                    }
                                    optionValue='value'
                                    optionLabel='label'
                                    onChange={(event) =>
                                        component.setState((state) => {
                                            state.element.selectType.selected =
                                                event.value;
                                        })
                                    }
                                    unselectable={false}
                                />
                            </div>
                        )}

                    {component.state.element.selectType.selected ===
                        'DAILY' && (
                        <div className='form-group'>
                            <NeoSelectButton
                                value={
                                    component.state.element.selectDays.selected
                                }
                                options={
                                    component.state.element.selectDays.options
                                }
                                optionValue='value'
                                optionLabel='label'
                                onChange={(event) =>
                                    component.setState((state) => {
                                        state.element.selectDays.selected =
                                            event.value;
                                    })
                                }
                                multiple
                            />
                        </div>
                    )}
                    {component.state.element.selectType.selected ===
                        'SPECIFIC' && (
                        <NeoCalendar
                            minDate={new Date()}
                            value={component.state.element.specificDate.value}
                            label='Fecha'
                            onChange={(event) =>
                                component.setState((state) => {
                                    state.element.specificDate.value =
                                        event.value;
                                })
                            }
                            multiple
                        />
                    )}
                    {props?.automaticReply?.type !== 'GENERIC' &&
                        props.type !== 'GENERIC' && (
                            <div className='form-group input-switch'>
                                <NeoInputSwitch
                                    label='Horarios específicos'
                                    checked={
                                        component.state.element.hasSchedules
                                            .value
                                    }
                                    onChange={(event) =>
                                        component.setState((state) => {
                                            state.element.hasSchedules.value =
                                                event.value;
                                        })
                                    }
                                />
                            </div>
                        )}
                </NeoColumn>
                <Divider md='0' layout='vertical' />
                <NeoColumn>
                    <div className='message'>
                        <NeoInputTextarea
                            rightIcon={
                                <InfoTooltip
                                    id='message'
                                    body='La respuesta automática se enviará solo una vez cada 24 horas cuando el contacto envíe un mensaje.'
                                />
                            }
                            maxLength={1024}
                            value={component.state.element.textArea.value}
                            error={component.state.element.textArea.error}
                            onChange={(event) => {
                                component.setState((state) => {
                                    state.element.textArea.value =
                                        event.target.value;
                                });
                            }}
                        />
                        <ChatActionEmojisItem
                            onEmojiSelect={(emoji) => {
                                component.setState((state) => {
                                    state.element.textArea.value =
                                        state.element.textArea.value +
                                        emoji.emoji;
                                });
                            }}
                        />
                    </div>
                    <NeoDataThird
                        extra='p-mt-0 text-align-right'
                        fact={
                            component.state.element.textArea.value.length +
                            '/1024'
                        }
                        label='Caracteres'
                    />
                </NeoColumn>
                <NeoColumn md='12'>
                    {component.state.element.hasSchedules.value && (
                        <div>
                            <h2>Horario</h2>
                            {component.state.element.selectSchedules.values?.map(
                                (_, i) => (
                                    <div
                                        key={`schedule-${i}`}
                                        className='schedule'
                                    >
                                        <NeoCalendar
                                            timeOnly
                                            hourFormat='12'
                                            label='Horario de inicio'
                                            name={`schedule-start-${i}`}
                                            value={
                                                component.state.element
                                                    .selectSchedules.values[i]
                                                    .start
                                            }
                                            onChange={(event) =>
                                                onScheduleChange(
                                                    event,
                                                    'start',
                                                    i
                                                )
                                            }
                                        />
                                        <NeoCalendar
                                            timeOnly
                                            hourFormat='12'
                                            label='Horario de finalización'
                                            name={`schedule-end-${i}`}
                                            value={
                                                component.state.element
                                                    .selectSchedules.values[i]
                                                    .end
                                            }
                                            onChange={(event) =>
                                                onScheduleChange(
                                                    event,
                                                    'end',
                                                    i
                                                )
                                            }
                                        />
                                        {component.state.element.selectSchedules
                                            .values.length > 1 ? (
                                            <NeoButtonText
                                                label='Eliminar'
                                                extra='p-button-danger p-button-text btn'
                                                onClick={() =>
                                                    deleteSchedule(i)
                                                }
                                            />
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                )
                            )}
                            <NeoButtonText
                                label='Agregar horario'
                                extra='p-button-text btn btn--add-schedule'
                                onClick={() =>
                                    component.setState((state) => {
                                        state.element.selectSchedules.values.push(
                                            { start: '', end: '' }
                                        );
                                    })
                                }
                                icon='pi pi-plus-circle'
                            />
                        </div>
                    )}
                </NeoColumn>
            </NeoGridContainer>
        </ActionDialog>
    );
};

export default AutomaticReplyModify;
