import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import LineIconItemName from 'views/chat/components/items/LineIconItemName.component';
import LineIconItemPhoto from 'views/chat/components/items/LineIconItemPhoto.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {string} id Id of line
 * @property {string} name Name of line
 * @property {string} photo URL of photo of line
 * @property {string} phone Phone of line
 * @property {boolean} isAlert Show alert icon
 */

const Component = {
  name: 'LineIconItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);
  
    const createBody = () => {
      const id = props.id && <div className={`${Component.name}__body__id`}>{ObjectUtils.getJSXElement(props.id, props)}</div>;
      const name = props.name && <LineIconItemName name={props.name} />;
      const photo = props.photo && <LineIconItemPhoto photo={props.photo} />;
      const phone = props.phone && <div className={`${Component.name}__body__phone`}>{ObjectUtils.getJSXElement(props.phone, props)}</div>;
      const isAlert = props.isAlert && <div className={`${Component.name}__body__isAlert`}>!</div>;

      return (
          <div className={`${Component.name}__body ${photo ? `${Component.name}__body--photo` : `${Component.name}__body--name`}`}>
              {photo ?? name}
              {isAlert}
          </div>
      );
  };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
          {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  phone: PropTypes.string,
  isAlert: PropTypes.bool
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  name: null,
  photo: null,
  phone: null,
  isAlert: null
};

export default Component.element;