import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import ChatEvent from 'views/chat/components/items/ChatEvent.component';
import ChatComment from 'views/chat/components/items/ChatComment.component';
import ChatBalloon from 'views/chat/components/items/ChatBalloon.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} event Chat event
 */

const Component = {
  name: 'ChatContentItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);
    
    const createBody = () => {
      const event = props.event && <>
        {(() => {
          switch (props.event?.type) {
            case 'EVENT':
              return <ChatEvent event={props.event} />;
            case 'COMMENT':
              return <ChatComment event={props.event} />;
            default:
              return (
                <ChatBalloon
                  event={props.event}
                />
              );
          }
        })()}
      </>;

      return (
        <>
          {event}
        </>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  event: PropTypes.object,
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  event: null,
};

export default Component.element;