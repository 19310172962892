import {useState, useEffect} from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import CAMPAIGNS from 'consts/campaigns.consts';

const mainTemplateDropdownOptions =
  Object.entries(CAMPAIGNS.INITIAL_TEMPLATE_SENDING_TYPE)
    .map(([value, label]) => ({ label, value }));

export default function DynamicCampaignForm(props) {
  const [state, setState] = useStateParams();
  const [hasQuickReplyButtons, setHasQuickReplyButtons] = useState(false);
  const [isDynamic, setIsDynamic] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [initialSentType, setInitialSentType] = useState(mainTemplateDropdownOptions[0].value);

  useEffect(() => initialize(), []);
  useEffect(() => reboot(), [props.mainTemplate]);
  useEffect(() => handleChangeDynamicCampaignConfig(), [isDynamic, isRecurrent, initialSentType, hasQuickReplyButtons]);

  const initialize = () => {
    setIsDynamic(state?.dynamicCampaignConfig?.isDynamic ?? false);
    setIsRecurrent(state?.dynamicCampaignConfig?.isRecurrent ?? false);
    setInitialSentType(state?.dynamicCampaignConfig?.initialSentType ?? mainTemplateDropdownOptions[0].value);
  }

  const reboot = () => {
    if(props.mainTemplate?.structure !== undefined) {
      const replyButtons = props.mainTemplate.structure.buttons?.every((button) => button.type === 'QUICK_REPLY');
      if(replyButtons !== true) {
        if(state.mainRecurrentDynamicCampaign === undefined) {
          setIsDynamic(false);
          setIsRecurrent(false);
          setInitialSentType(mainTemplateDropdownOptions[0].value);
        }
      }
      setHasQuickReplyButtons(replyButtons === true);
    }
  }

  const handleChangeDynamicCampaignConfig = () => {
    const event = {
      valid: false,
      payload: {
        isDynamic,
        isRecurrent
      }
    };
    const {payload} = event;
    if(payload.isRecurrent === true) {
      payload.initialSentType = initialSentType;
    }
    event.valid = (payload.isRecurrent === false || payload.initialSentType !== undefined)
      && (state.mainRecurrentDynamicCampaign === undefined || hasQuickReplyButtons === true);
    setState({dynamicCampaignConfig: payload}, {merge: true});
    props.onChange(event);
  }

  const handleDynamicSwitchChange = (event) => {
    const value = event.value;
    setIsDynamic(value);
    setIsRecurrent(false);
  }

  return (
    <>
      {
        (hasQuickReplyButtons === true && props.mainTemplate?.structure !== undefined) &&
        <NeoCard>
          <NeoInnerTitle extra='primero'>Campaña dinámica</NeoInnerTitle>
          <NeoGridContainer col='12'>
            <NeoColumn col='12' md='6'>
              <NeoInputSwitch
                label='Habilitar campaña dinámica'
                checked={isDynamic}
                disabled={state.mainRecurrentDynamicCampaign !== undefined}
                onChange={handleDynamicSwitchChange}
              />
            </NeoColumn>
            <NeoDropdown
              col='12' md='6'
              label='Tipo de envío de plantilla inicial'
              value={initialSentType}
              disabled={isRecurrent === false || state.mainRecurrentDynamicCampaign !== undefined}
              options={mainTemplateDropdownOptions}
              onChange={(event) => setInitialSentType(event.value)}
            />
            <NeoColumn col='12' md='6'>
              <NeoInputSwitch
                label='Campaña dinámica recurrente'
                checked={isRecurrent}
                disabled={isDynamic === false || state.mainRecurrentDynamicCampaign !== undefined}
                onChange={(event) => setIsRecurrent(event.value)}
              />
            </NeoColumn>
          </NeoGridContainer>
        </NeoCard>
      }
    </>
  );
}