import {useState, useEffect} from 'react';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import CampaignFlowPreview from 'components/CampaignFlowPreview.component';

export default function CampaignTemplatePreview(props) {
  const [campaignFlow, setCampaignFlow] = useState([]);
  const [testStructure, setTestStructure] = useState(props.flow);

  useEffect(() => reboot(), [props.randomIndex]);

  const reboot = () => {
    const mapping = props.testMapping[props.randomIndex];
    if(mapping !== undefined) {
      const structure = getTestMappingStructure(props.flow.structure, mapping.map);
      const updatedTestStructure = {...props.flow, structure};
      if(props.isDynamicCampaign === true) {
        updatedTestStructure.replyButtons = {};
        for(const [text, {autoReply}] of Object.entries(props.flow.replyButtons)) {
          if(autoReply !== undefined) {
            updatedTestStructure.replyButtons[text] = {autoReply};
            if(autoReply.type === 'TEMPLATE') {
              const autoReplyStructure = getTestMappingStructure(autoReply.template.structure, mapping.autoReplies[text].map);
              updatedTestStructure.replyButtons[text] = {autoReply: {...autoReply, template: {structure: autoReplyStructure}}};
            }
          }
        }
      }
      setTestStructure(updatedTestStructure);
    }
  }

  const getTestMappingStructure = (structure, map) => {
    const updatedTestStructure = {
      ...structure,
      header: {...structure.header},
      body: structure.body
    };
    Object.entries(map).forEach(([paramNotation, paramValue]) => {
      const paramParts = paramNotation.split('.');
      const section = paramParts[0];
      if(section == 'header') {
        const headerType = paramParts[1];
        const paramName = paramParts[2];
        if(headerType == 'text') {
          updatedTestStructure.header.text = updatedTestStructure.header.text.replace(`{{${paramName}}}`, paramValue);
        }
        else {
          const headerTypeParams = updatedTestStructure.header[headerType] ?? {};
          headerTypeParams[paramName] = paramValue;
          updatedTestStructure.header[headerType] = headerTypeParams;
        }
      }
      if(section == 'body') {
        const paramName = paramParts[1];
        updatedTestStructure.body = updatedTestStructure.body.replace(`{{${paramName}}}`, paramValue);
      }
    });
    return updatedTestStructure;
  }

  const handleRegenerateTestClick = () => {
    let randomIndex = Math.floor(Math.random() * props.testMapping.length);
    while(props.randomIndex === randomIndex) {
      randomIndex = Math.floor(Math.random() * props.testMapping.length);
    }
    props.onRandomIndexChange(randomIndex);
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>
        Previsualización de campaña {props.isDynamicCampaign === true ? 'dinámica' : ''}
      </NeoInnerTitle>
      <CampaignFlowPreview
        value={campaignFlow}
        flow={testStructure}
        isDynamicCampaign={props.isDynamicCampaign === true}
        onChange={(event) => setCampaignFlow(event)}
      />
      <NeoButtonSection align='center'>
        <NeoButtonMain
          label='Regenerar prueba'
          icon='pi pi-fw pi-refresh'
          disabled={props.testMapping.length <= 1}
          onClick={handleRegenerateTestClick}
        />
        {
          (props.isDynamicCampaign === true) &&
          <NeoButtonMain
            label='Reiniciar demo de campaña'
            icon='pi pi-fw pi-refresh'
            onClick={() => setCampaignFlow([])}
          />
        }
      </NeoButtonSection>
    </NeoCard>
  );
}