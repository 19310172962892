import {useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoOverlayMenu from 'design/design_components/neo/overlay/NeoOverlayMenu.base'
import TemplateUsageTypeEditDialog from 'views/templates/components/TemplateUsageTypeEditDialog.component'
import ActionDialog from 'components/ActionDialog.component';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import TemplatesService from 'services/templates.service';

export default function TemplateDetailHeader(props) {
  const history = useHistory();
  const messages = useMessages();
  const menuRef = useRef(null);
  const lineId = props.lineId;
  const {name} = props.template;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditTypeOfUseDialog, setShowEditTypeOfUseDialog] = useState(false);

  const getMenuItems = () => {
    let items = [
      {
        label: 'Editar tipo de uso',
        icon: 'pi pi-pencil',
        command: () => setShowEditTypeOfUseDialog(true)
      },
      {
        label: 'Crear traducción',
        icon: 'pi pi-plus',
        command: () => history.push({
          pathname: MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH,
          state: {lineId, name: props.template.name, typeOfUse: props.typeOfUse}
        })
      },
      {
        label: 'Eliminar plantilla',
        icon: 'pi pi-trash',
        command: () => setShowDeleteDialog(true)
      }
    ]
    if(props.template.status == 'REJECTED') {
      const createCopyOption = {
        label: 'Crear duplicado',
        icon: 'pi pi-copy',
        command: () => history.push({
          pathname: MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH,
          state: {
            lineId,
            generalData: {language: props.template.language, category: props.template.category, typeOfUse: props.typeOfUse},
            ...props.template.structure
          }
        })
      }
      items = [createCopyOption, ...items];
    }
    return items;
  }

  const deleteTemplate = async () => {
    const response = await TemplatesService.deleteTemplate(lineId, name);
    return { success: response.success };
  }

  const handleEditTypeOfUseDialogHide = (event) => {
    if(event?.success === true) {
      props.onTypeOfUseChange(event.payload);
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se actualizó el tipo de uso de la plantilla.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar el tipo de uso de la plantilla, inténtalo de nuevo.');
    }
  }

  const handleDeleteDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        history.push(`${MENU.ADMIN.TEMPLATES.ROOT.PATH}?line=${lineId}`);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se eliminó la plantilla.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo eliminar la plantilla, inténtalo de nuevo.');
      }
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          icon={Icono}
          title={`${props.template.name} (${props.template.language})`}
          subtitle='Detalle de plantilla'
        />
        <NeoButtonSection align='right'>
          <NeoButtonText
            icon="pi pi-ellipsis-v"
            label='Acciones de la plantilla'
            aria-controls='popup_menu'
            aria-haspopup
            onClick={(event) => menuRef.current.toggle(event)}
          />
        </NeoButtonSection>
        <NeoOverlayMenu
          popup
          ref={menuRef}
          id='popup_menu'
          model={getMenuItems()}
        />
      </NeoGridContainer>
      {
        <TemplateUsageTypeEditDialog
          visible={showEditTypeOfUseDialog}
          visibleSetter={setShowEditTypeOfUseDialog}
          lineId={lineId}
          name={props.template.name}
          typeOfUse={props.typeOfUse ?? []}
          onHide={handleEditTypeOfUseDialogHide}
        />
      }
      {
        <ActionDialog
          header='Eliminar plantilla'
          visible={showDeleteDialog}
          visibleSetter={setShowDeleteDialog}
          acceptMessage='Eliminando plantilla'
          onAccept={deleteTemplate}
          onHide={handleDeleteDialogHide}
        >
          Se eliminarán todas las traducciones de la plantilla y el nombre no se podrá utilizar durante 30 días. ¿Deseas proceder?
        </ActionDialog>
      }
    </>
  );
}
