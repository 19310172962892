import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class ConsumptionService {

  static async getCompanyStatistics(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/companyStatistics', {params});
    if(success) {
      body.updatedAt = dateTimeUtil.backendDateStringToDate(body.updatedAt);
      return {success, payload: body};
    }
    return {success, error};
  }

  static async getCompanyConsumptionDetail(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/companyConsumptionDetail', {params});
    return success ? {success, payload: body.companyConsumptionDetail} : {success, error};
  }

  static async getCompanyConsumptionDetailFile(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/companyConsumptionDetailFile', {params});
    return success ? {success, payload: body} : {success, error};
  }

}