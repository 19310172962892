import backendRequestUtil from 'utils/backendRequest.util';

export default class SettingsService {

  static async getSettings() {
    const {success, body, error} = await backendRequestUtil.get('/rest/settings');
    return success ? {success, payload: body} : {success, error};
  }

  static async updateSettings(requestBody) {
    const {success, error} = await backendRequestUtil.put('/rest/settings', requestBody);
    return success ? {success} : {success, error};
  }

}