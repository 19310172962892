import {createContext, useRef} from 'react';
import NeoToast from 'design/design_components/neo/messages/NeoToast.base';

const MessagesContext = createContext();
export default MessagesContext;

export function MessagesContextProvider(props) {
  const toast = useRef(null);

  return (
    <MessagesContext.Provider value={{toast}}>
      {props.children}
      <NeoToast ref={toast}/>
    </MessagesContext.Provider>
  );
}