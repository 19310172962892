import useComponent from "hooks/useComponent.hook";
import ChatService from 'services/chat.service';
import ActionDialog from "components/ActionDialog.component";
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import useMessages from "hooks/useMessages.hook";
import TOAST from "consts/toast.consts"

const QuicklyReplyCreate = (props) => {
  const messages = useMessages()
  const component = useComponent({
    state: {
      element: {
        createQuickReply: {
          show: false,
          title: '',
          replie: ''
        }
      }
    }
  });

  const resetForm = () => {
    component.setState((state) => {
      state.element.createQuickReply.title = '';
      state.element.createQuickReply.replie = '';
    })
  }

  const handleAccept = async () => {
    const quicklyReply = {
      title: component.state.element.createQuickReply.title,
      replie: component.state.element.createQuickReply.replie
    }
    const response = await ChatService.createQuicklyReply(quicklyReply);
    if (response.success) {
      if(typeof props.onCreate === 'function') {
        props.onCreate(response.payload);
      }
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se creó la respuesta rápida.'
      );
      return { success: true, payload: { ...response.payload } };
    }
    messages.showToast(
      TOAST.SEVERITY.ERROR,
      'Algo salió mal',
      'No se pudo crear la respuesta rápida, inténtalo de nuevo.'
    );
    return { success: false, internal: true };
  }

  const handleHide = (event) => {
    resetForm();
  }

  return (
    <>
      <ActionDialog
        header='Crear respuesta rápida'
        visible={props.show}
        visibleSetter={props.visibleSetter}
        acceptMessage='Creando respuesta rápida'
        acceptDisabled={component.state.element.createQuickReply.title.length == 0 || component.state.element.createQuickReply.replie.length === 0}
        onAccept={handleAccept}
        onHide={handleHide}
        acceptHiddenController={(payload) => payload.success || payload.internal}
      >
        <NeoInputText
          label='Nombre'
          value={component.state.element.createQuickReply.title}
          error={component.state.element.createQuickReply.titleError}
          onChange={(event) => {
            component.setState((state) => {
              state.element.createQuickReply.title = event.target.value;
            })
          }}
        />
        <NeoInputTextarea
          rows={3}
          value={component.state.element.createQuickReply.replie} 
          placeholder="Respuesta rápida"
          onChange={(event) => {
            component.setState((state) => {
              state.element.createQuickReply.replie = event.target.value;
            })
          }}
        />
      </ActionDialog>
    </>
  );
}

export default QuicklyReplyCreate;
