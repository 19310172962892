function getCurrentYear() {
  const now = new Date();
  const year = now.getFullYear();
  return year < 2022 ? 2022 : year;
}

function getMonthDate(month, year) {
  if(!month || !year || month < 1 || month > 12) {
    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    return new Date(`${year}-${month}-1`);
  }
  return new Date(`${year}-${month}-1`);
}

function backendDateStringToDate(dateTimeString) {
  if(dateTimeString) {
    const [dateString, timeString] = dateTimeString.split(' ');
    const [dayString, monthString, yearString] = dateString.split('/');
    return new Date(`${yearString}-${monthString}-${dayString} ${timeString}`);
  }
}

function getDateString(date, options = {}) {
  return date?.toLocaleString('es-MX', options);
}

function monthNumberToString(month) {
  return month == 1 ? 'Enero' 
    : month == 2 ? 'Febrero' 
    : month == 3 ? 'Marzo' 
    : month == 4 ? 'Abril' 
    : month == 5 ? 'Mayo' 
    : month == 6 ? 'Junio' 
    : month == 7 ? 'Julio' 
    : month == 8 ? 'Agosto' 
    : month == 9 ? 'Septiembre' 
    : month == 10 ? 'Octubre' 
    : month == 11 ? 'Noviembre' 
    : month == 12 ? 'Diciembre' 
    : null;
}

function filterDate(value, filter) {
  const date = new Date(value)
  if(filter[0] && !filter[1]) {
    return date.getTime() >= filter[0].getTime();
  }
  if(!(filter[0] && filter[1])) {
    return false;
  }
  const dateToFilter = date.getTime();
  const firstDate = filter[0].getTime();
  let secondDate = new Date(filter[1].getTime());
  secondDate.setHours(23);
  secondDate.setMinutes(59);
  secondDate.setSeconds(59);
  secondDate = secondDate.getTime();
  return dateToFilter >= firstDate && dateToFilter <= secondDate;
}

function waitMilliseconds(milliseconds) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve();
    }, milliseconds);
  });
}

export default {
  getCurrentYear,
  getMonthDate,
  backendDateStringToDate,
  getDateString,
  monthNumberToString,
  filterDate,
  waitMilliseconds
};