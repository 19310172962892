import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';

export default function TemplateVariableMapping(props) {
    
  const handleVariableDropdownChange = (event) => {
    const {value} = event;
    props.onVariableKeyChange(value);
  }

  const handleMappingDropdownChange = (event) => {
    const {value} = event;
    props.onMappingChange(value);
  }

  return (
    <NeoFlexContainer jc='between' ai='center'>
      {
        (typeof(props.variable) === 'string') &&
        <span>{props.variable}</span>
      }
      {
        (Array.isArray(props.variable) === true) &&
        <NeoDropdown
          col='6'
          value={props.variableKey}
          options={props.variable}
          onChange={handleVariableDropdownChange}
        />
      }
      <NeoDropdown
        col='6'
        value={props.mapping}
        placeholder={`${props.databaseType == 'CONTACTS' ? 'Campo' : 'Columna'} a mapear`}
        options={props.mappingOptions}
        onChange={handleMappingDropdownChange}
      />
    </NeoFlexContainer>
  );
}