import { useEffect, useMemo, useRef } from 'react';
import {Switch, Redirect} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import AUTHENTICATION from 'consts/authentication.consts';
import useAuthentication from 'hooks/useAuthentication.hook';
import Route from 'routers/components/Route.component';
import RouterContainer from 'routers/components/RouterContainer.component';
import AgentChat from 'views/chat/AgentChat.view';
import Contacts from 'views/contacts/Contacts.view';
import CreateContact from 'views/contacts/CreateContact.view';
import ContactDetail from 'views/contacts/ContactDetail.view';
import StartConversation from 'views/conversations/StartConversation.view';
import TemplatesList from 'views/templates/TemplatesList.view';
import CreateTemplate from 'views/templates/CreateTemplate.view';
import TemplatePreview from 'views/templates/TemplatePreview.view';
import TemplateDetail from 'views/templates/TemplateDetail.view';
import Campaigns from 'views/campaigns/Campaigns.view';
import CreateCampaign from 'views/campaigns/CreateCampaign.view';
import CampaignDetail from 'views/dashboard/CampaignDetail.view';
import CampaignContactsOrigin from 'views/campaigns/CampaignContactsOrigin.view';
import CampaignTemplatesMapping from 'views/campaigns/CampaignTemplatesMapping.view';
import CampaignContacts from 'views/campaigns/CampaignContacts.view';
import CampaignPreviewAndTesting from 'views/campaigns/CampaignPreviewAndTesting.view';
import RecurrentCampaignDetail from 'views/dashboard/RecurrentCampaignDetail.view';
import EmployeesService from 'services/employees.service';
import ChatService from 'services/chat.service';
import useChat from 'hooks/useChat.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDialogo from 'design/design_components/neo/overlay/NeoDialog.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';

export default function AdminRouter() {
  const chat = useChat();
  const authentication = useAuthentication();
  const asyncState = useAsyncState();
  const prevStatusSocketRef = useRef();

  const reboot = async () => {
    await asyncState.allPromises(
      [ getEmployeeLines(),
        getConversationsUnassigned(),
        getConversations(),
        getQuicklyReplies() ],
      { initialization: true }
    );
  }

  useEffect(() => {
    if(prevStatusSocketRef.current === 'DISCONNECTED' && authentication.statusSocket === 'CONNECTED') {
      reboot();
    }
    prevStatusSocketRef.current = authentication.statusSocket;
  }, [authentication.statusSocket])

  useEffect(() => {
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.USER.SET.TYPE,
        payload: {
          user: authentication.user
        }
      }
    })
  }, [authentication.user])

  useEffect(() => {
    reboot();
    const intervalCheckTimeElapsed = setInterval(() => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.TIME_ELAPSED_CHECK.TYPE
        }
      })
    }, 1000);

    Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
      authentication.socket.off(eventName);
      authentication.socket.on(eventName, eventFn);
    })

    return () => {
      clearInterval(intervalCheckTimeElapsed);
      Object.entries(socketListeners).forEach(([eventName]) => {
        authentication.socket.off(eventName);
      })
    }
  }, [])

  const getEmployeeLines = async () => {
    const response = await EmployeesService.getEmployeeLines();
    if(response.success) {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.LINES.ASSIGNED.TYPE,
          payload: {
            lines: response.payload
          }
        }
      })
      return {success: true};
    }
    return {success: false};
  }

  const getConversationsUnassigned = async () => {
    const response = await ChatService.getUnassigned();
    if(response.success) {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.UNASSIGNED.TYPE,
          payload: {
            conversations: response.payload.chats
          }
        }
      })
      return {success: true};
    }
    return {success: false};
  };

  const getConversations = async () => {
    /* Pendiente agregar a la respuesta del objeto
      sendingMessage: true
      lastMessageIsReceived: true
      lastMessageIsViewed: true
     */
    const response = await EmployeesService.getAssignedChats();
    if(response.success) {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.TYPE,
          payload: {
            conversations: response.payload.chats
          }
        }
      })
      return {success: true};
    }
    return {success: false};
  };

  const getQuicklyReplies = async () => {
    const response = await ChatService.getQuicklyReplies();
    if(response.success) {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.QUICKLY_REPLIES.ASSIGNED.TYPE,
          payload: {
            quicklyReplies: response.payload.quicklyReplies
          }
        }
      })
      return {success: true};
    }
    return {success: false};
  }

  const socketListeners = {
    unassignedConversation: ({line, conversation}) => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.UNASSIGNED.UPSERT.TYPE,
          payload: {
            conversation
          }
        }
      })
    },
    assignedConversation: ({line, user, conversation}) => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.UPSERT.TYPE,
          payload: {
            user,
            conversation
          }
        }
      })
    },
    updatedConversation: ({line, conversation}) => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.UPDATE.TYPE,
          payload: {
            conversation
          }
        }
      })
    },
    finishConversation: ({line, conversation}) => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.FINISH.TYPE,
          payload: {
            conversation
          }
        }
      })
    },
    updateEmployeeStatus: ({user, status}) => {
      if(authentication.user.uid === user.id) {
        authentication.status.replace(status, false);
      }
    },
  }

  const menu = useMemo(() => 
    [
      {
        label: MENU.ADMIN.CHAT.ROOT.LABEL,
        redirect: MENU.ADMIN.CHAT.ROOT.PATH
      },
      {
        label: MENU.ADMIN.CONTACTS.ROOT.LABEL,
        redirect: MENU.ADMIN.CONTACTS.ROOT.PATH
      },
      {
        label: MENU.ADMIN.TEMPLATES.ROOT.LABEL,
        redirect: MENU.ADMIN.TEMPLATES.ROOT.PATH
      },
      {
        label: MENU.ADMIN.CAMPAIGNS.ROOT.LABEL,
        redirect: MENU.ADMIN.CAMPAIGNS.ROOT.PATH
      },
      {
        label: authentication.user.email.substring(0, 2).toUpperCase(),
        icon: 'pi pi-fw pi-circle-fill',
        className: `navAccount ${authentication.user?.status}`,
        items: [
          {
            label: authentication.user.email,
            className: 'email'
          },
          {
            label: 'Disponible', 
            command: async () => { 
              authentication.status.update('ONLINE');
            }
          },
          {
            label: 'Por terminar', 
            command: async () => { 
              authentication.status.update('TO_GET_OUT');
            }
          },
          {
            label: 'Ausente', 
            command: async () => { 
              authentication.status.update('AWAY');
            }
          },
          {
            label: 'Cerrar sesión', 
            className: 'logout',
            command: async () => { 
              await authentication.signOut();
              window.location = AUTHENTICATION.LOGOUT_URL;window.location.hash="/"; 
            },
          }
        ]
      }
    ]
  , [authentication.user]);

  return (<>
    <RouterContainer menu={menu}>
      <Switch>
        <Route exact path={MENU.ADMIN.CHAT.ROOT.PATH} component={AgentChat}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.ROOT.PATH} component={Contacts}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.CREATE_CONTACT.PATH} component={CreateContact}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.CONTACT_DETAIL.PATH} component={ContactDetail}/>
        <Route exact container path={MENU.ADMIN.CONVERSATIONS.START_CONVERSATION.PATH} component={StartConversation}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.ROOT.PATH} component={TemplatesList}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH} component={CreateTemplate}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.TEMPLATE_PREVIEW.PATH} component={TemplatePreview}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.TEMPLATE_DETAIL.PATH} component={TemplateDetail}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.ROOT.PATH} component={Campaigns}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CREATE_CAMPAIGN.PATH} component={CreateCampaign}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.RECURRENT_CAMPAIGN_DETAIL.PATH} component={RecurrentCampaignDetail}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.PATH} component={CampaignDetail}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS_ORIGIN.PATH} component={CampaignContactsOrigin}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_TEMPLATES_MAPPING.PATH} component={CampaignTemplatesMapping}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS.PATH} component={CampaignContacts}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH} component={CampaignPreviewAndTesting}/>
        <Route exact path="/" component={() => <Redirect to={MENU.ADMIN.CHAT.ROOT.PATH} />}/>
      </Switch>
    </RouterContainer>

    {(authentication.statusSocket === 'DISCONNECTED') &&
      <NeoDialogo
        custom="socket-status"
        visible={true}
        closable={false}
        header="Error de conexión"
        footer={<>
          <NeoButtonMain
            label="Recargar"
            onClick={() => {window.location.reload()}}
            loading={authentication.status.isUpdating}
          />
        </>}
      >
        <p>Se ha perdido la conexión con el servidor</p>
        <p>Por favor, revisa tu conexión a internet e intenta recargar la página</p>
      </NeoDialogo>
    }

    {(asyncState.isLoading) &&
      <NeoSpinner/>
    }
  </>);
}