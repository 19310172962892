import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import MultimediaFilesExplorer from 'views/multimedia/components/MultimediaFilesExplorer.component';
import AlreadyExistMultimediaDialog from 'views/multimedia/components/AlreadyExistMultimediaDialog.component';
import InternalSpinner from 'components/InternalSpinner.component';
import InfoTooltip from 'components/InfoTooltip.component';
import MultimediaService from 'services/multimedia.service';
import fileUtil from 'utils/file.util';

const FILE_STATUS = {
  ADDED: 'ADDED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  FAILED: 'FAILED'
};

export default function MultimediaFilesUploadTable() {
  const tableRef = useRef();
  const history = useHistory();
  const messages = useMessages();
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showAlreadyExistDialog, setShowAlreadyExistDialog] = useState(false);

  useEffect(() => handleFilesIdChange(), [files]);
  useEffect(async () => await handleFilesStatusChange(), [files]);

  const uploadMultimedia = async (index) => {
    const { id, file, overwrite } = files[index];
    const response = await MultimediaService.createMultimedia(id, file, { overwrite });
    const updatedFiles = [...files];
    updatedFiles[index].status = response.success ? FILE_STATUS.UPLOADED : FILE_STATUS.FAILED;
    updatedFiles[index].alreadyExist = !response.success && response.error.code === 'ALREADY_EXIST';
    setFiles(updatedFiles);
  }

  const handleFilesIdChange = () => {
    let changeExists = false;
    const updatedFiles = [...files];
    files.forEach((file, index) => {
      if (file.id.length > 0) {
        const repeatedId = updatedFiles.some((fileB, indexB) => fileB.id == file.id && indexB != index);
        if (repeatedId == true) {
          if (file.errorId.length == 0) {
            updatedFiles[index].errorId = 'El nombre debe ser único.';
            changeExists = true;
          }
        }
        else if (file.alreadyExist === true) {
          if (file.errorId.length == 0) {
            updatedFiles[index].errorId = 'La multimedia ya existe.';
            changeExists = true;
          }
        }
        else if (file.errorId.length > 0) {
          updatedFiles[index].errorId = '';
          changeExists = true;
        }
      }
      else if (file.errorId.length > 0) {
        updatedFiles[index].errorId = '';
        changeExists = true;
      }
    });
    if (changeExists) {
      setFiles(updatedFiles);
    }
  }

  const handleFilesStatusChange = async () => {
    if (files.length > 0) {
      const uploading = files.every((files) => files.status == FILE_STATUS.UPLOADING);
      const finishedUpload = files.every((files) => files.status != FILE_STATUS.ADDED && files.status != FILE_STATUS.UPLOADING);
      if (uploading) {
        setIsUpdated(false);
        setIsUploading(true);
        await Promise.allSettled(files.map((file, index) => uploadMultimedia(index)));
        setIsUploading(false);
      }
      if (!isUpdated && finishedUpload) {
        const updatedFiles = files.filter((file) => file.status == FILE_STATUS.FAILED);
        if (files.length > updatedFiles.length) {
          const uploaded = files.length - updatedFiles.length;
          messages.showToast(
            TOAST.SEVERITY.SUCCESS,
            'Operación exitosa',
            (uploaded == 1 ? 'Se cargó 1 archivo multimedia.' : `Se cargaron ${uploaded} archivos multimedia.`)
          );
          if (updatedFiles.length == 0) {
            history.push(MENU.ADMIN.MULTIMEDIA.ROOT.PATH);
          }
        }
        if (updatedFiles.length > 0) {
          const alreadyExist = updatedFiles.some((file) => file.alreadyExist === true);
          setShowAlreadyExistDialog(alreadyExist);
          messages.showToast(
            TOAST.SEVERITY.ERROR,
            'Algo salió mal',
            (updatedFiles.length == 1 ? 'No se pudo cargar 1 archivo multimedia.' : `No se pudieron cargar ${updatedFiles.length} archivos multimedia.`)
          );
        }
        setIsUpdated(true);
        setFiles(updatedFiles);
      }
    }
  }

  const defaultMultimediaId = (fileName) => {
    const [extension] = fileName.match(/\.[a-z0-9]+$/g) ?? [];
    const id = extension ? fileName.substring(0, fileName.length - extension.length) : fileName;
    return id.replace(/[^a-zA-ZñÑ0-9]+/g, '_').substring(0, 32).replace(/(^[_]+)|([_]+$)/g, '');
  }

  const handleFilesExplorerChange = (event) => {
    const newFiles = event
      .filter((file) => file.size <= 67108864)
      .map((file) => ({ id: defaultMultimediaId(file.name), errorId: '', status: FILE_STATUS.ADDED, ...file }));
    setFiles([...files, ...newFiles]);
  }

  const handleIdInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    if (value[0] != '_' && Number.isNaN(Number.parseInt(value[0]))) {
      const updatedFiles = [...files];
      updatedFiles[index].id = value;
      updatedFiles[index].alreadyExist = false;
      setFiles(updatedFiles);
    }
  }

  const handleDeleteButtonClick = (event) => {
    const updatedFiles = [...files];
    updatedFiles.splice(event.rowIndex, 1);
    setFiles(updatedFiles);
  }

  const handleUploadButtonClick = async () => {
    const updatedFiles = files.map((file) => ({ ...file, status: FILE_STATUS.UPLOADING, overwrite: false, alreadyExist: false }));
    setFiles(updatedFiles);
  }

  const handleAlreadyExistDialogHide = (event) => {
    if (event.action === 'replace') {
      const updatedFiles = files.map((file) => ({ ...file, status: FILE_STATUS.UPLOADING, overwrite: file.alreadyExist === true, alreadyExist: false }));
      setFiles(updatedFiles);
    }
  }

  const elements = {
    sizeColumnBody: (data) => (
      <>{fileUtil.getSizeString(data.size)}</>
    ),
    actionsColumnBody: (data, event) => {
      if (!isUploading) {
        return (
          <NeoButtonOutlined
            label='Eliminar'
            onClick={() => handleDeleteButtonClick(event)}
          />
        );
      }
      if (data.status == FILE_STATUS.UPLOADED) {
        return (
          <>Cargado</>
        );
      }
      if (data.status == FILE_STATUS.FAILED) {
        return (
          <>Fallido</>
        );
      }
      return (
        <InternalSpinner size={40} />
      );
    }
  };
  const footer = <><MultimediaFilesExplorer
    disabled={isUploading}
    onChange={handleFilesExplorerChange}
  /></>
  return (
    <>
      <NeoTable
        ref={tableRef}
        value={files}
        removableSort
        footer={footer}
        extra='no-filters with-footer  p-mb-3'
        emptyMessage='Aún no has agregado ningún archivo'
      >
        <NeoTableColumn
          header='Archivo de origen'
          field='name'
        />
        <NeoTableColumn
          header='Nombre de multimedia'
          body={(rowData, rowEvent) => (
            <NeoInputText
              value={files[rowEvent.rowIndex].id}
              error={files[rowEvent.rowIndex].errorId}
              disabled={isUploading}
              maxLength={32}
              keyfilter={/^[_a-zA-ZñÑ0-9]+$/}
              placeholder='Ingresa el nombre'
              onChange={(event) => handleIdInputChange(rowEvent.rowIndex, event)}
            />
          )}
        />
        <NeoTableColumn
          header={<InfoTooltip id='size' label='Tamaño' body='El tamaño máximo admitido es 64 MB.' />}
          field='size'
          body={elements.sizeColumnBody}
        />
        <NeoTableColumn
          header='Tipo'
          field='type'
        />
        <NeoTableColumn
          body={elements.actionsColumnBody}
        />
      </NeoTable>
      <div className='table-btn-footer'>

      </div>
      <NeoButtonSection align='right'>

        <NeoButtonMain
          label='Cargar archivos'
          icon='pi pi-upload'
          disabled={isUploading || files.length == 0 || !files.every((file) => file.id.length > 0 && file.errorId.length == 0)}
          onClick={handleUploadButtonClick}
        />
      </NeoButtonSection>
      {
        <AlreadyExistMultimediaDialog
          visible={showAlreadyExistDialog}
          visibleSetter={setShowAlreadyExistDialog}
          onHide={handleAlreadyExistDialogHide}
        />
      }
    </>
  );
}