import TEMPLATES from 'consts/templates.consts';

function getTemplateHeaderType(type, options = {}) {
  const templateHeaderType = TEMPLATES.HEADER.TYPES[type] ?? 'Desconocido';
  return options.lowerCase === true ? templateHeaderType.toLowerCase() : templateHeaderType;
}

function getTemplateStatus(status) {
  return TEMPLATES.STATUSES[status] ?? 'Desconocido';
}

function getTemplateCategory(category) {
  return TEMPLATES.CATEGORIES[category] ?? 'Desconocida';
}

function getTemplateTypeOfUse(typeOfUse) {
  return TEMPLATES.TYPE_OF_USE[typeOfUse] ?? 'Desconocido';
}

function getTemplateRequiredParams(structure) {
  const requiredParams = {};
  const headerType = structure?.header?.type;
  if(headerType == 'TEXT') {
    const [headerTextParam] = structure.header.text.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
    if(headerTextParam) {
      requiredParams.header = {type: headerType, param: headerTextParam};
    }
  }
  else if(headerType) {
    requiredParams.header = {type: headerType, param: 'url'};
  }
  requiredParams.body = structure?.body?.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
  if(structure.buttons) {
    const buttonsParams = [];
    structure.buttons
      .filter((button) => button.type == 'URL')
      .forEach((button, index) => {
        const [buttonUrlParam] = button.url.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
        if(buttonUrlParam) {
          buttonsParams.push({index, type: button.type, param: buttonUrlParam});
        }
      });
    if(buttonsParams.length > 0) {
      requiredParams.buttons = buttonsParams;
    }
  }
  return requiredParams;
}

const getMappingRequiredFields = (map, options = {}) => {
  let fields = [...new Set(Object.values(map.map))];
  if(options.isDynamic === true) {
    const autoReplies = Object.values(map.autoReplies ?? {});
    for(const autoReply of autoReplies) {
      fields = [...new Set([...fields, ...Object.values(autoReply.map)])];
    }
  }
  return fields;
}

export default {
  getTemplateHeaderType,
  getTemplateStatus,
  getTemplateCategory,
  getTemplateTypeOfUse,
  getTemplateRequiredParams,
  getMappingRequiredFields
};