import { useState, useEffect } from 'react';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import TemplatePreviewContent from './TemplatePreviewContent.component';

export default function TemplatePreview(props) {
  const [name, setName] = useState(props.template.name);
  const [language, setLanguage] = useState(props.template.language);
  const [structure, setStructure] = useState(props.template.structure);

  useEffect(() => {
    setName(props.template.name);
    setLanguage(props.template.language);
    setStructure(props.template.structure);
  }, [props.template]);

  useEffect(() => {
    if (props.realTimeDataTemplate) {
      setStructure(props.realTimeDataTemplate);
    }
  }, [props.realTimeDataTemplate])

  return (
    <>
      <NeoGridContainer col="12" className={`${props.extra ? props.extra : ""} `}>
        <NeoInnerTitle extra="primero">
          {props.title ?? 'Previsualización de plantilla usada'}
        </NeoInnerTitle>

        <NeoColumn col="12">
          {
            (name && language) &&
            <NeoInnerSubtitle>
              {name} ({language})</NeoInnerSubtitle>
          }
          <div className="wa-container p-mb-3" >
            <div className="wa-back"> </div>
            <TemplatePreviewContent structure={structure}/>
          </div>
        </NeoColumn>
      </NeoGridContainer>
    </>
  )
}