import backendRequestUtil from 'utils/backendRequest.util';

export default class AuthenticationService {

  static async login(session, token) {
    const bodyRequest = {session, token};
    const {success, body, error} = await backendRequestUtil.post('/rest/login', bodyRequest);
    return success ? {success, payload: body.token} : {success, error};
  }

  static async getUserAccount() {
    const {success, body, error} = await backendRequestUtil.get('/rest/userAccount');
    return success ? {success, payload: body} : {success, error};
  }

  static async getUserRole() {
    const {success, body, error} = await backendRequestUtil.get('/rest/userRole');
    return success ? {success, payload: body.role} : {success, error};
  }
}