import { useState, useEffect } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend } from 'recharts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoResponsiveContainer from 'design/design_components/neo/chart/NeoResponsiveContainer.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import InfoTooltip from 'components/InfoTooltip.component';
import dateTimeUtil from 'utils/dateTime.util';

const colors = ['#49ad48', '#45ABEC', '#FF3C3C'];

export default function CampaignsTemplatesConsumption(props) {
  const [data, setData] = useState([]);

  useEffect(async () => await reboot(), [props.data]);

  const reboot = async () => {
    if (props.data) {
      const data = props.data.map((value) => {
        const { day, month, year } = value.date;
        const dateString = new Date(`${year}-${month}-${day} 00:00`);
        const date = dateTimeUtil.getDateString(dateString, { dateStyle: 'medium' });
        return { ...value, date };
      });
      setData(data);
    }
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoFlexContainer extra="px-0">
        <NeoInnerTitle extra='primero'>Consumo de plantillas por línea</NeoInnerTitle>
        <InfoTooltip id='description'>
          <p>Esta gráfica muestra un comparativo del envío de plantillas entre líneas.</p>
        </InfoTooltip>
      </NeoFlexContainer>
      {
        (props.loading) &&
        <InternalSpinner />
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <NeoResponsiveContainer width='100%' height={250}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis label={{ value: 'Plantillas enviadas', angle: -90, position: 'insideBottomLeft' }} />
                <Tooltip />
                {
                  props.lines.map((line, index) => (
                    <Line
                      dot={false}
                      key={line.id}
                      type='monotone'
                      name={line.name}
                      dataKey={line.id}
                      stroke={colors[index % colors.length]}
                    />
                  ))
                }
                <Legend />
              </LineChart>
            </NeoResponsiveContainer>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  )
}