import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import TemplatePreview from 'components/TemplatePreview.component';
import StartConversationTemplateVariablesForm from 'views/conversations/components/StartConversationTemplateVariablesForm.component';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import MultimediaService from 'services/multimedia.service';
import CONTACTS from 'consts/contacts.consts';

const UNMAPPED = '';

const basicFieldsOptions = Object.entries(CONTACTS.DATA.BASIC).map(([_, {LABEL, FIELD}]) => ({label: LABEL, value: FIELD}));

const extraFieldsOptions = Object.entries(CONTACTS.DATA.EXTRA).map(([_, {LABEL, FIELD}]) => ({label: LABEL, value: FIELD}));

const extraTextFieldsOptions = Array(CONTACTS.DATA.EXTRA_TEXT_FIELDS).fill('').map((_, index) => {
  const label = `Texto ${index + 1}`;
  const field = `text${index + 1}`;
  return {label, value: field};
});

const extraNumberFieldsOptions = Array(CONTACTS.DATA.EXTRA_NUMBER_FIELDS).fill('').map((_, index) => {
  const label = `Número ${index + 1}`;
  const field = `number${index + 1}`;
  return {label, value: field};
});

const extraDateFieldsOptions = Array(CONTACTS.DATA.EXTRA_DATE_FIELDS).fill('').map((_, index) => {
  const label = `Fecha ${index + 1}`;
  const field = `date${index + 1}`;
  return {label, value: field};
});

const extraTokenFieldsOptions = Array(CONTACTS.DATA.EXTRA_TOKEN_FIELDS).fill('').map((_, index) => {
  const label = `Token ${index + 1}`;
  const field = `token${index + 1}`;
  return {label, value: field};
});

const extraFileNameFieldsOptions = Array(CONTACTS.DATA.EXTRA_FILENAME_FIELDS).fill('').map((_, index) => {
  const label = `Nombre de archivo ${index + 1}`;
  const field = `fileName${index + 1}`;
  return {label, value: field};
});

const contactFieldsOptions = [
  ...basicFieldsOptions,
  ...extraFieldsOptions,
  ...extraTextFieldsOptions,
  ...extraNumberFieldsOptions,
  ...extraDateFieldsOptions,
  ...extraTokenFieldsOptions,
  ...extraFileNameFieldsOptions
];

export default function StartConversationTemplateVariables(props) {
  const asyncState = useAsyncState();
  const [multimediaOptions, setMultimediaOptions] = useState([]);
  const [structure, setStructure] = useState(props.translation?.structure ?? {});

  useEffect(async () => await initialize(), []);
  useEffect(() => setStructure(props.translation?.structure ?? {}), [props.translation])
  useEffect(() => handleVariablesMappingChange(), [props.variablesMapping]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getMultimedia()],
      {initialization: true}
    );
  }

  const getMultimedia = async () => {
    const response = await MultimediaService.getMultimedia();
    if(response.success) {
      const options = response.payload.map((multimedia) => ({label: multimedia.id, value: multimedia.id}));
      setMultimediaOptions(options);
      return {success: true};
    }
    return {success: false};
  }

  const handleVariablesMappingChange = () => {
    const variables = {};
    const updatedStructure = {
      body: props.translation?.structure?.body ?? '',
      footer: props.translation?.structure?.footer,
      buttons: props.translation?.structure?.buttons
    };
    if(props.translation?.structure?.header) {
      updatedStructure.header = {
        type: props.translation?.structure?.header?.type,
        text: props.translation?.structure?.header?.text
      }
    }
    const {header, body, buttons} = props.variablesMapping;
    if(header !== undefined) {
      const {type} = header;
      variables.header = {type, payload: {}};
      if(type === 'TEXT') {
        for(const [key, value] of Object.entries(header.payload)) {
          const text = ((value.type === 'OPTION' ? props.contact[value.payload] : value.payload) ?? '').trim();
          variables.header.payload[key] = text;
          if(text.length > 0) {
            updatedStructure.header.text = updatedStructure.header.text.replace(`{{${key}}}`, text);
          }
        }
      }
      else {
        const idValue = header.payload?.id;
        const idVariable = idValue?.type === 'OPTION' ? 'id' : 'url';
        const idText = (idValue?.payload ?? '').trim();
        variables.header.payload[idVariable] = idText;
        if(type === 'DOCUMENT') {
          const filenameValue = header.payload?.filename;
          const filenameText = (filenameValue?.payload ?? '').trim();
          if(filenameText.length > 0) {
            variables.header.payload.filename = filenameText;
          }
        }
      }
    }
    if(body !== undefined) {
      variables.body = {};
      for(const [key, value] of Object.entries(body)) {
        const text = ((value.type === 'OPTION' ? props.contact[value.payload] : value.payload) ?? '').trim();
        variables.body[key] = text;
        if(text.length > 0) {
          updatedStructure.body = updatedStructure.body.replace(`{{${key}}}`, text);
        }
      }
    }
    if(buttons !== undefined) {
      variables.buttons = {};
      for(const [index, payload] of Object.entries(buttons)) {
        variables.buttons[index] = {};
        for(const [key, value] of Object.entries(payload ?? {})) {
          const text = ((value.type === 'OPTION' ? props.contact[value.payload] : value.payload) ?? '').trim();
          variables.buttons[index][key] = text;
        }
      }
    }
    setStructure(updatedStructure);
    props.onVariablesChange(variables);
  }

  const handleTemplateVariablesChange = (event) => {
    props.onVariablesMappingChange(event);
  }
  const handleTemplateVariablesValidChange = (event) => {
    props.onValidChange(event);
  }

  return (
    <>
      {
        (props.template !== undefined && props.translation !== undefined) &&
        <>
          {
            (asyncState.isLoading) &&
            <NeoCard>
              <InternalSpinner/>
            </NeoCard>
          }
          {
            (!asyncState.isLoading) &&
            <>
              {
                (asyncState.isInitialized) &&
                <NeoGridContainer>
                  <NeoColumn md='6' col='12'>
                    <StartConversationTemplateVariablesForm
                      variables={props.variablesMapping}
                      name={props.template.name}
                      language={props.translation.language}
                      structure={props.translation.structure}
                      multimediaOptions={multimediaOptions}
                      contactFieldsOptions={contactFieldsOptions}
                      onChange={handleTemplateVariablesChange}
                      onValidChange={handleTemplateVariablesValidChange}
                    />
                  </NeoColumn>
                  <NeoColumn md='6' col='12'>
                    <NeoCard>
                      <TemplatePreview
                        title='Previsualización de plantilla'
                        template={{structure}}
                      />
                    </NeoCard>
                  </NeoColumn>
                </NeoGridContainer>
              }
              {
                (!asyncState.isInitialized) &&
                <NeoCard>
                  <DataFetchError 
                    internal 
                    onRetry={initialize}
                  />
                </NeoCard>
              }
            </>
          }
        </>
      }
    </>
  );
}