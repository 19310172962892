export default {
  VERTICAL: {
    'Automotive': 'Automotriz',
    'Beauty, Spa and Salon': 'Belleza, spa y salón',
    'Clothing and Apparel': 'Ropa y vestimenta',
    'Education': 'Educación',
    'Entertainment': 'Entretenimiento',
    'Event Planning and Service': 'Servicio y planificación de eventos',
    'Finance and Banking': 'Finanzas y banca',
    'Food and Grocery': 'Alimentos y abarrotes',
    'Public Service': 'Servicio público',
    'Hotel and Lodging': 'Hotel y alojamiento',
    'Medical and Health': 'Médico y salud',
    'Non-profit': 'Sin fines de lucro',
    'Professional Services': 'Servicio profesional',
    'Shopping and Retail': 'Compras y ventas al por menor',
    'Travel and Transportation': 'Viajes y transporte',
    'Restaurant': 'Restaurante',
    'Other': 'Otro'
  }
};