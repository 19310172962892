import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import StartConversationTemplateVariable from 'views/conversations/components/StartConversationTemplateVariable.component';
import templatesUtil from 'utils/templates.util';

const UNMAPPED = '';

export default function StartConversationTemplateHeaderVariables(props) {
  const [type, setType] = useState();
  const [multimediaValue, setMultimediaValue] = useState();
  const [filenameValue, setFilenameValue] = useState();
  const [textVariable, setTextVariable] = useState();

  useEffect(() => getVariables(), [props.requiredVariables]);

  const getVariables = () => {
    const headerType = props.requiredVariables?.type;
    if(headerType === 'TEXT') {
      const variable = props.requiredVariables?.param;
      const label = variable;
      const key = variable.substring(2, variable.length - 2);
      const value = props.value?.payload?.[key] ?? {type: 'OPTION', payload: UNMAPPED};
      setTextVariable({label, key, value});
    }
    else {
      const value = props.value?.payload?.id ?? {type: 'OPTION', payload: UNMAPPED};
      setMultimediaValue(value);
      if(headerType === 'DOCUMENT') {
        setFilenameValue(props.value?.payload?.filename ?? {type: 'TEXT', payload: UNMAPPED});
      }
    }
    setType(headerType);
  }

  const handleMultimediaChange = (event) => {
    const payload = {
      type,
      payload: {
        id: event,
        filename: {...filenameValue}
      }
    }
    props.onChange(payload);
  }

  const handleFilenameChange = (event) => {
    const payload = {
      type,
      payload: {
        id: {...multimediaValue},
        filename: event
      }
    }
    props.onChange(payload);
  }

  const handleTextVariableChange = (event) => {
    const payload = {
      type,
      payload: {[textVariable.key]: event}
    };
    props.onChange(payload);
  }

  return (
    <>
      {
        (type !== undefined) &&
        <>
          <NeoInnerSubtitle>Encabezado</NeoInnerSubtitle>
          {
            (type === 'TEXT') &&
            <StartConversationTemplateVariable
              optionPlaceholder='Campo a mapear'
              textPlaceholder='Valor de variable'
              variable={textVariable.label}
              value={textVariable.value}
              options={props.contactFieldsOptions}
              onChange={handleTextVariableChange}
            />
          }
          {
            (type !== 'TEXT') &&
            <>
              <StartConversationTemplateVariable
                optionPlaceholder='Multimedia a mapear'
                textPlaceholder='Url de multimedia'
                variable={templatesUtil.getTemplateHeaderType(type)}
                value={multimediaValue}
                options={props.multimediaOptions}
                onChange={handleMultimediaChange}
              />
              {
                (type === 'DOCUMENT') &&
                <StartConversationTemplateVariable
                  only='TEXT'
                  textPlaceholder='Valor de variable'
                  variable='Nombre de archivo'
                  value={filenameValue}
                  onChange={handleFilenameChange}
                />
              }
            </>
          }
        </>
      }
    </>
  );
}