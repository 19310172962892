import MENU from 'consts/menu.consts';
import AUTHENTICATION from 'consts/authentication.consts';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import authenticationUtil from 'utils/authentication.util';

const url = `${AUTHENTICATION.URL}${MENU.UNAUTHENTICATED.LOGIN.SSO.PATH}`;
const sessionId = authenticationUtil.generateSessionId();
const app = 'NeoConecta';

export default function Login() {
  localStorage.setItem('sessionId', sessionId);
  window.location = `${AUTHENTICATION.LOGIN_URL}?continue=${url}&session=${sessionId}&app=${app}`;
  return (
    <NeoSpinner/>
  );
}