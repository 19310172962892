import TemplatePreview from 'components/TemplatePreview.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import useComponent from 'hooks/useComponent.hook';

const AutomaticReplyDefault = (props) => {
    const component = useComponent({
        state: {
            element: {
                templatePreview: {
                    template: {
                        structure: {
                            body: null
                        }
                    }
                }
            }
        }
    });

    component.useEffect(() => {
        if(Array.isArray(props.automaticReplies) && props.automaticReplies[0]) {
            const [automaticReply] = props.automaticReplies;
            const {message} = automaticReply;
            component.setState((state) => {state.element.templatePreview.template.structure.body = message})
        } else {
            component.setState((state) => {state.element.templatePreview.template.structure.body = null})
        }
    }, [props.automaticReplies])

    return (<>
        <NeoCard>
            {component.state.element.templatePreview.template.structure.body &&
                <TemplatePreview
                    title='Previsualización del mensaje'
                    template={component.state.element.templatePreview.template}
                />
            }
            <NeoButtonSection align='center' col='12'>
                <NeoButtonMain
                    label='Configurar respuesta por defecto'
                    onClick={() => {
                        const [automaticReply] = props.automaticReplies;
                        props.actions?.onAutomaticReplyClick(automaticReply ?? {})
                        props.actions?.onModifyClick()
                    }}
                />
            </NeoButtonSection>
        </NeoCard>
    </>)
}

export default AutomaticReplyDefault;
