import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export default function useQueryParams() {
  const history = useHistory();
  const [params, setParams] = useState();

  useEffect(() => getQueryParams(), [history.location.search]);

  const getQueryParams = () => {
    const queryParams = {};
    const urlSearchParams = new URLSearchParams(history.location.search);
    for(const searchParam of urlSearchParams.keys()) {
      queryParams[searchParam] = urlSearchParams.get(searchParam);
    }
    setParams(queryParams);
  }

  const setQueryParams = (queryParams) => {
    const queryEntriesString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`);
    const queryString = queryEntriesString.join('&');
    const path = `${history.location.pathname}?${queryString}`;
    history.replace(path);
  }
  
  return [params, setQueryParams];
}