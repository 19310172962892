import { forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import AgentConversationItem from 'views/chat/components/items/AgentConversationItem.component';
import useChat from 'hooks/useChat.hook';
import useComponent from 'hooks/useComponent.hook';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {Array} agents Array of objects agents
 * @property {Object} agentSelected Agente selected
 * @property {Function} onAgentSelect Action on select agent
 */

const Component = {
  name: 'AgentConversationsList',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const component = useComponent();
    const chat = useChat();
    const elementRef = useRef(ref);

    const createBody = () => {
      const agentsLazyLoading = (props.isLoading) && [...Array(7)].map((value, key) => {
        return <AgentConversationItem key={key} isLoading={true} />
      });

      const agents = (props.agents && !props.isLoading ) && props.agents.map((agent, key) => {
        return <AgentConversationItem
          key={key}
          onClick={() => props?.onAgentSelect(agent)}
          name={agent?.name}
          email={agent?.email}
          status={agent?.status}
          area={agent?.area}
          detail={agent?.detail}
          className={agent.id === props?.agentSelected?.id ? 'active' : ''}
        />
      });

      return (
        <div className={`${Component.name}__body`}>
          {agentsLazyLoading}
          {agents}
        </div>
      );
    };

    const createFooter = () => {
      const unassignedConversations = <AgentConversationItem
        onClick={() => props?.onAgentSelect('UNASSIGNED_CONVERSATIONS')}
        name="Conversaciones en cola"
        area={`Total: ${totalConversationsUnassigned}`}
        detail={{}}
        className={component.classNames({'active': props.activeUnassignedConversations})}
      />

      return (
        <div className={`${Component.name}__footer`}>
          {unassignedConversations}
        </div>
      );
    }

    const totalConversationsUnassigned = useMemo(() => {
      return chat.state.conversationsUnassigned.reduce((initialValue, conversation) => {
        if(conversation.lineId === chat.state.lineSelected.id) {
          return initialValue + 1;
        }
        return initialValue;
      }, 0);
    }, [chat.state.conversationsUnassigned])

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();
    const footer = createFooter();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
        {footer}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  agents: PropTypes.array,
  agentSelected: PropTypes.object,
  onAgentSelect: PropTypes.func
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  agents: null,
  agentSelected: null,
  onAgentSelect: null
};

export default Component.element;