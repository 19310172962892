import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

export default function RegisteredContacts(props) {
  const [data, setData] = useState({});

  useEffect(async () => reboot(), [props.data]);

  const reboot = () => {
    const valid = props.data?.valid ?? 0;
    const invalid = props.data?.invalid ?? 0;
    const notValidated = props.data?.notValidated ?? 0;
    setData({total: valid + invalid + notValidated, invalid});
  }

  return (
    <NeoCard extra='p-mb-0'>
      <NeoInnerTitle extra='primero'>Contactos registrados</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>
              <NeoColumn col='6'>
                <NeoDataSecond
                  label='Total'
                  fact={`${data.total}`}
                />
              </NeoColumn>
              <NeoColumn col='6'>
                <NeoDataSecond
                  label='Sin WhatsApp'
                  fact={`${data.invalid}`}
                />
              </NeoColumn>
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry} />
          }
        </>
      }
    </NeoCard>
  );
}