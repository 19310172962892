import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import TemplatesService from 'services/templates.service';
import TEMPLATES from 'consts/templates.consts';

export default function CampaignMainTemplateForm(props) {
  const asyncState = useAsyncState();
  const [state, setState] = useStateParams();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedTranslation, setSelectedTranslation] = useState();

  useEffect(async () => await reboot(), [props.line]);
  useEffect(() => handleChangeMainTemplate(), [selectedTemplate, selectedTranslation]);
  useEffect(() => props.onTemplatesChange([]), []);

  const reboot = async () => {
    if(props.line?.id !== undefined) {
      setSelectedTemplate(undefined);
      setSelectedTranslation(undefined);
      await asyncState.allPromises(
        [getTemplates()]
      );
    }
  }

  const handleChangeMainTemplate = () => {
    const event = {
      valid: false,
      payload: {
        name: selectedTemplate?.name,
        ...selectedTranslation
      }
    };
    const {payload} = event;
    event.valid = payload.name !== undefined
      && payload.language !== undefined
      && payload.structure !== undefined;
    if(event.valid === true) {
      setState({template: payload}, {merge: true});
    }
    props.onChange(event);
  }

  const getTemplates = async () => {
    const response = await TemplatesService.getTemplates(props.line.id);
    if(response.success) {
      const validTemplates = response.payload.filter((template) => template.status == 'APPROVED' && template?.typeOfUse?.includes('CAMPAIGN'));
      const templatesNames = [...new Set(validTemplates.map((template) => template.name))];
      const templates = templatesNames.map((templateName) => {
        const translations = response.payload
          .filter((template) => template.name == templateName)
          .map(({language, structure, internal}) => ({language, structure, internal}));
        return {name: templateName, translations};
      });
      const selectableTemplates = templates.map((template) => {
        const translations = template.translations.filter((translation) => {
          if(state.mainRecurrentDynamicCampaign !== undefined) {
            const quickReplyButtons = translation.structure.buttons?.every((button) => button.type === 'QUICK_REPLY');
            return quickReplyButtons === true;
          }
          return true;
        });
        return {...template, translations};
      })
      .filter((template) => template.translations.length > 0);
      const selectedTemplate = getSelectedTemplate(selectableTemplates);
      if(selectedTemplate) {
        const selectedTranslation = getSelectedTranslation(selectedTemplate);
        setTemplates(selectableTemplates);
        setSelectedTemplate(selectedTemplate);
        setSelectedTranslation(selectedTranslation);
      }
      props.onTemplatesChange(templates);
      return {success: true};
    }
    return {success: false};
  }

  const getSelectedTemplate = (templates) => {
    const templateName = state.template?.name;
    if(templateName !== undefined) {
      for(const template of templates) {
        if(template.name == templateName) {
          return template;
        }
      }
    }
    return templates[0];
  }

  const getSelectedTranslation = (selectedTemplate) => {
    const templateLanguage = state.template?.language;
    if(templateLanguage !== undefined) {
      for(const translation of selectedTemplate.translations) {
        if(translation.language == templateLanguage) {
          return translation;
        }
      }
    }
    return selectedTemplate?.translations[0];
  }

  const handleTemplateDropdownChange = (event) => {
    const selectedTemplate = event.value;
    const selectedTranslation = selectedTemplate.translations[0];
    setSelectedTemplate(selectedTemplate);
    setSelectedTranslation(selectedTranslation);
  }

  const handleLanguageDropdownChange = (event) => {
    const selectedTranslation = event.value;
    setSelectedTranslation(selectedTranslation);
  }

  return (
    <>
      {
        (props.line?.id !== undefined) &&
        <NeoCard>
          {
            (asyncState.isLoading) &&
            <InternalSpinner/>
          }
          {
            (!asyncState.isLoading) &&
            <>
              {
                (asyncState.isSuccessfully) &&
                <>
                  <NeoDropdown md='6'
                    label='Nombre de plantilla'
                    emptyMessage='Sin plantillas creadas'
                    value={selectedTemplate}
                    options={templates.map((template) => ({label: template.name, value: template}))}
                    onChange={handleTemplateDropdownChange}
                  />
                  <NeoDropdown md='6'
                    label='Idioma de plantilla'
                    emptyMessage='Selecciona una plantilla'
                    value={selectedTranslation}
                    options={selectedTemplate?.translations.map((translation) => ({label: TEMPLATES.LANGUAGES[translation.language], value: translation}))}
                    onChange={handleLanguageDropdownChange}
                  />
                </>
              }
              {
                (!asyncState.isSuccessfully) &&
                <DataFetchError internal align='start' onRetry={reboot} />
              }
            </>
          }
        </NeoCard>
      }
    </>
  );
}