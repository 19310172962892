const EMOJI_REGEXP = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;

function containsEmojis(str) {
  return EMOJI_REGEXP.test(str);
}

function emojisCount(str) {
  return str?.match(EMOJI_REGEXP)?.length ?? 0;
}

const insertAt = (original = '', insert = '', pos = 0) => {
  return [original.slice(0, pos), insert, original.slice(pos)].join("");
}

export default {
  containsEmojis,
  emojisCount,
  insertAt
};