import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import TemplateVariableMapping from 'views/campaigns/components/TemplateVariableMapping.component';

export default function DatabasePhoneMapping(props) {
  return (
    <NeoGridContainer>
      <NeoColumn col="12">
        <NeoCard>
          <NeoInnerTitle extra='primero'>
            Configuración general
          </NeoInnerTitle>
          <TemplateVariableMapping
            variable='Número de contacto'
            mapping={props.mapping}
            databaseType='FILE'
            mappingOptions={props.mappingOptions}
            onMappingChange={(event) => props.onMappingChange(event)}
          />
        </NeoCard>
      </NeoColumn>
    </NeoGridContainer>
  );
}