import { useState, useEffect } from 'react';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import Icono from 'design/assets/img/cta/icons/icon-apis.svg';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import CreateApiKeyDialog from 'views/apiSettings/components/CreateApiKeyDialog.component';
import WebhookSettingsDialog from 'views/apiSettings/components/WebhookSettingsDialog.component';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import WebhookService from 'services/webhook.service';
import ApiKeysService from 'services/apiKeys.service';

export default function ApiSettingsHeader(props) {
  const messages = useMessages();
  const asyncState = useAsyncState();
  const [webhookUrl, setWebhookUrl] = useState(null);
  const [apiKeys, setApiKeys] = useState(null);
  const [showConfigWebhookDialog, setShowConfigWebhookDialog] = useState(false);
  const [showCreateApiKeyDialog, setShowCreateApiKeyDialog] = useState(false);

  useEffect(async () => await initialize(), []);
  useEffect(() => handleChange(), [webhookUrl, apiKeys]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getWebhookUrl(), getApiKeys()],
      { initialization: true }
    );
  }

  const rebootApiKeys = async () => {
    setApiKeys(null);
    await asyncState.allPromises(
      [getApiKeys()]
    );
  }

  const handleChange = () => {
    props.onChange({ webhookUrl, apiKeys });
  }

  const getWebhookUrl = async () => {
    const response = await WebhookService.getWebhookUrl();
    if (response.success) {
      setWebhookUrl(response.payload.url);
      return { success: true };
    }
    return { success: false };
  }

  const getApiKeys = async () => {
    const response = await ApiKeysService.getApiKeys();
    if (response.success) {
      setApiKeys(response.payload.credentials);
      return { success: true };
    }
    return { success: false };
  }

  const handleConfigWebhookDialogHide = (event) => {
    if (event.success) {
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se actualizó la dirección url del webhook.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar la dirección url del webhook, inténtalo de nuevo.');
    }
  }

  const handleCreateApiKeyDialogHide = async (event) => {
    if (event.success) {
      const { name } = event.payload;
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', `Se creó la API Key ${name}.`);
      await rebootApiKeys();
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo crear la API Key, inténtalo de nuevo.');
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain
          icon={Icono}
          title='API'
        />
        {
          (!asyncState.isLoading && asyncState.isInitialized) &&
          <NeoButtonSection align='right'>
            <NeoButtonMain
              icon="pi pi-cog"
              label='Configurar webhook'
              onClick={() => setShowConfigWebhookDialog(true)}
            />
            <NeoButtonMain
              icon='pi pi-plus'
              label='Crear nueva API Key'
              onClick={() => setShowCreateApiKeyDialog(true)}
            />
          </NeoButtonSection>
        }
      </NeoGridContainer>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner />
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <CreateApiKeyDialog
                visible={showCreateApiKeyDialog}
                visibleSetter={setShowCreateApiKeyDialog}
                onHide={handleCreateApiKeyDialogHide}
              />
              <WebhookSettingsDialog
                url={webhookUrl}
                visible={showConfigWebhookDialog}
                visibleSetter={setShowConfigWebhookDialog}
                onHide={handleConfigWebhookDialogHide}
              />
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <NeoCard>
              <DataFetchError 
                internal 
                title='No se pudieron cargar las API Keys'
                onRetry={initialize}
              />
            </NeoCard>
          }
        </>
      }
    </>
  );
}