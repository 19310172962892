import templatesUtil from 'utils/templates.util';
import NeoButtonWhatsapp from 'design/design_components/neo/button/NeoButtonWhatsapp.base';

export default function TemplatePreviewContent({structure}) {
  return (
    <div className="wa-balloon wa-sender">
      <span className="wa-svg-balloon-container-sender"><svg viewBox="0 0 8 13" width="8" height="13" className=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="#FFFFFF" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg></span>
      {
        (structure.header && structure.header.type) &&
        <p >
          {
            (structure.header.type == 'TEXT') &&
            <span className='wa-header-title'>{structure.header.text}</span>
          }
          {
            (structure.header.type != 'TEXT') &&
            <div>
              {
                (structure.header.type == 'IMAGE') &&
                <>
                  {
                    (structure.header.image?.url) &&
                    <img src={structure.header.image?.url} style={{ width: '100%', height: '100%' }} />
                  }
                  {
                    (!structure.header.image?.url) &&
                    <>{templatesUtil.getTemplateHeaderType(structure.header.type)}</>
                  }
                </>
              }
              {
                (structure.header.type != 'IMAGE') &&
                <>{structure.header.document?.filename ?? templatesUtil.getTemplateHeaderType(structure.header.type)}</>
              }
            </div>
          }
        </p>
      }
      {
        structure.body?.split('\n').map((text, index) => (
          text.length == 0
            ? <br key={index} />
            : <p key={index} >{text}</p>
        ))
      }
      {
        (structure.footer) &&
        <span className='wa-footer'>{structure.footer}</span>
      }

      {
        (structure.buttons?.length > 0) &&
        <div className='wa-buttons'>
          {
            (structure.buttons?.length > 0) &&
            structure.buttons.map((button, index) => (
              <NeoButtonWhatsapp key={index} label={button.text} onClick={() => {
                if(button.type === "URL") {
                  window.open(button.url, "_blank");
                }
              }} />
            ))
          }
        </div>
      }
    </div>
  )
}