import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} area area of agent
 */

const Component = {
  name: 'AgentConversationItemInfo',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const name = props?.name && <div className={`${Component.name}__body__name`}>{ObjectUtils.getJSXElement(props.name, props)}</div>;
      const email = props?.email && <div className={`${Component.name}__body__email`}>{ObjectUtils.getJSXElement(props.email, props)}</div>;
      const area = props?.area && <div className={`${Component.name}__body__area`}>{ObjectUtils.getJSXElement(props.area, props)}</div>;

      return (
        <div className={`${Component.name}__body`}>
          {name || email}
          {area}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  area: PropTypes.string
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  area: null
};

export default Component.element;