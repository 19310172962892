import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import StartConversationTemplateHeaderVariables from 'views/conversations/components/StartConversationTemplateHeaderMapping.component';
import StartConversationTemplateBodyVariables from 'views/conversations/components/StartConversationTemplateBodyMapping.component';
import StartConversationTemplateButtonsVariables from 'views/conversations/components/StartConversationTemplateButtonsMapping.component';
import templatesUtil from 'utils/templates.util';

const UNMAPPED = '';

export default function StartConversationTemplateVariablesForm(props) {
  const requiredVariables = templatesUtil.getTemplateRequiredParams(props.structure);

  useEffect(() => props.onChange({}), [props.structure]);
  useEffect(() => handleVariablesChange(), [props.variables]);

  const handleHeaderVariablesChange = (event) => {
    handleChange({
      header: event,
      body: props.variables?.body,
      buttons: props.variables?.buttons
    });
  }

  const handleBodyVariablesChange = (event) => {
    handleChange({
      header: props.variables?.header,
      body: event,
      buttons: props.variables?.buttons
    });
  }

  const handleButtonsVariablesChange = (event) => {
    handleChange({
      header: props.variables?.header,
      body: props.variables?.body,
      buttons: event
    });
  }

  const handleChange = (payload) => {
    const event = {};
    if(requiredVariables.header !== undefined) {
      event.header = payload.header;
    }
    if(requiredVariables.body.length > 0) {
      event.body = payload.body;
    }
    if(requiredVariables.buttons !== undefined) {
      event.buttons = payload.buttons;
    }
    props.onChange(event);
    
  }

  const handleVariablesChange = () => {
    const isValid = isValidVariables(props.variables);
    props.onValidChange(isValid);
  }

  const isValidVariables = (variables) => {
    if(requiredVariables.header !== undefined) {
      const {type, param} = requiredVariables.header;
      if(type === 'TEXT') {
        const key = param.substring(2, param.length - 2);
        const value = variables.header?.payload?.[key];
        const payload = (value?.payload ?? UNMAPPED).trim();
        if(payload === UNMAPPED) {
          return false;
        }
      }
      else {
        const value = variables.header?.payload?.id;
        const payload = (value?.payload ?? UNMAPPED).trim();
        if(payload === UNMAPPED) {
          return false;
        }
      }
    }
    if(requiredVariables.body.length > 0) {
      const bodyVariables = [...requiredVariables.body];
      for(const [key, value] of Object.entries(variables.body ?? {})) {
        const payload = (value.payload ?? UNMAPPED).trim();
        if(payload !== UNMAPPED) {
          const index = bodyVariables.indexOf(`{{${key}}}`);
          bodyVariables.splice(index, 1);
        }
      }
      if(bodyVariables.length > 0) {
        return false;
      }
    }
    if(requiredVariables.buttons !== undefined) {
      for(const {index, param} of (requiredVariables.buttons ?? [])) {
        const key = param.substring(2, param.length - 2);
        const value = variables.buttons?.[index]?.[key];
        const payload = (value?.payload ?? UNMAPPED).trim();
        if(payload === UNMAPPED) {
          return false;
        }
      }
    }
    return true;
  }

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>
        {props.name} ({props.language})
      </NeoInnerTitle>
      {
        (
          requiredVariables.header === undefined &&
          requiredVariables.body.length === 0 &&
          requiredVariables.buttons === undefined
        ) &&
        <NeoInnerSubtitle>La plantilla no tiene variables.</NeoInnerSubtitle>
      }
      <StartConversationTemplateHeaderVariables
        value={props.variables?.header ?? {}}
        requiredVariables={requiredVariables.header}
        multimediaOptions={props.multimediaOptions}
        contactFieldsOptions={props.contactFieldsOptions}
        onChange={handleHeaderVariablesChange}
      />
      <StartConversationTemplateBodyVariables
        value={props.variables?.body ?? {}}
        requiredVariables={requiredVariables.body}
        options={props.contactFieldsOptions}
        onChange={handleBodyVariablesChange}
      />
      <StartConversationTemplateButtonsVariables
        value={props.variables?.buttons ?? {}}
        requiredVariables={requiredVariables.buttons}
        options={props.contactFieldsOptions}
        onChange={handleButtonsVariablesChange}
      />
    </NeoCard>
  );
}