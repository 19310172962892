export default {
  TYPES: {
    AUDIO: 'Audio',
    BUTTON: 'Botón',
    CONTACT: 'Contacto',
    DOCUMENT: 'Documento',
    IMAGE: 'Imagen',
    LOCATION: 'Ubicación',
    STICKER: 'Sticker',
    TEMPLATE: 'Plantilla',
    TEXT: 'Texto',
    VIDEO: 'Video',
    VOICE: 'Mensaje de voz'
  },
  STATUSES: {
    FAILED: 'Fallido',
    WRITTEN: 'Escrito',
    SENT: 'Enviado',
    DELIVERED: 'Recibido',
    READ: 'Leído',
    ERROR: 'No enviado'
  }
};