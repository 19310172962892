import {useState, useEffect} from 'react';
import useMessages from 'hooks/useMessages.hook';
import TOAST from 'consts/toast.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import LoadingMessage from 'components/LoadingMessage.component';
import DashboardsService from 'services/dashboards.service';
import dateTimeUtil from 'utils/dateTime.util';

export default function DashboardUpdate(props) {
  const messages = useMessages();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [blockUpdateButton, setBlockUpdateButton] = useState(false);

  useEffect(() => reboot(), [props.data]);

  useEffect(() => {
    getCompanyDashboardsStatus();
    const interval = setInterval(() => getCompanyDashboardsStatus(), 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(isUpdating === false && isFirstTime === false) {
      props.onRetry();
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se actualizaron las estadísticas.');
    }
    setIsFirstTime(false);
  }, [isUpdating]);

  const reboot = () => {
    if(props.data) {
      setUpdatedAt(props.data);
    }
  }

  const getCompanyDashboardsStatus = async () => {
    const response = await DashboardsService.getCompanyDashboardsStatus();
    if(response.success) {
      setIsUpdating(response.payload === 'UPDATING');
    }
  }

  const handleUpdateButtonClick = async () => {
    setBlockUpdateButton(true);
    const response = await DashboardsService.updateCompanyDashboards();
    if(response.success) {
      setIsUpdating(true);
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudieron actualizar las estadísticas, inténtalo de nuevo.');
    }
    setBlockUpdateButton(false);
  }

  return (
    <NeoCard>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <>

                <NeoDataSecond
                  label='Última fecha de actualización'
                  fact={dateTimeUtil.getDateString(updatedAt, {dateStyle: 'medium', timeStyle: 'short'})}
                />
                {
                  (blockUpdateButton === false && isUpdating === false) &&
                  <NeoButtonOutlined
                    icon="pi pi-sync"
                    label='Actualizar'
                    onClick={handleUpdateButtonClick}
                  />
                }
                {
                  (blockUpdateButton === true || isUpdating === true) &&
                  <LoadingMessage
                    marginLeft='1.5rem'
                    message='Actualizando estadísticas'
                  />
                }
            </>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry}/>
          }
        </>
      }
    </NeoCard>
  );
}