import {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import CAMPAIGNS from 'consts/campaigns.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import CampaignsService from 'services/campaigns.service';
import dateTimeUtil from 'utils/dateTime.util';
import campaignsUtil from 'utils/campaigns.util';

const statusOptions =
  Object.entries(CAMPAIGNS.STATUSES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({ label, value }));

export default function RecurrentCampaignSendings(props) {
  const tableRef = useRef();
  const history = useHistory();
  const asyncState = useAsyncState();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(async () => await initialize(), []);
  useEffect(() => handleCampaignsChange(), [campaigns]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaigns()]
    );
  }

  const handleCampaignsChange = () => {
    const sortedCampaigns = campaigns.sort((campaign1, campaign2) => {
      const recurrentCampaignNumber1 = campaign1.recurrentCampaignNumber ?? 0;
      const recurrentCampaignNumber2 = campaign2.recurrentCampaignNumber ?? 0;
      return recurrentCampaignNumber1 - recurrentCampaignNumber2;
    });
    const campaign = sortedCampaigns[sortedCampaigns.length-1];
    props.onLastSendingChange(campaign);
  }

  const getCampaigns = async () => {
    const {lineId, name} = props.campaign;
    const response = await CampaignsService.getCampaigns(lineId, {name});
    if(response.success) {
      const campaigns = response.payload.map((campaign) => {
        const sent = campaign.statusCount?.sent ?? 0;
        const delivered = campaign.statusCount?.delivered ?? 0;
        const read = campaign.statusCount?.read ?? 0;
        return {
          ...campaign,
          recurrentCampaignName: `Envío ${campaign.recurrentCampaignNumber}${campaign.sendingName ? ` - ${campaign.sendingName}` : ''}`,
          startedAt: campaign.startedAt ?? campaign.scheduledAt,
          sentTemplates: sent + delivered + read
        };
      });
      setCampaigns(campaigns);
      return {success: true};
    }
    return {success: false};
  }

  const handleTableRowSelect = (event) => {
    const {lineId, name, recurrentCampaignNumber} = event.data;
    const campaignId = `${lineId}-${name}-${recurrentCampaignNumber}`;
    history.push(`${MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.BASE}/${campaignId}`);
  };

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='recurrentCampaignName' placeholder='Buscar por nombre'/>
    ),
    startedAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='startedAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    sentTemplatesFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='sentTemplates' placeholder='Buscar por cantidad' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={statusOptions}
        field='status'
        placeholder='Todos'
        selectedItemsLabel='{0} estatus seleccionados'
      />
    ),
    nameColumnBody: (data) => (
      <b>{data.recurrentCampaignName}</b>
    ),
    startedAtColumnBody: (data) => (
      <>
        {
          (!data.startedAt)
            ? '-'
            : dateTimeUtil.getDateString(data.startedAt, {dateStyle: 'medium', timeStyle: 'short'})
        }
      </>
    ),
    statusColumnBody: (data) => (
      <NeoCellDataStatus extra="no-circle" status={campaignsUtil.getCampaignStatus(data.status)} label={campaignsUtil.getCampaignStatus(data.status)}/>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner/>
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <NeoTable
              ref={tableRef}
              selectionMode='single'
              value={campaigns}
              paginator
              rows={10}
              removableSort
              sortField='startedAt'
              sortOrder={-1}
              emptyMessage='No hay campañas'
              onRowSelect={handleTableRowSelect}
            >
              <NeoTableColumn
                field='recurrentCampaignName'
                header='Nombre'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.nameFilterInput}
                body={elements.nameColumnBody}
              />
              <NeoTableColumn
                header='Fecha de envío'
                field='startedAt'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.startedAtFilterRange}
                filterFunction={dateTimeUtil.filterDate}
                body={elements.startedAtColumnBody}
                style={{width: '25%'}}
              />
              <NeoTableColumn
                field='sentTemplates'
                header='Plantillas enviadas'
                filter
                sortable
                filterMatchMode='contains'
                filterElement={elements.sentTemplatesFilterInput}
                style={{width: '10%'}}
              />
              <NeoTableColumn
                field='status'
                header='Estatus'
                filter
                sortable
                filterMatchMode='custom'
                filterElement={elements.statusFilterMultiSelect}
                body={elements.statusColumnBody}
                style={{width: '15%'}}
              />
            </NeoTable>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError internal align='start' onRetry={initialize}/>
            </NeoCard>
          }
        </>
      }
    </>
  );
}