import React from "react";
import BaseCardInner from "../../sisu/panel/BaseCardInner.sisu";


const NeoCardInterna = (props) => {
  return (<>
    <BaseCardInner custom="card-interna" {...props} />
  </>);
};

export default NeoCardInterna;