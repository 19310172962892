import {useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import useAuthentication from 'hooks/useAuthentication.hook';
import MENU from 'consts/menu.consts';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import DataFetchError from 'components/DataFetchError.component';
import ContactsService from 'services/contacts.service';
import dateTimeUtil from 'utils/dateTime.util';
import contactsUtil from 'utils/contacts.util';
import AvatarContact from 'views/chat/components/items/AvatarContact.component';
import useComponent from 'hooks/useComponent.hook';

const statusOptions =
  Object.entries(CONTACTS.STATUSES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({ label, value }));

export default function ContactsList() {
  const authentication = useAuthentication();
  const tableRef = useRef();
  const history = useHistory();
  const asyncState = useAsyncState({ isInitialized: true });
  const component = useComponent({
    state: {
      element: {
        tableContacts: {
          contacts: [],
          delayFilterWrite: false,
          allowPrevious: false,
          allowNext: false,
          lazyParams: {
            first: 0,
            rows: 25,
            page: 0,
          }
        }
      }
    }
  });
  const wait = useRef(null);
  let conversationButtonClick = false;

  component.useEffect(() => {
    if(component.state.element.tableContacts.delayFilterWrite === false) {
      reboot().catch((error) => {});
    } else {
      clearTimeout(wait.current);
      wait.current = setTimeout(() => {
        reboot().catch((error) => {});
        component.setState((state) => {state.element.tableContacts.delayFilterWrite = false});
      }, [500]);
    }
  }, [component.state.element.tableContacts.lazyParams])

  const reboot = async () => {
    await asyncState.allPromises(
      [getContacts()]
    );
  }

  const getContacts = component.useAsync(async () => {
    const {page, rows, sortField, sortOrder, filters} = component.state.element.tableContacts.lazyParams;
    const options = {};
    if(sortField) {options.orderBy = sortField}
    if(sortOrder === -1) {options.order = 'desc'}
    if(filters) {options.filters = filters}
    const response = await ContactsService.getContacts(page+1,rows, options);
    if (response.success) {
      component.setState((state) => {
        state.element.tableContacts.contacts = response.payload
        state.element.tableContacts.allowPrevious = response.cursor.previous;
        state.element.tableContacts.allowNext = response.cursor.next;
      })
      return { success: true };
    }
    return { success: false };
  });

  const onPage = (event) => {
    component.setState((state) => {
      Object.assign(state.element.tableContacts.lazyParams, event);
    })
  }
  
  const onSort = (event) => {
    component.setState((state) => {
      Object.assign(state.element.tableContacts.lazyParams, event);
    })
  }

  const onFilter = (event) => {
    component.setState((state) => {
      Object.assign(state.element.tableContacts.lazyParams, event);
      state.element.tableContacts.lazyParams['first'] = 0;
      state.element.tableContacts.delayFilterWrite = true;
    })
  }

  const handleTableRowSelect = (event) => {
    const {id} = event.data;
    if(conversationButtonClick) {
      history.push(`${MENU.ADMIN.CONVERSATIONS.START_CONVERSATION.BASE}/${id}`);
    }
    else {
      history.push(`${MENU.ADMIN.CONTACTS.CONTACT_DETAIL.BASE}/${id}`);
    }
    conversationButtonClick = false;
  }

  const elements = {
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' filterMatch='startsWith' placeholder='Buscar por nombre' />
    ),
    aliasFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='alias' filterMatch='startsWith' placeholder='Buscar por alias' />
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' filterMatch='startsWith' placeholder='Buscar por teléfono' />
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='between' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={statusOptions}
        field='status'
        placeholder='Todos'
        selectedItemsLabel='{0} estatus seleccionados'
      />
    ),
    nameColumnBody: (data) => (
      <div style={{display: 'flex', alignItems: 'center', gap: '1em'}}>
        <AvatarContact
          name={data.name ?? '?'}
          phone={data.phone}
        />
        <b>{data.name || '~'}</b>
      </div>
    ),
    aliasColumnBody: (data) => (
      <b>{data.alias || '-'}</b>
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, { dateStyle: 'medium', timeStyle: 'short' }) || '-'}</>
    ),
    statusColumnBody: (data) => (
      <>{contactsUtil.getContactStatus(data.status)}</>
    ),
    actionsColumnBody: (data) => { 
      return <NeoButtonMain
        icon='fa fa-comment-medical'
        onClick={() => conversationButtonClick = true}
      />
     }
  };

  const paginatorTemplate = useMemo(() => {
    const layout = [];
    if(component.state.element.tableContacts.allowPrevious) layout.push('PrevPageLink');
    if(component.state.element.tableContacts.allowNext) layout.push('NextPageLink');
    return {layout: layout.join(' ')};
  }, [component.state.element.tableContacts.allowPrevious, component.state.element.tableContacts.allowNext]);



  return (
    <>
      <NeoTable
        ref={tableRef}
        selectionMode='single'
        value={component.state.element.tableContacts.contacts}
        lazy
        paginator
        paginatorTemplate={paginatorTemplate}
        first={component.state.element.tableContacts.lazyParams.first}
        rows={component.state.element.tableContacts.lazyParams.rows}
        totalRecords={Number.MAX_VALUE}
        onPage={onPage}
        onSort={onSort}
        sortField={component.state.element.tableContacts.lazyParams.sortField}
        sortOrder={component.state.element.tableContacts.lazyParams.sortOrder}
        removableSort
        onFilter={onFilter}
        loading={component.isLoading}
        emptyMessage='No hay contactos'
        onRowSelect={handleTableRowSelect}
      >
        <NeoTableColumn
          field='name'
          header='Nombre'
          filter
          sortable
          filterElement={elements.nameFilterInput}
          body={elements.nameColumnBody}
          style={{width: '30%'}}
        />
        <NeoTableColumn
          field='alias'
          header='Alias'
          filter
          sortable
          filterElement={elements.aliasFilterInput}
          body={elements.aliasColumnBody}
          style={{width: '30%'}}
        />
        <NeoTableColumn
          field='phone'
          header='Teléfono'
          filter
          sortable
          filterElement={elements.phoneFilterInput}
          style={{width: '25%'}}
        />
        <NeoTableColumn
          field='createdAt'
          header='Fecha de creación'
          filter
          sortable
          filterElement={elements.createdAtFilterRange}
          filterFunction={dateTimeUtil.filterDate}
          body={elements.createdAtColumnBody}
          style={{width: '25%'}}
        />
        <NeoTableColumn
          field='status'
          header='Estatus'
          filter
          sortable
          filterMatchMode='custom'
          filterElement={elements.statusFilterMultiSelect}
          body={elements.statusColumnBody}
          style={{width: '20%'}}
        />
        {
          (authentication.user.role === 'AGENT') &&
          <NeoTableColumn
            body={elements.actionsColumnBody}
            style={{width: '8%'}}
          />
        }
      </NeoTable>
      {
        (!asyncState.isSuccessfully && !asyncState.isLoading) &&
        <NeoCard>
          <DataFetchError 
            internal 
            onRetry={reboot}
            title='No se pudieron cargar los contactos'
          />
        </NeoCard>
      }
    </>
  );
}
