import {useState} from 'react';
import DashboardFilters from 'views/dashboard/components/DashboardFilters.component';
import CampaignsStatistics from 'views/dashboard/components/CampaignsStatistics.component';

export default function CampaignsDashboard() {
  const [filters, setFilters] = useState(null);

  const handleFiltersChange = (event) => {
    setFilters(event);
  }

  return (
    <>
      <DashboardFilters 
        title='Estadísticas de campañas'
        onChange={handleFiltersChange}
      />
      {
        (filters) &&
        <CampaignsStatistics filters={filters}/>
      }
    </>
  );
}