import React from 'react'
import { ProgressBar } from 'primereact/progressbar'

const BaseProggressBar = (props) => {
  return <ProgressBar 
    className={`${props.custom ? props.custom : ''} ${ props.extra ? props.extra : ''}`} 
    {...props} />
}

export default BaseProggressBar
