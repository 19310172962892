import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Array} actions Array of component to actions
 */

const Component = {
  name: 'ChatHeaderActionsSection',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const actions = props.actions && props.actions.map((action, key) => {
        return <div className={`${Component.name}__body__action`} key={key}>{ObjectUtils.getJSXElement(action, props)}</div>;
      });

      return (
        <div className={`${Component.name}__body`}>
          {actions}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  actions: PropTypes.array
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  actions: null
};

export default Component.element;