import {useState, useEffect} from 'react';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import MessageBalloonPreview from 'components/MessageBalloonPreview.component';
import dateTimeUtil from 'utils/dateTime.util';

export default function CampaignFlowPreview(props) {
  const [messages, setMessages] = useState([]);

  useEffect(() => rebootFlow(), [props.flow]);
  useEffect(async () => await rebootValue(), [props.value]);

  const rebootFlow = () => {
    const updatedMessages = [{isSender: true, type: 'TEMPLATE', content: props.flow}];
    if(props.value?.length === 1) {
      const buttonText = props.value[0];
      updatedMessages.push({isSender: false, type: 'TEXT', content: buttonText});
      const autoReply = (props.flow.replyButtons ?? {})[buttonText]?.autoReply;
      if(autoReply !== undefined) {
        const lowerCaseType = autoReply.type.toLowerCase();
        updatedMessages.push({isSender: true, type: autoReply.type, content: autoReply[lowerCaseType]});
      }
    }
    setMessages(updatedMessages);
  }

  const rebootValue = async () => {
    const updatedMessages = [{isSender: true, type: 'TEMPLATE', content: props.flow}];
    if(props.value?.length === 1) {
      const buttonText = props.value[0];
      updatedMessages.push({isSender: false, type: 'TEXT', content: buttonText});
      setMessages([...updatedMessages]);
      await dateTimeUtil.waitMilliseconds(500);
      const autoReply = (props.flow.replyButtons ?? {})[buttonText]?.autoReply;
      if(autoReply !== undefined) {
        const lowerCaseType = autoReply.type.toLowerCase();
        updatedMessages.push({isSender: true, type: autoReply.type, content: autoReply[lowerCaseType]});
      }
    }
    setMessages(updatedMessages);
  }

  const handleAutoReplyClick = (event, index) => {
    if(props.isDynamicCampaign === true && index === 0) {
      if(Array.isArray(props.value) && props.value[index] === undefined) {
        const updatedValue = [event];
        props.onChange(updatedValue);
      }
    }
  }

  return (
    <div className='wa-container p-mb-3'>
      <div className='wa-back'/>
      {
        messages.map((message, index) => (
          <NeoColumn key={index}>
            <MessageBalloonPreview
              internal={props.campaign?.internal}
              trackingURL={props.campaign?.trackingURL}
              isSender={message.isSender}
              type={message.type}
              content={message.content}
              onAutoReplyClick={(event) => handleAutoReplyClick(event, index)}
            />
          </NeoColumn>
        ))
      }
    </div>
  );
}