const units = ['B', 'KB', 'MB', 'GB'];

function getSizeString(size) {
  let index = 0;
  for(; index < units.length && size > 1024; ++index, size /= 1024);
  return `${Number.parseInt(size * 100) / 100} ${units[index]}`
}

function getTypeOfFileFromMimeType(mimeType = '') {
  const types = {
    audio: ['^audio\/.+$'],
    document: ['^text\/.+$', '^application\/.+$'],
    image: ['^image\/.+$'],
    video: ['^video\/.+$'],
  };
  
  for (const [key, values] of Object.entries(types)) {
    for (const regex of values) {
      if(mimeType.search(regex) !== -1) {
        return {type: key}
      }
    }
  }

  return null;
}

export default {
  getSizeString,
  getTypeOfFileFromMimeType
};