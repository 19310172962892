import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import useQueryParams from 'hooks/useQueryParams.hook';
import useAuthentication from 'hooks/useAuthentication.hook';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import ActionDialog from 'components/ActionDialog.component';
import AuthenticationService from 'services/authentication.service';

export default function LoginSSO() {
  const history = useHistory();
  const [queryParams, setQueryParams] = useQueryParams();
  const asyncState = useAsyncState();
  const authentication = useAuthentication();
  const [showNonExistingAccountDialog, setShowNonExistingAccountDialog] = useState(false);

  useEffect(async () => await initialize(), [queryParams]);
  useEffect(() => {
    if(authentication.isAuthenticated()) {
      history.replace('/');
    }
  }, [authentication.isAuthenticated()]);

  const initialize = async () => {
    if(queryParams) {
      if(!queryParams.nonExistingAccount) {
        await asyncState.allPromises(
          [login()], 
          {initialization: true}
        );
      }
      else {
        setShowNonExistingAccountDialog(true);
      }
    }
  }

  const login = async () => {
    const result = await signInWithCustomToken();
    if(result.success) {
      const response = await AuthenticationService.getUserAccount();
      if(response.success) {
        return {success: true};
      }
      await authentication.signOut();
      setQueryParams({nonExistingAccount: true});
      return {success: false};
    }
    return {success: false};
  }

  const signInWithCustomToken = async () => {
    try {
      const session = localStorage.getItem('sessionId') ?? '';
      const token = queryParams.token ?? '';
      await authentication.login(session, token);
      return {success: true};
    }
    catch(error) {
      return {success: false};
    }
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/>
      }
      {
        <ActionDialog
          header='Cuenta no configurada'
          visible={showNonExistingAccountDialog}
          visibleSetter={setShowNonExistingAccountDialog}
          onHide={() => history.replace('/')}
          cancel={false}
        >
          Esta cuenta no está configurada, por favor contacta con tu DN para activarla.
        </ActionDialog>
      }
    </>
  );
}