import useComponent from 'hooks/useComponent.hook';
import useAuthentication from 'hooks/useAuthentication.hook';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import AssignAgentDialog from './AssignAgentDialog.component';
import TOAST from 'consts/toast.consts';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';

export default (props) => {
  const authentication = useAuthentication();
  const messages = useMessages();
  const component = useComponent({
    state: {
      showAssignAgentDialog: false,
      contact: props.contact
    }
  });

  const handleAssignAgentDialogHide = (event) => {
    if (event.success) {
      const agentAssigned = event.payload;
      component.setState((state) => {
        state.contact.agentAssignedId = agentAssigned.id;
        state.contact.agentAssigned = agentAssigned;
      });
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Agente asignado.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo actualizar el agente asignado, inténtalo de nuevo.');
    }
  }

  const enableAssignAgent = component.useMemo(() => {
    return ['ADMIN', 'SUPERVISOR'].includes(authentication.user.role);
  }, [authentication.user.role])

  const currentAgent = component.useMemo(() => {
    if(component.state.contact?.agentAssignedId && component.state.contact?.agentAssigned) {
      return component.state.contact?.agentAssigned;
    }
    return null;
  }, [component.state.contact])

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Agente asignado</NeoInnerTitle>
      {currentAgent === null ? 'Sin agente asignado' : currentAgent.name ?? currentAgent.email}
      {enableAssignAgent && <>
        <NeoButtonSection
          align="right"
        >
            <NeoButtonMain
              label='Asignar agente'
              icon="pi pi-user"
              onClick={() => {component.setState((state) => {state.showAssignAgentDialog = true})}}
            />
        </NeoButtonSection>
          <AssignAgentDialog
            contact={component.state.contact}
            visible={component.state.showAssignAgentDialog}
            visibleSetter={(visible) => {component.setState((state) => {state.showAssignAgentDialog = visible})}}
            onHide={handleAssignAgentDialogHide}
          />
      </>}
    </NeoCard>
  )
}