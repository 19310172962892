import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import TemplateVariableMapping from 'views/campaigns/components/TemplateVariableMapping.component';

const UNMAPPED = '';

export default function TemplateBodyMapping(props) {
  const [variables, setVariables] = useState(() => {
    const requiredVariables = props.requiredVariables?.map((variable) => {
      const label = variable;
      const key = `body.${variable.substring(2, variable.length - 2)}`;
      const map = props.mapping[key] ?? UNMAPPED;
      return {label, key, map};
    });
    return requiredVariables ?? [];
  });

  useEffect(() => handleMappingChange(), [props.mapping]);

  const handleMappingChange = () => {
    const updatedVariables = variables.map((variable) => {
      const map = props.mapping[variable.key] ?? UNMAPPED;
      return {...variable, map};
    });
    setVariables(updatedVariables);
  }

  const handleVariableMappingChange = (event, index) => {
    const updatedVariables = [...variables];
    updatedVariables[index].map = event;
    const mapping = Object.fromEntries(updatedVariables.map(({key, map}) => [key, (map !== UNMAPPED ? map : undefined)]));
    props.onChange(mapping);
  }

  return (
    <>
      {
        (variables.length > 0) &&
        <>
          <NeoInnerSubtitle>Cuerpo</NeoInnerSubtitle>
          {
            variables.map((variable, index) => (
              <TemplateVariableMapping
                key={index}
                variable={variable.label}
                mapping={variable.map}
                databaseType={props.databaseType}
                mappingOptions={props.mappingOptions}
                onMappingChange={(event) => handleVariableMappingChange(event, index)}
              />
            ))
          }
        </>
      }
    </>
  );
}