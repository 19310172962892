import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import ConversationsHeaderInfoItem from 'views/chat/components/items/ConversationsHeaderInfoItem.component';
import ConversationsHeaderFiltersSection from 'views/chat/components/sections/ConversationsHeaderFiltersSection.component';
import AgentConversationItem from 'views/chat/components/items/AgentConversationItem.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {Object} line Line
 * @property {Object} agent Agent
 * @property {Array} filters Array of filters
 */

const Component = {
  name: 'ConversationsHeader',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const line = props.line && (<>
        <ConversationsHeaderInfoItem line={props.line} />
        {(props.line?.name || props.line?.phone) && 
          <div className={`${Component.name}__body__line`}>
            {props.line?.name &&
              <div className={`${Component.name}__body__line__name`}>{ObjectUtils.getJSXElement(props.line?.name, props)}</div>
            }
            {props.line?.phone &&
              <div className={`${Component.name}__body__line__phone`}>{ObjectUtils.getJSXElement(props.line?.phone, props)}</div>
            }
          </div>
        }
      </>)

      const agent = props?.agent && <AgentConversationItem
        name={props.agent?.name}
        email={props.agent?.email}
        status={props.agent?.status}
        area={props.agent?.area}
        detail={props.agent?.detail}
      />;

      const filters = (!props.isLoading && props.filters) && <ConversationsHeaderFiltersSection filters={props.filters} />;

      return (
        <div className={`${Component.name}__body`}>
          {agent ?? line}
          {filters}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  line: PropTypes.object,
  agent: PropTypes.object,
  filters: PropTypes.array
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  line: null,
  agent: null,
  filters: null
};

export default Component.element;