import {useState} from 'react';
import ActionDialog from 'components/ActionDialog.component';
import NeoInputPhoto from 'design/design_components/neo/form/NeoInputPhoto.base';

const MAX_SIZE = 5242880;

export default function LinePhoto(props) {
  const [showSizeErrorDialog, setShowSizeErrorDialog] = useState(false);
  const [showMaxDimensionErrorDialog, setShowMaxDimensionErrorDialog] = useState(false);
  const [showMinDimensionErrorDialog, setShowMinDimensionErrorDialog] = useState(false);
  const [showSquareDimensionErrorDialog, setShowSquareDimensionErrorDialog] = useState(false);

  const handleFileChange = (event) => {
    const file = event;
    if(file.size <= MAX_SIZE) {
      const image = new Image();
      image.src = window.URL.createObjectURL(file);
      image.onload = () => {
        if(image.width != image.height) {
          setShowSquareDimensionErrorDialog(true);
        }
        else if(image.width < 192) {
          setShowMinDimensionErrorDialog(true);
        }
        else if(image.width > 640) {
          setShowMaxDimensionErrorDialog(true);
        }
        else {
          props.onChange(file);
        }
      }
    }
    else {
      setShowSizeErrorDialog(true);
    }
  }

  return (
    <>
      <NeoInputPhoto
        height='100px'
        circle={true}
        file={props.file}
        tooltip='La foto de perfil debe ser cuadrada, con un tamaño máximo de 5 MB, un borde mínimo de 192px y máximo de 640px.'
        onChange={handleFileChange}
      />
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showSizeErrorDialog}
          visibleSetter={setShowSizeErrorDialog}
          cancel={false}
        >
          La foto debe tener un tamaño máximo de 5 MB.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showSquareDimensionErrorDialog}
          visibleSetter={setShowSquareDimensionErrorDialog}
          cancel={false}
        >
          La foto debe ser cuadrada.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showMinDimensionErrorDialog}
          visibleSetter={setShowMinDimensionErrorDialog}
          cancel={false}
        >
          La foto debe tener un ancho y altura mínima de 192 píxeles.
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Archivo inválido'
          visible={showMaxDimensionErrorDialog}
          visibleSetter={setShowMaxDimensionErrorDialog}
          cancel={false}
        >
          La foto debe tener un ancho y altura máxima de 640 píxeles.
        </ActionDialog>
      }
    </>
  );
}