import Icono from 'design/assets/img/cta/icons/icon-contacts.svg';
import {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import useAuthentication from 'hooks/useAuthentication.hook';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import DataFetchError from 'components/DataFetchError.component';
import ResourceNotFound from 'components/ResourceNotFound.component';
import LoadingDialog from 'components/LoadingDialog.component';
import ActionDialog from 'components/ActionDialog.component';
import StartConversationLine from 'views/conversations/components/StartConversationLine.component';
import StartConversationTemplate from 'views/conversations/components/StartConversationTemplate.component';
import StartConversationTemplateVariables from 'views/conversations/components/StartConversationTemplateVariables.component';
import ContactsService from 'services/contacts.service';
import ChatService from 'services/chat.service';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';

export default function StartConversationView() {
  const {id} = useParams();
  const history = useHistory();
  const messages = useMessages();
  const asyncState = useAsyncState();
  const authentication = useAuthentication();
  const [lineId, setLineId] = useState();
  const [template, setTemplate] = useState();
  const [translation, setTranslation] = useState();
  const [contact, setContact] = useState(null);
  const [variables, setVariables] = useState({});
  const [variablesMapping, setVariablesMapping] = useState({});
  const [isValidVariables, setIsValidVariables] = useState(true);
  const [isValidVariablesMapping, setIsValidVariablesMapping] = useState(true);
  const [showStartingChatDialog, setShowStartingChatDialog] = useState(false);
  const [showAwayStatusDialog, setShowAwayStatusDialog] = useState(false);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getContact()], 
      {initialization: true}
    );
  }

  const getContact = async () => {
    const response = await ContactsService.getContact(id);
    if(response.success) {
      setContact(response.payload);
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  const getTemplatePayload = () => {
    const {header, body, buttons} = variables;
    const payload = {
      name: template.name,
      language: translation.language,
      parameters: {}
    };
    if(header !== undefined) {
      const {type} = header;
      payload.parameters.header = {type};
      if(type === 'TEXT') {
        payload.parameters.header.text = {};
        for(const [key, value] of Object.entries(header.payload)) {
          payload.parameters.header.text[key] = value?.trim() ?? '';
        }
      }
      else {
        const lowerCaseType = type.toLowerCase();
        payload.parameters.header[lowerCaseType] = {};
        for(const [key, value] of Object.entries(header.payload)) {
          payload.parameters.header[lowerCaseType][key] = value?.trim() ?? '';
        }
      }
    }
    if(body !== undefined) {
      payload.parameters.body = {};
      for(const [key, value] of Object.entries(body)) {
        payload.parameters.body[key] = {type: 'TEXT', text: value?.trim() ?? ''};
      }
    }
    if(buttons !== undefined) {
      payload.parameters.buttons = {};
      for(const [index, buttonPayload] of Object.entries(buttons)) {
        const buttonIndex = `${Number.parseInt(index) + 1}`;
        payload.parameters.buttons[buttonIndex] = {type: 'URL', url: {}};
        for(const [key, value] of Object.entries(buttonPayload)) {
          payload.parameters.buttons[buttonIndex].url[key] = value?.trim() ?? '';
        }
      }
    }
    return payload;
  }

  const getErrorDetail = (error) => {
    let detail = 'No se pudo iniciar la conversación, inténtalo de nuevo.';
    if(error.code === 'CONTACT_NOT_AVAILABLE_FOR_NEW_CHAT') {
      detail = 'El contacto ya tiene una conversación iniciada con esta línea.';
    }
    else if(error.code === 'INVALID_PHONE_NUMBER') {
      detail = 'El número de teléfono del contacto es inválido.';
    }
    return detail;
  }

  const startChat = async (showDialog) => {
    setShowStartingChatDialog(showDialog === true);
    const templatePayload = getTemplatePayload();
    const response = await ChatService.startChat(lineId, id, templatePayload);
    if(response.success) {
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        `Se inició la conversación con ${contact.name ?? `~ ${contact.alias}`}.`
      );
      history.replace(MENU.ADMIN.CHAT.ROOT.PATH);
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        getErrorDetail(response.error)
      );
    }
    setShowStartingChatDialog(false);
  }

  const handleStartChatButtonClick = async () => {
    const userStatus = authentication.user?.status;
    if(userStatus !== 'AWAY') {
      await startChat(true);
    }
    else {
      setShowAwayStatusDialog(true);
    }
  }

  const getIsValidVariables = (payload) => {
    const {header, body, buttons} = payload;
    if(header !== undefined) {
      if(header.type === 'TEXT') {
        for(const value of Object.values(header?.payload ?? {})) {
          if((value?.trim() ?? '').length === 0) {
            return false;
          }
        }
      }
      else {
        const idValue = header?.payload?.id?.trim() ?? '';
        const urlValue = header?.payload?.url?.trim() ?? '';
        if(idValue.length === 0 && urlValue.length === 0) {
          return false;
        }
      }
    }
    for(const value of Object.values(body ?? {})) {
      if((value?.trim() ?? '').length === 0) {
        return false;
      }
    }
    for(const [index, payload] of Object.entries(buttons ?? {})) {
      for(const [key, value] of Object.entries(payload ?? {})) {
        if((value?.trim() ?? '').length === 0) {
          return false;
        }
      }
    }
    return true;
  }

  const handleVariablesChange = (event) => {
    setVariables(event);
    setIsValidVariables(getIsValidVariables(event));
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/> 
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (contact) &&
                <>
                  <NeoGridContainer>
                    <NeoTitleSecond
                      icon={Icono}
                      title={`Iniciar conversación con ${contact.name ?? `~ ${contact.alias}`}`}
                      subtitle={`${contact.name ?? `~ ${contact.alias}`} (${contact.phone})`}
                    />
                  </NeoGridContainer>
                  <StartConversationLine
                    lineId={lineId}
                    onLineIdChange={(event) => setLineId(event)}
                  />
                  <StartConversationTemplate
                    lineId={lineId}
                    template={template}
                    translation={translation}
                    onTemplateChange={(event) => setTemplate(event)}
                    onTranslationChange={(event) => setTranslation(event)}
                  />
                  <StartConversationTemplateVariables
                    contact={contact}
                    variablesMapping={variablesMapping}
                    template={template}
                    translation={translation}
                    onValidChange={(event) => setIsValidVariablesMapping(event)}
                    onVariablesChange={handleVariablesChange}
                    onVariablesMappingChange={(event) => setVariablesMapping(event)}
                  />
                  <NeoButtonSection align='right'>
                    <NeoButtonMain
                      label='Iniciar conversación'
                      disabled={lineId === undefined || template === undefined || translation === undefined || isValidVariablesMapping === false || isValidVariables === false}
                      onClick={handleStartChatButtonClick}
                    />
                  </NeoButtonSection>
                  {
                    <ActionDialog
                      header='Cambio de estatus'
                      visible={showAwayStatusDialog}
                      visibleSetter={setShowAwayStatusDialog}
                      onAccept={async () => await startChat(false)}
                      acceptMessage={`Iniciando conversación con ${contact.name ?? `~ ${contact.alias}`}`}
                    >
                      Actualmente estás ausente, al iniciar la conversación tu estatus cambiará a disponible.
                    </ActionDialog>
                  }
                  {
                    <LoadingDialog
                      visible={showStartingChatDialog}
                      message={`Iniciando conversación con ${contact.name ?? `~ ${contact.alias}`}`}
                    />
                  }
                </>
              }
              {
                (!contact) &&
                <ResourceNotFound 
                  title='El contacto no existe'
                  redirection={{label: 'Ir a contactos', path: MENU.ADMIN.CONTACTS.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}