import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import AvatarContact from 'views/chat/components/items/AvatarContact.component';
import ContactConversationItemInfo from 'views/chat/components/items/ContactConversationItemInfo.component';
import ContactConversationItemDetail from 'views/chat/components/items/ContactConversationItemDetail.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} name Name of contact
 * @property {string} alias Alias of contact
 * @property {string} phone Phone of contact
 * @property {string} lastMessageSentAt Datetime of last message
 * @property {Number} newMessages Count of new messages
 * @property {boolean} lastMessageWasSentByContact Define if last message was sent by contact
 * @property {boolean} isReassigned Define if conversation was reassigned
 * @property {boolean} sendingMessage Define if a message is being sent
 * @property {boolean} lastMessageIsViewed Define if last message was viewed by user
 * @property {boolean} isExpired Define if conversation is expired
 * @property {boolean} lastMessageIsReceived Define if last message was received
 * @property {boolean} timeElapsedLastMessage Time beetween lastMessageSentAt and now
 * @property {string} color Class of conversation
 * @property {boolean} showAvatar Define if show avatar icon
 * @property {boolean} showVirgulilla Define if show virgulilla on alias
 * @property {boolean} showAliasWithName Define if show alias with name
 */

const Component = {
  name: 'ContactConversationItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const name = props.isLoading ? '' : (props?.name ?? '?');

      const showAvatar = props.showAvatar ?? true;
      const icon = showAvatar && (props.isLoading || name) && <AvatarContact isLoading={props.isLoading} name={name} phone={props?.phone}/>;
      
      const info = (props.isLoading || (props?.name || props?.phone || props?.alias)) && <ContactConversationItemInfo isLoading={props.isLoading} name={props.name} phone={props.phone } alias={props.alias} showVirgulilla={props.showVirgulilla} showAliasWithName={props.showAliasWithName}/>;
      const detail = (props.isLoading || props?.lastMessageSentAt) && 
        <ContactConversationItemDetail 
          isLoading={props.isLoading} 
          lastMessageSentAt={props?.lastMessageSentAt}
          newMessages={props?.newMessages}
          lastMessageWasSentByContact={props?.lastMessageWasSentByContact}
          isReassigned={props?.isReassigned}
          sendingMessage={props?.sendingMessage}
          lastMessageIsViewed={props?.lastMessageIsViewed}
          isExpired={props?.isExpired}
          lastMessageIsReceived={props?.lastMessageIsReceived}
          timeElapsedLastMessage={props?.timeElapsedLastMessage}
        />;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
          {info}
          {detail}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  alias: PropTypes.string,
  phone: PropTypes.string,
  lastMessageSentAt: PropTypes.string,
  newMessages: PropTypes.number,
  lastMessageWasSentByContact: PropTypes.bool,
  isReassigned: PropTypes.bool,
  sendingMessage: PropTypes.bool,
  lastMessageIsViewed: PropTypes.bool,
  isExpired: PropTypes.bool,
  lastMessageIsReceived: PropTypes.bool,
  timeElapsedLastMessage: PropTypes.string,
  color:  PropTypes.string,
  showAvatar: PropTypes.bool,
  showVirgulilla: PropTypes.bool,
  showAliasWithName: PropTypes.bool
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  name: null,
  alias: null,
  phone: null,
  lastMessageSentAt: null,
  newMessages: null,
  lastMessageWasSentByContact: null,
  isReassigned: null,
  sendingMessage: null,
  lastMessageIsViewed: null,
  isExpired: null,
  lastMessageIsReceived: null,
  timeElapsedLastMessage: null,
  color: null,
  showAvatar: null,
  showVirgulilla: null,
  showAliasWithName: null
};

export default Component.element;