import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import DataFetchError from 'components/DataFetchError.component';
import ResourceNotFound from 'components/ResourceNotFound.component';
import TemplatePreviewWithVariablesForm from 'views/templates/components/TemplatePreviewWithVariablesForm.component';
import TemplateDetailHeader from 'views/templates/components/TemplateDetailHeader.component';
import TemplatesService from 'services/templates.service';
import TEMPLATES from 'consts/templates.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoDataSecond from 'design/design_components/neo/data/NeoDataSecond.base';
import templatesUtil from 'utils/templates.util';

export default function TemplateDetail() {
  const { id } = useParams();
  const asyncState = useAsyncState();
  const [lineId, setLineId] = useState(null);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState();
  const [typeOfUse, setTypeOfUse] = useState([]);
  const [translations, setTranslations] = useState();
  const [selectedTranslation, setSelectedTranslation] = useState();

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getTemplateTranslations()],
      { initialization: true }
    );
  }

  const getTemplateTranslations = async () => {
    const [lineId, name] = id.split('-');
    const response = await TemplatesService.getTemplates(lineId, { name });
    if (response.success) {
      if (response.payload.length > 0) {
        setLineId(lineId);
        setName(name);
        setCategory(response.payload[0].category);
        setTypeOfUse(response.payload[0].typeOfUse ?? []);
        setTranslations(response.payload);
        setSelectedTranslation(response.payload[0]);
      }
      return { success: true };
    }
    if (response.error.internal) {
      return { success: false };
    }
    return { success: true };
  }

  const getTypeOfUse = () => {
    return typeOfUse.map((type) => templatesUtil.getTemplateTypeOfUse(type))?.join(' / ')
  }

  const getTypeOfButtons = () => {
    if(selectedTranslation !== undefined) {
      const {structure} = selectedTranslation;
      const buttons = structure.buttons ?? [];
      if(buttons.length > 0) {
        const buttonType = buttons[0].type;
        if(buttonType === 'QUICK_REPLY') {
          return 'Respuestas rápidas';
        }
        return 'Acciones';
      }
      return 'Ninguno';
    }
    return 'Desconocido';
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (lineId && name) &&
                <>
                  <TemplateDetailHeader
                    lineId={lineId}
                    template={{name, ...selectedTranslation}}
                    typeOfUse={typeOfUse}
                    onTypeOfUseChange={(updatedTypeOfUse) => setTypeOfUse(updatedTypeOfUse)}
                  />
                  <NeoGridContainer>
                    <NeoColumn col='12'>
                      <NeoCard>
                        <NeoInnerTitle extra='primero'>Datos generales</NeoInnerTitle>
                        <NeoGridContainer col='12'>
                          <NeoColumn col='12' md="6">
                            <NeoDataSecond
                              label='Categoría'
                              fact={templatesUtil.getTemplateCategory(category)}
                            />
                          </NeoColumn>
                          <NeoColumn col='12' md="6">
                            <NeoDataSecond
                              label='Tipo de uso'
                              fact={getTypeOfUse()}
                            />
                          </NeoColumn>
                        </NeoGridContainer>
                      </NeoCard>
                      <NeoCard>
                        <NeoInnerTitle extra='primero'>Detalle de traducción</NeoInnerTitle>
                        <NeoGridContainer col='12'>
                          <NeoColumn col='12' md={selectedTranslation?.structure?.buttons?.length > 0 ? '4' : '6'}>
                            <NeoDropdown
                              label='Idiomas disponibles'
                              value={selectedTranslation}
                              options={translations.map((translation) => ({ label: TEMPLATES.LANGUAGES[translation.language], value: translation }))}
                              onChange={(event) => setSelectedTranslation(event.value)}
                            />
                          </NeoColumn>
                          <NeoColumn col='12' md={selectedTranslation?.structure?.buttons?.length > 0 ? '3' : '6'}>
                            <NeoDataSecond
                              label='Estatus de traducción'
                              fact={templatesUtil.getTemplateStatus(selectedTranslation.status)}
                            />
                          </NeoColumn>
                          {
                            (selectedTranslation?.structure?.buttons?.length > 0) &&
                            <NeoColumn col='12' md="5">
                              <NeoDataSecond
                                label='Tipo de botones'
                                fact={getTypeOfButtons()}
                              />
                            </NeoColumn>
                          }
                        </NeoGridContainer>
                      </NeoCard>
                    </NeoColumn>
                    <TemplatePreviewWithVariablesForm template={selectedTranslation} />
                  </NeoGridContainer>
                </>
              }
              {
                (!lineId || !name) &&
                <ResourceNotFound
                  title='La plantilla no existe'
                  redirection={{ label: 'Ir a plantillas', path: MENU.ADMIN.TEMPLATES.ROOT.PATH }}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize} />
          }
        </>
      }
    </>
  );
}