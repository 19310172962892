import {Route} from 'react-router-dom';
import NeoMainContainer from 'design/design_components/neo/layout/NeoMainContainer.base';

export default function RouteComponent(props) {

  const route = (<Route exact={props.exact === true} path={props.path} component={props.component}/>);

  if(props.container === true) {
    return (
      <NeoMainContainer>
        {route}
      </NeoMainContainer>
    );
  }
  return (route);
}