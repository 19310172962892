import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoDataMain from 'design/design_components/neo/data/NeoDataMain.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoPieChartCustom from 'design/design_components/neo/chart/NeoPieChartCustom.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';

const colors = {
  byName: ['green', 'blue', 'yellow', 'gray'],
  byCode: ['#49ad48', '#45ABEC', '#FDE432', '#BAC3C3']
};

export default function CampaignInitialTemplateStatus(props) {
  const [messagesStatus, setMessagesStatus] = useState({});
  const [messagesTotal, setMessagesTotal] = useState(0);

  useEffect(() => reboot(), [props.data]);

	const reboot = () => {
    const pending = props.data?.pending ?? 0;
    const sent = props.data?.sent ?? 0;
    const delivered = props.data?.delivered ?? 0;
    const read = props.data?.read ?? 0;
    setMessagesStatus([
      {name: 'Leídos', messages: read, messagestatus: 'read'},
      {name: 'Enviados y recibidos', messages: delivered, messagestatus: 'delivered'},
      {name: 'Enviados y no recibidos', messages: sent, messagestatus: 'sent'},
      {name: 'Por enviar', messages: pending, messagestatus: 'pending'}
    ]);
    setMessagesTotal(pending + sent + delivered + read);
	}

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Estatus de mensaje inicial de campaña</NeoInnerTitle>
      {
        (props.loading) &&
        <InternalSpinner/>
      }
      {
        (!props.loading) &&
        <>
          {
            (props.successfully) &&
            <NeoGridContainer>
              {
                (messagesTotal > 0) &&
                <NeoPieChartCustom
                  data={messagesStatus}
                  colors={colors}
                  dataKey='messages'
                  dataName='name'
                  dataStatus='messagestatus'
                  colorsCharByKey='byCode'
                  colorsLabelByKey='byName'
                  formatNumber={true}
                  contractNumber={true}
                  noChartTitle={true}
                />
              }
              <NeoColumn col='12'>
                <NeoDataMain
                  label='Total de mensajes'
                  fact={`${messagesTotal}`}
                  extra="p-text-center"
                />
              </NeoColumn>
            </NeoGridContainer>
          }
          {
            (!props.successfully) &&
            <DataFetchError internal align='start' onRetry={props.onRetry}/>
          }
        </>
      }
    </NeoCard>
  );
}