import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} name Name of contact
 * @property {string} phone Phone of contact
 */

const Component = {
  name: 'AvatarContact',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const getColor = (phone) => {
      const digit = String(phone).replace(/\D/g, "").split("").reduce((digit, number) => {
          return Number(digit) + Number(number);
      }, 0);
      
      if(String(digit).length === 1) return digit;
      return getColor(digit);
    }

    const createBody = () => {
      const firstLetterOfEachWord = props?.name.split(/\s/).reduce((response, word) => `${response}${word.slice(0,1)}`, '');
      const icon = firstLetterOfEachWord && <div className={`${Component.name}__body__icon ${Component.name}__body__icon--light`}>{ObjectUtils.getJSXElement(firstLetterOfEachWord, props)}</div>;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const color = `bg-color-${getColor(props.phone)}`;
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, color, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  name: null,
  phone: null
};

export default Component.element;