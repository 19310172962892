import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import MultimediaFilesUploadTable from 'views/multimedia/components/MultimediaFilesUploadTable.component';
import Icono from 'design/assets/img/cta/icons/icon-multimedia.svg';

export default function CreateMultimediaView() {
  return (
    <>
      <NeoTitleSecond 
        icon={Icono} 
        title='Agregar multimedia' 
        subtitle='Carga de archivos'
      />
      <MultimediaFilesUploadTable/>
    </>
  );
}