import NeoDialog from 'design/design_components/neo/overlay/NeoDialog.base';
import LoadingMessage from 'components/LoadingMessage.component';

export default function LoadingDialog(props) {
  return (
    <NeoDialog
      visible={props.visible}
      closable={false}
    >
      <LoadingMessage
        message={props.message}
        progress={props.progress}
        showValue={props.showValue}
      />
    </NeoDialog>
  );
}