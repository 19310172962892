import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import dateTimeUtil from 'utils/dateTime.util';
import LinesService from 'services/lines.service';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';
import MENU from 'consts/menu.consts';

export default function GeneralCampaignDetails(props) {
  const history = useHistory();
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(props.campaign);
  const [line, setLine] = useState();

  useEffect(async () => await initialize(), []);
  useEffect(() => setCampaign(props.campaign), [props.campaign]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLine()],
      { initialization: true }
    );
  }

  const getLine = async () => {
    const response = await LinesService.getLine(campaign.lineId);
    if (response.success) {
      setLine(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  const getStatus = (status) => {
    return status == 'PENDING' ? 'Pendiente'
      : status == 'SENDING' ? 'Enviando'
        : status == 'FINISHED' ? 'Finalizada'
          : status == 'CANCELLED' ? 'Cancelada'
            : status == 'STOPPED' ? 'Detenida'
              : status == 'FAILED' ? 'Fallida'
                : 'Desconocido';
  }

  const handleRecurrentCampaignButtonClick = () => {
    const { lineId, name } = campaign;
    history.push(`${MENU.ADMIN.DASHBOARD.RECURRENT_CAMPAIGN_DETAIL.BASE}/${lineId}-${name}`);
  }

  const elements = {
    recurrentCampaignLink: (
      <NeoButtonText
        label={campaign.name}
        icon='pi pi-arrow-up-right'
        iconPos='right'
        onClick={handleRecurrentCampaignButtonClick}
        extra='recurrent-campaign-link no-underline'
      />
    )
  };

  return (
    <NeoCard>
      {
        (asyncState.isLoading) &&
        <InternalSpinner />
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <NeoInnerTitle extra='primero'>Información general</NeoInnerTitle>
              <NeoGridContainer subExtra='mx-0' col='12'>
                {
                  (campaign.isRecurrent === true) &&
                  <>

                    <NeoColumn md={campaign.sendingName === undefined ? '12' : '7'}>
                      <NeoDataThird
                        extra='reverse'
                        label='Detalle de campaña recurrente'
                        fact={elements.recurrentCampaignLink}
                      />
                    </NeoColumn>

                    {
                      (campaign.sendingName) &&

                      <NeoColumn md="5">
                        <NeoDataThird
                          extra='reverse'
                          label='Nombre del envío'
                          fact={campaign.sendingName}
                        />
                      </NeoColumn>

                    }
                    {
                      (campaign.sendingDetail) &&

                      <NeoColumn md="12">
                        <NeoDataThird
                          extra='reverse'
                          label='Detalle del envío'
                          fact={campaign.sendingDetail}
                        />
                      </NeoColumn>

                    }
                  </>
                }
                <NeoColumn md='7'>
                  <NeoDataThird
                    extra='reverse'
                    label='Linea de envío'
                    fact={`${line.name} | ${line.phone}`}
                  />
                </NeoColumn>
                <NeoColumn md='5'>
                  <NeoDataThird
                    extra='reverse'
                    label='Plantilla inicial'
                    fact={campaign?.template?.name ?? campaign?.flow?.name}
                  />
                </NeoColumn>
                <NeoColumn md='7'>
                  <NeoDataThird
                    extra='reverse'
                    label='Estatus de campaña'
                    fact={getStatus(campaign.status)}
                  />
                </NeoColumn>
                <NeoColumn md='5'>
                  <NeoDataThird
                    extra='reverse'
                    label='Fecha de creación'
                    fact={dateTimeUtil.getDateString(campaign.createdAt, { dateStyle: 'medium', timeStyle: 'short' })}
                  />
                </NeoColumn>
                {
                  ((campaign.status == 'PENDING' || campaign.status == 'CANCELLED') && campaign.scheduledAt) &&
                  <NeoColumn md='7'>
                    <NeoDataThird
                      extra='reverse'
                      label='Fecha de envío programado'
                      fact={dateTimeUtil.getDateString(campaign.scheduledAt, { dateStyle: 'medium', timeStyle: 'short' })}
                    />
                  </NeoColumn>
                }
                {
                  (campaign.status != 'PENDING' && campaign.status != 'CANCELLED' && campaign.startedAt) &&
                  <NeoColumn md='7'>
                    <NeoDataThird
                      extra='reverse'
                      label='Fecha de envío'
                      fact={dateTimeUtil.getDateString(campaign.startedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                    />
                  </NeoColumn>
                }
                {
                  (campaign.status == 'FINISHED' && campaign.finishedAt) &&
                  <NeoColumn md='5'>
                    <NeoDataThird
                      extra='reverse'
                      label='Fecha de finalización'
                      fact={dateTimeUtil.getDateString(campaign.finishedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                    />
                  </NeoColumn>
                }
                {
                  (campaign.status == 'CANCELLED' && campaign.cancelledAt) &&
                  <NeoColumn md='5'>
                    <NeoDataThird
                      extra='reverse'
                      label='Fecha de cancelación'
                      fact={dateTimeUtil.getDateString(campaign.cancelledAt, { dateStyle: 'medium', timeStyle: 'short' })}
                    />
                  </NeoColumn>
                }
                {
                  (campaign.status == 'STOPPED' && campaign.stoppedAt) &&
                  <NeoColumn md='7'>
                    <NeoDataThird
                      extra='reverse'
                      label='Fecha de detención'
                      fact={dateTimeUtil.getDateString(campaign.stoppedAt, { dateStyle: 'medium', timeStyle: 'short' })}
                    />
                  </NeoColumn>
                }
              </NeoGridContainer>
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError internal align='start' onRetry={initialize} />
          }
        </>
      }
    </NeoCard>
  );
}