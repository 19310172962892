import { useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import stringUtil from 'utils/string.util';

const footerTypesOptions = [
  { label: 'Ninguno', value: 'NONE' },
  { label: 'Texto', value: 'TEXT' }
];

export default function TemplateFooterStructureForm(props) {
  const [state] = useStateParams();
  const [selectedType, setSelectedType] = useState();
  const [footerText, setFooterText] = useState('');
  const [variableCount, setVariableCount] = useState(0);
  const [footerTextError, setFooterTextError] = useState('');

  useEffect(() => initialize(), []);
  useEffect(() => handleChange(), [selectedType, footerText]);

  const initialize = () => {
    setSelectedType(state.footer ? 'TEXT' : 'NONE');
    setFooterText(state.footer ?? '');
  }

  const handleChange = () => {
    const event = {valid: true, footer: undefined};
    if (selectedType == 'TEXT') {
      const containsEmojis = stringUtil.containsEmojis(footerText);
      event.footer = footerText;
      const params = footerText.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
      event.valid = (footerText.length > 0 && params.length === 0 && !containsEmojis);
      setVariableCount(params.length);
      setFooterTextError(
        params.length > 0 ? 'El pie de página no puede contener variables.'
        : containsEmojis ? 'El pie de página no puede contener emojis.'
        : ''
      );
    }
    props.onChange(event);
  }

  const handleFooterTextInputChange = (event) => {
    const footerText = event.value ?? event.target.value;
    setFooterText(footerText);
  }

  return (
    <NeoCard>
      <NeoInnerSubtitle >Pie de página</NeoInnerSubtitle>
      <NeoDropdown
        label="Tipo de pie de página"
        value={selectedType}
        options={footerTypesOptions}
        onChange={(event) => setSelectedType(event.value)}
      />
      {
        (selectedType == 'TEXT') &&
        <>
          <NeoInputText
            col="12"
            md="9"
            label='Texto del pie de página'
            rightIcon={<InfoTooltip id='footer' body='El pie de página no soporta el uso de variables.' />}
            maxlength={60}
            value={footerText}
            error={footerTextError}
            onChange={handleFooterTextInputChange}
          />
          <NeoFlexContainer jc="end">
            <fiv className="p-pt-0 p-mr-3">
              <NeoDataThird extra="p-mt-0 text-align-right" fact={variableCount + "/0"} label="Variables" />
            </fiv>
            <fiv className="p-pt-0" col="12">
              <NeoDataThird extra="p-mt-0 text-align-right" fact={footerText.length + "/60"} label="Caracteres" />
            </fiv>
          </NeoFlexContainer>
        </>
      }
    </NeoCard>
  );
}