import { useState, useEffect, useMemo } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import useComponent from 'hooks/useComponent.hook';

const ContactExtraFileNameFieldsForm = (props) => {
  const component = useComponent({state: {changed: {}}});
  const [files, setFiles] = useState(Array(CONTACTS.DATA.EXTRA_FILENAME_FIELDS).fill('').map((_, index) => {
    const field = `fileName${index + 1}`;
    return props.contact ? (props.contact[field] ?? '') : '';
  }));

  useEffect(() => component.setState((state) => {state.changed = {}}), [props.contact]);
  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [files]);

  const render = useMemo(() => {
    if(props.showOnlyFills) {
      return files.reduce((render, value, index) => {
        return render === true ? true : (value.length > 0 || component.state.changed[`fileName${index + 1}`])
      }, false);
    }
    return true;
  }, [props.showOnlyFills, files, component.state.changed]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = files.map((file, index) => {
      const field = `fileName${index + 1}`;
      event.payload[field] = file;
      return props.contact && file !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleFileInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedFiles = [...files];
    updatedFiles[index] = value;
    setFiles(updatedFiles);
  }

  return (<>
  {render &&
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de nombre de archivo</NeoInnerTitle>
      {
        files.map((file, index) => (
          (!props.showOnlyFills || file.length > 0 || component.state.changed[`fileName${index + 1}`]) &&
            <NeoInputText
              key={index}
              md={props?.elements?.fileName?.md}
              col="12"
              label={`Nombre de archivo ${index + 1}`}
              value={file}
              onChange={(event) => handleFileInputChange(index, event)}
              onFocus={() => {component.setState((state) => {state.changed[`fileName${index + 1}`] = true})}}
            />
        ))
      }
    </NeoCard>
  }</>);
}

ContactExtraFileNameFieldsForm.defaultProps = {
  elements: {
    fileName: {
      md: '4'
    }
  },
  showOnlyFills: false
}

export default ContactExtraFileNameFieldsForm;