import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import AgentConversationItemIcon from 'views/chat/components/items/AgentConversationItemIcon.component';
import AgentConversationItemInfo from 'views/chat/components/items/AgentConversationItemInfo.component';
import AgentConversationItemDetail from 'views/chat/components/items/AgentConversationItemDetail.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} name Name of agent
 * @property {string} email Email of agent
 * @property {string} status Status of agent
 * @property {string} area Area of agent
 * @property {Object} detail Object with details conversation
 */

const Component = {
  name: 'AgentConversationItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const name = props.isLoading ? '' : (props?.name ?? '?');
      const icon = (props.isLoading || (name && props?.status)) && <AgentConversationItemIcon isLoading={props.isLoading} name={name} status={props.status} />;
      const info = (props.isLoading || (props?.name || props?.email || props?.area)) && <AgentConversationItemInfo isLoading={props.isLoading} name={props.name} email={props.email} area={props.area} />;
      const detail = (props.isLoading || props?.detail) && 
        <AgentConversationItemDetail 
          isLoading={props.isLoading} 
          timeout={props.detail?.timeout} 
        />;

      return (
        <div className={`${Component.name}__body`}>
          {icon}
          {info}
          {detail}
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  status: PropTypes.string,
  area: PropTypes.string,
  detail: PropTypes.object
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  name: null,
  email: null,
  status: null,
  area: null,
  detail: null
};

export default Component.element;