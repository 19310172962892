import { useState, useEffect, useMemo } from 'react';
import CONTACTS from 'consts/contacts.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import useComponent from 'hooks/useComponent.hook';

const ContactExtraDateFieldsForm = (props) => {
  const component = useComponent({state: {changed: {}}});
  const [dates, setDates] = useState(Array(CONTACTS.DATA.EXTRA_DATE_FIELDS).fill(null).map((_, index) => {
    const field = `date${index + 1}`;
    if (props.contact && props.contact[field]?.length > 0) {
      const [day, month, year] = props.contact[field].split('/');
      return new Date(`${year}-${month}-${day} 00:00`);
    }
    return null;
  }));

  useEffect(() => component.setState((state) => {state.changed = {}}), [props.contact]);
  useEffect(() => handleChange(), [dates]);

  const render = useMemo(() => {
    if(props.showOnlyFills) {
      return dates.reduce((render, value, index) => {
        return render === true ? true : (Boolean(value) || component.state.changed[`date${index + 1}`])
      }, false);
    }
    return true;
  }, [props.showOnlyFills, dates, component.state.changed]);

  const handleChange = () => {
    const event = { payload: {} };
    const changes = dates.map((date, index) => {
      const field = `date${index + 1}`;
      const dateString = date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : ''
      event.payload[field] = dateString;
      return props.contact && dateString !== props.contact[field];
    });
    if (props.contact) {
      event.change = changes.some((change) => change === true);
    }
    props.onChange(event);
  }

  const handleDateInputChange = (index, event) => {
    const value = event.value ?? event.target.value;
    const updatedDates = [...dates];
    updatedDates[index] = value;
    setDates(updatedDates);
  }

  return (<>
  {render &&
    <NeoCard>
      <NeoInnerTitle extra='primero'>Campos de fecha</NeoInnerTitle>
      {
        dates.map((date, index) => (
          (!props.showOnlyFills || date || component.state.changed[`date${index + 1}`]) &&
            <NeoCalendar
              key={index}
              md={props?.elements?.date?.md}
              col="12"
              label={`Fecha ${index + 1}`}
              value={date}
              dateFormat='dd MM yy'
              monthNavigator={true}
              yearNavigator={true}
              yearRange='1970:2099'
              onChange={(event) => handleDateInputChange(index, event)}
              onFocus={() => {component.setState((state) => {state.changed[`date${index + 1}`] = true})}}
            />
        ))
      }
    </NeoCard>
  }</>);
}

ContactExtraDateFieldsForm.defaultProps = {
  elements: {
    date: {
      md: '4'
    }
  },
  showOnlyFills: false
}

export default ContactExtraDateFieldsForm;