import {useState} from 'react';
import DashboardFilters from 'views/dashboard/components/DashboardFilters.component';
import InvalidPhonesDownloadButton from 'views/dashboard/components/InvalidPhonesDownloadButton.component';
import ContactsStatistics from 'views/dashboard/components/ContactsStatistics.component';

export default function ContactsDashboard() {
  const [filters, setFilters] = useState(null);

  const handleFiltersChange = (event) => {
    setFilters(event);
  }

  const elements = {
    downloadButton: (
      <InvalidPhonesDownloadButton
        loading={filters === null}
        period={filters?.period}
      />
    )
  };

  return (
    <>
      <DashboardFilters 
        title='Estadísticas de contactos'
        linesFilter={false}
        onChange={handleFiltersChange}
        rightComponent={elements.downloadButton}
      />
      {
        (filters) &&
        <ContactsStatistics filters={filters}/>
      }
    </>
  );
}