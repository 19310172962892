import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import CampaignConversationsCount from 'views/dashboard/components/CampaignConversationsCount.component';
import CampaignInitialTemplateStatus from 'views/dashboard/components/CampaignInitialTemplateStatus.component';
import CampaignConversationsErrorsStatus from 'views/dashboard/components/CampaignConversationsErrorsStatus.component';
import CampaignMessagesDetailTable from 'views/dashboard/components/CampaignMessagesDetailTable.component';
import CampaignsService from 'services/campaigns.service';

export default function CampaignStatistics(props) {
  const asyncState = useAsyncState({isInitialized: true});
  const [statistics, setStatistics] = useState();

  useEffect(async () => await initialize(), [props.campaign]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaignStatistics()]
    );
  }

  const getCampaignStatistics = async () => {
    const {lineId, name, recurrentCampaignNumber} = props.campaign;
    const response = await CampaignsService.getCampaignStatistics(lineId, name, {recurrentCampaignNumber});
    if(response.success) {
      setStatistics(response.payload);
      props.onResultsChange(true);
      return {success: true};
    }
    return {success: false};
  }

  return (
    <NeoGridContainer>
      <NeoColumn col='12' md='6'>
        {/* <CampaignConversationsCount
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.messagesStatus}
          onRetry={() => initialize()}
        /> */}
        <CampaignInitialTemplateStatus
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.messagesStatus}
          onRetry={() => initialize()}
        />
        {/* <CampaignConversationsErrorsStatus
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.errorsStatus}
          onRetry={() => initialize()}
        /> */}
      </NeoColumn>
      <NeoColumn col='12' md='6'>
        <CampaignMessagesDetailTable
          loading={asyncState.isLoading}
          successfully={asyncState.isSuccessfully}
          data={statistics?.messagesDetail}
          onRetry={() => initialize()}
        />
      </NeoColumn>
    </NeoGridContainer>
  );
}