import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import { DateTime } from 'luxon';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} event Chat event
 */

const Component = {
  name: 'ChatEvent',
  altName:'ChatBalloon',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const text = props?.event && <div className={`${Component.altName}__body__text`}>
        {props.event?.event.type === 'INITIATED' &&
          <span>Conversación iniciada por {props.event?.event?.payload?.initiatedBy === 'CLIENT' ? 'el cliente' : 'el agente'}</span>
        }
        {props.event?.event.type === 'FINISHED' &&
          <span>Conversación finalizada</span>
        }
        {props.event?.event.type === 'EXPIRED' &&
          <span>Conversación expirada</span>
        }
        {props.event?.event.type === 'CAMPAIGN' &&
          <span>Campaña - {props.event.event?.payload?.campaignId}</span>
        }
        {props.event?.event.type === 'REASSIGNED' && <>
          <p>Reasignación de conversación</p>
          {props.event.event?.payload?.prevUser && <p>por {props.event.event?.payload?.prevUser} </p>}
          <p>"{props.event.event?.payload?.reason}"</p>
        </>}
      </div>;

      return (
        <div className={`${Component.altName}__body`}>
          {text}
        </div>
      );
    };

    const createFooter = () => {
      const date = props?.event?.timestamp && <div className={`${Component.altName}__footer__date`}>
        <span>
          {(() => {
            const now = DateTime.now();
            const datetime = DateTime.fromISO(props.event.timestamp);
            const diff = now.diff(datetime, ['days']).toObject();

            if(diff.days < 1) {
              return datetime.toFormat('h:mm a').toLowerCase();
            } else {
              return datetime.toFormat('dd-MM-yyyy h:mm a').toLowerCase();
            }
          })()}
        </span>
      </div>;

      return (
        <div className={`${Component.altName}__footer`}>
          {date}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.altName, `${Component.altName}--event`, props.className);
    const body = createBody();
    const footer = createFooter();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
        {footer}
      </div>
    );
  })
};

Component.element.displayName = Component.altName;

Component.element.propTypes = {
  id: PropTypes.string,
  event: PropTypes.object,
};

Component.element.defaultProps = {
  __TYPE: Component.altName,
  id: null,
  style: null,
  className: null,
  event: null,
};

export default Component.element;
