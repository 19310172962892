import React from "react";
import BaseButtonMain from "../../sisu/button/BaseButtonMain.sisu"


const NeoButtonSmallSquared = (props) => {
    const { subtitle, ...params } = props;
    
    return (<>
        <BaseButtonMain custom="p-button-rounded squared-button"  {...params} />
    </>);
};

export default NeoButtonSmallSquared;