import {useState, useEffect} from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';

export default function CampaignContactsOriginOptions(props) {
  const [files, setFiles] = useState(props.files ?? []);

  useEffect(() => setFiles(props.files ?? []), [props.files]);

  const handleDeleteButtonClick = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    props.onFilesChange(updatedFiles);
  }

  return (
    <NeoCard>
      {
        (files.length > 0) &&
        <NeoGridContainer col='12'>
          <NeoInnerTitle extra='primero'>Archivos cargados</NeoInnerTitle>
          {
            files.map((file, index) => (
              <NeoColumn md='12'>
                <NeoFlexContainer ai='center' jc='between'>
                  <b>{file.file.name}</b>
                  <NeoButtonOutlined
                    label='Eliminar'
                    onClick={() => handleDeleteButtonClick(index)}
                  />
                </NeoFlexContainer>
              </NeoColumn>
            ))
          }
        </NeoGridContainer>
      }
      {
        (props.isCampaignWithContacts === true) && 
        <>
          <p style={{textAlign: 'center'}}>
            Escogiste incluir contactos desde la base de datos de NeoConecta, podrás seleccionar quienes recibirán esta campaña más adelante.
          </p>
          {
            (files.length === 0) &&
            <p style={{textAlign: 'center'}}>
              Si deseas incluir otros contactos que no estén en la base de datos de NeoConecta, carga archivos .csv.
            </p>
          }
        </>
      }
      {
        (props.isCampaignWithContacts === false && files.length === 0) && 
        <p style={{textAlign: 'center'}}>
          Carga tus archivos .csv de contactos y/o selecciona la opción de utilizar la base de contactos de NeoConecta.
        </p>
      }
    </NeoCard>
  );
}