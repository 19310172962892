import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';
import LinesService from './lines.service';

export default class EmployeesService {

  static async getEmployees() {
    const {success, body, error} = await backendRequestUtil.get('/rest/employees');
    if(success) {
      const employees = body.employees.map((employee) => {
        employee.createdAt = dateTimeUtil.backendDateStringToDate(employee.createdAt);
        return employee;
      });
      return {success, payload: employees};
    }
    return {success, error};
  }

  static async registerEmployees(file) {
    const headers = {'Content-Type': 'text/csv'};
    const {success, body, error} = await backendRequestUtil.post('/rest/employees', file, {headers});
    if(!success && !error.internal) {
      if(/^Cuenta principal no configurada$/i.test(error.message)) {
        error.code = 'NON_EXISTENT_MAIN_ACCOUNT';
      }
      else if(/^La contraseña del empleado [^]+ es inválida$/i.test(error.message)) {
        error.code = 'INVALID_PASSWORD';
      }
    }
    return success ? {success, payload: body.registeredEmployeesCount} : {success, error};
  }

  static async getEmployeeLines() {
    const {success, body, error} = await backendRequestUtil.get('/rest/employeeLines');
    if(success) {
      await Promise.allSettled(body.lines.map(async (line) => {
        const {success, payload} = await LinesService.getLinePhoto(line.id);
        if(success) {
          const blob = new Blob([payload.buffer]);
          const url = (window.URL || window.webkitURL).createObjectURL(blob);
          line.photo = url;
        }
      }));
    }
    return success ? {success, payload: body.lines} : {success, error};
  }

  static async getAssignedChats(lineId) {
    const params = {lineId};
    const {success, body, error} = await backendRequestUtil.get('/rest/assignedChats', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getAgentChats(lineId, agentId) {
    const params = {lineId, agentId};
    const {success, body, error} = await backendRequestUtil.get('/rest/agentChats', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getStatus() {
    const {success, body, error} = await backendRequestUtil.get('/rest/employeeStatus');
    return success ? {success, payload: body.status} : {success, error};
  }

  static async updateStatus(status) {
    const requestBody = {status};
    const {success, body, error} = await backendRequestUtil.put('/rest/employeeStatus', requestBody);
    return success ? {success, payload: body.status} : {success, error};
  }

  static async getAgents(lineId, areaId) {
    const params = {lineId, areaId};
    const {success, body, error} = await backendRequestUtil.get('/rest/agents', {params});
    if(success) {
      const agents = body.agents.map((agent) => {
        if(!agent.hasOwnProperty('status')) {
          agent.status = 'DISCONNECTED';
        }
        return agent;
      });
      return {success, payload: {agents}};
    }
    return {success, error}
  }
}