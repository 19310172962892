import { useEffect, useRef } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from 'hooks/useComponent.hook';
import ConversationsHeader from 'views/chat/components/headers/ConversationsHeader.component';
import ContactConversationsList from 'views/chat/components/lists/ContactConversationsList.component.js';
import NeoSlideMenu from 'design/design_components/neo/menu/NeoSlideMenu.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import useAuthentication from 'hooks/useAuthentication.hook';
import useChat from 'hooks/useChat.hook';

const FILTER_STATUS = {
  ALL: 1,
  NEW_MESSAGES: 2,
  TIME_ELAPSED: 3,
  TIMEOUT: 4,
  REASSIGNED: 5
};

const FILTER_ORDER = {
  LONGER_WAITING_TIME: 1,
  MORE_RECENT: 2
};

export default function ConversationsSection(props) {
  const chat = useChat();
  const authentication = useAuthentication();
  const asyncState = useAsyncState();
  const component = useComponent({
    state: {
      transition: '',
      filterStatusActive: Object.values(FILTER_STATUS),
      filterOrderActive: FILTER_ORDER.LONGER_WAITING_TIME
    }
  });

  useEffect(() => {
    setTimeout(() => {
      component.setState((state) => {
        state.transition = "transition";
      })
    }, 1)

    return () => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATION.SELECTED.TYPE,
          payload: {
            conversation: null
          }
        }
      })
    }
  }, []);

  useEffect(() => {
    const conversationsFilteredByLineId = chat.state.conversations.filter((conversation) => {
      return (conversation.lineId === chat.state.lineSelected.id && (chat.state.agentSelected?.id === undefined || chat.state.agentSelected?.id === conversation.userId));
    });

    const conversationsFilteredByStatus = conversationsFilteredByLineId.filter((conversation) => {
      let add = component.state.filterStatusActive.includes(FILTER_STATUS.ALL);

      if(!add && component.state.filterStatusActive.includes(FILTER_STATUS.NEW_MESSAGES)) {
        add = Boolean(conversation?.newMessages);
      }

      if(!add && component.state.filterStatusActive.includes(FILTER_STATUS.TIME_ELAPSED)) {
        add = Boolean(conversation?.timeElapsedLastMessage);
      }

      if(!add && component.state.filterStatusActive.includes(FILTER_STATUS.TIMEOUT)) {
        add = Boolean(conversation?.timeout);
      }

      if(!add && component.state.filterStatusActive.includes(FILTER_STATUS.REASSIGNED)) {
        add = Boolean(conversation?.reassigned);
      }

      return add;
    });

    let conversations = conversationsFilteredByStatus;
    switch (component.state.filterOrderActive) {
      case FILTER_ORDER.LONGER_WAITING_TIME:
        conversations = orderConversationsByUserWaiting(conversationsFilteredByStatus);
        break;
      case FILTER_ORDER.MORE_RECENT:
        conversations = orderConversationsByRecentAsc(conversationsFilteredByStatus);
        break;
    }
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.CONVERSATIONS.ASSIGNED.FILTERED.TYPE,
        payload: {
          conversations
        }
      }
    })

    return () => {
      chat.dispatch((TYPE) => {
        return {
          type: TYPE.CONVERSATIONS.ASSIGNED.FILTERED.TYPE,
          payload: {
            conversations: []
          }
        }
      })
    }
  }, [chat.state.lineSelected.id, chat.state.agentSelected?.id, chat.state.conversations, component.state.filterStatusActive, component.state.filterOrderActive])

  const orderConversationsByUserWaiting = (conversations) => {
    const newArrayOfConversations = [];

    const conversationsWithMessageUser  = orderConversationsByRecentAsc(conversations.filter((conversation) => {
      if(!conversation?.lastMessageWasSentByContact) {
        newArrayOfConversations.push(conversation);
      }
      return conversation?.lastMessageWasSentByContact;
    })).reverse();

    newArrayOfConversations.unshift(...conversationsWithMessageUser);
    return newArrayOfConversations;
  }

  const orderConversationsByRecentAsc = (conversations) => {
    return [...conversations].sort((conversationA, conversationB) => {
      return new Date(conversationB.lastMessageSentAt) - new Date(conversationA.lastMessageSentAt);
    });
  }

  const FilterStatus = () => {
    const menu = useRef(null);
    const filterStatusesArray = (status) => {
      if(component.state.filterStatusActive.includes(status)) {
        component.setState((state) => {
          state.filterStatusActive = state.filterStatusActive.filter(filter => filter !== status && filter !== FILTER_STATUS.ALL);
        })
      } else {
        component.setState((state) => {
          state.filterStatusActive = state.filterStatusActive.filter(filter => filter !== FILTER_STATUS.ALL).concat([status]);
        })
      }
    }

    const items = [
      {
        label: 'Todas mis conversaciones',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.ALL) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          if(component.state.filterStatusActive.includes(FILTER_STATUS.ALL)) {
            component.setState((state) => {
              state.filterStatusActive = state.filterStatusActive.filter(f => f !== FILTER_STATUS.ALL);
            })
          } else {
            component.setState((state) => {
              state.filterStatusActive = Object.values(FILTER_STATUS);
            })
          }
        }
      },
      {
        label: 'Con mensajes nuevos',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.NEW_MESSAGES) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          filterStatusesArray(FILTER_STATUS.NEW_MESSAGES);
        }
      },
      {
        label: 'Tiempo de respuesta excedido',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.TIME_ELAPSED) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          filterStatusesArray(FILTER_STATUS.TIME_ELAPSED);
        }
      },
      {
        label: 'Finalizado automáticamente',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.TIMEOUT) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          filterStatusesArray(FILTER_STATUS.TIMEOUT);
        }
      },
      {
        label: 'Desasignada de mi',
        icon: component.state.filterStatusActive.includes(FILTER_STATUS.REASSIGNED) ? 'fa-regular fa-square-check' : 'fa-regular fa-square',
        command: () => {
          filterStatusesArray(FILTER_STATUS.REASSIGNED);
        }
      }
    ];

    return <>
      <NeoSlideMenu ref={menu} model={items} popup className='FilterStatus'/>
      <NeoButtonMain type="button" icon="pi pi-filter" onClick={(event) => menu.current.toggle(event)} />
    </>
  }
  
  const FilterOrder = () => {
    const menu = useRef(null);
    const items = [
      {
        label: 'Cliente con más tiempo de espera',
        icon: component.state.filterOrderActive === FILTER_ORDER.LONGER_WAITING_TIME ? 'pi pi-circle-on' : 'pi pi-circle-off',
        command: () => {
          component.setState((state) => {
            state.filterOrderActive = FILTER_ORDER.LONGER_WAITING_TIME;
          })
        }
      },
      {
        label: 'Mensaje más reciente',
        icon: component.state.filterOrderActive === FILTER_ORDER.MORE_RECENT ? 'pi pi-circle-on' : 'pi pi-circle-off',
        command: () => {
          component.setState((state) => {
            state.filterOrderActive = FILTER_ORDER.MORE_RECENT;
          })
        }
      }
    ];

    return <>
      <NeoSlideMenu ref={menu} model={items} popup className='FilterOrder'/>
      <NeoButtonMain type="button" icon="pi pi-sort-amount-down" onClick={(event) => menu.current.toggle(event)} />
    </>
  }

  return (
    <div className={`ConversationsSection ${component.state.transition}`}>
      <ConversationsHeader
        isLoading={asyncState.isLoading}
        line={chat.state.lineSelected}
        agent={chat.state.agentSelected}
        filters={[FilterStatus, FilterOrder]}
      />

      <ContactConversationsList
        elements={props?.elements?.ContactConversationsList}
        isLoading={asyncState.isLoading}
        conversations={chat.state.conversationsFiltered}
        conversationSelected={chat.state.conversationSelected}
        onConversationSelect={(conversation) => {
          if(conversation !== 'UNASSIGNED_CONVERSATIONS') {
            chat.dispatch((TYPE) => {
              return {
                type: TYPE.CONVERSATION.SELECTED.TYPE,
                payload: {
                  conversation
                }
              }
            })
            if(conversation?.isExpired || conversation?.isReassigned) {
              if(conversation?.isExpired) {
                chat.dispatch((TYPE) => {
                  return {
                    type: TYPE.CONVERSATIONS.ASSIGNED.FINISH.TYPE,
                    payload: {
                      conversation
                    }
                  }
                })
              }
              else if(conversation?.isReassigned) {
                chat.dispatch((TYPE) => {
                  return {
                    type: TYPE.CONVERSATIONS.ASSIGNED.REASSIGNED.TYPE,
                    payload: {
                      conversation
                    }
                  }
                })
              }
              if(authentication.user.role === 'AGENT') {
                if(conversation?.isExpired) {
                  authentication.socket.emit('expiredConversationRead', {
                    line: chat.state.lineSelected,
                    user: authentication.user,
                    conversation
                  });
                } else if(conversation?.isReassigned) {
                  authentication.socket.emit('reassignedConversationRead', {
                    line: chat.state.lineSelected,
                    user: authentication.user,
                    conversation
                  });
                }
              }
            }
          }
        }}
      />
    </div>
  );
}