import { classNames } from 'primereact/utils';
import CalendarBody from './CalendarBody.component';
import CalendarHeader from './CalendarHeader.component';

export default function Calendar(props) {
    const {
        calendar,
        isWeekly = false,
        replies,
        showNumber = false,
        actions,
    } = props;
    return (
        <div
            className={classNames('calendar', {
                'calendar--monthly': !isWeekly,
                'calendar--weekly': isWeekly,
            })}
        >
            <CalendarHeader calendar={calendar} showNumber={showNumber} />
            <CalendarBody
                isWeekly={isWeekly}
                calendar={calendar}
                replies={replies}
                actions={actions}
            />
        </div>
    );
}
