import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import { DateTime } from 'luxon';
import NeoTooltip from 'design/design_components/neo/overlay/NeoTooltip.base';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {boolean} isLoading Define whether to show lazy loading
 * @property {string} lastMessageSentAt Datetime of last message
 * @property {Number} newMessages Count of new messages
 * @property {boolean} lastMessageWasSentByContact Define if last message was sent by contact
 * @property {boolean} isReassigned Define if conversation was reassigned
 * @property {boolean} sendingMessage Define if a message is being sent
 * @property {boolean} lastMessageIsViewed Define if last message was viewed by user
 * @property {boolean} isExpired Define if conversation is expired
 * @property {boolean} lastMessageIsReceived Define if last message was received
 * @property {boolean} timeElapsedLastMessage Time beetween lastMessageSentAt and now
 */

const Component = {
  name: 'ContactConversationItemDetail',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      
      const dateLastMessageToFormat = () => {
        const now = DateTime.now();
        const lastMessageSentAt = DateTime.fromISO(props.lastMessageSentAt);
        const diff = now.diff(lastMessageSentAt, ['days']).toObject();

        if(diff.days < 1) {
          return lastMessageSentAt.toFormat('h:mm a').toLowerCase();
        } else if(diff.days > 1 && diff.days < 2) {
          return 'Ayer';
        } else {
          return lastMessageSentAt.toFormat('dd-MM-yyyy');
        }
      }
      

      const lastMessageSentAt = props?.lastMessageSentAt && <div className={`${Component.name}__body__dateLastMessage`}>{ObjectUtils.getJSXElement(dateLastMessageToFormat, props)}</div>;
      const timeElapsedLastMessage = props?.timeElapsedLastMessage && (
        <div className={`${Component.name}__body__timeElapsedLastMessage`}>
          {ObjectUtils.getJSXElement(props.timeElapsedLastMessage, props)}
          <NeoTooltip
                target={`.${Component.name}__body__timeElapsedLastMessage`}
                position='bottom'
          >
            Esta conversación ha estado desatendida por demasiado tiempo
          </NeoTooltip>
        </div>
      );
      const newMessages = Boolean(props?.newMessages) && <div className={`${Component.name}__body__newMessages`}>{ObjectUtils.getJSXElement(props.newMessages, props)}</div>;
      const reassigned = props?.isReassigned && (
        <div className={`${Component.name}__body__reassigned`}>
          <i className="pi pi-bell" style={{'fontSize': '.7em'}}></i>
          <NeoTooltip
                target={`.${Component.name}__body__reassigned`}
                position='bottom'
          >
            La conversación ha sido reasignada a otro agente
          </NeoTooltip>
        </div>
      );
      const sendingMessage = props?.sendingMessage && (
        <div className={`${Component.name}__body__sendingMessage`}>
          <i className="pi pi-clock" style={{'fontSize': '.7em'}}></i>
        </div>
      );
      const expired = props?.isExpired && (
        <div className={`${Component.name}__body__timeout`}>
          <i className="pi pi-info" style={{'fontSize': '.6em'}}></i>
          <NeoTooltip
                target={`.${Component.name}__body__timeout`}
                position='bottom'
          >
            Esta conversación se ha terminado por llegar al tiempo límite de 24 horas
          </NeoTooltip>
        </div>
      );
      const lastMessageIsViewed = props?.lastMessageIsViewed && (
        <div className={`${Component.name}__body__lastMessageIsViewed`}>
          <i className="fa-solid fa-check-double"></i>
        </div>
      );
      const lastMessageIsReceived = props?.lastMessageIsReceived && (
        <div className={`${Component.name}__body__lastMessageIsReceived`}>
          <i className="fa-solid fa-check-double"></i>
        </div>
      );

      return (
        <div className={`${Component.name}__body`}>
          <div>
          {lastMessageSentAt}
          </div>
          <div className={`${Component.name}__body__notifications`}>
          {timeElapsedLastMessage}
          {newMessages}
          {reassigned}
          {sendingMessage}
          {expired}
          {lastMessageIsViewed}
          {lastMessageIsReceived}
          </div>
        </div>
      );
    };

    const lazy = props.isLoading ? `${Component.name}--lazy` : '';
    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className, lazy);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  lastMessageSentAt: PropTypes.string,
  newMessages: PropTypes.number,
  lastMessageWasSentByContact: PropTypes.bool,
  isReassigned: PropTypes.bool,
  sendingMessage: PropTypes.bool,
  lastMessageIsViewed: PropTypes.bool,
  isExpired: PropTypes.bool,
  lastMessageIsReceived: PropTypes.bool,
  timeElapsedLastMessage: PropTypes.string
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  isLoading: null,
  lastMessageSentAt: null,
  newMessages: null,
  lastMessageWasSentByContact: null,
  isReassigned: null,
  sendingMessage: null,
  lastMessageIsViewed: null,
  isExpired: null,
  lastMessageIsReceived: null,
  timeElapsedLastMessage: null
};

export default Component.element;