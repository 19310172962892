import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import TemplateVariableMapping from 'views/campaigns/components/TemplateVariableMapping.component';

const UNMAPPED = '';

export default function TemplateButtonsMapping(props) {
  const [variables, setVariables] = useState(() => {
    const requiredVariables = props.requiredVariables?.map((variable) => {
      const index = variable.index;
      const label = variable.param;
      const key = `buttons.${variable.index + 1}.url.${variable.param.substring(2, variable.param.length - 2)}`;
      const map = props.mapping[key] ?? UNMAPPED;
      return {index, label, key, map};
    });
    return requiredVariables ?? [];
  });

  useEffect(() => handleMappingChange(), [props.mapping]);

  const handleMappingChange = () => {
    const updatedVariables = variables.map((variable) => {
      const map = props.mapping[variable.key] ?? UNMAPPED;
      return {...variable, map};
    });
    setVariables(updatedVariables);
  }

  const handleVariableMappingChange = (event, index) => {
    const updatedVariables = [...variables];
    updatedVariables[index].map = event;
    const mapping = Object.fromEntries(updatedVariables.map(({key, map}) => [key, (map !== UNMAPPED ? map : undefined)]));
    props.onChange(mapping);
  }

  return (
    <>
      {
        variables.map((variable, index) => (
          <>
            <NeoInnerSubtitle>Botón {variable.index + 1}</NeoInnerSubtitle>
            <TemplateVariableMapping
              variable={variable.label}
              mapping={variable.map}
              databaseType={props.databaseType}
              mappingOptions={props.mappingOptions}
              onMappingChange={(event) => handleVariableMappingChange(event, index)}
            />
          </>
        ))
      }
    </>
  );
}