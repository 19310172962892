import LinesSection from 'views/chat/components/sections/LinesSection.component';
import AgentsSection from 'views/chat/components/sections/AgentsSection.component';
import ConversationsSection from 'views/chat/components/sections/ConversationsSection.component';
import ConversationsUnassignedSection from 'views/chat/components/sections/ConversationsUnassignedSection.component';
import ChatSection from 'views/chat/components/sections/ChatSection.component';
import useChat from 'hooks/useChat.hook';
import useComponent from 'hooks/useComponent.hook';

export default function SupervisorChatView() {
  const chat = useChat();
  const component = useComponent({
    state: {
      sectionToShow: 'AGENTS'
    }
  });

  const render = {
    conversationInfo: {
      actions: {
        finalize: {
          isRender: false
        }
      }
    },
    conversation: {
      addMessage: {
        isRender: false,
      }
    }
  }

  return (
    <div className='Chat SupervisorChat'>
      <LinesSection />
      {
        (chat.state.lineSelected) &&
        <AgentsSection 
          onAgentSelect={(agent) => {
            if(agent === 'UNASSIGNED_CONVERSATIONS') {
              component.setState((state) => {state.sectionToShow = 'UNASSIGNED_CONVERSATIONS'});
              chat.dispatch((TYPE) => {
                return {
                  type: TYPE.AGENT.SELECTED.TYPE,
                  payload: {
                    agent: null
                  }
                }
              })
            } else {
              component.setState((state) => {state.sectionToShow = 'AGENTS'})
            }
          }}
          activeUnassignedConversations={component.state.sectionToShow === 'UNASSIGNED_CONVERSATIONS'}
        />
      }
      {
        (chat.state.agentSelected && component.state.sectionToShow === 'AGENTS') &&
        <ConversationsSection
          elements={{
            ContactConversationsList: {
              footer: {
                show: false
              }
            }
          }}
        />
      }
      {(component.state.sectionToShow === 'UNASSIGNED_CONVERSATIONS') &&
        <ConversationsUnassignedSection/>
      }
      {
        (chat.state.conversationSelected) && 
        <ChatSection
          render={render}
        />
      }
    </div>
  );
}