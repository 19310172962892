import {useState, useEffect} from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import CsvFileExplorer from 'views/campaigns/components/CsvFileExplorer.component';
import NeoInputSwitch from 'design/design_components/neo/form/NeoInputSwitch.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';

export default function CampaignContactsOriginOptions(props) {
  const [state] = useStateParams();
  const [isCampaignWithContacts, setIsCampaignWithContacts] = useState(state?.isCampaignWithContacts ?? false);

  useEffect(() => props.onIsCampaignWithContactsChange(isCampaignWithContacts), [isCampaignWithContacts]);

  return (
    <NeoCard>
      <NeoInnerTitle extra='primero'>Opciones de origen de contactos</NeoInnerTitle>
      <NeoGridContainer col='12'>
        <NeoColumn md='12'>
          <CsvFileExplorer onChange={(event) => props.onFilesAdd(event)}/>
        </NeoColumn>
        <NeoColumn md='12'>
          <NeoInputSwitch
            label='Quiero enviar a algunos contactos de mi base en NeoConecta'
            checked={isCampaignWithContacts}
            onChange={(event) => setIsCampaignWithContacts(event.value)}
          />
        </NeoColumn>
      </NeoGridContainer>
    </NeoCard>
  );
}