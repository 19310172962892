import {useState, useEffect} from 'react';
import useAuthentication from 'hooks/useAuthentication.hook';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import AdminRouter from 'routers/Admin.router';
import UnknownRouter from 'routers/Unknown.router';
import UnauthenticatedRouter from 'routers/Unauthenticated.router';
import { ChatContextProvider } from 'contexts/chat.context';
import NeoDialogo from 'design/design_components/neo/overlay/NeoDialog.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import AgentRouter from 'routers/Agent.router';
import SupervisorRouter from 'routers/Supervisor.router';


export default function MainRouter() {
  const authentication = useAuthentication();
  const [isAuthenticated, setIsAuthenticated] = useState();

  useEffect(async () => reboot(), [authentication.isAuthenticated()]);

  const reboot = async () => {
    const updatedIsAuthenticated = authentication.isAuthenticated();
    setIsAuthenticated(updatedIsAuthenticated);
  }

  if(isAuthenticated === true) {
    if(authentication.user.role === 'ADMIN') {
      return (<AdminRouter/>);
    }
    if(authentication.user.role === 'AGENT' || authentication.user.role === 'SUPERVISOR') {
      return <ChatContextProvider>
        {authentication.user.role === 'AGENT' 
          ? <AgentRouter/>
          : <SupervisorRouter/>
        }
      </ChatContextProvider>
    }

    return (<UnknownRouter/>);
  }
  if(isAuthenticated === false) {
    return (<UnauthenticatedRouter/>);
  }
  return (<>
    {(authentication.statusSocket === 'DISCONNECTED') &&
      <NeoDialogo
        custom="socket-status"
        visible={true}
        closable={false}
        header="Error de conexión"
        footer={<>
          <NeoButtonMain
            label="Recargar"
            onClick={() => {window.location.reload()}}
          />
        </>}
      >
        <p>Se ha perdido la conexión con el servidor, por favor intenta recargar la página</p>
      </NeoDialogo>
    }
    {(authentication.statusSocket === 'CONNECTING') &&
      <NeoSpinner/>
    }
  </>);
}