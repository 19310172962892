import CalendarDay from './CalendarDay.component';

export default function CalendarHeader(props) {
    const { calendar } = props;
    const days = [
        { name: '', number: '' },
        ...Array.from({ length: 7 }, (_, i) => {
            const date = calendar.startOf('week').plus({ days: i });
            return { name: date.weekdayShort, number: date.get('day') };
        }),
    ];

    return (
        <div className='calendar__header'>
            {days.map((day) => (
                <CalendarDay
                    key={`${day.name}-${day.number}`}
                    name={day.name}
                    number={day.number}
                    showNumber={props.showNumber}
                />
            ))}
        </div>
    );
}
