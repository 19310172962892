import NeoInputFile from 'design/design_components/neo/form/NeoInputFile.base';
import csvUtil from 'utils/csv.util';

const types = [
  'text/csv',
  'application/vnd.ms-excel'
];

export default function EmployeesCsvFileExplorer(props) {

  const handleFileInputChange = async (event) => {
    const csvFiles = event.files.filter((file) => types.includes(file.type));
    const [csvFile] = csvFiles;
    event.options.clear();
    if(csvFile !== undefined) {
      const buffer = Buffer.from(await csvFile.arrayBuffer());
      const worksheet = await csvUtil.readFromBuffer(buffer);
      props.onChange({file: csvFile, worksheet});
    }
  }

  return (
    <NeoInputFile
      auto
      customUpload
      multiple={false}
      accept='.csv'
      chooseLabel='Carga archivo .csv'
      emptyTemplate={<p>O arrástralo aquí</p>}
      uploadHandler={handleFileInputChange}
    />
  );
}