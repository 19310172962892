export default function CalendarMessageListItem(props) {
    const { reply, actions } = props;
    return (
        <li
            className='message-list__item'
            onClick={() => {
                actions.onAutomaticReplyClick(reply);
                actions.onModifyClick();
            }}
        >
            <span
                className='dot'
                style={{ backgroundColor: reply.backgroundColor ?? '#9AC987' }}
            />
            <p className='text-ellipsis'>{reply.title}</p>
        </li>
    );
}
