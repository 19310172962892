import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import MultimediaList from 'views/multimedia/components/MultimediaList.component';
import Icono from 'design/assets/img/cta/icons/icon-multimedia.svg';

export default function Multimedia() {
  const history = useHistory();

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title='Multimedia' icon={Icono} />
        <NeoButtonSection align='right' >
          <NeoButtonMain
            icon="pi pi-plus"
            label='Agregar multimedia'
            onClick={() => history.push(MENU.ADMIN.MULTIMEDIA.CREATE_MULTIMEDIA.PATH)}
          />
        </NeoButtonSection>
      </NeoGridContainer>
      <MultimediaList />
    </>
  );
}