export default {
  DATA: {
    BASIC: {
      PHONE: {
        FIELD: 'phone',
        LABEL: 'Número de teléfono'
      },
      ALIAS: {
        FIELD: 'alias',
        LABEL: 'Alias'
      },
      FIRST_NAME: {
        FIELD: 'firstName',
        LABEL: 'Nombre(s)'
      },
      LAST_NAME: {
        FIELD: 'lastName',
        LABEL: 'Apellido(s)'
      }
    },
    EXTRA: {
      GENDER: {
        FIELD: 'gender',
        LABEL: 'Género'
      },
      TYPE: {
        FIELD: 'type',
        LABEL: 'Tipo'
      },
      COMPANY: {
        FIELD: 'company',
        LABEL: 'Empresa'
      },
      COUNTRY: {
        FIELD: 'country',
        LABEL: 'País'
      },
      ADDRESS: {
        FIELD: 'address',
        LABEL: 'Domicilio'
      },
      POSTAL_CODE: {
        FIELD: 'postalCode',
        LABEL: 'Código postal'
      },
      EMAIL: {
        FIELD: 'email',
        LABEL: 'Correo electrónico'
      },
      ADDRESS: {
        FIELD: 'userId',
        LABEL: 'ID usuario'
      }
    },
    EXTRA_TEXT_FIELDS: 6,
    EXTRA_NUMBER_FIELDS: 6,
    EXTRA_DATE_FIELDS: 6,
    EXTRA_TOKEN_FIELDS: 3,
    EXTRA_FILENAME_FIELDS: 3
  },
  STATUSES: {
    VALID: 'Válido',
    INVALID: 'Inválido',
    NOT_VALIDATED: 'Sin validar'
  }
};