import reactStringReplace from 'react-string-replace';
import NeoButtonWhatsapp from 'design/design_components/neo/button/NeoButtonWhatsapp.base';
import templatesUtil from 'utils/templates.util';
import NeoTooltip from 'design/design_components/neo/overlay/NeoTooltip.base';

export default function MessageBalloonPreview(props) {
  return (
    <div
      className={`wa-balloon ${props.isSender === false ? 'wa-receiver' : 'wa-sender'}`}
    >
      {
        (props.isSender === true) &&
        <span className='wa-svg-balloon-container-sender'>
          <svg viewBox="0 0 8 13" width="8" height="13" className="">
            <path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
            <path fill="#FFFFFF" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
          </svg>
        </span>
      }
      {
        (props.isSender === false) &&
        <span className='wa-svg-balloon-container-receiver'>
          <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.13" d="M6.46733 3.52372L-3.39338e-07 12V1H5.18827C6.95836 1 7.52638 2.13607 6.46733 3.52372Z" fill="black" />
            <path d="M6.46733 2.52372L-3.39338e-07 11V0H5.18827C6.95836 0 7.52638 1.13607 6.46733 2.52372Z" fill="#E4FFCA" />
          </svg>
        </span>
      }
      {
        (props.type === 'TEMPLATE') &&
        <TemplateContent
          internal={props?.internal}
          trackingURL={props?.trackingURL}
          content={props.content}
          onAutoReplyClick={props.onAutoReplyClick}
        />
      }
      {
        (props.type === 'TEXT') &&
        <TextContent content={props.content} />
      }
    </div>
  );
}

function TemplateContent(props) {
  const structure = props.content?.structure;

  const getMappedInternalBodyTemplate = (text) => {
    if(props?.internal?.parameters) {
      const internalParamsBody = props.internal.parameters?.body;
      if(Array.isArray(internalParamsBody)) {

        return reactStringReplace(text, /(\{\{[_0-9a-zA-Z]+\}\})/g, (match, i) => {
          const parameter = internalParamsBody.find(p => String(p.index) === match.replace(/[{}]/g, ''));
          if(parameter) {
            if(props?.trackingURL) {
              const value = parameter?.urlTracked ?? 'https://neo.red/abcdefghi123456789';
              return <>
              <span
                className={`parameter-${parameter.index}`}
              >
                <b>{value}</b>
              </span>
              <NeoTooltip
                target={`.parameter-${parameter.index}`}
                position='top'
                mouseTrack={true}
                mouseTrackTop={10}
              >
                <div><b>Link original</b></div>
                <div><i>{parameter.value}</i></div>
              </NeoTooltip>
            </>
            } else {
              return parameter.value;
            }
          }

          return match;
        });
      }
    }

    return text;
  }

  return (
    <>
      {
        (structure?.header && structure?.header?.type) &&
        <p>
          {
            (structure?.header?.type == 'TEXT') &&
            <span className='wa-header-title'>{structure?.header?.text}</span>
          }
          {
            (structure?.header?.type != 'TEXT') &&
            <>{structure?.header?.document?.filename ?? templatesUtil.getTemplateHeaderType(structure?.header?.type)}</>
          }
        </p>
      }
      {
        structure?.body?.split('\n').map((text, index) => (
          text.length == 0
            ? <br />
            : <p key={index}>{getMappedInternalBodyTemplate(text)}</p>
        ))
      }
      {
        (structure?.footer) &&
        <span className='wa-footer'>{structure?.footer}</span>
      }
      {
        (structure?.buttons?.length > 0) &&
        <div className='wa-buttons'>
          {
            structure?.buttons?.map((button, index) => (
              <NeoButtonWhatsapp
                key={index}
                label={button.text}
                onClick={() => button.type == 'QUICK_REPLY' && props.onAutoReplyClick(button.text)}
              />
            ))
          }
        </div>
      }
    </>
  );
}

function TextContent(props) {
  return (
    <>{props.content}</>
  );
}