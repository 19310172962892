import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class ContactsService {

  static async getContacts(page = 1, limit = 50, options = {}) {
    const params = {page, limit, ...options};
    const {success, body, error} = await backendRequestUtil.get('/api/contacts', {params});
    if(success) {
      const contacts = body.contacts.map((contact) => {
        contact.createdAt = dateTimeUtil.backendDateStringToDate(contact.createdAt);
        contact.updatedAt = dateTimeUtil.backendDateStringToDate(contact.updatedAt);
        return contact;
      });
      return {success, payload: contacts, cursor: body.cursor};
    }
    return {success, error};
  }

  static async getContact(contactId) {
    const params = {contactId};
    const {success, body, error} = await backendRequestUtil.get('/api/contact', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async createContact(phone, name, extraFields = {}) {
    const requestBody = {phone, name, ...extraFields};
    const {success, error} = await backendRequestUtil.post('/api/contact', requestBody);
    if(!success && !error.internal) {
      if(/[^]+ teléfono inválido/i.test(error.message)) {
        error.code = 'INVALID_PHONE_NUMBER';
      }
      if(/^[^]+ ya se encuentra asociado a un contacto$/i.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
    }
    return success ? {success} : {success, error};
  }
  
  static async updateContact(contactId, fields = {}) {
    const params = {contactId};
    const requestBody = {...fields};
    const {success, error} = await backendRequestUtil.put('/api/contact', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async getContactsStatistics(fromDateString, toDateString) {
    const params = {
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/contactsStatistics', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getInvalidPhonesDetail(fromDateString, toDateString) {
    const params = {
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/invalidPhonesDetail', {params});
    return success ? {success, payload: body.invalidPhonesDetail} : {success, error};
  }

  static async getInvalidPhonesDetailFile(fromDateString, toDateString) {
    const params = {
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/invalidPhonesDetailFile', {params});
    return success ? {success, payload: body} : {success, error};
  }

}