import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import CampaignTestSending from 'views/campaigns/components/CampaignTestSending.component';
import CampaignTemplatePreview from 'views/campaigns/components/CampaignTemplatePreview.component';
import CampaignSchedulingDialog from 'views/campaigns/components/CampaignSchedulingDialog.component';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import ActionDialog from 'components/ActionDialog.component';
import CampaignsService from 'services/campaigns.service';

export default function CampaignPreviewAndTesting() {
  const history = useHistory();
  const messages = useMessages();
  const [state, setState] = useStateParams();
  const [campaign, setCampaign] = useState({});
  const [showCampaignStartDialog, setShowCampaignStartDialog] = useState(false);
  const [showCampaignSchedulingDialog, setShowCampaignSchedulingDialog] = useState(false);
  const [randomIndex, setRandomIndex] = useState(0);

  useEffect(() => initialize(), []);

  const initialize = () => {
    if(state?.flow === undefined) {
      history.replace(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
    setCampaign(getCampaignPayload());
  }

  const getSanitizedFlow = () => {
    const {name, language, sendingType, replyButtons} = state.flow;
    const flow = {name, language};
    if(sendingType !== undefined) { flow.sendingType = sendingType; }
    if(replyButtons !== undefined) {
      flow.replyButtons = {};
      for(const [text, replyButton] of Object.entries(replyButtons)) {
        const replyButtonPayload = {};
        if(replyButton.action !== undefined) { replyButtonPayload.action = replyButton.action; }
        if(replyButton.autoReply !== undefined) {
          if(replyButton.autoReply.type === 'TEMPLATE') {
            const {name, language} = replyButton.autoReply.template;
            replyButtonPayload.autoReply = {type: 'TEMPLATE', template: {name, language}};
          }
          if(replyButton.autoReply.type === 'TEXT') {
            replyButtonPayload.autoReply = {...replyButton.autoReply};
          }
        }
        flow.replyButtons[text] = replyButtonPayload;
      }
    }
    return flow;
  }

  const getCampaignPayload = () => {
    const payload = {
      name: state.name,
      lineId: state.line?.id,
      flow: getSanitizedFlow(),
      isDynamic: state.isDynamic,
      isRecurrent: state.isRecurrent,
      sendingName: state.sendingName,
      sendingDetail: state.sendingDetail
    };
    if(state.mainRecurrentDynamicCampaign !== undefined) { payload.mainRecurrentDynamicCampaign = state.mainRecurrentDynamicCampaign; }
    if(state.files?.length > 0) { payload.files = state.files; }
    if(state.contacts !== undefined) { payload.contacts = state.contacts; }
    return payload;
  }

  const handleCampaignStartDialogAccept = async () => {
    const response = await CampaignsService.createCampaign(campaign);
    return response;
  }

  const getErrorDetail = (error, options = {}) => {
    let detail = `No se pudo ${options.scheduling === true ? 'programar' : 'iniciar'} la campaña. `;
    if (error.internal === true) {
      detail += 'Ocurrió un error interno, inténtalo de nuevo.';
    }
    else if (error.code === 'NON_EXISTENT_TEMPLATE') {
      detail += 'La plantilla seleccionada no existe.';
    }
    else if (error.code === 'UNAPPROVED_TEMPLATE') {
      detail += 'La plantilla seleccionada no se encuentra aprobada.';
    }
    else if (error.code === 'ALREADY_EXIST') {
      detail += 'La campaña ya existe.';
    }
    else if (error.code === 'PARAMETER_TO_IS_NOT_MAPPED') {
      const { file } = error;
      detail += `Error en mapeo de variable de número de teléfono en archivo ${file}.`;
    }
    else if (error.code === 'MISSING_HEADER_IN_FILE') {
      const { header, file } = error;
      detail += `Error en mapeo de columna ${header} en archivo ${file}.`;
    }
    else if (error.code === 'DIFFERENT_PARAMETER_TYPE_IN_HEADER_CSV') {
      detail += 'Error en mapeo de parámetros de encabezado.';
    }
    else if (error.code === 'TEMPLATE_STRUCTURE') {
      const { file } = error;
      detail += `Error en estructura de plantilla en archivo ${file}.`;
    }
    return detail;
  }

  const handleCampaignStartDialogHide = (event) => {
    if (event.action == 'accept') {
      if (event.payload.success) {
        history.push(`${MENU.ADMIN.CAMPAIGNS.ROOT.PATH}?line=${state.line.id}`);
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Se inició la campaña.'
        );
      }
      else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          getErrorDetail(event.payload.error)
        );
      }
    }
  }

  const handleCampaignSchedulingDialogHide = (event) => {
    if (event.success == true) {
      history.push(`${MENU.ADMIN.CAMPAIGNS.ROOT.PATH}?line=${state.line.id}`);
      messages.showToast(
        TOAST.SEVERITY.SUCCESS,
        'Operación exitosa',
        'Se programó la campaña.'
      );
    }
    else {
      messages.showToast(
        TOAST.SEVERITY.ERROR,
        'Algo salió mal',
        getErrorDetail(event.error, { scheduling: true })
      );
    }
  }

  return (
    <>
      {
        (state?.flow !== undefined) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond
              icon={Icono}
              title='Crear campaña'
              subtitle='Previsualización y pruebas'
            />
          </NeoGridContainer>
          <NeoGridContainer>
            <NeoColumn md='6'>
              <CampaignTestSending
                line={state.line}
                campaign={campaign}
                mapping={state.testMapping[randomIndex]}
              />
            </NeoColumn>
            <NeoColumn md='6'>
              <CampaignTemplatePreview
                randomIndex={randomIndex}
                flow={state.flow}
                isDynamicCampaign={state?.isDynamic === true}
                testMapping={state.testMapping}
                onRandomIndexChange={(event) => setRandomIndex(event)}
              />
            </NeoColumn>
          </NeoGridContainer>
          <NeoButtonSection align='right'>
            <NeoButtonMain
              label='Programar campaña'
              icon="pi pi-clock"
              onClick={() => setShowCampaignSchedulingDialog(true)}
            />
            <NeoButtonMain
              icon="pi pi-play"
              label='Iniciar campaña ahora'
              onClick={() => setShowCampaignStartDialog(true)}
            />
          </NeoButtonSection>
          {
            <CampaignSchedulingDialog
              visible={showCampaignSchedulingDialog}
              visibleSetter={setShowCampaignSchedulingDialog}
              campaign={campaign}
              onHide={handleCampaignSchedulingDialogHide}
            />
          }
          {
            <ActionDialog
              header='Iniciar campaña ahora'
              visible={showCampaignStartDialog}
              visibleSetter={setShowCampaignStartDialog}
              acceptMessage='Iniciando campaña'
              onAccept={handleCampaignStartDialogAccept}
              onHide={handleCampaignStartDialogHide}
            >
              Se iniciará el envío de la campaña. ¿Deseas proceder?
            </ActionDialog>
          }
        </>
      }
    </>
  );
}