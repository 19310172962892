import { useContext } from 'react';
import ChatContext from 'contexts/chat.context';
import { DISPATCH } from 'consts/chat.consts';

const useChat = () => {
  const {state, dispatch} = useContext(ChatContext);

  /**
   * This callback type is called `eventCallback` and is displayed as a global symbol.
   *
   * @callback dispatchCallback
   * @param {DISPATCH} TYPE
   */

  /**
   * Does something asynchronously and executes the callback on completion.
   * @param {dispatchCallback} dispatchCallback - The callback that handles the response.
   */
  const customDispatch = (dispatchCallback) => {
    if(typeof dispatchCallback === "function") {
      const {type, payload} = dispatchCallback(DISPATCH);
      if(typeof type === "string") {
        return dispatch({type, payload});
      }
      return null;
    }
  };

  return {
    state,
    dispatch: customDispatch
  }
}

export default useChat;