export const DISPATCH = {
  USER: {
    SET: {
      TYPE: "USER_SET_TYPE"
    }
  },
  LINES: {
    ASSIGNED: {
      TYPE: "LINES_ASSIGNED_TYPE"
    }
  },
  LINE: {
    SELECTED: {
      TYPE: "LINE_SELECTED_TYPE"
    }
  },
  AGENTS: {
    ASSIGNED: {
      TYPE: "AGENTS_ASSIGNED_TYPE",
      UPDATE: {
        TYPE: "AGENTS_ASSIGNED_UPDATE_TYPE"
      },
      FILTERED: {
        TYPE: "AGENTS_ASSIGNED_FILTERED_TYPE"
      }
    }
  },
  AGENT: {
    SELECTED: {
      TYPE: "AGENT_SELECTED_TYPE"
    }
  },
  CONVERSATIONS: {
    ASSIGNED: {
      TYPE: "CONVERSATIONS_ASSIGNED_TYPE",
      UPSERT: {
        TYPE: "CONVERSATIONS_ASSIGNED_UPSERT_TYPE"
      },
      UPDATE: {
        TYPE: "CONVERSATIONS_ASSIGNED_UPDATE_TYPE"
      },
      REASSIGNED: {
        TYPE: "CONVERSATIONS_ASSIGNED_REASSIGNED_TYPE"
      },
      FINISH: {
        TYPE: "CONVERSATIONS_ASSIGNED_FINISH_TYPE"
      },
      TIME_ELAPSED_CHECK: {
        TYPE: "CONVERSATIONS_ASSIGNED_TIME_ELAPSED_CHECK_TYPE"
      },
      FILTERED: {
        TYPE: "CONVERSATIONS_ASSIGNED_FILTERED_TYPE"
      }
    },
    UNASSIGNED: {
      TYPE: "CONVERSATIONS_UNASSIGNED_TYPE",
      UPSERT: {
        TYPE: "CONVERSATIONS_UNASSIGNED_UPSERT_TYPE",
      },
      TIME_ELAPSED_CHECK: {
        TYPE: "CONVERSATIONS_UNASSIGNED_TIME_ELAPSED_CHECK_TYPE"
      },
      FILTERED: {
        TYPE: "CONVERSATIONS_UNASSIGNED_FILTERED_TYPE",
      }
    }
  },
  CONVERSATION: {
    SELECTED: {
      TYPE: "CONVERSATION_SELECTED_TYPE",
      CONTENT: {
        TYPE: "CONVERSATION_SELECTED_CONTENT_TYPE",
        GET: {
          TYPE: "CONVERSATION_SELECTED_CONTENT_GET_TYPE",
        },
        UPSERT: {
          TYPE: "CONVERSATION_SELECTED_CONTENT_UPSERT_TYPE"
        },
        FILTERED: {
          TYPE: "CONVERSATION_SELECTED_CONTENT_FILTERED_TYPE"
        }
      }
    }
  },
  CONTACT: {
    UPDATE: {
      TYPE: "CONTACT_UPDATE_TYPE"
    },
  },
  QUICKLY_REPLIES: {
    ASSIGNED: {
      TYPE: "QUICKLY_REPLIES_ASSIGNED_TYPE",
      FILTERED: {
        TYPE: "QUICKLY_REPLIES_ASSIGNED_FILTERED_TYPE"
      }
    }
  }
};