import { useState, useEffect, useMemo,  } from 'react';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import useComponent from 'hooks/useComponent.hook';

const ExtraContactDataForm = (props) => {
  const component = useComponent({state: {changed: {}}});
  const [gender, setGender] = useState(props.contact?.gender ?? '');
  const [type, setType] = useState(props.contact?.type ?? '');
  const [company, setCompany] = useState(props.contact?.company ?? '');
  const [country, setCountry] = useState(props.contact?.country ?? '');
  const [address, setAddress] = useState(props.contact?.address ?? '');
  const [postalCode, setPostalCode] = useState(props.contact?.postalCode ?? '');
  const [email, setEmail] = useState(props.contact?.email ?? '');
  const [userId, setUserId] = useState(props.contact?.userId ?? '');

  useEffect(() => component.setState((state) => {state.changed = {}}), [props.contact]);
  useEffect(() => handleChange(), [props.contact]);
  useEffect(() => handleChange(), [gender, type, company, country, address, postalCode, email, userId]);

  const render = useMemo(() => {
    if(props.showOnlyFills) {
      if((gender.length === 0 && !component.state.changed.gender)
        && (type.length === 0 && !component.state.changed.type)
        && (company.length === 0 && !component.state.changed.company)
        && (country.length === 0 && !component.state.changed.country)
        && (address.length === 0 && !component.state.changed.address)
        && (postalCode.length === 0 && !component.state.changed.postalCode)
        && (email.length === 0 && !component.state.changed.email)
        && (userId.length === 0 && !component.state.changed.userId)
      ) {
        return false;
      }
    }
    return true;
  }, [props.showOnlyFills, gender, type, company, country, address, postalCode, email, userId, component.state.changed]);

  const handleChange = () => {
    const event = { payload: { gender, type, company, country, address, postalCode, email, userId } };
    if (props.contact) {
      event.change = gender !== props.contact.gender
        || type !== props.contact.type
        || company !== props.contact.company
        || country !== props.contact.country
        || address !== props.contact.address
        || postalCode !== props.contact.postalCode
        || email !== props.contact.email
        || userId !== props.contact.userId;
    }
    props.onChange(event);
  }

  const handleInputChange = (setter, event) => {
    const value = event.value ?? event.target.value;
    setter(value);
  }

  return (<>
  {render &&
    <NeoCard>
      <NeoInnerTitle extra='primero'>Información adicional</NeoInnerTitle>
      {(!props.showOnlyFills || email.length > 0 || component.state.changed.email) &&
        <NeoInputText md={props?.elements?.email?.md} col="12"
          label='Correo electrónico'
          value={email}
          onChange={(event) => handleInputChange(setEmail, event)}
          onFocus={() => {component.setState((state) => {state.changed.email = true})}}
        />
      }
      {(!props.showOnlyFills || userId.length > 0 || component.state.changed.userId) &&
        <NeoInputText md={props?.elements?.userId?.md} col="12"
          label='ID usuario'
          value={userId}
          onChange={(event) => handleInputChange(setUserId, event)}
          onFocus={() => {component.setState((state) => {state.changed.userId = true})}}
        />
      }
      {(!props.showOnlyFills || company.length > 0 || component.state.changed.company) &&
        <NeoInputText md={props?.elements?.company?.md} col="12"
          label='Empresa'
          value={company}
          onChange={(event) => handleInputChange(setCompany, event)}
          onFocus={() => {component.setState((state) => {state.changed.company = true})}}
        />
      }
      {(!props.showOnlyFills || address.length > 0 || component.state.changed.address) &&
        <NeoInputText md={props?.elements?.address?.md} col="12"
          label='Domicilio'
          value={address}
          onChange={(event) => handleInputChange(setAddress, event)}
          onFocus={() => {component.setState((state) => {state.changed.address = true})}}
        />
      }
      {(!props.showOnlyFills || postalCode.length > 0 || component.state.changed.postalCode) &&
        <NeoInputText md={props?.elements?.postalCode?.md} col="12"
          label='Código postal'
          value={postalCode}
          onChange={(event) => handleInputChange(setPostalCode, event)}
          onFocus={() => {component.setState((state) => {state.changed.postalCode = true})}}
        />
      }
      {(!props.showOnlyFills || country.length > 0 || component.state.changed.country) &&
        <NeoInputText md={props?.elements?.country?.md} col="12"
          label='País'
          value={country}
          onChange={(event) => handleInputChange(setCountry, event)}
          onFocus={() => {component.setState((state) => {state.changed.country = true})}}
        />
      }
      {(!props.showOnlyFills || gender.length > 0 || component.state.changed.gender) &&
        <NeoInputText md={props?.elements?.gender?.md} col="12"
          label='Género'
          value={gender}
          onChange={(event) => handleInputChange(setGender, event)}
          onFocus={() => {component.setState((state) => {state.changed.gender = true})}}
        />
      }
      {(!props.showOnlyFills || type.length > 0 || component.state.changed.type) &&
        <NeoInputText md={props?.elements?.type?.md} col="12"
          label='Tipo'
          value={type}
          onChange={(event) => handleInputChange(setType, event)}
          onFocus={() => {component.setState((state) => {state.changed.type = true})}}
        />
      }
    </NeoCard>
  }</>);
}

ExtraContactDataForm.defaultProps = {
  elements: {
    email: {
      md: '4'
    },
    userId: {
      md: '4'
    },
    company: {
      md: '4'
    },
    address: {
      md: '6'
    },
    postalCode: {
      md: '2'
    },
    country: {
      md: '4'
    },
    gender: {
      md: '4'
    },
    type: {
      md: '4'
    },
  },
  showOnlyFills: false
}

export default ExtraContactDataForm;