import { useState, useEffect } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoFlexColumn from 'design/design_components/neo/layout/NeoFlexColumn.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import CampaignsResults from 'views/dashboard/components/CampaignsResults.component';
import CampaignsIncluded from 'views/dashboard/components/CampaignsIncluded.component';
import CampaignsMessagesStatus from 'views/dashboard/components/CampaignsMessagesStatus.component';
import CampaignsTemplatesConsumption from 'views/dashboard/components/CampaignsTemplatesConsumption.component';
import DashboardUpdate from 'views/dashboard/components/DashboardUpdate.component';
import CampaignsService from 'services/campaigns.service';

export default function CampaignsStatistics(props) {
  const asyncState = useAsyncState({ isInitialized: true });
  const [statistics, setStatistics] = useState();

  useEffect(async () => await reboot(), [props.filters]);

  const reboot = async () => {
    await asyncState.allPromises([getCampaignsStatistics()]);
  }

  const getCampaignsStatistics = async () => {
    const linesId = props.filters.lines.map((line) => line.id);
    const fromDateString = props.filters.period.from;
    const toDateString = props.filters.period.to;
    const response = await CampaignsService.getCampaignsStatistics(linesId, fromDateString, toDateString);
    if (response.success) {
      setStatistics(response.payload);
      return { success: true };
    }
    return { success: false };
  }

  return (
    <>
      {
        (props.filters.lines.length > 0 && props.filters.period) &&
        <NeoGridContainer>
          <NeoFlexColumn md='7'>
            <CampaignsResults
              loading={asyncState.isLoading}
              successfully={asyncState.isSuccessfully}
              data={statistics?.result}
              onRetry={() => reboot()}
            />
            <CampaignsMessagesStatus
              loading={asyncState.isLoading}
              successfully={asyncState.isSuccessfully}
              data={statistics?.messagesStatus}
              onRetry={() => reboot()}
            />
          </NeoFlexColumn>
          <NeoFlexColumn md='5'>
            <CampaignsIncluded
              loading={asyncState.isLoading}
              successfully={asyncState.isSuccessfully}
              data={statistics?.campaigns}
              onRetry={() => reboot()}
            />
          </NeoFlexColumn>
          <NeoColumn col='12'>
            <CampaignsTemplatesConsumption
              loading={asyncState.isLoading}
              successfully={asyncState.isSuccessfully}
              lines={props.filters.lines}
              data={statistics?.linesCampaignsTemplates}
              onRetry={() => reboot()}
            />
          </NeoColumn>
          <NeoColumn col='12'>
            <DashboardUpdate
              loading={asyncState.isLoading}
              successfully={asyncState.isSuccessfully}
              data={statistics?.updatedAt}
              onRetry={() => reboot()}
            />
          </NeoColumn>
        </NeoGridContainer>
      }
      {
        (props.filters.lines.length == 0) &&
        <NeoInnerSubtitle>No hay líneas seleccionadas</NeoInnerSubtitle>
      }
    </>
  );
}