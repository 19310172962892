import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

export default function VariablesInfo() {
  return (
    <NeoCard extra="p-mb-0" >
      <NeoInnerTitle extra='primero'>Personaliza lo que dices</NeoInnerTitle>
      <NeoColumn col='12'>
        <p>
          Las plantillas pueden usar variables de los contactos como nombre, correo electrónico, etc.
          Estos datos los puedes tomar de tu lista de contactos o cargar en un .csv al enviarlas.
        </p>
        <NeoFlexContainer extra="px-0">
          <NeoFlexContainer col='5' ai='center' extra="p-py-0 " jc="end">
            <span className='fs-2  p-pr-2'>{'{{variable}}'}</span>
          </NeoFlexContainer>
          <NeoFlexContainer col='7' extra='p-flex-column p-0'>
            <p className=' p-mt-0 p-mb-1'>Para definir el espacio en donde se pondrá una variable en la plantilla usa el formato {'{{variable}}'} en el texto.</p>
            <p className=' p-my-0'>Ejemplo: {'{{apellidos}}'}</p>
          </NeoFlexContainer>
        </NeoFlexContainer>
        <p>
          Cada variable tiene un tamaño reservado de 40 caracteres.
        </p>
      </NeoColumn>
    </NeoCard>
  );
}