import {useState, useEffect} from 'react';
import NeoMultiSelect from 'design/design_components/neo/form/NeoMultiSelect.base';
import ActionDialog from 'components/ActionDialog.component'
import TEMPLATES from 'consts/templates.consts';
import TemplatesService from 'services/templates.service';

const typeOfUseOptions =
  Object.entries(TEMPLATES.TYPE_OF_USE)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({label, value}));

export default function TemplateUsageTypeEditDialog(props) {
  const [typeOfUse, setTypeOfUse] = useState(props.typeOfUse ?? []);

  useEffect(() => resetFrom(), [props.typeOfUse]);

  const resetFrom = () => {
    setTypeOfUse(props.typeOfUse ?? [])
  }

  const updateTemplate = async () => {
    const fields = {typeOfUse};
    const response = await TemplatesService.updateTemplate(props.lineId, props.name, fields);
    if(response.success) {
      return {success: true, payload: typeOfUse};
    }
    return {success: false, internal: true};
  }

  const handleHide = (event) => {
    if(event.action == 'accept') {
      props.onHide(event.payload);
    }
    if(event.action == 'cancel') {
      resetFrom();
    }
  }

  const handleTypeOfUseMultiselectChange = (event) => {
    const typeOfUse = event.value;
    setTypeOfUse(typeOfUse);
  }

  return (
    <ActionDialog
      header='Editar tipo de uso'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Actualizando tipo de uso'
      acceptDisabled={!(typeOfUse?.length > 0) || props.typeOfUse.toString() === typeOfUse.toString()}
      onAccept={updateTemplate}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <span>
        Modifica el uso que se le puede dar a esta plantilla, esto afecta a todas las traducciones.
      </span>
      <NeoMultiSelect
        display='chip'
        label='Tipo de uso de la plantilla'
        value={typeOfUse}
        options={typeOfUseOptions}
        onChange={handleTypeOfUseMultiselectChange}
      />
    </ActionDialog>
  );
}