import {Switch} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import AUTHENTICATION from 'consts/authentication.consts';
import useAuthentication from 'hooks/useAuthentication.hook';
import Route from 'routers/components/Route.component';
import RouterContainer from 'routers/components/RouterContainer.component';
import GeneralDashboard from 'views/dashboard/GeneralDashboard.view';
import CampaignsDashboard from 'views/dashboard/CampaignsDashboard.view';
import RecurrentCampaignDetail from 'views/dashboard/RecurrentCampaignDetail.view';
import ContactsDashboard from 'views/dashboard/ContactsDashboard.view';
import TemplatesList from 'views/templates/TemplatesList.view';
import CampaignDetail from 'views/dashboard/CampaignDetail.view';
import ApiSettings from 'views/apiSettings/ApiSettings.view';
import ApiKeyDetail from 'views/apiSettings/ApiKeyDetail.view';
import CreateTemplate from 'views/templates/CreateTemplate.view';
import TemplatePreview from 'views/templates/TemplatePreview.view';
import TemplateDetail from 'views/templates/TemplateDetail.view';
import Campaigns from 'views/campaigns/Campaigns.view';
import CreateCampaign from 'views/campaigns/CreateCampaign.view';
import CampaignContactsOrigin from 'views/campaigns/CampaignContactsOrigin.view';
import CampaignTemplatesMapping from 'views/campaigns/CampaignTemplatesMapping.view';
import CampaignContacts from 'views/campaigns/CampaignContacts.view';
import CampaignPreviewAndTesting from 'views/campaigns/CampaignPreviewAndTesting.view';
import Settings from 'views/settings/Settings.view';
import Multimedia from 'views/multimedia/Multimedia.view';
import CreateMultimedia from 'views/multimedia/MultimediaFilesUpload.view';
import Contacts from 'views/contacts/Contacts.view';
import CreateContact from 'views/contacts/CreateContact.view';
import ContactDetail from 'views/contacts/ContactDetail.view';
import Lines from 'views/lines/Lines.view';
import LineProfile from 'views/lines/LineDetail.view';
import Employees from 'views/employees/Employees.view';
import MultimediaDemo from 'views/demo/MultimediaDemo.view';
import AutomaticReply from 'views/settings/AutomaticReply.view';

export default function AdminRouter() {
  const authentication = useAuthentication();

  const menu = [
    {
      label: MENU.ADMIN.DASHBOARD.ROOT.LABEL,
      items: [
        {
          label: MENU.ADMIN.DASHBOARD.GENERAL.LABEL,
          redirect: MENU.ADMIN.DASHBOARD.GENERAL.PATH
        },
        {
          label: MENU.ADMIN.DASHBOARD.CAMPAIGNS.LABEL,
          redirect: MENU.ADMIN.DASHBOARD.CAMPAIGNS.PATH
        },
        {
          label: MENU.ADMIN.DASHBOARD.CONTACTS.LABEL,
          redirect: MENU.ADMIN.DASHBOARD.CONTACTS.PATH
        }
      ]
    },
    {
      label: MENU.ADMIN.CONTACTS.ROOT.LABEL,
      redirect: MENU.ADMIN.CONTACTS.ROOT.PATH
    },
    {
      label: MENU.ADMIN.TEMPLATES.ROOT.LABEL,
      redirect: MENU.ADMIN.TEMPLATES.ROOT.PATH
    },
    {
      label: MENU.ADMIN.CAMPAIGNS.ROOT.LABEL,
      redirect: MENU.ADMIN.CAMPAIGNS.ROOT.PATH
    },
    {
      label: MENU.ADMIN.MULTIMEDIA.ROOT.LABEL,
      redirect: MENU.ADMIN.MULTIMEDIA.ROOT.PATH
    },
    {
      label: MENU.ADMIN.LINES.ROOT.LABEL,
      redirect: MENU.ADMIN.LINES.ROOT.PATH
    },
    {
      label: MENU.ADMIN.EMPLOYEES.ROOT.LABEL,
      redirect: MENU.ADMIN.EMPLOYEES.ROOT.PATH
    },
    {
      label: MENU.ADMIN.API.ROOT.LABEL,
      redirect: MENU.ADMIN.API.ROOT.PATH
    },
    {
      label: MENU.ADMIN.SETTINGS.ROOT.LABEL,
      items: [
        {
          label: MENU.ADMIN.SETTINGS.GENERAL.LABEL,
          redirect: MENU.ADMIN.SETTINGS.GENERAL.PATH
        },
        {
          label: MENU.ADMIN.SETTINGS.AUTOMATIC_REPLY.LABEL,
          redirect: MENU.ADMIN.SETTINGS.AUTOMATIC_REPLY.PATH
        }
      ]
    },
    {
      label: authentication.user.email,
      className: 'signout',
      command: async () => {
        await authentication.signOut();
        window.location = `${AUTHENTICATION.LOGOUT_URL}?continue=${AUTHENTICATION.URL}${MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH}`;
      }
    }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.ROOT.PATH} component={Campaigns}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CREATE_CAMPAIGN.PATH} component={CreateCampaign}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS_ORIGIN.PATH} component={CampaignContactsOrigin}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_TEMPLATES_MAPPING.PATH} component={CampaignTemplatesMapping}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS.PATH} component={CampaignContacts}/>
        <Route exact container path={MENU.ADMIN.CAMPAIGNS.CAMPAIGN_PREVIEW_AND_TESTING.PATH} component={CampaignPreviewAndTesting}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.GENERAL.PATH} component={GeneralDashboard}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.ROOT.PATH} component={TemplatesList}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.CREATE_TEMPLATE.PATH} component={CreateTemplate}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.TEMPLATE_PREVIEW.PATH} component={TemplatePreview}/>
        <Route exact container path={MENU.ADMIN.TEMPLATES.TEMPLATE_DETAIL.PATH} component={TemplateDetail}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.CAMPAIGNS.PATH} component={CampaignsDashboard}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.RECURRENT_CAMPAIGN_DETAIL.PATH} component={RecurrentCampaignDetail}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.CAMPAIGN_DETAIL.PATH} component={CampaignDetail}/>
        <Route exact container path={MENU.ADMIN.DASHBOARD.CONTACTS.PATH} component={ContactsDashboard}/>
        <Route exact container path={MENU.ADMIN.API.ROOT.PATH} component={ApiSettings}/>
        <Route exact container path={MENU.ADMIN.API.API_KEY_DETAIL.PATH} component={ApiKeyDetail}/>
        <Route exact container path={MENU.ADMIN.SETTINGS.GENERAL.PATH} component={Settings}/>
        <Route exact container path={MENU.ADMIN.SETTINGS.AUTOMATIC_REPLY.PATH} component={AutomaticReply}/>
        <Route exact container path={MENU.ADMIN.MULTIMEDIA.ROOT.PATH} component={Multimedia}/>
        <Route exact container path={MENU.ADMIN.MULTIMEDIA.CREATE_MULTIMEDIA.PATH} component={CreateMultimedia}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.ROOT.PATH} component={Contacts}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.CREATE_CONTACT.PATH} component={CreateContact}/>
        <Route exact container path={MENU.ADMIN.CONTACTS.CONTACT_DETAIL.PATH} component={ContactDetail}/>
        <Route exact container path={MENU.ADMIN.LINES.ROOT.PATH} component={Lines}/>
        <Route exact container path={MENU.ADMIN.LINES.LINE_PROFILE.PATH} component={LineProfile}/>
        <Route exact container path={MENU.ADMIN.EMPLOYEES.ROOT.PATH} component={Employees}/>
        <Route exact container path='/multimedia-demo' component={MultimediaDemo}/>
      </Switch>
    </RouterContainer>
  );
}
