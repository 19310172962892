import {useState, useEffect} from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import DynamicCampaignButtonActionsForm from 'views/campaigns/components/DynamicCampaignButtonActionsForm.component';

export default function DynamicCampaignButtonsActionsForm(props) {
	const [state, setState] = useStateParams();
	const [hasQuickReplyButtons, setHasQuickReplyButtons] = useState(false);
	const [isDynamic, setIsDynamic] = useState(false);
	const [replyButton1, setReplyButton1] = useState();
	const [replyButton2, setReplyButton2] = useState(); 
	const [replyButton3, setReplyButton3] = useState();

	
	useEffect(() => reboot(), [props.mainTemplate, props.dynamicCampaignConfig]);
	useEffect(() => handleReplyButtonsChange(), [replyButton1, replyButton2, replyButton3]);

	const reboot = () => {
		if(props.mainTemplate?.structure !== undefined && props.dynamicCampaignConfig?.isDynamic !== undefined) {
			const replyButtons = props.mainTemplate.structure.buttons?.every((button) => button.type === 'QUICK_REPLY');
			setHasQuickReplyButtons(replyButtons === true);
			setIsDynamic(props.dynamicCampaignConfig?.isDynamic === true);
			if(props.mainTemplate.structure?.buttons?.[0] === undefined) setReplyButton1();
			if(props.mainTemplate.structure?.buttons?.[1] === undefined) setReplyButton2();
			if(props.mainTemplate.structure?.buttons?.[2] === undefined) setReplyButton3();
		}
	}

	const handleReplyButtonsChange = () => {
		const replyButtons = [replyButton1, replyButton2, replyButton3].filter((replyButton) => replyButton !== undefined);
		const event = {
			valid: replyButtons.every((replyButton) => replyButton.valid === true),
			payload: Object.fromEntries(replyButtons.map(({text, ...payload}) => [text, payload.payload]))
		};
		setState({replyButtons: event.payload}, {merge: true});
		props.onChange(event);
	}

	const handleCampaignButtonActionsChange = (index, text, event) => {
		const replyButton = {text, ...event};
		if(index === 0) { setReplyButton1(replyButton); }
		if(index === 1) { setReplyButton2(replyButton); }
		if(index === 2) { setReplyButton3(replyButton); }
	}

	return (
		<>
			{
				(hasQuickReplyButtons === true && isDynamic === true && props.mainTemplate?.structure !== undefined) &&
				<>
					<NeoTitleMain title='Acciones de botones'/>
					{
						props.mainTemplate?.structure?.buttons?.map((button, index) => (
							<DynamicCampaignButtonActionsForm 
								key={index}
								text={button.text}
								templates={props.templates}
								dynamicCampaignConfig={props.dynamicCampaignConfig}
								onChange={(event) => handleCampaignButtonActionsChange(index, button.text, event)}
							/>
						))
					}
				</>
			}
		</>
	);
}