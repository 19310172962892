import { useState } from 'react';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import ActionDialog from 'components/ActionDialog.component';

export default function CreateCommentDialog(props) {
  const [comment, setComment] = useState('');

  const handleAccept = () => {
    if(props?.onSubmit) {
      props.onSubmit(comment);
    }
  }

  const handleHide = () => {
    setComment('');
  }

  const handleCommentInputChange = (event) => {
    setComment(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header={props.title}
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Agregando comentario'
      acceptDisabled={comment.trim().length == 0}
      onAccept={handleAccept}
      onHide={handleHide}
    >
      <span>
        {props.description}
      </span>
      <NeoInputTextarea
        value={comment}
        onChange={handleCommentInputChange}
        label={props.placeholder}
      />
    </ActionDialog>
  );
}

CreateCommentDialog.defaultProps = {
  title: 'Agregar comentario',
  description: 'Este comentario solo será visto por el supervisor y los agentes que tengan acceso a la conversación.',
  placeholder: 'Escribe tu comentario:'
}