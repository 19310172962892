import LinesService from 'services/lines.service';
import useAsyncState from 'hooks/useAsyncState.hook';
import useComponent from 'hooks/useComponent.hook';
import AutomaticReplyHeader from 'views/settings/components/AutomaticReplyHeader.component';
import AutomaticReplyTabPanel from 'views/settings/components/AutomaticReplyTabPanel.component';
import AutomaticReplyCreate from './automaticReply/AutomaticReplyCreate.dialog.component';
import AutomaticRepliesService from 'services/automaticReplies.service';
import AutomaticReplyModify from './automaticReply/AutomaticReplyModify.dialog.component';

const AutomaticReplyView = () => {
    const asyncState = useAsyncState();
    const component = useComponent({
        state: {
            lines: [],
            reply: {},
            element: {
                header: {
                    type: 'DAILY',
                    lineSelected: null,
                    viewSelected: 'Mes',
                },
                tabPanel: {
                    mode: 'NO_AGENTS',
                    automaticReplies: [],
                },
                create: {
                    visible: false,
                },
                modify: {
                    visible: false,
                },
            },
        },
    });

    component.useEffect(() => initialize(), []);

    const initialize = async () => {
        await asyncState.allPromises([getLines()]);
    };

    const getLines = async () => {
        const response = await LinesService.getLines();
        if (response.success) {
            component.setState((state) => {
                state.lines = response.payload;
            });
            return { success: true };
        }
        return { success: false };
    };

    const getAutomaticReplies = async (lineId, type, mode) => {
        const response = await AutomaticRepliesService.getAutomaticReplies(
            lineId,
            type,
            mode
        );
        if (response.success) {
            component.setState((state) => {
                state.element.tabPanel.automaticReplies = response.payload;
            });
            return { success: true };
        }
        return { success: false };
    };

    component.useEffect(() => {
        component.setState((state) => {
            state.element.tabPanel.automaticReplies = [];
        });
        if (component.state.element.header.lineSelected) {
            const lineId = component.state.element.header.lineSelected;
            getAutomaticReplies(
                lineId,
                component.state.element.header.type,
                component.state.element.tabPanel.mode
            );
        }
    }, [
        component.state.element.header.lineSelected,
        component.state.element.header.type,
        component.state.element.tabPanel.mode,
    ]);

    return (
        <>
            <AutomaticReplyHeader
                type={component.state.element.header.type}
                lines={component.state.lines}
                view={component.state.element.header.viewSelected}
                onTypeChange={(type) => {
                    component.setState((state) => {
                        state.element.header.type = type;
                    });
                }}
                onLineSelected={(lineSelected) => {
                    component.setState((state) => {
                        state.element.header.lineSelected = lineSelected;
                    });
                }}
                onViewSelected={(viewSelected) => {
                    component.setState((state) => {
                        state.element.header.viewSelected = viewSelected;
                    });
                }}
                actions={{
                    onCreateClick: () => {
                        component.setState((state) => {
                            state.element.create.visible = true;
                        });
                    },
                }}
            />

            {component.state.element.header.lineSelected && (
                <AutomaticReplyTabPanel
                    type={component.state.element.header.type}
                    mode={component.state.element.tabPanel.mode}
                    view={component.state.element.header.viewSelected}
                    automaticReplies={
                        component.state.element.tabPanel.automaticReplies
                    }
                    onModeChange={(mode) => {
                        component.setState((state) => {
                            state.element.tabPanel.mode = mode;
                        });
                    }}
                    generic={{
                        actions: {
                            onConfigClick: () => {
                                component.setState((state) => {
                                    state.element.create.visible = true;
                                });
                            },
                            onAutomaticReplyClick: (reply) => {
                                component.setState((state) => {
                                    state.reply = reply;
                                });
                            },
                            onModifyClick: (show = true) => {
                                component.setState((state) => {
                                    state.element.modify.visible = show;
                                });
                            },
                        },
                    }}
                />
            )}

            <AutomaticReplyCreate
                visible={component.state.element.create.visible}
                visibleSetter={(visible) => {
                    component.setState((state) => {
                        state.element.create.visible = visible;
                    });
                }}
                lines={component.state.lines}
                automaticReply={{
                    type: component.state.element.header.type,
                    mode: component.state.element.tabPanel.mode,
                }}
                actions={{
                    onCreate: (reply) => {
                        component.setState((state) => {
                            if (
                                reply.mode === state.element.tabPanel.mode &&
                                reply.type === state.element.header.type
                            ) {
                                state.element.tabPanel.automaticReplies.push(
                                    reply
                                );
                            }
                        });
                    },
                }}
            />

            <AutomaticReplyModify
                visible={component.state.element.modify.visible}
                visibleSetter={(visible) => {
                    component.setState((state) => {
                        state.element.modify.visible = visible;
                    });
                }}
                type={component.state.element.header.type}
                lines={component.state.lines}
                automaticReply={component.state.reply}
                actions={{
                    onCreate: (reply) => {
                        component.setState((state) => {
                            if (
                                reply.mode === state.element.tabPanel.mode &&
                                reply.type === state.element.header.type
                            ) {
                                state.element.tabPanel.automaticReplies.push(
                                    reply
                                );
                                state.reply = {};
                            }
                        });
                    },
                    onModify: (replyId, updatedReply) => {
                        component.setState((state) => {
                            const index =
                                state.element.tabPanel.automaticReplies.findIndex(
                                    (reply) => reply.replyId === replyId
                                );
                            state.element.tabPanel.automaticReplies[index] = {
                                ...updatedReply,
                            };
                            if (
                                updatedReply.mode !==
                                component.state.element.tabPanel.mode ||
                                updatedReply.type !==
                                component.state.element.header.type
                            ) {
                                state.element.tabPanel.automaticReplies =
                                    state.element.tabPanel.automaticReplies.filter(
                                        (reply) => reply.replyId === replyId
                                    );
                            }
                        });
                    },
                    onDelete: (replyId) => {
                        component.setState((state) => {
                            state.element.tabPanel.automaticReplies =
                                state.element.tabPanel.automaticReplies.filter(
                                    (reply) => reply.replyId !== replyId
                                );
                        });
                    },
                }}
            />
        </>
    );
};

export default AutomaticReplyView;
