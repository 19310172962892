import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import dateTimeUtil from 'utils/dateTime.util';
import LinesService from 'services/lines.service';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import campaignsUtil from 'utils/campaigns.util';

export default function GeneralRecurrentCampaignDetails(props) {
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(props.campaign);
  const [line, setLine] = useState();

  useEffect(async () => await initialize(), []);
  useEffect(() => setCampaign(props.campaign), [props.campaign]);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLine()],
      { initialization: true }
    );
  }

  const getLine = async () => {
    const {lineId} = campaign;
    const response = await LinesService.getLine(lineId);
    if(response.success) {
      const line = {id: lineId, name: response.payload.name, phone: response.payload.phone};
      setLine(line);
      props.onLineChange(line);
      return {success: true};
    }
    return {success: false};
  }

  return (
    <NeoCard>
      {
        (asyncState.isLoading) &&
        <InternalSpinner/>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              <NeoInnerTitle extra='primero'>Información general</NeoInnerTitle>
              <NeoColumn col='5'>
                <NeoDataThird
                  label='Línea de envío'
                  fact={`${line.name} | ${line.phone}`}
                />
              </NeoColumn>
              <NeoColumn col='4'>
                <NeoDataThird
                  label='Tipo de envío de plantilla inicial'
                  fact={campaignsUtil.getInitialTemplateSendingType(campaign?.flow?.sendingType)}
                />
              </NeoColumn>
              <NeoColumn col='3'>
                <NeoDataThird
                  label='Creación de campaña'
                  fact={dateTimeUtil.getDateString(campaign.createdAt, {dateStyle: 'medium', timeStyle: 'short'})}
                />
              </NeoColumn>
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError internal align='start' onRetry={initialize}/>
          }
        </>
      }
    </NeoCard>
  );
}