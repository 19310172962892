export default function CalendarMessage(props) {
    const { reply, schedule, day, backgroundColor, actions } = props;
    const lines = reply.lineIds.length;

    const colStart = Number(day !== 0 ? day : 7);
    const colEnd = Number(day !== 0 ? day : 7);
    const [rowStart, hourStart] = schedule.start.split(':').map(Number);
    const [rowEnd, hourEnd] = schedule.end.split(':').map(Number);

    const hourDiff = rowEnd + (hourEnd === 0 ? 1 : 2) - rowStart;
    const insetTop = Math.round((100 * (hourStart / 60)) / hourDiff);
    const insetBottom =
        hourEnd > 0 ? Math.round(100 * (1 - hourEnd / 60)) / hourDiff : 0;

    const styleMessageContainer = {
        gridColumn: `${colStart + 1} / ${colEnd + 2}`,
        gridRow: `${rowStart + 1} / ${rowEnd + (hourEnd === 0 ? 1 : 2)}`,
        inset: `calc(${insetTop}% + 1px) 0.5rem calc(${insetBottom}% + ${
            hourEnd !== 0 ? 4.5 : 1
        }px)`,
    };

    const styleMessage = {
        backgroundColor: backgroundColor ?? '#9AC987',
        height: '100%',
    };

    return (
        <div
            className='message-block-container'
            style={styleMessageContainer}
            onClick={() => {
                actions.onAutomaticReplyClick(reply);
                actions.onModifyClick();
            }}
        >
            <div className='message-block' style={styleMessage}>
                <header className='message-block__header'>
                    {props.reply.title}
                </header>
                <p className='message-block__body text-ellipsis'>
                    {props.reply.message}
                </p>
                <footer className='message-block__footer'>
                    {lines} línea
                    {lines > 1 ? 's' : ''}
                </footer>
            </div>
        </div>
    );
}
