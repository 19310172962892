import {useContext} from 'react';
import TOAST from 'consts/toast.consts';
import MessagesContext from 'contexts/messages.context';

export default function useMessages() {
  const {toast} = useContext(MessagesContext);

  const showToast = (
    severity = TOAST.SEVERITY.ERROR, 
    summary = 'Algo salió mal', 
    detail = undefined,
    options = {}
  ) => {
    toast.current.show({severity, summary, detail, ...options});
  }

  return {
    showToast
  };
}