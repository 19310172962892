import { useHistory } from 'react-router-dom';
import MENU from 'consts/menu.consts';
import Icono from 'design/assets/img/cta/icons/icon-contacts.svg';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ContactsList from 'views/contacts/components/ContactsList.component';

export default function ContactsView() {
  const history = useHistory();

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title='Contactos' icon={Icono} />
        <NeoButtonSection align='right' >
          <NeoButtonMain
            icon="pi pi-plus"
            label='Crear nuevo contacto'
            onClick={() => history.push(MENU.ADMIN.CONTACTS.CREATE_CONTACT.PATH)}
          />
        </NeoButtonSection>
      </NeoGridContainer>
      <ContactsList />
    </>
  );
}