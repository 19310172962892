import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoFlexContainer from 'design/design_components/neo/layout/NeoFlexContainer.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';

const UNMAPPED = '';

export default function StartConversationTemplateVariable(props) {

  const handleTypeButtonClick = (type) => {
    props.onChange({type, payload: UNMAPPED});
  }

  const handleDropdownChange = (event) => {
    const payload = event?.value;
    props.onChange({type: 'OPTION', payload});
  }

  const handleInputTextChange = (event) => {
    const payload = event?.target?.value ?? event?.value;
    props.onChange({type: 'TEXT', payload});
  }

  return (
    <NeoFlexContainer jc='between' ai='center'>
      <span>{props.variable}</span>
      {
        (props.only !== 'TEXT' && props.value?.type === 'OPTION') &&
        <div style={{display: 'flex', alignItems: 'center', width: '60%'}}>
          <NeoDropdown
            value={props.value?.payload}
            placeholder={props.optionPlaceholder ?? 'Selecciona una opción'}
            options={props.options}
            onChange={handleDropdownChange}
          />
          <NeoButtonOutlined
            icon='pi pi-pencil'
            onClick={() => handleTypeButtonClick('TEXT')}
          />
        </div>
      }
      {
        (props.value?.type === 'TEXT') &&
        <div style={{display: 'flex', alignItems: 'center', width: '60%'}}>
          <NeoInputText
            placeholder={props.textPlaceholder ?? 'Ingresa un valor'}
            value={props.value?.payload}
            onChange={handleInputTextChange}
          />
          {
            (props.only !== 'TEXT') &&
            <NeoButtonMain
              icon='pi pi-pencil'
              onClick={() => handleTypeButtonClick('OPTION')}
            />
          }
        </div>
      }
    </NeoFlexContainer>
  );
}