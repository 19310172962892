import backendRequestUtil from 'utils/backendRequest.util';
import dateTimeUtil from 'utils/dateTime.util';

export default class CampaignsService {

  static async isExistingCampaign(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/api/campaign', {params});
    if(success) {
      return {success, payload: {exists: true, campaign: body}};
    }
    if(!error.internal) {
      if(/^La campaña [^]+ no existe en la línea [^]+$/i.test(error.message)) {
        return {success: true, payload: {exists: false}};
      }
    }
    return {success, error};
  }

  static async getCampaign(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/api/campaign', {params});
    if(success) {
      body.createdAt = dateTimeUtil.backendDateStringToDate(body.createdAt);
      body.finishedAt = dateTimeUtil.backendDateStringToDate(body.finishedAt);
      body.scheduledAt = dateTimeUtil.backendDateStringToDate(body.scheduledAt);
      body.startedAt = dateTimeUtil.backendDateStringToDate(body.startedAt);
      body.cancelledAt = dateTimeUtil.backendDateStringToDate(body.cancelledAt);
      body.stoppedAt = dateTimeUtil.backendDateStringToDate(body.stoppedAt);
      body.failedAt = dateTimeUtil.backendDateStringToDate(body.failedAt);
      return {success, payload: body};
    }
    return {success, error};
  }

  static async getCampaigns(lineId, options = {}) {
    const params = {linesId: lineId.toString(), ...options, limit: 1000000};
    const {success, body, error} = await backendRequestUtil.get('/api/campaigns', {params});
    if(success) {
      const campaigns = body.campaigns.map((campaign) => {
        campaign.isDynamic = campaign.isDynamic === true;
        campaign.isRecurrent = campaign.isRecurrent === true;
        campaign.createdAt = dateTimeUtil.backendDateStringToDate(campaign.createdAt);
        campaign.finishedAt = dateTimeUtil.backendDateStringToDate(campaign.finishedAt);
        campaign.scheduledAt = dateTimeUtil.backendDateStringToDate(campaign.scheduledAt);
        campaign.startedAt = dateTimeUtil.backendDateStringToDate(campaign.startedAt);
        campaign.cancelledAt = dateTimeUtil.backendDateStringToDate(campaign.cancelledAt);
        campaign.stoppedAt = dateTimeUtil.backendDateStringToDate(campaign.stoppedAt);
        campaign.failedAt = dateTimeUtil.backendDateStringToDate(campaign.failedAt);
        return campaign;
      });
      return {success, payload: campaigns};
    }
    return {success, error};
  }
  
  static async updateCampaignStatus(lineId, campaignName, status, options = {}) {
    const params = {lineId, name: campaignName, ...options};
    const requestBody = {status};
    const {success, error} = await backendRequestUtil.put('/api/campaignStatus', requestBody, {params});
    return success ? {success} : {success, error};
  }

  static async getCampaignsStatistics(linesId, fromDateString, toDateString) {
    const params = {
      linesId: linesId.join(','),
      fromDate: fromDateString,
      toDate: toDateString
    };
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignsStatistics', {params});
    if(success) {
      body.updatedAt = dateTimeUtil.backendDateStringToDate(body.updatedAt);
      body.campaigns = body.campaigns.map((campaign) => {
        campaign.createdAt = dateTimeUtil.backendDateStringToDate(campaign.createdAt);
        campaign.finishedAt = dateTimeUtil.backendDateStringToDate(campaign.finishedAt);
        campaign.scheduledAt = dateTimeUtil.backendDateStringToDate(campaign.scheduledAt);
        campaign.startedAt = dateTimeUtil.backendDateStringToDate(campaign.startedAt);
        campaign.cancelledAt = dateTimeUtil.backendDateStringToDate(campaign.cancelledAt);
        campaign.stoppedAt = dateTimeUtil.backendDateStringToDate(campaign.stoppedAt);
        campaign.failedAt = dateTimeUtil.backendDateStringToDate(campaign.failedAt);
        return campaign;
      });
      return {success, payload: body}
    }
    return {success, error};
  }

  static async getCampaignStatistics(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignStatistics', {params});
    if(success) {
      const messagesDetail = body.messagesDetail.map((messageDetail) => {
        messageDetail.sentAt = dateTimeUtil.backendDateStringToDate(messageDetail.sentAt);
        messageDetail.deliveredAt = dateTimeUtil.backendDateStringToDate(messageDetail.deliveredAt);
        messageDetail.readAt = dateTimeUtil.backendDateStringToDate(messageDetail.readAt);
        messageDetail.failedAt = dateTimeUtil.backendDateStringToDate(messageDetail.failedAt);
        messageDetail.errorAt = dateTimeUtil.backendDateStringToDate(messageDetail.errorAt);
        return {message: messageDetail};
      });
      return {success, payload: {...body, messagesDetail}};
    }
    return {success, error};
  }

  static async createCampaign(campaign) {
    const multipart = new FormData();
    multipart.set('name', campaign.name);
    multipart.set('lineId', campaign.lineId);
    multipart.set('flow', JSON.stringify(campaign.flow));
    multipart.set('isDynamic', campaign.isDynamic);
    multipart.set('isRecurrent', campaign.isRecurrent);
    if(campaign.scheduledAt !== undefined) { multipart.set('scheduledAt', campaign.scheduledAt); }
    if(campaign.mainRecurrentDynamicCampaign !== undefined) { multipart.set('mainRecurrentDynamicCampaign', campaign.mainRecurrentDynamicCampaign); }
    if(campaign.sendingName !== undefined) { multipart.set('sendingName', campaign.sendingName); }
    if(campaign.sendingDetail !== undefined) { multipart.set('sendingDetail', campaign.sendingDetail); }
    if(campaign.contacts !== undefined) { multipart.set('contacts', JSON.stringify(campaign.contacts)); }
    if(campaign.files !== undefined) {
      campaign.files.forEach(({file, map}, index) => {
        const fileField = `file${index+1}`;
        const fileMappingField = `${fileField}.map`;
        multipart.set(fileField, file);
        multipart.set(fileMappingField, JSON.stringify(map));
      });
    }
    const {success, error} = await backendRequestUtil.post('/api/campaign', multipart);
    if(!success && !error.internal) {
      if(/^La plantilla [^]+ en idioma [^]+ no existe$/i.test(error.message)) {
        error.code = 'NON_EXISTENT_TEMPLATE';
      }
      else if(/^La plantilla [^]+ en idioma [^]+ no se encuentra aprobada$/i.test(error.message)) {
        error.code = 'UNAPPROVED_TEMPLATE';
      }
      else if(/^La campaña [^]+ ya existe en la línea [^]+$/i.test(error.message)) {
        error.code = 'ALREADY_EXIST';
      }
      else if(/^El parámetro [to] debe ser mapeado en [^]+$/i.test(error.message)) {
        const file = error.message
          .replace(/El parámetro [to] debe ser mapeado en /i, '');
        error.code = 'PARAMETER_TO_IS_NOT_MAPPED';
        error.file = file.replace(/file/i, '');
      }
      else if(/^La cabezera [^]+ no existe en [^]+$/i.test(error.message)) {
        const [header, file] = error.message
          .replace(/La cabezera /i, '')
          .replace(/ no existe en /i, ';')
          .split(';');
        error.code = 'MISSING_HEADER_IN_FILE';
        error.header = header;
        error.file = file.replace(/file/i, '');
      }
      else if(/^El campo header contiene tipos diferentes$/i.test(error.message)) {
        error.code = 'DIFFERENT_PARAMETER_TYPE_IN_HEADER_CSV';
      }
      else if(/^[^]+ en [^]+$/i.test(error.message)) {
        const file = error.message
          .replace(/[^]+ en /i, '');
        error.code = 'TEMPLATE_STRUCTURE';
        error.file = file.replace(/file/i, '');
      }
    }
    return success ? {success} : {success, error};
  }

  static async getCampaignMessagesDetails(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignMessagesDetails', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getCampaignMessagesDetailsFile(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignMessagesDetailsFile', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getCampaignResponsesDetails(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignResponsesDetails', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getCampaignResponsesDetailsFile(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignResponsesDetailsFile', {params});
    return success ? {success, payload: body} : {success, error};
  }

  static async getCampaignTrackedLinks(lineId, name, options = {}) {
    const params = {lineId, name, ...options};
    const {success, body, error} = await backendRequestUtil.get('/rest/campaignTrackedLinks', {params});
    return success ? {success, payload: body.trackedLinks} : {success, error};
  }

}