import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export default function useStateParams() {
  const history = useHistory();
  const [params, setParams] = useState(history.location.state ?? {});

  useEffect(() => getStateParams(), [history.location.state]);

  const getStateParams = () => {
    setParams(history.location.state ?? {});
  }

  const setStateParams = (stateParams, options = {}) => {
    const state = options.merge === true ? {...(params ?? {}), ...stateParams} : stateParams;
    history.replace({
      pathname: history.location.pathname,
      state
    });
  }

  return [params, setStateParams];
}