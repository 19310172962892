import { SplitButton } from 'primereact/splitbutton';

const BaseButtonSplit = (props) => {
  return (
    <SplitButton className={`
    ${props.custom ? props.custom : ""}
    ${props.extra ? props.extra : ""}
    `}
      {...props} />
  );
};

export default BaseButtonSplit;