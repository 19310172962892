import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useAsyncState from 'hooks/useAsyncState.hook';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import GeneralCampaignDataForm from 'views/campaigns/components/GeneralCampaignDataForm.component';
import CampaignMainTemplateForm from 'views/campaigns/components/CampaignMainTemplateForm.component';
import DynamicCampaignForm from 'views/campaigns/components/DynamicCampaignForm.component';
import DynamicCampaignButtonsActionsForm from 'views/campaigns/components/DynamicCampaignButtonsActionsForm.component';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import CampaignsService from 'services/campaigns.service';

export default function CreateCampaign() {
  const history = useHistory();
  const messages = useMessages();
  const asyncState = useAsyncState({isInitialized: true});
  const [state] = useStateParams();
  const [generalData, setGeneralData] = useState(null);
  const [mainTemplate, setMainTemplate] = useState(null);
  const [dynamicCampaignConfig, setDynamicCampaignConfig] = useState(null);
  const [campaignButtonsActions, setCampaignButtonsActions] = useState(null);
  const [templates, setTemplates] = useState([]);

  const getCampaignPayload = () => {
    const isDynamic = dynamicCampaignConfig?.payload?.isDynamic === true;
    let payload = {
      ...generalData?.payload,
      isDynamic,
      isRecurrent: dynamicCampaignConfig?.payload?.isRecurrent === true,
      flow: {
        ...mainTemplate?.payload,
        sendingType: dynamicCampaignConfig?.payload?.initialSentType
      }
    };

    if(payload?.flow?.internal?.parameters) {
      const internalParamsBody = payload.flow.internal.parameters?.body;
      if(Array.isArray(internalParamsBody)) {
        for(const internalParamBody of internalParamsBody) {
          payload.flow.structure.body = payload.flow.structure.body.replace(`{{${internalParamBody.index}}}`, internalParamBody.value);
        }
      }
    }

    if(isDynamic === true) {
      payload.flow.replyButtons = campaignButtonsActions.payload;
    }
    if(payload.isRecurrent === false) {
      delete payload.sendingName;
      delete payload.sendingDetail;
    }
    if(state.mainRecurrentDynamicCampaign !== undefined) {
      payload.mainRecurrentDynamicCampaign = state.mainRecurrentDynamicCampaign;
    }
    return payload;
  }

  const campaignAlreadyExists = () => {
    messages.showToast(
      TOAST.SEVERITY.ERROR,
      'Nombre de campaña incorrecto',
      `La campaña ${generalData.payload.name} ya existe en la línea ${generalData.payload.line.name}.`,
      {life: 5000}
    );
    return {success: true};
  }

  const recurrentCampaignNotExists = () => {
    messages.showToast(
      TOAST.SEVERITY.ERROR,
      'Nombre de campaña recurrente incorrecto',
      `La campaña recurrente ${generalData.payload.name} no existe en la línea ${generalData.payload.line.name}.`,
      {life: 5000}
    );
    return {success: true};
  }

  const isExistingCampaign = async () => {
    const lineId = generalData.payload.line.id;
    const name = generalData.payload.name;
    const responses = await Promise.allSettled([
      CampaignsService.isExistingCampaign(lineId, name),
      CampaignsService.isExistingCampaign(lineId, name, {recurrentCampaignNumber: 1})
    ]);
    const [basicCampaign, recurrentCampaign] = responses.map((response) => response.value?.payload);
    if(basicCampaign !== undefined && recurrentCampaign !== undefined) {
      const isRecurrent = dynamicCampaignConfig?.payload?.isRecurrent === true;
      if(isRecurrent === true && state.mainRecurrentDynamicCampaign !== undefined) {
        if(basicCampaign.exists === true) {
          return campaignAlreadyExists();
        }
        if(recurrentCampaign.exists === false) {
          return recurrentCampaignNotExists();
        }
      }
      else if(basicCampaign.exists === true || recurrentCampaign.exists === true) {
        return campaignAlreadyExists();
      }
      history.push({
        pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS_ORIGIN.PATH,
        state: getCampaignPayload()
      });
      return {success: true};
    }
    messages.showToast(
      TOAST.SEVERITY.ERROR,
      'Algo salío mal',
      'No se pudo validar la campaña, inténtalo de nuevo.'
    );
    return {success: false};
  }

  const handleNextButtonClick = async () => {
    asyncState.allPromises(
      [isExistingCampaign()]
    );
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner />
      }
      {
        (generalData) &&
        <NeoGridContainer>
          <NeoTitleSecond icon={Icono} title='Crear campaña' subtitle='Configuración general' />
        </NeoGridContainer>
      }
      <GeneralCampaignDataForm 
        onChange={(event) => setGeneralData(event)}
        dynamicCampaignConfig={dynamicCampaignConfig?.payload}
      />
      <CampaignMainTemplateForm
        line={generalData?.payload?.line}
        onChange={(event) => setMainTemplate(event)}
        onTemplatesChange={(event) => setTemplates(event)}
      />
      <DynamicCampaignForm
        mainTemplate={mainTemplate?.payload}
        onChange={(event) => setDynamicCampaignConfig(event)}
      />
      <DynamicCampaignButtonsActionsForm
        templates={templates}
        mainTemplate={mainTemplate?.payload}
        dynamicCampaignConfig={dynamicCampaignConfig?.payload}
        onChange={(event) => setCampaignButtonsActions(event)}
      />
      {
        (generalData) &&
        <NeoButtonSection align='right'>
          <NeoButtonMain
            label='Siguiente'
            disabled={
              generalData?.valid === false 
              || mainTemplate?.valid === false
              || dynamicCampaignConfig?.valid === false
              || campaignButtonsActions?.valid === false
            }
            onClick={handleNextButtonClick}
          />
        </NeoButtonSection>
      }
    </>
  )
}