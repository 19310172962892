import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import TemplatesService from 'services/templates.service';
import TEMPLATES from 'consts/templates.consts';

export default function StartConversationTemplate(props) {
  const asyncState = useAsyncState();
  const [templates, setTemplates] = useState([]);

  useEffect(async () => await reboot(), [props.lineId]);

  const reboot = async () => {
    if(props.lineId !== undefined) {
      await asyncState.allPromises(
        [getTemplates()]
      );
    }
  }

  const getTemplates = async () => {
    const response = await TemplatesService.getTemplates(props.lineId);
    if(response.success) {
      const validTemplates = response.payload.filter((template) => template.status == 'APPROVED' && template?.typeOfUse?.includes('START_CONVERSATION'));
      const templatesNames = [...new Set(validTemplates.map((template) => template.name))];
      const selectableTemplates = templatesNames.map((templateName) => {
        const translations = response.payload
          .filter((template) => template.name == templateName)
          .map(({language, structure, internal}) => ({language, structure, internal}));
        return {name: templateName, translations};
      });
      const selectedTemplate = selectableTemplates[0];
      const selectedTranslation = selectedTemplate?.translations[0];
      setTemplates(selectableTemplates);
      props.onTemplateChange(selectedTemplate);
      props.onTranslationChange(selectedTranslation);
      return {success: true};
    }
    return {success: false};
  }

  const handleTemplateDropdownChange = (event) => {
    const selectedTemplate = event.value;
    const selectedTranslation = selectedTemplate.translations[0];
    props.onTemplateChange(selectedTemplate);
    props.onTranslationChange(selectedTranslation);
  }

  const handleTranslationDropdownChange = (event) => {
    const selectedTranslation = event.value;
    props.onTranslationChange(selectedTranslation);
  }

  return (
    <NeoCard>
      {
        (props.lineId === undefined || asyncState.isLoading) &&
        <InternalSpinner/>
      }
      {
        (props.lineId !== undefined && !asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoDropdown
                md='6'
                label='Nombre de plantilla'
                value={props.template}
                options={templates.map((template) => ({label: template.name, value: template}))}
                onChange={handleTemplateDropdownChange}
              />
              <NeoDropdown
                md='6'
                label='Idioma de plantilla'
                value={props.translation}
                options={props.template?.translations.map((translation) => ({label: TEMPLATES.LANGUAGES[translation.language], value: translation}))}
                onChange={handleTranslationDropdownChange}
              />
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <DataFetchError 
              internal 
              title='No se pudieron cargar las plantillas'
              onRetry={reboot}
            />
          }
        </>
      }
    </NeoCard>
  );
}