import {useState, useEffect, useRef} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoDateRangeFilterBase from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import DataFetchError from 'components/DataFetchError.component';
import InternalSpinner from 'components/InternalSpinner.component';
import ContactsService from 'services/contacts.service';
import templatesUtil from 'utils/templates.util';
import dateTimeUtil from 'utils/dateTime.util';

const statusOptions = [
  {value: true, label: 'Completo'},
  {value: false, label: 'Incompleto'}
];

export default function CampaignContactsSelection(props) {
  const tableRef = useRef();
  const asyncState = useAsyncState();
  const [contacts, setContacts] = useState([]);
  const [requiredFields] = useState(() => (
    props.isCampaignWithContacts === true
      ? templatesUtil.getMappingRequiredFields(props.contacts.map, {isDynamic: props.isDynamic})
      : []
  ));

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getContacts()],
      {initialization: true}
    );
  }

  const getContacts = async () => {
    const response = await ContactsService.getContacts();
    if(response.success) {
      const loadedContacts = response.payload.map((contact) => {
        const includesRequiredFields = contactIncludesRequiredFields(contact);
        return {...contact, includesRequiredFields};
      });
      setContacts(loadedContacts);
      getInitialSelectedContacts(loadedContacts);
      return {success: true};
    }
    return {success: false};
  }

  const getInitialSelectedContacts = (loadedContacts) => {
    const initial = props.contacts.contacts ?? [];
    const updatedContacts = {...props.contacts};
    updatedContacts.contacts = [];
    for(const contact of initial) {
      const index = loadedContacts.findIndex((loadedContact) => loadedContact.id === contact.id);
      if(index >= 0 && loadedContacts[index].includesRequiredFields === true) {
        updatedContacts.contacts.push(loadedContacts[index]);
      }
    }
    props.onSelectionChange(updatedContacts);
  }

  const contactIncludesRequiredFields = (contactData) => {
    return requiredFields.every((requiredField) => contactData[requiredField]?.length > 0) === true;
  }

  const handleRowClassName = (contactData) => {
    return {
      'contacto-no-seleccionable': contactData.includesRequiredFields === false
    }
  }

  const handleTableSelectionChange = (event) => {
    const updatedContacts = {...props.contacts};
    updatedContacts.contacts = event.value.filter((contact) => contact.includesRequiredFields === true);
    props.onSelectionChange(updatedContacts);
  }

  const createdAtSortFunction = (event) => {
    return contacts.sort((contact1, contact2) => {
      const createdAt1 = contact1.createdAt ?? new Date(0);
      const createdAt2 = contact2.createdAt ?? new Date(0);
      return (createdAt1 - createdAt2) * event.order;
    });
  }

  const elements = {
    nameColumnBody: (data) => (
      <>{data.name}</>
    ),
    phoneColumnBody: (data) => (
      <>{data.phone}</>
    ),
    createdAtColumnBody: (data) => (
      <>
        {
          (!data.createdAt)
            ? '-'
            : dateTimeUtil.getDateString(data.createdAt, { dateStyle: 'medium', timeStyle: 'short' })
        }
      </>
    ),
    statusColumnBody: (data) => (
      <>
        {
          (data.includesRequiredFields === true) &&
          <NeoCellDataStatus 
            status='on' 
            label='Completo'
            circle={true}
          />
        }
        {
          (data.includesRequiredFields === false) &&
          <NeoCellDataStatus 
            status='off' 
            label='Incompleto'
            tooltip='Este contacto no cuenta con los campos necesarios.'
            circle={true}
          />
        }
      </>
    ),
    nameFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='name' placeholder='Buscar por nombre'/>
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' placeholder='Buscar por teléfono'/>
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilterBase ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom' />
    ),
    statusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={statusOptions}
        field='includesRequiredFields'
        placeholder='Todos'
        selectedItemsLabel='{0} estatus seleccionados'
      />
    ),
  };

  return (
    <>
      {
        (props.isCampaignWithContacts === true) &&
        <>
          <NeoTitleMain title='Selección de contactos de NeoConecta'/>
          {
            (!asyncState.isLoading) &&
            <>
              {
                (asyncState.isSuccessfully) &&
                <NeoTable
                  extra='with-footer p-mb-3'
                  ref={tableRef}
                  value={contacts}
                  selection={props.contacts.contacts ?? []}
                  dataKey='id'
                  selectionMode='single'
                  paginator
                  rows={10}
                  removableSort
                  sortField='includesRequiredFields'
                  sortOrder={-1}
                  emptyMessage='No hay contactos'
                  footer={`${props.contacts.contacts?.length ?? 0} contacto${props.contacts.contacts?.length !== 1 ? 's' : ''} seleccionado${props.contacts.contacts?.length !== 1 ? 's' : ''}`}
                  rowClassName={handleRowClassName}
                  onSelectionChange={handleTableSelectionChange}
                >
                  <NeoTableColumn
                    selectionMode='multiple'
                    style={{width: '3em'}}
                  />
                  <NeoTableColumn
                    field='name'
                    header='Nombre'
                    filter
                    sortable
                    filterMatchMode='contains'
                    filterElement={elements.nameFilterInput}
                    body={elements.nameColumnBody}
                  />
                  <NeoTableColumn
                    field='phone'
                    header='Teléfono'
                    filter
                    sortable
                    filterMatchMode='contains'
                    filterElement={elements.phoneFilterInput}
                    body={elements.phoneColumnBody}
                  />
                  <NeoTableColumn
                      field='createdAt'
                      header='Fecha de creación'
                      filter
                      sortable
                      sortFunction={createdAtSortFunction}
                      filterElement={elements.createdAtFilterRange}
                      filterFunction={dateTimeUtil.filterDate}
                      body={elements.createdAtColumnBody}
                      style={{ width: '15%' }}
                    />
                    <NeoTableColumn
                    field='includesRequiredFields'
                    header='Estatus'
                    filter
                    sortable
                    filterMatchMode='custom'
                    filterElement={elements.statusFilterMultiSelect}
                    body={elements.statusColumnBody}
                  />
                </NeoTable>
              }
              {
                (!asyncState.isSuccessfully) &&
                <NeoCard>
                  <DataFetchError internal align='start' onRetry={initialize}/>
                </NeoCard>
              }
            </>
          }
          {
            (asyncState.isLoading) &&
            <NeoCard>
              <InternalSpinner/>
            </NeoCard>
          }
        </>
      }
    </>
  );
}