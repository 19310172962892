export default {
  STATUSES: {
    PENDING: 'Pendiente',
    SENDING: 'Enviando',
    CANCELLED: 'Cancelada',
    STOPPED: 'Detenida',
    FAILED: 'Fallida',
    FINISHED: 'Finalizada'
  },
  INITIAL_TEMPLATE_SENDING_TYPE: {
    UNIQUE: 'Envío único por contacto',
    RECURRENT: 'Envío recurrente con cada campaña'
  },
  MESSAGE_ERRORS_TYPES: {
    INVALID_PHONE_NUMBER: 'Número de teléfono inválido',
    INVALID_TEMPLATE_STRUCTURE: 'Estructura de plantilla incorrecta',
    MESSAGING_ERROR: 'Error de mensajería',
    UNREGISTERED_CONTACT: 'Contacto no registrado',
    MISSING_CONTACT_FIELDS: 'Campos de contacto incompletos',
    CONTACT_QUERY_ERROR: 'Error de consulta de contacto',
    ALREADY_ANSWERED: 'Respuesta guardada a plantilla',
    INTERNAL_ERROR: 'Error interno',
    UNSUBSCRIBED_TO_CAMPAIGN: 'No suscrito a la campaña',
    PHONE_NUMBER_VALIDATION_ERROR: 'Error de validación del número de teléfono',
    CONTACT_ALREADY_REACHED: 'El mensaje ya fue enviado anteriormente a este número',
    CONTACT_BLACK_LIST: 'El número de teléfono se encuentra en la lista negra'
  },
  TYPES: {
    BASIC: 'Básica',
    DYNAMIC: 'Dinámica',
    RECURRENT_DYNAMIC: 'Dinámica recurrente'
  },
  EXAMPLES_PER_DATABASE: 50
};