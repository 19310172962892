import { forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import stringUtil from 'utils/string.util';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {string} value Value of input
 * @property {Function} onChange Function to on change
 * @property {Function} onKeyEnterDown Function to on key enter
 */

const Component = {
  name: 'ChatActionInputItem',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);
    const bodyInputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        bodyInputRef.current?.focus();
      },
      addText: (text) => {
        const newValue = stringUtil.insertAt(props.value ?? '', text, bodyInputRef.current.selectionStart);
        props?.onChange(newValue);
      }
    }));
    
    const createBody = () => {    
      const input = <div className={`${Component.name}__body__input`}>
        <NeoInputTextarea 
          ref={bodyInputRef} 
          rows={1} 
          value={props.value} 
          placeholder="Mensaje"
          onChange={(event) => {
            props?.onChange(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              props?.onKeyEnterDown(event);
              event.preventDefault();
            }
          }}
          maxlength={4096}
        />
      </div>;

      return (
        <div className={`${Component.name}__body`}>
          {input}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
      </div>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyEnterDown: PropTypes.func
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  value: null,
  onChange: null,
  onKeyEnterDown: null
};

export default Component.element;