import { useState, useEffect } from 'react';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';

export default function ContactsDatabaseNavigation(props) {
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => setSelectedIndex(props.value), [props.value]);

  return (
    <NeoButtonSection align='left'>
      {
        props.contactsDatabase?.map((database, index) => (
          <>
            {
              (selectedIndex === index) &&
              <>
                <NeoButtonMain
                  icon={database.valid === true ? 'pi pi-check' : ''}
                  label={database.valid === true ? "" : `${index + 1}`}
                  extra="database-button-filled"
                />
                <b style={{ marginRight: '1em' }}>
                  {
                    (database.type === 'FILE') &&
                    <>{database.file.name}</>
                  }
                  {
                    (database.type === 'CONTACTS') &&
                    <>Base de contactos NeoConecta</>
                  }
                </b>
              </>
            }
            {
              (selectedIndex !== index) &&
              <>
                {
                  (database.valid !== true) &&
                  <NeoButtonOutlined
                    label={`${index + 1}`}
                    onClick={() => props.onChange(index)}
                    extra="database-button-outlined"
                  />
                }
                {
                  (database.valid === true) &&
                  <NeoButtonMain
                    icon='pi pi-check'
                    onClick={() => props.onChange(index)}
                    extra="database-button-validated"
                  />
                }
              </>
            }
          </>
        ))
      }
    </NeoButtonSection>
  );
}