import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState.hook';
import MENU from 'consts/menu.consts';
import ResourceNotFound from 'components/ResourceNotFound.component';
import NeoSpinner from 'design/design_components/neo/overlay/NeoSpinner.base';
import DataFetchError from 'components/DataFetchError.component';
import RecurrentCampaignDetailHeader from 'views/dashboard/components/RecurrentCampaignDetailHeader.component';
import GeneralRecurrentCampaignDetails from 'views/dashboard/components/GeneralRecurrentCampaignDetails.component';
import RecurrentCampaignSendings from 'views/dashboard/components/RecurrentCampaignSendings.component';
import CampaignsService from 'services/campaigns.service';

export default function RecurrentCampaignDetail() {
  const {id} = useParams();
  const asyncState = useAsyncState();
  const [campaign, setCampaign] = useState(null);
  const [line, setLine] = useState();
  const [lastSending, setLastSending] = useState();

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getCampaign()],
      { initialization: true }
    );
  }

  const getCampaign = async () => {
    const [lineId, name] = id.split('-');
    const response = await CampaignsService.getCampaign(lineId, name, {recurrentCampaignNumber: 1});
    if(response.success) {
      setCampaign(response.payload);
      return {success: true};
    }
    if(response.error.internal) {
      return {success: false};
    }
    return {success: true};
  }

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoSpinner/>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <>
              {
                (campaign) &&
                <>
                  <RecurrentCampaignDetailHeader
                    campaign={campaign}
                    line={line}
                    lastSendingChange={lastSending}
                  />
                  <GeneralRecurrentCampaignDetails
                    campaign={campaign}
                    onLineChange={(event) => setLine(event)}
                  />
                  <RecurrentCampaignSendings
                    campaign={campaign}
                    onLastSendingChange={(event) => setLastSending(event)}
                  />
                </>
              }
              {
                (!campaign) &&
                <ResourceNotFound
                  title='La campaña recurrente no existe'
                  redirection={{label: 'Ir a campañas', path: MENU.ADMIN.CAMPAIGNS.ROOT.PATH}}
                />
              }
            </>
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError onRetry={initialize}/>
          }
        </>
      }
    </>
  );
}