import {Switch} from 'react-router-dom';
import MENU from 'consts/menu.consts';
import AUTHENTICATION from 'consts/authentication.consts';
import useAuthentication from 'hooks/useAuthentication.hook';
import RouterContainer from 'routers/components/RouterContainer.component';

export default function UnknownRouter() {
  const authentication = useAuthentication();

  const menu = [
    {
      label: authentication.user.email,
      className: 'signout',
      command: async () => {
        await authentication.signOut();
        window.location = `${AUTHENTICATION.LOGOUT_URL}?continue=${AUTHENTICATION.URL}${MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH}`;
      }
    }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
      </Switch>
    </RouterContainer>
  );
}