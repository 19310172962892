import {useState, useEffect} from 'react';
import CONTACTS from 'consts/contacts.consts';
import CsvFilePreview from 'views/campaigns/components/CsvFilePreview.component';
import DatabasePhoneMapping from 'views/campaigns/components/DatabasePhoneMapping.component';
import DatabaseTemplateMapping from 'views/campaigns/components/DatabaseTemplateMapping.component';

const basicFieldsOptions = Object.entries(CONTACTS.DATA.BASIC).map(([_, {LABEL, FIELD}]) => ({label: LABEL, value: FIELD}));

const extraFieldsOptions = Object.entries(CONTACTS.DATA.EXTRA).map(([_, {LABEL, FIELD}]) => ({label: LABEL, value: FIELD}));

const extraTextFieldsOptions = Array(CONTACTS.DATA.EXTRA_TEXT_FIELDS).fill('').map((_, index) => {
  const label = `Texto ${index + 1}`;
  const field = `text${index + 1}`;
  return {label, value: field};
});

const extraNumberFieldsOptions = Array(CONTACTS.DATA.EXTRA_NUMBER_FIELDS).fill('').map((_, index) => {
  const label = `Número ${index + 1}`;
  const field = `number${index + 1}`;
  return {label, value: field};
});

const extraDateFieldsOptions = Array(CONTACTS.DATA.EXTRA_DATE_FIELDS).fill('').map((_, index) => {
  const label = `Fecha ${index + 1}`;
  const field = `date${index + 1}`;
  return {label, value: field};
});

const extraTokenFieldsOptions = Array(CONTACTS.DATA.EXTRA_TOKEN_FIELDS).fill('').map((_, index) => {
  const label = `Token ${index + 1}`;
  const field = `token${index + 1}`;
  return {label, value: field};
});

const extraFileNameFieldsOptions = Array(CONTACTS.DATA.EXTRA_FILENAME_FIELDS).fill('').map((_, index) => {
  const label = `Nombre de archivo ${index + 1}`;
  const field = `fileName${index + 1}`;
  return {label, value: field};
});

const contactFieldsOptions = [
  ...basicFieldsOptions,
  ...extraFieldsOptions,
  ...extraTextFieldsOptions,
  ...extraNumberFieldsOptions,
  ...extraDateFieldsOptions,
  ...extraTokenFieldsOptions,
  ...extraFileNameFieldsOptions
];

export default function DatabaseTemplatesMapping(props) {
  const [mappingOptions, setMappingOptions] = useState([]);
  const [phoneMapping, setPhoneMapping] = useState();
  const [initialTemplate, setInitialTemplate] = useState({});
  const [autoReplies, setAutoReplies] = useState([]);

  useEffect(() => handleDatabaseChange(), [props.database?.type, props.database?.file]);
  useEffect(() => handleMappingChange(), [phoneMapping, initialTemplate, autoReplies]);

  const getAutoReplies = () => {
    if(props.isDynamic === true) {
      return Object.entries(props.flow?.replyButtons ?? {})
        .map((replyButtonEntrie) => {
          const [text, {autoReply}] = replyButtonEntrie;
          if(autoReply?.type === 'TEMPLATE') {
            return {
              text, 
              template: autoReply.template, 
              valid: false, 
              mapping: (props.database?.map?.autoReplies ?? {})[text]?.map ?? {}
            };
          }
        })
        .filter((value) => value !== undefined);
    }
    return [];
  }

  const handleDatabaseChange = () => {
    const database = props.database ?? {};
    const {type} = database;
    if(type === 'FILE') {
      const options = Object.entries(database.worksheet.headers)
        .map(([value, label]) => ({label, value}))
        .filter(({label}) => (label ?? '').trim().length > 0);
      setMappingOptions(options);
    }
    if(type === 'CONTACTS') {
      const options = contactFieldsOptions;
      setMappingOptions(options);
    }
    setPhoneMapping(props.database?.map?.to);
    setInitialTemplate({mapping: props.database?.map?.map, valid: false});
    setAutoReplies(getAutoReplies());
  }

  const handleMappingChange = () => {
    const map = {to: phoneMapping, map: initialTemplate?.mapping};
    let valid = (props.database?.type === 'CONTACTS' || map.to !== undefined) && initialTemplate?.valid === true;
    if(autoReplies.length > 0) {
      valid = valid && autoReplies.every((autoReply) => autoReply.valid === true);
      const autoRepliesEntries = autoReplies.map((autoReply) => [autoReply.text, {map: autoReply.mapping}]);
      map.autoReplies = Object.fromEntries(autoRepliesEntries);
    }
    const updatedDatabase = {...props.database, map, valid};
    props.onChange(updatedDatabase);
  }

  const handleInitialTemplateMappingChange = (event) => {
    setInitialTemplate({...initialTemplate, mapping: event});
  }

  const handleInitialTemplateMappingValidationChange = (event) => {
    setInitialTemplate({...initialTemplate, valid: event});
  }

  const handleAutoReplyTemplateMappingChange = (event, index) => {
    const updatedAutoReplies = [...autoReplies];
    updatedAutoReplies[index].mapping = event;
    setAutoReplies(updatedAutoReplies);
  }

  const handleAutoReplyTemplateMappingValidationChange = (event, index) => {
    const updatedAutoReplies = [...autoReplies];
    updatedAutoReplies[index].valid = event;
    setAutoReplies(updatedAutoReplies);
  }

  return (
    <>
      {
        (props.database?.type === 'FILE') &&
        <>
          {/* 
            <CsvFilePreview
              worksheet={props.database?.worksheet ?? {}}
            /> 
          */}
          <DatabasePhoneMapping
            mapping={phoneMapping}
            mappingOptions={mappingOptions}
            onMappingChange={(event) => setPhoneMapping(event)}
          />
        </>
      }
      <DatabaseTemplateMapping
        mapping={initialTemplate?.mapping}
        template={props.flow}
        mappingOptions={mappingOptions}
        databaseType={props.database?.type}
        onMappingChange={(event) => handleInitialTemplateMappingChange(event)}
        onMappingValidationChange={(event) => handleInitialTemplateMappingValidationChange(event)}
      />
      {
        autoReplies.map((autoReply, index) => (
          <DatabaseTemplateMapping
            key={index}
            mapping={autoReply?.mapping}
            template={autoReply.template}
            mappingOptions={mappingOptions}
            databaseType={props.database?.type}
            onMappingChange={(event) => handleAutoReplyTemplateMappingChange(event, index)}
            onMappingValidationChange={(event) => handleAutoReplyTemplateMappingValidationChange(event, index)}
          />
        ))
      }
    </>
  );
}