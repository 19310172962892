import Icono from 'design/assets/img/cta/icons/icon-config.svg';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoTabMenu from 'design/design_components/neo/menu/NeoTabMenu.base';
import useComponent from 'hooks/useComponent.hook';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';

const MODELS = {
    GENERIC: {label: 'Por defecto'},
    DAILY: {label: 'Cotidianas'},
    SPECIFIC: {label: 'Fecha especifica'},
};

const AutomaticReplyHeader = (props) => {
    const component = useComponent({
        state: {
            activeIndex: 0,
            view: 'Mes',
            element: {
                selectLine: {
                    selected: null,
                    options: []
                },
                selectView: {
                    selected: 'Mes',
                    options: ['Mes', 'Semana']
                }
            }
        }
    });

    component.useEffect(() => {
        if(props.type) {
            const activeIndex = Object.entries(MODELS).findIndex(([KEY]) => {
                return KEY === props.type;
            });
            if(activeIndex !== -1 && component.state.activeIndex !== activeIndex) {
                component.setState((state) => {
                    state.activeIndex = activeIndex;
                })
            }
        }
    }, [props.type])

    component.useEffect(() => {
        if(Array.isArray(props.lines)) {
            component.setState((state) => {state.element.selectLine.options = props.lines});
        }
    }, [props.lines])

    const getModels = () => {
        return Object.entries(MODELS).map(([KEY, properties]) => {
            const {label} = properties;
            return {label};
        });
    }

    return (<>
        <NeoTitleMain title='Respuestas automáticas' icon={Icono} extra="col-12"/>
        <NeoGridContainer>
            <NeoTabMenu 
                model={getModels()}
                activeIndex={component.state.activeIndex}
                onTabChange={(event) => {
                    const {index} = event;
                    component.setState((state) => {
                        state.activeIndex = index;
                    })
                    if(typeof props?.onTypeChange === 'function') {
                        const [KEY] = Object.entries(MODELS)[index];
                        props.onTypeChange(KEY);
                    }
                }}
            />
            <NeoButtonSection align='right'>
                <NeoDropdown
                    value={component.state.element.selectLine.selected}
                    options={component.state.element.selectLine.options}
                    optionLabel="id" 
                    optionValue="id"
                    label='Línea'
                    onChange={(event) => {
                        component.setState((state) => {state.element.selectLine.selected = event.value})
                        if(typeof props?.onLineSelected === 'function') {
                            props.onLineSelected(event.value);
                        }
                    }}
                />
                {props.type === 'SPECIFIC' &&
                    <NeoDropdown
                        value={component.state.element.selectView.selected}
                        options={component.state.element.selectView.options}
                        label='Vista de calendario'
                        onChange={(event) => {
                          component.setState((state) => {state.element.selectView.selected = event.value})
                          if(typeof props?.onViewSelected === 'function') {
                              props.onViewSelected(event.value);
                          }
                        }}
                    />
                }
                {props.type !== 'GENERIC' &&
                    <NeoButtonMain
                        icon="pi pi-plus"
                        label='Crear nueva respuesta automática'
                        onClick={props?.actions?.onCreateClick}
                    />
                }
            </NeoButtonSection>
        </NeoGridContainer>
    </>)
}

export default AutomaticReplyHeader;
