import { useState, useEffect } from 'react';
import NeoInputTextarea from 'design/design_components/neo/form/NeoInputTextarea.base';
import ActionDialog from 'components/ActionDialog.component';
import LinesService from 'services/lines.service';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import AgentSelectGroupStatus from 'components/AgentSelectGroupStatus.component';

export default function ReassignedConversationDialog(props) {
  const asyncState = useAsyncState();
  const [area, setArea] = useState();
  const [areas, setAreas] = useState([]);
  const [reason, setReason] = useState('');
  const [agentId, setAgentId] = useState(null);

  const reboot = async () => {
    setAreas([]);
    await asyncState.allPromises(
      [getAreas()]
    );
  }

  useEffect(async () => {
    reboot()
  }, [props?.line?.id]);

  const getAreas = async () => {
    const response = await LinesService.getAreas(props?.line?.id);
    if(response.success) {
      setAreas(response.payload?.areas.map((area) => {
        if(area.id === props.conversation?.areaId) {
          area.name += ' (actual)';
          setArea(area);
        }
        return area;
      }));
      return {success: true};
    }
    return {success: false};
  }

  const handleAccept = () => {
    if(props?.onSubmit) {
      props.onSubmit(area.id, agentId, reason);
    }
  }

  const handleHide = () => {
    setReason('');
  }

  const handleCommentInputChange = (event) => {
    setReason(event.value ?? event.target.value);
  }

  return (
    <ActionDialog
      header={props.title}
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Aceptar'
      acceptDisabled={reason.trim().length === 0 || !area || !agentId}
      onAccept={handleAccept}
      onHide={handleHide}
    >
      <NeoDropdown
        value={area}
        label="Lista de áreas"
        options={areas.map((area) => ({ value: area, label: `${area.name}` }))}
        onChange={(event) => {
          const { value } = event;
          setArea(value)
        }}
      />
      <AgentSelectGroupStatus
        lineId={props?.line?.id}
        areaId={area?.id}
        agentsFilter={({id}) => id !== props.conversation?.userId}
        onChange={(agent) => {
          setAgentId(agent === null ? null : agent.id);
        }}
      />
      <NeoInputTextarea
        value={reason}
        onChange={handleCommentInputChange}
        label={props.placeholder}
      />
    </ActionDialog>
  );
}

ReassignedConversationDialog.defaultProps = {
  title: 'Realizar reasignación',
  placeholder: 'Escribe tu comentario:'
}