import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import DatabaseTemplatesMapping from 'views/campaigns/components/DatabaseTemplatesMapping.component';
import ContactsDatabaseNavigation from 'views/campaigns/components/ContactsDatabaseNavigation.component';
import ContactsDatabaseNavigationButtons from 'views/campaigns/components/ContactsDatabaseNavigationButtons.component';

export default function CampaignTemplatesMapping() {
  const history = useHistory();
  const [state, setState] = useStateParams();
  const [selectedDatabaseIndex, setSelectedDatabaseIndex] = useState(0);

  const [contactsDatabase, setContactsDatabase] = useState(() => {
    const files = state?.files ?? [];
    const isCampaignWithContacts = state?.isCampaignWithContacts ?? false;
    const database = files.map((file) => ({type: 'FILE', ...file}));
    if(isCampaignWithContacts === true) database.push({type: 'CONTACTS'});
    const mapping = state.mapping ?? [];
    return database.map((db, index) => ({...db, map: mapping[index]?.map ?? {map: {}}, valid: mapping[index]?.valid ?? false}));
  });

  useEffect(() => initialize(), []);

  const initialize = () => {
    if(state?.flow === undefined) {
      history.replace(MENU.ADMIN.CAMPAIGNS.ROOT.PATH);
    }
  }

  const getCampaignPayload = () => {
    const payload = {...state};
    payload.files = contactsDatabase
      .filter((database) => database.type === 'FILE')
      .map(({file, worksheet, map}) => ({file, worksheet, map}));
    const lastDatabase = contactsDatabase[contactsDatabase.length - 1];
    if(lastDatabase.type === 'CONTACTS') {
      payload.contacts = {map: lastDatabase.map};
    }
    delete payload.mapping;
    return payload;
  }

  const handleDatabaseTemplatesMappingChange = (event) => {
    const updatedContactsDatabase = [...contactsDatabase];
    updatedContactsDatabase[selectedDatabaseIndex] = event;
    const mapping = updatedContactsDatabase.map(({map, valid}) => ({map, valid}));
    setContactsDatabase(updatedContactsDatabase);
    setState({mapping}, {merge: true});
  }

  const handleNextButtonClick = () => {
    history.push({
      pathname: MENU.ADMIN.CAMPAIGNS.CAMPAIGN_CONTACTS.PATH,
      state: getCampaignPayload()
    });
  }

  return (
    <>
      {
        (state?.flow !== undefined) &&
        <>
          <NeoGridContainer>
            <NeoTitleSecond 
              icon={Icono} 
              title='Crear campaña' 
              subtitle='Mapeo de plantillas'
            />
          </NeoGridContainer>
          <ContactsDatabaseNavigation
            value={selectedDatabaseIndex}
            contactsDatabase={contactsDatabase}
            onChange={(event) => setSelectedDatabaseIndex(event)}
          />
          <DatabaseTemplatesMapping
            flow={state?.flow}
            isDynamic={state?.isDynamic}
            database={contactsDatabase[selectedDatabaseIndex]}
            onChange={handleDatabaseTemplatesMappingChange}
          />
          <ContactsDatabaseNavigationButtons
            value={selectedDatabaseIndex}
            contactsDatabase={contactsDatabase}
            onChange={(event) => setSelectedDatabaseIndex(event)}
          />
          <NeoButtonSection align='right'>
            <NeoButtonOutlined
              label='Atrás'
              onClick={() => history.goBack()}
              
            />
            <NeoButtonMain
              label='Siguiente'
              disabled={!contactsDatabase.every((database) => database.valid === true)}
              onClick={handleNextButtonClick}
            />
          </NeoButtonSection>
        </>
      }
    </>
  );
}