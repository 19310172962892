import CalendarCell from './CalendarCell.component';
import CalendarCellTime from './CalendarCellTime.component';
import CalendarMessageBlock from './CalendarMessageBlock.component';
import { DateTime } from 'luxon';
import CalendarMessageList from './CalendarMessageList.component';

const hours = [
    '',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
];

const colors = [
    '#4D8834',
    '#DB93A0',
    '#66988B',
    '#C48966',
    '#9AC987',
    '#9578EA',
    '#EABA9D',
    '#D04588',
    '#D9B020',
    '#93B7AE',
];

// rows * columns
// 24 [horas] * (7 [días] + 1 [tiempo]) = 192
// 6 [semanas] * (7 [días] + 1 [tiempo oculta]) = 48

export default function CalendarBody(props) {
    const { calendar, isWeekly, replies, actions } = props;
    const cells = isWeekly ? 192 : 48;

    let hourIndex = 0;
    let dayIndex = 0;
    let colorPerMonth = 0;
    const populateWithDates = (_, i) =>
        i % 8 === 0
            ? DateTime.invalid('CELL_TIME', i)
            : calendar.start.plus({ days: dayIndex++ });

    const dates = Array.from({ length: cells }, populateWithDates);

    return (
        <div className='calendar__body'>
            {dates.map((date) => {
                if (typeof date === 'object' && date.invalidReason) {
                    return (
                        <CalendarCellTime
                            key={`cell-time-${date.invalidExplanation}`}
                            hour={hours[hourIndex++]}
                        />
                    );
                }

                if (isWeekly) {
                    return (
                        <CalendarCell key={`cell-${date.ordinal ?? date}`} />
                    );
                }

                const filtered = replies.reduce((acc, reply) => {
                    if (
                        +DateTime.fromISO(reply.date).startOf('day') === +date
                    ) {
                        acc.push({
                            ...reply,
                            backgroundColor: colors[colorPerMonth++ % 10],
                        });
                    }
                    return acc;
                }, []);

                return (
                    <CalendarCell key={`cell-${date.ordinal ?? date}`}>
                        <span className='calendar__cell-number'>
                            {date.day}
                        </span>
                        <CalendarMessageList
                            replies={filtered}
                            actions={actions}
                        />
                    </CalendarCell>
                );
            })}

            {isWeekly ? (
                <>
                    {replies.map((reply, i) => {
                        if (reply.date) {
                            const date = +DateTime.fromISO(reply.date);
                            if (
                                +date < calendar.startOf('week') ||
                                +date >
                                    calendar.startOf('week').plus({ days: 7 })
                            ) {
                                return null;
                            }
                            return (
                                <CalendarMessageBlock
                                    key={reply.replyId}
                                    reply={reply}
                                    isWeekly={isWeekly}
                                    backgroundColor={colors[i % 10]}
                                    actions={actions}
                                />
                            );
                        }
                        return (
                            <CalendarMessageBlock
                                key={reply.replyId}
                                reply={reply}
                                isWeekly={isWeekly}
                                backgroundColor={colors[i % 10]}
                                actions={actions}
                            />
                        );
                    })}
                </>
            ) : null}
        </div>
    );
}
