import { useState, useEffect } from 'react';
import TemplatePreview from 'components/TemplatePreview.component';
import TemplateStructureVariablesForm from 'views/templates/components/TemplateStructureVariablesForm.component'
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import useComponent from 'hooks/useComponent.hook';

export default function TemplatePreviewWithVariablesForm(props) {
  const component = useComponent({
    state: {
      template: props.template,
      realTimeStructure: props.template.structure
    }
  });

  const handleVariablesFormChange = (event) => {
    component.setState((state) => {
      if (event.header) {
        const headerParam = event.header;
        state.realTimeStructure.header = {...state.template.structure.header};
        if(state.realTimeStructure.header.type === "TEXT") {
          if (headerParam.value.length > 0) {
            state.realTimeStructure.header.text = state.realTimeStructure.header.text.replace(headerParam.name, headerParam.value);
          }
        } else {
          const headerType = state.realTimeStructure.header.type.toLowerCase();
          const headerTypeParams = state.realTimeStructure.header[headerType] ?? {};
          headerTypeParams.url = headerParam.value.length > 0 ? headerParam.value : undefined;
          state.realTimeStructure.header[headerType] = headerTypeParams;
        }
        if(typeof props.handleTemplateHeaderVariablesFormChange === "function") {
          props.handleTemplateHeaderVariablesFormChange(event.header);
        }
      }
      if(event.body) {
        state.realTimeStructure.body = state.template.structure.body;
        for (const bodyParam of event.body) {
          if (bodyParam.value.length > 0) {
            state.realTimeStructure.body = state.realTimeStructure.body.replace(bodyParam.name, bodyParam.value);
          }
        }
        if(typeof props.handleTemplateBodyVariablesFormChange === "function") {
          props.handleTemplateBodyVariablesFormChange(event.body);
        }
      }
      if(event.buttons) {
        state.realTimeStructure.buttons = [...state.template.structure.buttons];
        event.buttons.forEach((buttonParam, index) => {
          const button = {...state.realTimeStructure.buttons[index]};
          if (buttonParam.value.length > 0) {
            if(button.type === "URL") {
              button.url = button.url.replace(buttonParam.name, buttonParam.value);
            }
          }
          state.realTimeStructure.buttons[index] = button;
        })
        if(typeof props.handleTemplateButtonsVariablesFormChange === "function") {
          props.handleTemplateButtonsVariablesFormChange(event.buttons);
        }
      }
    })
  }

  return (
    <>
      <NeoColumn md="6" col="12">
        <TemplateStructureVariablesForm
          structure={component.state.template.structure}
          onChange={handleVariablesFormChange}
          onChangeValidate={props.onChangeValidate}
        />
      </NeoColumn>
      <NeoColumn md="6" col="12">
        <NeoCard>
          <TemplatePreview
            template={{ status: component.state.template.status, structure: component.state.realTimeStructure }}
          />
        </NeoCard>
      </NeoColumn>
    </>
  );
}