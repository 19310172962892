import { forwardRef, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import reactStringReplace from 'react-string-replace';
import mime from 'mime-types';
import ChatService from 'services/chat.service';
import TemplatePreviewContent from 'components/TemplatePreviewContent.component';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} event Chat event
 */

const Component = {
  name: 'ChatBalloon',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);
    const [urlDataFile, setUrlDataFile] = useState();

    useEffect(() => {
      (async () => {
        let mediaId;
        let mediaUrl;
        switch (props.event?.type) {
          case 'STICKER':
            mediaId = props.event.sticker?.id;
            mediaUrl = props.event.sticker?.url;
            break;
          case 'IMAGE':
            mediaId = props.event.image?.id;
            mediaUrl = props.event.image?.url;
            break;
          case 'DOCUMENT':
            mediaId = props.event.document?.id;
            mediaUrl = props.event.document?.url;
            break;
          case 'VOICE':
            mediaId = props.event.voice?.id;
            mediaUrl = props.event.voice?.url;
            break;
          case 'VIDEO':
            mediaId = props.event.video?.id;
            mediaUrl = props.event.video?.url;
            break;
        }

        if (mediaId) {
          const { lineId } = props.event;
          const response = await ChatService.getMedia({ lineId, mediaId });
          mediaUrl = (window.URL || window.webkitURL).createObjectURL(response.payload);
        }
        
        if(mediaUrl) {
          setUrlDataFile(mediaUrl);
        }
      })()
    }, [props?.event?.id])

    const Sticker = useCallback(() => {
      const spinner = !urlDataFile && <p className='spinner'><i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i></p>
      const sticker = urlDataFile && <img className='sticker' src={urlDataFile} />;
      return <>
        {spinner}
        {sticker}
      </>
    }, [urlDataFile])

    const Image = useCallback(() => {
      const spinner = !urlDataFile && <p className='spinner'><i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i></p>;
      const download = urlDataFile && <div className='download' onClick={() => window.open(urlDataFile, "_blank")}><i className='pi pi-cloud-download' /></div>;
      const image = urlDataFile && <img className='image' src={urlDataFile} />;
      const caption = props.event?.image?.caption && <p className='caption'>{props.event?.image?.caption}</p>;
      return <>
        {download}
        {spinner}
        {image}
        {caption}
      </>
    }, [urlDataFile])

    const Document = useCallback(() => {
      const DocumentHTMLType = useCallback(({ mimeType, urlDataFile }) => {
        let HTMLType;
        let showIconDownload = true;
        switch (mimeType) {
          case 'audio/mpeg':
            showIconDownload = false;
            HTMLType = <audio className='audio' src={urlDataFile} controls />
            break;
          case 'application/pdf':
            if(navigator?.plugins && 'PDF Viewer' in navigator.plugins) {
              HTMLType = <embed className='document document--pdf' src={urlDataFile} />
            } else {
              HTMLType = <div className='document document--file'><i className='pi pi-file' /></div>
            }
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            HTMLType = <div className='document document--xlsx'><i className='pi pi-file-excel' /></div>
            break;
          default:
            HTMLType = <div className='document'><i className='pi pi-file' /></div>
            break;
        }

        return <>
          {HTMLType}
        </>
      }, [urlDataFile])

      const download = urlDataFile && <div className='download' onClick={() => window.open(urlDataFile, "_blank")}><i className='pi pi-cloud-download' /></div>;
      const document = urlDataFile && <DocumentHTMLType mimeType={props.event.document?.mimeType ?? mime.lookup(props.event?.document?.filename)} urlDataFile={urlDataFile} />
      const caption = props.event?.document?.caption && <p className='caption'>{props.event.document.caption}</p>;
      const filename = (props.event?.document?.filename && props.event?.document?.filename !== props.event?.document?.caption) && <p className='filename'>{props.event.document.filename}</p>;

      return <>
        {download}
        {document}
        {caption}
        {filename}
      </>
    }, [urlDataFile])

    const Voice = useCallback(() => {
      const voice = urlDataFile && <audio className='voice' src={urlDataFile} controls />;
      return <>
        {voice}
      </>
    }, [urlDataFile])

    const Video = useCallback(() => {
      const spinner = !urlDataFile && <p className='spinner'><i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i></p>
      const video = urlDataFile && <video className='video' src={urlDataFile} controls />;
      const caption = props.event?.video?.caption && <p>{props.event?.video?.caption}</p>;
      return <>
        {spinner}
        {video}
        {caption}
      </>
    }, [urlDataFile])

    const Contact = useCallback(() => {
      const contact = props.event.contacts?.map((contact, key) => {
        const { formattedName: name } = contact.name;
        const phones = contact.phones.map((phone, key) => {
          return <p className='chat-contact__phone' key={`contact__phone-${key}`}>{phone.phone}</p>
        })

        return <div className='chat-contact' key={key}>
          <p className='chat-contact__name'>{name}</p>
          {phones}
        </div>;
      })

      return <>
        {contact}
      </>
    }, [props?.event?.id])

    const Location = useCallback(() => {
      const location = (() => {
        const url = `https://www.google.com/maps/place/${props.event.location.latitude},${props.event.location.longitude}`;
        return <span>Locación: <a href={url} target="_blank">{props.event.location.latitude},{props.event.location.longitude}</a></span>
      })();

      return <>
        {location}
      </>
    }, [props?.event?.id])

    const Template = useCallback(() => {
      const template = <TemplatePreviewContent structure={props.event.template.structure}/>

      return <>
        {template}
      </>
    }, [props?.event?.id])

    const Text = useCallback(() => {
      // Match Line break
      let replacedText = reactStringReplace(props.event.text, '\n', (match, i) => (
        <br key={i}/>
      ));

      // Match URLs
      replacedText = reactStringReplace(replacedText, /(https?:\/\/\S+)/gi, (url, i) => (
        <a key={url + i} href={url} target='_blank'>{url}</a>
      ));

      return <>
        {replacedText}
      </>
    }, [props?.event?.id])

    const createBody = () => {
      const text = props?.event?.type === 'TEXT' && <p className={`${Component.name}__body__text`}>
        <Text/>
      </p>;

      const ephemeral = props?.event?.type === 'EPHEMERAL' && <p className={`${Component.name}__body__ephemeral`}>
        <i className="pi pi-ban"/> Mensaje temporal y no se puede ver
      </p>;

      const media = (props?.event?.type !== 'TEXT') && <div className={`${Component.name}__body__media`}>
        {props.event.type === 'STICKER' &&
          <Sticker/>
        }
        {props.event.type === 'IMAGE' &&
          <Image/>
        }
        {props.event.type === 'DOCUMENT' &&
          <Document/>
        }
        {props.event.type === 'VOICE' &&
          <Voice/>
        }
        {props.event.type === 'VIDEO' &&
          <Video/>
        }
        {props.event.type === 'CONTACTS' &&
          <Contact/>
        }
        {props.event.type === 'LOCATION' &&
          <Location/>
        }
        {props.event.type === 'TEMPLATE' &&
          <Template/>
        }
        {props.event.type === 'BUTTON' &&
          props.event.button.text
        }
      </div>;

      return (
        <>
          {media}
          {text}
          {ephemeral}
        </>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);
    const body = createBody();

    return (
      <>
        {body}
      </>
    );
  })
};

Component.element.displayName = Component.name;

Component.element.propTypes = {
  id: PropTypes.string,
  event: PropTypes.object,
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  event: null,
};

export default Component.element;
