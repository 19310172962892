import {useState} from 'react';
import useMessages from 'hooks/useMessages.hook';
import TOAST from 'consts/toast.consts';
import Icono from 'design/assets/img/cta/icons/icon-contacts.svg';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import RegisterEmployeesDialog from 'views/employees/components/RegisterEmployeesDialog.component';

export default function EmployeesHeader(props) {
  const messages = useMessages();
  const [showRegisterEmployeesDialog, setShowRegisterEmployeesDialog] = useState(false);

  const handleRegisterEmployeeDialogHide = (event) => {
    if(event.success === true) {
      let detail = `Se registraron ${event.payload} empleados`;
      if(event.payload === 1) { detail = `Se registró ${event.payload} empleado`; }
      else if(event.payload === 0) { detail = `No se registró ningún empleado`; }
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', detail);
      props.onRegisterEmployees(event.payload);
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', `No se pudieron registrar los empleados, inténtalo de nuevo.`);
    }
  }

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title='Empleados' icon={Icono}/>
        <NeoButtonSection align='right' >
          <NeoButtonMain
            icon="pi pi-plus"
            label='Registrar empleados'
            onClick={() => setShowRegisterEmployeesDialog(true)}
          />
        </NeoButtonSection>
      </NeoGridContainer>
      {
        <RegisterEmployeesDialog
          visible={showRegisterEmployeesDialog}
          visibleSetter={setShowRegisterEmployeesDialog}
          onHide={handleRegisterEmployeeDialogHide}
        />
      }
    </>
  );
}