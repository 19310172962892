import {Switch} from 'react-router-dom';
import useAuthentication from 'hooks/useAuthentication.hook';
import MENU from 'consts/menu.consts';
import Route from 'routers/components/Route.component';
import RouterContainer from 'routers/components/RouterContainer.component';
import Login from 'views/authentication/Login.view';
import LoginSSO from 'views/authentication/LoginSSO.view';

export default function UnauthenticatedRouter() {
  const authentication = useAuthentication();

  const menu = [
    // {
    //   label: 'Iniciar sesión con jvelazquez@sisu.mx',
    //   command: async () => {
    //     await authentication.signInWithEmailAndPassword('jvelazquez@sisu.mx', '0123456789');
    //   }
    // },
    {
      label: MENU.UNAUTHENTICATED.LOGIN.ROOT.LABEL,
      redirect: MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH
    }
    // {
    //   label: MENU.UNAUTHENTICATED.LOGIN.ROOT.LABEL,
    //   redirect: MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH
    // }
  ];

  return (
    <RouterContainer menu={menu}>
      <Switch>
        <Route exact path={"/"} component={Login}/>
        <Route exact container path={MENU.UNAUTHENTICATED.LOGIN.ROOT.PATH} component={Login}/>
        <Route exact container path={MENU.UNAUTHENTICATED.LOGIN.SSO.PATH} component={LoginSSO}/>
      </Switch>
    </RouterContainer>
  );
}