import {useState} from 'react';

export default function useAsyncLoading(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfully, setIsSuccessfully] = useState(true);
  const [isInitialized, setIsInitialized] = useState(props?.isInitialized ?? false);
  const [isError, setIsError] = useState(props?.isError ?? null);

  const allPromises = async (promises, options = {}) => {
    try {
      setIsLoading(true);
      setIsSuccessfully(false);
      setIsInitialized(options.initialization !== true);
      setIsError(null);
      const results = await Promise.all(promises);
      const successfully = results.every((result) => result.success);
      setIsLoading(false);
      setIsSuccessfully(successfully);
      setIsInitialized(options.initialization !== true || successfully);
      setIsError(successfully === false);
      return {fulfilled: true, successfully, results};
    }
    catch(error) {
      setIsLoading(false);
      setIsError(true);
      return {fulfilled: false};
    }
  }

  return {
    isLoading,
    isSuccessfully,
    isInitialized,
    allPromises,
    isError
  };
}