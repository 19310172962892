import {useState, useEffect} from 'react';
import Icono from 'design/assets/img/cta/icons/icon-campaign.svg';
import TOAST from 'consts/toast.consts';
import useMessages from 'hooks/useMessages.hook';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonSplit from 'design/design_components/neo/button/NeoButtonSplit.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import ActionDialog from 'components/ActionDialog.component';
import LoadingDialog from 'components/LoadingDialog.component';
import CampaignsService from 'services/campaigns.service';
import useAuthentication from 'hooks/useAuthentication.hook';
import numberUtil from 'utils/number.util';

export default function CampaignDetailHeader(props) {
  const authentication = useAuthentication();
  const messages = useMessages();
  const [downloadMessagesButtonClickDisabled, setDownloadMessagesButtonClickDisabled] = useState(false);
  const [downloadQuickRepliesButtonClickDisabled, setDownloadQuickRepliesButtonClickDisabled] = useState(false);
  const [loadingDialogText, setLoadingDialogText] = useState('');
  const [loadingDialogProgress, setLoadingDialogProgress] = useState(0);
  const [campaign, setCampaign] = useState(props.campaign);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showStopDialog, setShowStopDialog] = useState(false);
  const [showDownloadingDialog, setShowDownloadingDialog] = useState(false);
  const [hasQuickReplies] = useState(() => {
    const quickRepliesButtons1 = props.campaign.template?.structure?.buttons?.filter((button) => button.type == 'QUICK_REPLY').map((button) => button.text);
    const quickRepliesButtons2 = props.campaign.flow?.structure?.buttons?.filter((button) => button.type == 'QUICK_REPLY').map((button) => button.text);
    return (quickRepliesButtons1 ?? quickRepliesButtons2 ?? []).length > 0;
  });
  const [splitButtonItems, setSplitButtonItems] = useState([]);

  useEffect(() => {
    Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
      authentication.socket.off(eventName);
      authentication.socket.on(eventName, eventFn);
    })

    return () => {
      Object.entries(socketListeners).forEach(([eventName]) => {
        authentication.socket.off(eventName);
      })
    }
  }, [])

  useEffect(() => rebootSplitButtonItems(), [hasQuickReplies]);

  const rebootSplitButtonItems = () => {
    const items = [{
      label: 'Reporte de fallidos',
      icon: 'pi pi-download',
      command: () => {}
    }];
    if(hasQuickReplies === true) {
      items.push({
        label: 'Reporte de respuestas',
        icon: 'pi pi-download',
        command: handleDownloadQuickRepliesButtonClick
      });
    }
    setSplitButtonItems(items);
  }

  const cancelCampaign = async () => {
    const {lineId, name, recurrentCampaignNumber} = campaign;
    const response = await CampaignsService.updateCampaignStatus(lineId, name, 'CANCELLED', {recurrentCampaignNumber});
    if(response.success) {
      const updatedCampaign = {...campaign, status: 'CANCELLED', cancelledAt: new Date()};
      return {success: true, updatedCampaign}
    }
    return {success: false};
  }

  const stopCampaign = async () => {
    const {lineId, name, recurrentCampaignNumber} = campaign;
    const response = await CampaignsService.updateCampaignStatus(lineId, name, 'STOPPED', {recurrentCampaignNumber});
    if(response.success) {
      const updatedCampaign = {...campaign, status: 'STOPPED', stoppedAt: new Date()};
      return {success: true, updatedCampaign}
    }
    return {success: false};
  }

  const handleDownloadMessagesButtonClick = async () => {
    setShowDownloadingDialog(true);
    setLoadingDialogText('Iniciando proceso...');
    setLoadingDialogProgress(0);
    setDownloadMessagesButtonClickDisabled(true);
    const {lineId, name, recurrentCampaignNumber} = props.campaign;
    const response = await CampaignsService.getCampaignMessagesDetailsFile(lineId, name, {recurrentCampaignNumber});
    if(response.success) {
      const downloadInstance = document.createElement('a');
      downloadInstance.href = response.payload.urlDownload;
      downloadInstance.target = '_blank';
      downloadInstance.click();
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se descargó el reporte de mensajes.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo descargar el reporte de mensajes, inténtalo de nuevo.');
    }
    setShowDownloadingDialog(false);
  }

  const handleDownloadQuickRepliesButtonClick = async () => {
    setShowDownloadingDialog(true);
    setLoadingDialogText('Iniciando proceso...');
    setLoadingDialogProgress(0);
    setDownloadQuickRepliesButtonClickDisabled(true);
    const {lineId, name, recurrentCampaignNumber} = props.campaign;
    const response = await CampaignsService.getCampaignResponsesDetailsFile(lineId, name, {recurrentCampaignNumber});
    if(response.success) {
      const downloadInstance = document.createElement('a');
      downloadInstance.href = response.payload.urlDownload;
      downloadInstance.target = '_blank';
      downloadInstance.click();
      messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se descargó el reporte de respuestas.');
    }
    else {
      messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo descargar el reporte de respuestas, inténtalo de nuevo.');
    }
    setShowDownloadingDialog(false);
  }

  const handleCancelDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        setCampaign(payload.updatedCampaign);
        props.onChange(payload.updatedCampaign);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se canceló la campaña.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo cancelar la campaña, inténtalo de nuevo.');
      }
    }
  }

  const handleStopDialogHide = (event) => {
    if (event.action == 'accept') {
      const payload = event.payload;
      if (payload?.success === true) {
        setCampaign(payload.updatedCampaign);
        props.onChange(payload.updatedCampaign);
        messages.showToast(TOAST.SEVERITY.SUCCESS, 'Operación exitosa', 'Se detuvo la campaña.');
      }
      else {
        messages.showToast(TOAST.SEVERITY.ERROR, 'Algo salió mal', 'No se pudo detener la campaña, inténtalo de nuevo.');
      }
    }
  }

  const socketListeners = {
    campaignMessagesDetailsFileProgress: ({ companyId, lineId, campaign, status, progress, numberOfMessagesToSetting }) => {
      setDownloadMessagesButtonClickDisabled(status === 'COMPLETE' ? false : true);
      if(props?.campaign?.lineId === campaign?.lineId 
        && props?.campaign?.name === campaign?.name
        && props?.campaign?.recurrentCampaignNumber === campaign?.recurrentCampaignNumber
      ) {
        if(typeof progress === 'number') {
          setLoadingDialogProgress(progress);
        }
        switch (status) {
          case 'CREATING_FILE':
            setLoadingDialogText('Creando archivo de mensajes...');
            break;
          case 'PROCESSING_DATA':
            setLoadingDialogText('Procesando mensajes...');
            break;
          case 'SETTING_MESSAGES_DATA':
            setLoadingDialogText(`${numberUtil.format(numberOfMessagesToSetting)} mensajes procesados...`);
            break;
          case 'SETTING_ERROR_LOGS_DATA':
            setLoadingDialogText(`${numberUtil.format(numberOfMessagesToSetting)} mensajes sin enviar procesados...`);
            break;
          case 'UPLOADING_FILE':
            setLoadingDialogText('Finalizando la creación del archivo de mensajes...');
            break;
          case 'ASSIGNING_PERMISSIONS':
            setLoadingDialogText('Configurando permisos para la descarga del archivo de mensajes...');
            break;
          case 'COMPLETE':
            setLoadingDialogText('Archivo generado!');
            break;
          default:
            break;
        }
      }
    },
    campaignResponsesDetailsFileProgress: ({ companyId, lineId, campaign, status, progress, numberOfAnswersToSetting }) => {
      setDownloadQuickRepliesButtonClickDisabled(status === 'COMPLETE' ? false : true);
      if(props?.campaign?.lineId === campaign?.lineId 
        && props?.campaign?.name === campaign?.name
        && props?.campaign?.recurrentCampaignNumber === campaign?.recurrentCampaignNumber
      ) {
        if(typeof progress === 'number') {
          setLoadingDialogProgress(progress);
        }
        switch (status) {
          case 'CREATING_FILE':
            setLoadingDialogText('Creando archivo de respuestas...');
            break;
          case 'PROCESSING_DATA':
            setLoadingDialogText('Procesando respuestas...');
            break;
          case 'SETTING_DATA':
            setLoadingDialogText(`${numberUtil.format(numberOfAnswersToSetting)} respuestas obtenidas...`);
            break;
          case 'UPLOADING_FILE':
            setLoadingDialogText('Finalizando la creación del archivo de respuestas...');
            break;
          case 'ASSIGNING_PERMISSIONS':
            setLoadingDialogText('Configurando permisos para la descarga del archivo de respuestas...');
            break;
          case 'COMPLETE':
            setLoadingDialogText('Archivo generado!');
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          icon={Icono}
          title={`
            ${campaign.name}
            ${
              campaign.isRecurrent === true && campaign.recurrentCampaignNumber !== undefined 
              ? `/ Envío ${campaign.recurrentCampaignNumber}`
              : ''
            }
          `}
          subtitle='Detalle de campaña'
        />
        <NeoButtonSection align='right' >
          {
            (campaign.status == 'PENDING') &&
            <NeoButtonMain 
              label='Cancelar campaña' 
              onClick={() => setShowCancelDialog(true)} 
            />
          }
          {
            (campaign.status == 'SENDING') &&
            <NeoButtonMain 
              label='Detener campaña' 
              onClick={() => setShowStopDialog(true)}
            />
          }
          {
            ((campaign.status == 'FINISHED' || campaign.status == 'STOPPED' || campaign.status == 'FAILED') && props.results === true) &&
            <>
              <NeoButtonMain 
                icon="pi pi-download" 
                label='Reporte de mensajes' 
                onClick={handleDownloadMessagesButtonClick}
                disabled={downloadMessagesButtonClickDisabled}
              />
              {
                (hasQuickReplies) &&
                <NeoButtonMain 
                  icon="pi pi-download" 
                  label='Reporte de respuestas' 
                  onClick={handleDownloadQuickRepliesButtonClick}
                  disabled={downloadQuickRepliesButtonClickDisabled}
                />
              }
              {/* <NeoButtonSplit
                icon='pi pi-download' 
                label='Reporte de mensajes' 
                onClick={handleDownloadMessagesButtonClick}
                model={splitButtonItems}
              /> */}
            </>
          }
        </NeoButtonSection>
      </NeoGridContainer>
      {
        <ActionDialog
          header='Cancelar campaña'
          visible={showCancelDialog}
          visibleSetter={setShowCancelDialog}
          acceptMessage='Cancelando campaña'
          onAccept={cancelCampaign}
          onHide={handleCancelDialogHide}
        >
          Se cancelará la campaña. ¿Deseas proceder?
        </ActionDialog>
      }
      {
        <ActionDialog
          header='Detener campaña'
          visible={showStopDialog}
          visibleSetter={setShowStopDialog}
          acceptMessage='Deteniendo campaña'
          onAccept={stopCampaign}
          onHide={handleStopDialogHide}
        >
          Se detendrá la campaña. ¿Deseas proceder?
        </ActionDialog>
      }
      {
        <LoadingDialog
          visible={showDownloadingDialog}
          message={loadingDialogText}
          progress={loadingDialogProgress}
          showValue={false}
        />
      }
    </>
  );
}