import backendRequestUtil from 'utils/backendRequest.util';

export default class WebhookService {

  static async getWebhookUrl() {
    const {success, body, error} = await backendRequestUtil.get('/api/webhook');
    return success ? {success, payload: {url: body.url ?? ''}} : {success, error};
  }

  static async updateWebhookUrl(url) {
    const requestBody = {url};
    const {success, error} = await backendRequestUtil.put('/api/webhook', requestBody);
    if(!success && !error.internal) {
      if(/^[^]+ debe ser una url válida$/i.test(error.message)) {
        error.code = 'INVALID_URL';
      }
    }
    return success ? {success} : {success, error};
  }

}