import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import StartConversationTemplateVariable from 'views/conversations/components/StartConversationTemplateVariable.component';

const UNMAPPED = '';

export default function StartConversationTemplateButtonsVariables(props) {
  const [variables, setVariables] = useState([]);

  useEffect(() => getVariables(), [props.requiredVariables]);

  const getVariables = () => {
    const requiredVariables = props.requiredVariables?.map((variable) => {
      const label = variable.param;
      const index = variable.index;
      const key = variable.param.substring(2, variable.param.length - 2);
      const value = props.value?.[index]?.[key] ?? {type: 'OPTION', payload: UNMAPPED};
      return {label, index, key, value};
    });
    setVariables(requiredVariables ?? []);
  }

  const handleVariableChange = (index, event) => {
    const updatedVariables = variables.map((variable) => ({...variable}));
    updatedVariables[index].value = event;
    const variablesEntries = updatedVariables.map((variable) => [variable.index, {[variable.key]: variable.value}]);
    const payload = Object.fromEntries(variablesEntries);
    props.onChange(payload);
  }
  
  return (
    <>
      {
        variables.map((variable, index) => (
          <>
            <NeoInnerSubtitle>Botón {variable.index + 1}</NeoInnerSubtitle>
            <StartConversationTemplateVariable
              optionPlaceholder='Campo a mapear'
              textPlaceholder='Valor de variable'
              variable={variable.label}
              value={variable.value}
              options={props.options}
              onChange={(event) => handleVariableChange(index, event)}
            />
          </>
        ))
      }
    </>
  );
}