import { useEffect } from 'react';
import LineIconItem from 'views/chat/components/items/LineIconItem.component';
import useChat from 'hooks/useChat.hook';

export default function LinesSection() {
  const chat = useChat();

  useEffect(() => {
    chat.dispatch((TYPE) => {
      return {
        type: TYPE.QUICKLY_REPLIES.ASSIGNED.FILTERED.TYPE,
        payload: {
          quicklyReplies: chat.state.quicklyReplies.filter(({lineId}) => {
            return !lineId || lineId === chat.state?.lineSelected?.id;
          })
        }
      }
    })
  }, [chat.state.lineSelected, chat.state.quicklyReplies]);

  return (
    <div className='LinesSection'>
      {chat.state.lines.map((line, key) => <LineIconItem 
        key={key}
        name={line?.name}
        photo={line?.photo}
        isAlert={line?.isAlert}
        onClick={() => {
          chat.dispatch((TYPE) => {
            return {
              type: TYPE.LINE.SELECTED.TYPE,
              payload: {
                line
              }
            }
          })
        }}
      />)}
    </div>
  );
}