import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import { DateTime } from 'luxon';

/**
 * @typedef {Object} RefType
 * @property {Object} current
 */

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {Object} event Chat event
 */

const Component = {
  name: 'ChatComment',
  altName: 'ChatBalloon',

  /**
   * @type {React.FC<Props>}
   * @type {RefType}
   */
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const createBody = () => {
      const text = props?.event?.comment && <div className={`${Component.altName}__body__text`}>
        <span>{props.event.comment}</span>
      </div>;

      return (
        <div className={`${Component.altName}__body`}>
          {text}
        </div>
      );
    };

    const createFooter = () => {
      const agent = props?.event?.user && <div className={`${Component.altName}__footer__agent`}>
        {<span>{props.event.user?.fullName ?? props.event.user?.email}</span>}
      </div>;
      const date = props?.event?.timestamp && <div className={`${Component.altName}__footer__date`}>
      <span>
        {(() => {
          const now = DateTime.now();
          const datetime = DateTime.fromISO(props.event.timestamp);
          const diff = now.diff(datetime, ['days']).toObject();

          if(diff.days < 1) {
            return datetime.toFormat('h:mm a').toLowerCase();
          } else {
            return datetime.toFormat('dd-MM-yyyy h:mm a').toLowerCase();
          }
        })()}
      </span>
    </div>;

      return (
        <div className={`${Component.altName}__footer`}>
          {agent}
          {date}
        </div>
      );
    };

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.altName, `${Component.altName}--comment`, props.className);
    const body = createBody();
    const footer = createFooter();

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        {body}
        {footer}
      </div>
    );
  })
};

Component.element.displayName = Component.altName;

Component.element.propTypes = {
  id: PropTypes.string,
  event: PropTypes.object,
};

Component.element.defaultProps = {
  __TYPE: Component.altName,
  id: null,
  style: null,
  className: null,
  event: null,
};

export default Component.element;