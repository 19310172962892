import { useState, useEffect } from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoDialog from 'design/design_components/neo/overlay/NeoDialog.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import InternalSpinner from 'components/InternalSpinner.component';
import NeoButtonText from 'design/design_components/neo/button/NeoButtonText.base';

export default function ActionDialog(props) {
  const [action, setAction] = useState();
  const asyncState = useAsyncState({ isInitialized: true });

  useEffect(() => reboot(), [props.visible]);

  const reboot = () => {
    if (props.visible === true) {
      setAction(null);
    }
  }

  const getAsyncStatusResponse = async (callback) => {
    try {
      const payload = await callback();
      return { success: true, payload }
    }
    catch (error) {
      return { success: false };
    }
  }

  const getHidePayload = async (callback) => {
    if (callback && typeof callback == 'function') {
      const allPromises = await asyncState.allPromises([getAsyncStatusResponse(callback)]);
      if (allPromises.fulfilled && allPromises.successfully) {
        return allPromises.results[0].payload;
      }
    }
  }

  const handleCancel = async () => {
    setAction('cancel');
    const payload = await getHidePayload(props.onCancel);
    props.visibleSetter(false);
    if (props.onHide) { props.onHide({ action: 'cancel', payload }); }
  }

  const handleAccept = async () => {
    setAction('accept');
    const payload = await getHidePayload(props.onAccept);
    const hide = props.acceptHiddenController ? props.acceptHiddenController(payload) : true;
    if (hide === true) {
      props.visibleSetter(false);
      if (props.onHide) { props.onHide({ action: 'accept', payload }); }
    }
  }

  const handleDelete = async () => {
    setAction('delete');
    const payload = await getHidePayload(props.onDelete);
    const hide = props.deleteHiddenController ? props.deleteHiddenController(payload) : true;
    if (hide) {
      props.visibleSetter(false)
      if (props.onHide) {
        props.onHide({ action: 'delete', payload });
      }
    }
  }

  const elements = {
    header: (
      <>
        {
          (!asyncState.isLoading) &&
          <>{props.header}</>
        }
      </>
    ),
    footer: (
      <>
        {
          (!asyncState.isLoading) &&
          <>
            {
              !!props.delete &&
              <NeoButtonText 
                label='Eliminar' 
                disabled={props.deleteDisabled}
                onClick={handleDelete}
                extra="p-button-danger p-button-text"
              /> 
            }
            <NeoButtonMain 
              label={props.acceptLabel ?? 'Aceptar'} 
              disabled={props.acceptDisabled === true}
              onClick={handleAccept}
            />
          </>
        }
      </>
    )
  };

  return (
    <NeoDialog
      header={elements.header}
      footer={elements.footer}
      visible={props.visible}
      maximizable={props.maximizable === true}
      closable={props.closable === true || props.cancel !== false}
      dismissableMask={props.dismissableMask === true}
      onHide={handleCancel}
      extra={props.extra}
    >
      {
        (asyncState.isLoading) &&
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <InternalSpinner size={40} />
          <span style={{ marginLeft: '1em' }}>
            {
              (action === 'delete') && 
              <>{props.deleteMessage ?? 'Por favor espere'}</>
            }
            {
              (action == 'cancel') &&
              <>{props.cancelMessage ?? 'Por favor espere'}</>
            }
            {
              (action == 'accept') &&
              <>{props.acceptMessage ?? 'Por favor espere'}</>
            }
          </span>
        </div>
      }
      {
        (!asyncState.isLoading) &&
        <>{props.children}</>
      }
    </NeoDialog>
  );

}
