import { useState, useEffect } from 'react';
import useStateParams from 'hooks/useStateParams.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoCardInterna from 'design/design_components/neo/panel/NeoCardInterna.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoInputText from 'design/design_components/neo/form/NeoInputText.base';
import NeoInputMask from 'design/design_components/neo/form/NeoInputMask.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoFlexColumn from 'design/design_components/neo/layout/NeoFlexColumn.base';
import InfoTooltip from 'components/InfoTooltip.component';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import NeoDataThird from 'design/design_components/neo/data/NeoDataThird.base';
import stringUtil from 'utils/string.util';

const phonePattern = /^[+][0-9]{11,13}[_]*$/;
const dynamicUrlPattern = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)(\{\{[_0-9a-zA-Z]+\}\})?$/i;

const buttonsTypes = [
  { label: 'Ninguno', value: 'NONE' },
  { label: 'Respuestas rápidas', value: 'QUICK_REPLIES' },
  { label: 'Acciones', value: 'ACTIONS' }
];

const actionButtonTypes = [
  { label: 'Url', value: 'URL' },
  { label: 'Teléfono', value: 'PHONE' }
];

export default function TemplateButtonsStructureForm(props) {
  const [state] = useStateParams();
  const [selectedType, setSelectedType] = useState();
  const [buttons, setButtons] = useState([]);
  const [buttonsParamsErrors, setButtonsParamsErrors] = useState([]);
  const [isTypeDropdownDisabled, setIsTypeDropdownDisabled] = useState(false);
  const [urlVariableCount, setUrlVariableCount] = useState(0);

  useEffect(() => initialize(), []);
  useEffect(() => handleChange(), [buttons]);

  const initialize = () => {
    const selectedType = !state.buttons || !state.buttons[0] ? 'NONE'
      : state.buttons[0].type == 'QUICK_REPLY' ? 'QUICK_REPLIES'
        : 'ACTIONS';
    setButtons(state.buttons ?? []);
    setSelectedType(selectedType);
    setIsTypeDropdownDisabled(selectedType == 'ACTIONS' && state.buttons?.length == 2);
  }

  const handleChange = () => {
    const event = { valid: true, buttons: undefined };
    if (selectedType != 'NONE') {
      event.buttons = buttons.map(({textError, ...button}) => button);
      const validButtons = buttons.map((button, index) => {
        const lowerCaseType = button.type?.toLowerCase();
        const containsEmojis = stringUtil.containsEmojis(button.text);
        buttons[index].textError = containsEmojis ? 'El texto del botón no puede contener emojis.' : '';
        if (!lowerCaseType) { return false; }
        if (button.type == 'URL') {
          if (button.url.length > 0) {
            const params = button.url.match(/\{\{[_0-9a-zA-Z]+\}\}/g) ?? [];
            setUrlVariableCount(params.length);
            if (params.length > 1) {
              handleButtonParamErrorChange(index, 'La dirección url solo puede contener 1 variable al final.');
              return false;
            }
            if (!dynamicUrlPattern.test(button.url)) {
              handleButtonParamErrorChange(index, 'La dirección url es inválida.');
              return false;
            }
            handleButtonParamErrorChange(index, '');
          }
          else {
            handleButtonParamErrorChange(index, '');
            return false;
          }
        }
        if (button.type == 'PHONE') {
          if (button.phone.length > 0) {
            if (!phonePattern.test(button.phone)) {
              handleButtonParamErrorChange(index, 'El número de teléfono es inválido.');
              return false;
            }
            handleButtonParamErrorChange(index, '');
          }
          else {
            handleButtonParamErrorChange(index, '');
            return false;
          }
        }
        return button.text.length > 0 && !containsEmojis;
      });
      const allValidButtons = validButtons.every((value) => value == true);
      event.valid = (buttons.length > 0 && allValidButtons);
    }
    props.onChange(event);
  }

  const handleTypeDropdownChange = (event) => {
    setButtons([]);
    setButtonsParamsErrors([]);
    setSelectedType(event.value);
  }

  const handleButtonTextInputChange = (index, event) => {
    const buttonText = event.value ?? event.target.value;
    const updatedButtons = [...buttons];
    updatedButtons[index].text = buttonText;
    setButtons(updatedButtons);
  }

  const handleButtonTypeDropdownChange = (index, event) => {
    const type = event.value;
    const otherButtonIndex = index == 0 ? 1 : 0;
    const otherButton = buttons[otherButtonIndex];
    const lowerCaseType = type.toLowerCase();
    const updatedButtons = [...buttons];
    const updatedButtonsParamsErrors = [...buttonsParamsErrors];
    const { text } = updatedButtons[index];
    updatedButtons[index] = { type: event.value, text, [lowerCaseType]: '' };
    updatedButtonsParamsErrors[index] = '';
    if (otherButton) {
      const otherButtonType = type == 'URL' ? 'PHONE' : 'URL';
      const { text } = updatedButtons[otherButtonIndex];
      updatedButtons[otherButtonIndex] = { type: otherButtonType, text, [otherButtonType.toLowerCase()]: '' };
      updatedButtonsParamsErrors[otherButtonIndex] = '';
      setIsTypeDropdownDisabled(true);
    }
    setUrlVariableCount(0);
    setButtons(updatedButtons);
    setButtonsParamsErrors(updatedButtonsParamsErrors);
  }

  const handleUrlButtonInputChange = (index, event) => {
    const url = event.value ?? event.target.value;
    const updatedButtons = [...buttons];
    updatedButtons[index].url = url;
    setButtons(updatedButtons);
  }

  const handlePhoneButtonInputChange = (index, event) => {
    const phone = event.value ?? event.target.value;
    const updatedButtons = [...buttons];
    updatedButtons[index].phone = phone;
    setButtons(updatedButtons);
  }

  const handleAddButtonClick = () => {
    const button = { type: null, text: '' };
    if (selectedType == 'QUICK_REPLIES') {
      button.type = 'QUICK_REPLY';
    }
    else if (buttons.length == 1) {
      if (buttons[0].type == 'URL') {
        button.type = 'PHONE';
        button.phone = '';
        setIsTypeDropdownDisabled(true);
      }
      if (buttons[0].type == 'PHONE') {
        button.type = 'URL';
        button.url = '';
        setIsTypeDropdownDisabled(true);
      }
    }
    setButtons([...buttons, button]);
    setButtonsParamsErrors([...buttonsParamsErrors, '']);
  }

  const handleButtonParamErrorChange = (index, value) => {
    const updatedButtonsParamsErrors = [...buttonsParamsErrors];
    updatedButtonsParamsErrors[index] = value;
    setButtonsParamsErrors(updatedButtonsParamsErrors);
  }

  const deleteButton = (index) => {
    if (buttons[index].type == 'URL') {
      setUrlVariableCount(0);
    }
    const reducedButtons = [...buttons];
    const reducedButtonsParamsErrors = [...buttonsParamsErrors];
    reducedButtons.splice(index, 1);
    reducedButtonsParamsErrors.splice(index, 1);
    setButtons(reducedButtons);
    setButtonsParamsErrors(reducedButtonsParamsErrors);
    setIsTypeDropdownDisabled(false);
  }

  return (
    <NeoCard>
      <NeoInnerSubtitle >Botones</NeoInnerSubtitle>
      <NeoDropdown
        label='Tipo de botones'
        value={selectedType}
        options={buttonsTypes}
        onChange={handleTypeDropdownChange}
      />
      <NeoColumn col='12'>
        {
          (selectedType != 'NONE') &&
          <NeoGridContainer>
            {
              buttons.map((button, index) => (
                <NeoFlexColumn col="12" md={selectedType === 'ACTIONS' ? '6' : '4'}   >
                  <NeoCardInterna extra='card-outlined p-0'>
                    <NeoGridContainer >
                      <NeoInnerSubtitle extra='p-pl-2' col='6'>{`Botón ${index + 1}`}</NeoInnerSubtitle>
                      <NeoButtonSection align='right' ai="end" extra='p-col-align-center p-flex-sm-row p-flex-column'>
                        <NeoButtonMain extra="button-circle p-mr-2" label='' icon="pi pi-trash" onClick={() => deleteButton(index)} />
                      </NeoButtonSection>
                      {
                        (selectedType == 'ACTIONS') &&
                        <NeoDropdown
                          col='12'
                          label='Tipo de botón'
                          value={button.type}
                          options={actionButtonTypes}
                          disabled={isTypeDropdownDisabled}
                          onChange={(event) => handleButtonTypeDropdownChange(index, event)}
                        />
                      }
                      <>
                        <NeoInputText
                          label='Texto del botón'
                          maxlength={25}
                          col="12"
                          value={button.text}
                          error={button.textError}
                          onChange={(event) => handleButtonTextInputChange(index, event)}
                        />
                        <NeoFlexColumn extra="p-pt-0">
                          <NeoDataThird extra="p-mt-0 text-align-right" fact={button.text.length + "/25"} label="Caracteres" />
                        </NeoFlexColumn>
                      </>
                      {
                        (button.type === 'URL') &&
                        <>
                          <NeoInputText
                            col="12"
                            label='Dirección url'
                            rightIcon={<InfoTooltip id='url' body='La dirección url puede contener solo una variable al final. Ejemplo: https://www.ejemplo.com/{{variable}}' />}
                            keyfilter={/^[^ ]+$/}
                            value={button.url}
                            error={buttonsParamsErrors[index]}
                            onChange={(event) => handleUrlButtonInputChange(index, event)}
                          />

                          <NeoFlexColumn extra="p-pt-0">
                            <NeoDataThird extra="p-mt-0 text-align-right" fact={urlVariableCount + "/1"} label="Variables" />
                          </NeoFlexColumn>
                        </>
                      }
                      {
                        (button.type === 'PHONE') &&
                        <NeoInputMask
                          col="12"
                          label='Número de teléfono'
                          rightIcon={<InfoTooltip id='phone' body='El teléfono debe incluir el código de país.' />}
                          slotChar=''
                          mask='+99999999999?99'
                          value={button.phone}
                          error={buttonsParamsErrors[index]}
                          onChange={(event) => handlePhoneButtonInputChange(index, event)}
                        />
                      }
                    </NeoGridContainer>
                  </NeoCardInterna>
                </NeoFlexColumn>
              ))
            }
            {
              ((selectedType == 'QUICK_REPLIES' && buttons.length < 3) || buttons.length < 2) &&
              <NeoFlexColumn>
                <NeoCardInterna extra='p-pt-0 card-outlined center-btn'>
                  <NeoButtonMain
                    label='Añadir botón'
                    onClick={handleAddButtonClick}
                  />
                </NeoCardInterna>
              </NeoFlexColumn>
            }
          </NeoGridContainer>
        }
      </NeoColumn>
    </NeoCard >
  );
}