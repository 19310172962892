import Icono from 'design/assets/img/cta/icons/icon-config.svg';
import NeoTitleMain from 'design/design_components/neo/title/NeoTitleMain.base';
import Settings from './components/Settings.component';

export default function SettingsView() {
  return (
    <>
      <NeoTitleMain title='Configuración' icon={Icono}/>
      <Settings/>
    </>
  );
}