import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import ActionDialog from 'components/ActionDialog.component';
import ContactsService from 'services/contacts.service';
import useComponent from 'hooks/useComponent.hook';
import EmployeesService from 'services/employees.service';

export default (props) => {
  const component = useComponent({
    name: 'AssignAgentDialog',
    state: {
      agentSelectedId: null
    },
    async: {
      get: async () => {
        const response = await EmployeesService.getAgents();
        if(response.success) {
          return { agents: response.payload.agents };
        }
        return null;
      }
    }
  });

  component.useEffect(() => resetFrom(), [props.contact]);

  const resetFrom = () => {
    component.setState((state) => {state.agentSelectedId = props.contact?.agentAssignedId ?? null});
  }

  const handleHide = (event) => {
    if(event.action == 'accept') { props.onHide(event.payload); }
    if(event.action == 'cancel' || !event.payload.success) { resetFrom(); }
  }

  const updateAgent = async () => {
    const response = await ContactsService.updateContact(props.contact.id, { agentAssignedId: component.state.agentSelectedId });
    if(response.success) {
      const agent = component.async.get.data.agents.find(({ id }) => {
        return id === component.state.agentSelectedId;
      })
      return {success: true, payload: agent};
    }
    return {success: false, internal: true};
  }

  const agentsDropdown = component.useMemo(() => {
    if(Array.isArray(component.async.get.data?.agents)) {
      return component.async.get.data.agents.map((agent) => {
        const { id, name, email } = agent;
        return { 
          label: name ?? email,
          value: id
        }
      })
    }
    return [];
  }, [component.async.get.data?.agents])

  return (
    <ActionDialog
      header='Asignar agente'
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptMessage='Asignando agente'
      acceptDisabled={component.state.agentSelectedId === null}
      onAccept={updateAgent}
      onHide={handleHide}
      acceptHiddenController={(payload) => payload.success || payload.internal}
    >
      <NeoDropdown
        label="Seleccionar agente"
        value={component.state.agentSelectedId}
        options={agentsDropdown}
        onChange={({ value: agentSelectedId }) => {
          component.setState((state) => {state.agentSelectedId = agentSelectedId});
        }}
      />
    </ActionDialog>
  );
}
