import { forwardRef, useRef, useState } from 'react';
import ComponentUtils from 'utils/component.util';
import ObjectUtils from 'utils/object.util';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import CreateCommentDialog from 'views/chat/components/dialogs/CreateCommentDialog.component';

const Component = {
  name: 'ChatContentCommentIconItem',
  element: forwardRef((props, ref) => {
    const elementRef = useRef(ref);

    const [showCreateCommentDialog, setShowCreateCommentDialog] = useState(false);

    const otherProps = ObjectUtils.findDiffKeys(props, Component.element.defaultProps);
    const className = ComponentUtils.classNames(Component.name, props.className);

    return (
      <div id={props.id} ref={elementRef} className={className} style={props.style} {...otherProps}>
        <div className={`${Component.name}__body`}>
          <NeoButtonMain 
            icon='fa fa-comment-medical'
            onClick={() => setShowCreateCommentDialog(true)}
          />
          {
            <CreateCommentDialog
              visible={showCreateCommentDialog}
              visibleSetter={setShowCreateCommentDialog}
              onHide={() => {}}
              onSubmit={props.onSubmit}
            />
          }
        </div>
      </div>
    );
  })
};

Component.element.defaultProps = {
  __TYPE: Component.name,
  id: null,
  style: null,
  className: null,
  onSubmit: null
};

export default Component.element;