import {useState, useEffect} from 'react';
import NeoInnerSubtitle from 'design/design_components/neo/title/NeoInnerSubtitle.base';
import TemplateVariableMapping from 'views/campaigns/components/TemplateVariableMapping.component';
import templatesUtil from 'utils/templates.util';

const UNMAPPED = '';

export default function TemplateHeaderMapping(props) {
  const [variables, setVariables] = useState(() => {
    if(props.requiredVariables?.type !== undefined) {
      if(props.requiredVariables?.type === 'TEXT') {
        const variable = props.requiredVariables.param;
        const label = variable;
        const key = `header.text.${variable.substring(2, variable.length - 2)}`;
        const map = props.mapping[key] ?? UNMAPPED;
        return [{label, key, map}];
      }
      const lowerCaseType = props.requiredVariables.type.toLowerCase();
      const headerType = templatesUtil.getTemplateHeaderType(props.requiredVariables.type, {lowerCase: true})
      const idKey = `header.${lowerCaseType}.id`;
      const urlKey = `header.${lowerCaseType}.url`;
      const label = [
        {label: `Id de ${headerType}`, value: idKey},
        {label: `Url de ${headerType}`, value: urlKey}
      ];
      const key = props.mapping[idKey] !== undefined ? idKey : urlKey;
      const map = props.mapping[key] ?? UNMAPPED;
      const requiredVariables = [{label, key, map}];
      if(props.requiredVariables?.type === 'DOCUMENT') {
        requiredVariables.push({
          label: 'Nombre de documento',
          key: 'header.document.filename',
          map: props.mapping['header.document.filename'] ?? UNMAPPED
        });
      }
      return requiredVariables;
    }
    return [];
  });

  useEffect(() => handleMappingChange(), [props.mapping]);

  const handleMappingChange = () => {
    const updatedVariables = variables.map((variable) => {
      if(Array.isArray(variable.label) === true) {
        const [key] = variable.label
          .map(({value}) => value)
          .filter((value) => props.mapping[value] !== undefined);
        if(key !== undefined) {
          const map = props.mapping[key] ?? UNMAPPED;
          return {...variable, key, map};
        }
      }
      const map = props.mapping[variable.key] ?? UNMAPPED;
      return {...variable, map};
    });
    setVariables(updatedVariables);
  }

  const handleVariableKeyChange = (event, index) => {
    const updatedVariables = [...variables];
    updatedVariables[index].key = event;
    handleVariableChange(updatedVariables);
  }

  const handleVariableMappingChange = (event, index) => {
    const updatedVariables = [...variables];
    updatedVariables[index].map = event;
    handleVariableChange(updatedVariables);
  }

  const handleVariableChange = (updatedVariables) => {
    const mapping = Object.fromEntries(updatedVariables.map(({key, map}) => [key, (map !== UNMAPPED ? map : undefined)]));
    const unusedKeys = updatedVariables.map(({label, key}) => (
      Array.isArray(label) === true
        ? label.map(({value}) => value).filter((value) => value !== key)
        : []
    ));
    const unusedMapping = Object.fromEntries([].concat(...unusedKeys).map((key) => [key, undefined]));
    props.onChange({...mapping, ...unusedMapping});
  }

  return (
    <>
      {
        (variables.length > 0) &&
        <>
          <NeoInnerSubtitle>Encabezado</NeoInnerSubtitle>
          {
            variables.map((variable, index) => (
              <TemplateVariableMapping
                variable={variable.label}
                mapping={variable.map}
                variableKey={variable.key}
                databaseType={props.databaseType}
                mappingOptions={props.mappingOptions}
                onMappingChange={(event) => handleVariableMappingChange(event, index)}
                onVariableKeyChange={(event) => handleVariableKeyChange(event, index)}
              />
            ))
          }
        </>
      }
    </>
  );
}