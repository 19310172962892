import {useState, useEffect, useRef} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import EMPLOYEES from 'consts/employees.consts';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoTable from 'design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoCellDataStatus from 'design/design_components/neo/data/NeoCellDataStatus.base';
import NeoInputTextFilter from 'design/design_components/neo/table/NeoInputTextFilter.base';
import NeoDateRangeFilter from 'design/design_components/neo/table/NeoDateRangeFilter.base';
import NeoMultiSelectFilter from 'design/design_components/neo/table/NeoMultiSelectFilter.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import EmployeesService from 'services/employees.service';
import employeesUtil from 'utils/employees.util';
import dateTimeUtil from 'utils/dateTime.util';

const rolesOptions =
  Object.entries(EMPLOYEES.ROLES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({label, value}));

const generalStatusOptions =
  Object.entries(EMPLOYEES.GENERAL_STATUSES)
    .map(([value, label]) => [label, value])
    .sort()
    .map(([label, value]) => ({label, value}));

export default function EmployeesList(props) {
  const tableRef = useRef();
  const [employees, setEmployees] = useState([]);
  const asyncState = useAsyncState({isInitialized: true});

  useEffect(async () => await reboot(), []);
  useEffect(async () => await registeredEmployeesChange(), [props.registeredEmployees])

  const reboot = async () => {
    await asyncState.allPromises(
      [getEmployees()]
    );
  }

  const registeredEmployeesChange = async () => {
    if(props.registeredEmployees > 0) {
      await reboot();
      props.onReboot(0);
    }
  }

  const getEmployees = async () => {
    const response = await EmployeesService.getEmployees();
    if(response.success) {
      setEmployees(response.payload);
      return {success: true};
    }
    return {success: false};
  }

  const elements = {
    emailFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='email' placeholder='Buscar por correo electrónico'/>
    ),
    phoneFilterInput: (
      <NeoInputTextFilter ref={tableRef} field='phone' placeholder='Buscar por teléfono'/>
    ),
    createdAtFilterRange: (
      <NeoDateRangeFilter ref={tableRef} field='createdAt' placeholder='Rango de fechas' matchFilter='custom'/>
    ),
    rolesFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={rolesOptions}
        field='role'
        placeholder='Todos'
        selectedItemsLabel='{0} roles seleccionados'
      />
    ),
    generalStatusFilterMultiSelect: (
      <NeoMultiSelectFilter
        ref={tableRef}
        options={generalStatusOptions}
        field='generalStatus'
        placeholder='Todos'
        selectedItemsLabel='{0} estatus seleccionados'
      />
    ),
    createdAtColumnBody: (data) => (
      <>{dateTimeUtil.getDateString(data.createdAt, {dateStyle: 'medium', timeStyle: 'short'})}</>
    ),
    roleColumnBody: (data) => (
      <>{employeesUtil.getEmployeeRole(data.role)}</>
    ),
    generalStatusColumnBody: (data) => (
      data.generalStatus === 'ENABLED' 
        ? <NeoCellDataStatus status='on' label='Activo' circle={true}/>
        : <NeoCellDataStatus status='off' label='Inactivo' circle={true}/>
    )
  };

  return (
    <>
      {
        (asyncState.isLoading) &&
        <NeoCard>
          <InternalSpinner />
        </NeoCard>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isSuccessfully) &&
            <>
              <NeoTable
                ref={tableRef}
                value={employees}
                paginator
                rows={10}
                removableSort
                sortField='createdAt'
                sortOrder={-1}
                emptyMessage='No hay empleados'
              >
                <NeoTableColumn
                  field='email'
                  header='Correo electrónico'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.emailFilterInput}
                  style={{width: '35%'}}
                />
                <NeoTableColumn
                  field='createdAt'
                  header='Fecha de creación'
                  filter
                  sortable
                  filterMatchMode='contains'
                  filterElement={elements.createdAtFilterRange}
                  filterFunction={dateTimeUtil.filterDate}
                  body={elements.createdAtColumnBody}
                  style={{width: '25%'}}
                />
                <NeoTableColumn
                  field='role'
                  header='Rol'
                  filter
                  sortable
                  filterMatchMode='custom'
                  filterElement={elements.rolesFilterMultiSelect}
                  body={elements.roleColumnBody}
                  style={{width: '20%'}}
                />
                <NeoTableColumn
                  field='generalStatus'
                  header='Estatus'
                  filter
                  sortable
                  filterMatchMode='custom'
                  filterElement={elements.generalStatusFilterMultiSelect}
                  body={elements.generalStatusColumnBody}
                  style={{width: '20%'}}
                />
              </NeoTable>
            </>
          }
          {
            (!asyncState.isSuccessfully) &&
            <NeoCard>
              <DataFetchError 
                internal 
                onRetry={reboot}
                title='No se pudieron cargar los empleados'
              />
            </NeoCard>
          }
        </>
      }
    </>
  );
}