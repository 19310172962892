import { useState, useEffect } from 'react';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import NeoCalendar from 'design/design_components/neo/form/NeoCalendar.base';
import ActionDialog from 'components/ActionDialog.component';
import dateTimeUtil from 'utils/dateTime.util';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';

const minDate = new Date('2022-01-01 00:00');
const maxDate = new Date(`${dateTimeUtil.getCurrentYear()}-12-31 23:59`);

const periodTypesOptions = [
  { label: 'Mes', value: 'MONTH' },
  { label: 'Año', value: 'YEAR' },
  { label: 'Personalizado', value: 'CUSTOM' }
];

const yearsOptions = Array(dateTimeUtil.getCurrentYear() - 2022 + 1)
  .fill(0)
  .map((value, index) => ({label: `${index + 2022}`, value: index + 2022}));

export default function PeriodFilterDialog(props) {
  const [selectedPeriodType, setSelectedPeriodType] = useState();
  const [filterPayload, setFilterPayload] = useState({});

  useEffect(() => reboot(), [props.value]);

  const reboot = () => {
    if (props.value && !props.value.period && !props.value.label) {
      const { type, payload } = getPropsValues();
      handleChangeFilter({ type, payload });
    }
  }

  const getPropsValues = () => {
    const { type, payload } = props.value;
    setSelectedPeriodType(type);
    setFilterPayload(payload);
    return { type, payload };
  }

  const handleChangeFilter = (options = {}) => {
    const type = options.type ?? selectedPeriodType;
    const payload = options.payload ?? filterPayload;
    const label = getFilterLabel(type, payload);
    const period = getFilterPeriod(type, payload);
    props.onChange({ type, payload, label, period });
  }

  const getFilterLabel = (type, payload) => {
    if (type == 'MONTH') {
      const { month } = payload;
      return `${dateTimeUtil.monthNumberToString(month.getMonth() + 1)} ${month.getFullYear()}`;
    }
    if (type == 'YEAR') {
      return `${payload.year}`;
    }
    if (type == 'CUSTOM') {
      const fromDay = payload.from.getDate();
      const fromMonth = payload.from.getMonth() + 1;
      const toDay = payload.to.getDate();
      const toMonth = payload.to.getMonth() + 1;
      const from = `${fromDay < 10 ? '0' : ''}${fromDay}/${fromMonth < 10 ? '0' : ''}${fromMonth}/${payload.from.getFullYear()}`;
      const to = `${toDay < 10 ? '0' : ''}${toDay}/${toMonth < 10 ? '0' : ''}${toMonth}/${payload.to.getFullYear()}`;
      return `${from} - ${to}`;
    }
    return 'Desconocido';
  }

  const getFilterPeriod = (type, payload) => {
    if (type == 'MONTH') {
      const fromMonth = payload.month.getMonth() + 1;
      const fromYear = payload.month.getFullYear();
      const toMonth = fromMonth < 12 ? fromMonth + 1 : 1;
      const toYear = fromMonth < 12 ? fromYear : fromYear + 1;
      const toDate = new Date(new Date(`${toYear}-${toMonth}-1 00:00`).getTime() - 60000);
      const toDay = toDate.getDate();
      const from = `1/${fromMonth}/${fromYear}`;
      const to = `${toDay}/${fromMonth}/${fromYear}`;
      return { from, to };
    }
    if (type == 'YEAR') {
      const { year } = payload;
      const from = `1/1/${year}`;
      const to = `31/12/${year}`;
      return { from, to };
    }
    if (type == 'CUSTOM') {
      const from = `${payload.from.getDate()}/${payload.from.getMonth() + 1}/${payload.from.getFullYear()}`;
      const to = `${payload.to.getDate()}/${payload.to.getMonth() + 1}/${payload.to.getFullYear()}`;
      return { from, to };
    }
  }

  const isValidFilter = () => {
    if (selectedPeriodType == 'MONTH') {
      if (!filterPayload.month) {
        return false;
      }
    }
    if (selectedPeriodType == 'YEAR') {
      if (!filterPayload.year) {
        return false;
      }
    }
    if (selectedPeriodType == 'CUSTOM') {
      if (!filterPayload.from || !filterPayload.to) {
        return false;
      }
      if (filterPayload.from > filterPayload.to) {
        return false;
      }
    }
    return true;
  }

  const isDifferentFilter = () => {
    if (props.value) {
      const { type, payload } = props.value;
      if (selectedPeriodType == type) {
        for (const [key, value] of Object.entries(payload)) {
          const date = filterPayload[key];
          if (date && date.toString() != value.toString()) {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  }

  const handleDateFilterTypeDropdownChange = (event) => {
    const selectedDateFilterType = event.value ?? event;
    setSelectedPeriodType(selectedDateFilterType);
    setFilterPayload({});
  }

  return (
    <ActionDialog
      header='Filtrar por periodo de tiempo'
      acceptLabel='Aplicar'
      closable={true}
      cancel={false}
      dismissableMask={true}
      visible={props.visible}
      visibleSetter={props.visibleSetter}
      acceptDisabled={!isValidFilter() || !isDifferentFilter()}
      onCancel={() => getPropsValues()}
      onAccept={() => handleChangeFilter()}
    >
      <NeoDropdown
        label='Tipo de periodo'
        value={selectedPeriodType}
        options={periodTypesOptions}
        onChange={handleDateFilterTypeDropdownChange}
      />
      {
        (selectedPeriodType == 'MONTH') &&
        <NeoCalendar
          label='Mes'
          value={filterPayload.month}
          view='month'
          dateFormat='MM yy'
          yearNavigator={true}
          yearRange={`2022:${dateTimeUtil.getCurrentYear()}`}
          onChange={(event) => setFilterPayload({ month: event.value })}
        />
      }
      {
        (selectedPeriodType == 'YEAR') &&
        <NeoDropdown
          label='Año'
          value={filterPayload.year}
          options={yearsOptions}
          onChange={(event) => setFilterPayload({ year: event.value })}
        />
      }
      {
        (selectedPeriodType == 'CUSTOM') &&
        <>
          <NeoGridContainer subExtra="m-0">
            <NeoCalendar
              col="6"
              label='Desde'
              minDate={minDate}
              maxDate={maxDate}
              value={filterPayload.from}
              dateFormat='dd MM yy'
              monthNavigator={true}
              yearNavigator={true}
              yearRange={`2022:${dateTimeUtil.getCurrentYear()}`}
              onChange={(event) => setFilterPayload({ ...filterPayload, from: event.value })}
            />
            <NeoCalendar
              col="6"
              label='Hasta'
              minDate={minDate}
              maxDate={maxDate}
              value={filterPayload.to}
              dateFormat='dd MM yy'
              monthNavigator={true}
              yearNavigator={true}
              yearRange={`2022:${dateTimeUtil.getCurrentYear()}`}
              onChange={(event) => setFilterPayload({ ...filterPayload, to: event.value })}
            />
          </NeoGridContainer>
        </>
      }
    </ActionDialog>
  );
}