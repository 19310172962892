import {useState, useEffect} from 'react';
import useAsyncState from 'hooks/useAsyncState.hook';
import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoDropdown from 'design/design_components/neo/form/NeoDropdown.base';
import InternalSpinner from 'components/InternalSpinner.component';
import DataFetchError from 'components/DataFetchError.component';
import EmployeesService from 'services/employees.service';

export default function StartConversationLine(props) {
  const asyncState = useAsyncState();
  const [lines, setLines] = useState([]);

  useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await asyncState.allPromises(
      [getLines()],
      {initialization: true}
    );
  }

  const getLines = async () => {
    const response = await EmployeesService.getEmployeeLines();
    if(response.success) {
      const lines = response.payload.map((line) => ({id: line.id, name: line.name, phone: line.phone}));
      const selectedLine = getSelectedLine(lines);
      setLines(lines);
      props.onLineIdChange(selectedLine);
      return {success: true};
    }
    return {success: false};
  }

  const getSelectedLine = (lines) => {
    for(const line of lines) {
      if(line.id === props.lineId) {
        return props.lineId;
      }
    }
    return lines[0]?.id;
  }

  const handleLineDropdownChange = (event) => {
    const value = event.value;
    props.onLineIdChange(value);
  }

  return (
    <NeoCard>
      {
        (asyncState.isLoading) &&
        <InternalSpinner/>
      }
      {
        (!asyncState.isLoading) &&
        <>
          {
            (asyncState.isInitialized) &&
            <NeoDropdown
              md='6'
              label='Línea de teléfono'
              value={props.lineId}
              options={lines.map((line) => ({label: `${line.name} | ${line.phone}`, value: line.id}))}
              onChange={handleLineDropdownChange}
            />
          }
          {
            (!asyncState.isInitialized) &&
            <DataFetchError 
              internal 
              title='No se pudieron cargar las líneas'
              onRetry={initialize}
            />
          }
        </>
      }
    </NeoCard>
  );
}