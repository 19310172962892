import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMessages from 'hooks/useMessages.hook';
import useStateParams from 'hooks/useStateParams.hook';
import MENU from 'consts/menu.consts';
import TOAST from 'consts/toast.consts';
import NeoTitleSecond from 'design/design_components/neo/title/NeoTitleSecond.base';
import NeoButtonMain from 'design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import TemplatePreviewWithVariablesForm from 'views/templates/components/TemplatePreviewWithVariablesForm.component';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import ActionDialog from 'components/ActionDialog.component';
import Icono from 'design/assets/img/cta/icons/icon-template.svg';
import TemplatesService from 'services/templates.service';
import NeoButtonSection from 'design/design_components/neo/layout/NeoButtonSection.base';
import useComponent from 'hooks/useComponent.hook';

export default function TemplatePreview() {
  const history = useHistory();
  const messages = useMessages();
  const [state] = useStateParams();
  const component = useComponent({
    state: {template: null, exampleVariablesIsValid: false}
  });
  const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState(false);

  useEffect(() => initialize(), []);

  const initialize = () => {
    if (state.line && state.template) {
      component.setState(_state => {_state.template = state.template});
    }
    else {
      history.push(MENU.ADMIN.TEMPLATES.ROOT.PATH);
    }
  }

  const handleTemplateHeaderVariablesFormChange = ({name, value}) => {
    component.setState((state) => {
      state.template.structure.examples = state.template.structure?.examples ?? {};
      state.template.structure.examples.header = {};
      const nameParameter = name.match(/[_0-9a-zA-Z]+/);
      state.template.structure.examples.header[nameParameter] = value;
    })
  }

  const handleTemplateBodyVariablesFormChange = (event) => {
    component.setState((state) => {
      state.template.structure.examples = state.template.structure?.examples ?? {};
      state.template.structure.examples.body = {};
      event.forEach(({name, value}) => {
        const nameParameter = name.match(/[_0-9a-zA-Z]+/);
        state.template.structure.examples.body[nameParameter] = value;
      });
    })
  }

  const handleTemplateButtonsVariablesFormChange = (event) => {
    component.setState((state) => {
      state.template.structure.examples = state.template.structure?.examples ?? {};
      state.template.structure.examples.buttons = {};
      event.forEach(({name, value}, index) => {
        const nameParameter = name.match(/[_0-9a-zA-Z]+/);
        const param = {};
        param[nameParameter] = value;
        state.template.structure.examples.buttons[index+1] = param;
      });
    })
  }

  const handleCreateTemplateDialogAccept = async () => {
    const lineId = state.line.id;
    const response = await TemplatesService.createTemplate(lineId, component.state.template);
    return { success: response.success };
  }

  const handleCreateTemplateDialogHide = (event) => {
    if (event.action == 'accept') {
      if (event.payload.success) {
        history.push(`${MENU.ADMIN.TEMPLATES.ROOT.PATH}?line=${state.line.id}`);
        messages.showToast(
          TOAST.SEVERITY.SUCCESS,
          'Operación exitosa',
          'Plantilla enviada a aprobación con el proveedor.'
        );
      }
      else {
        messages.showToast(
          TOAST.SEVERITY.ERROR,
          'Algo salió mal',
          'No se pudo crear la plantilla, inténtalo de nuevo.'
        );
      }
    }
  }

  return (
    <>
      {
        (component.state.template) &&
        <>
          <NeoTitleSecond
            icon={Icono}
            title={`${component.state.template.name} (${component.state.template.language})`}
            subtitle={`${state.line.name} | ${state.line.phone}`}
          />
          <NeoGridContainer>
            <TemplatePreviewWithVariablesForm 
              template={JSON.parse(JSON.stringify(component.state.template))} 
              handleTemplateHeaderVariablesFormChange={handleTemplateHeaderVariablesFormChange}
              handleTemplateBodyVariablesFormChange={handleTemplateBodyVariablesFormChange}
              handleTemplateButtonsVariablesFormChange={handleTemplateButtonsVariablesFormChange}
              onChangeValidate={(isValid) => {
                component.setState((state) => {
                  state.exampleVariablesIsValid = isValid;
                })
              }}
            />
          </NeoGridContainer>
          <NeoButtonSection align='right' >
            <NeoButtonOutlined
              label='Cancelar'
              onClick={() => history.goBack()}
            />
            <NeoButtonMain
              label='Crear plantilla'
              onClick={() => setShowCreateTemplateDialog(true)}
              disabled={!component.state.exampleVariablesIsValid}
            />
          </NeoButtonSection>
          {
            <ActionDialog
              header='Crear plantilla'
              visible={showCreateTemplateDialog}
              visibleSetter={setShowCreateTemplateDialog}
              acceptMessage='Creando plantilla'
              onAccept={handleCreateTemplateDialogAccept}
              onHide={handleCreateTemplateDialogHide}
            >
              Los valores que proporciones en las variables serán los que Facebook utilizara para determinar la aprobación de la plantilla. ¿Deseas proceder?
            </ActionDialog>
          }
        </>
      }
    </>
  );
}