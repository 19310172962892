import React from "react";
import { Card } from "primereact/card";


const BaseCardInner = (props) => {
  return (<>
    <Card {...props}
    className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
      {props.children}
    </Card>
  </>);
};

export default BaseCardInner;