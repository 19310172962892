import NeoCard from 'design/design_components/neo/panel/NeoCard.base';
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from 'design/design_components/neo/layout/NeoGridContainer.base';
import TemplatePreview from 'components/TemplatePreview.component';
import TemplateMapping from 'views/campaigns/components/TemplateMapping.component';

export default function DatabaseTemplateMapping(props) {
  return (
    <NeoGridContainer>
      <NeoColumn md='6'>
        <TemplateMapping
          mapping={props.mapping ?? {}}
          template={props.template}
          databaseType={props.databaseType}
          mappingOptions={props.mappingOptions}
          onMappingChange={(event) => props.onMappingChange(event)}
          onMappingValidationChange={(event) => props.onMappingValidationChange(event)}
        />
      </NeoColumn>
      <NeoColumn md='6'>
        <NeoCard>
          <TemplatePreview
            title='Previsualización de plantilla'
            template={{structure: props.template.structure}}
            extra="template-mapping-template-preview"
          />
        </NeoCard>
      </NeoColumn>
    </NeoGridContainer>
  );
}